import React from "react";
// more core import statements
import {
  Button,
  Typography,
  Theme,
  } from "@material-ui/core";
import { createTheme, ThemeProvider,withStyles,createStyles } from "@material-ui/core/styles";

// Customizable Area Start

// custom import statements
import EmailAccountLoginControllerBlock, {
  LoginProps,
} from "./EmailAccountLoginController.web";

// Merge Engine - import assets - Start
import { localSearchLogo, advertiseOneLogo,buttonLogo,landingPageImage,landingPageImagembl } from "./assets";
// Merge Engine - import assets - End


// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End

// Customizable Area End
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export  class EmailAccountLoginBlock extends EmailAccountLoginControllerBlock {
  constructor(props: LoginProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

   
  render() {
    const {classes} = this.props;
    return (
      // Customizable Area Start
      // Merge Engine - render - Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div className={classes.mainContainer} style={{}} >
          <div className={classes.logoContainer}>
            <div className={classes.localsearch}>
             <img className={classes.localsearchLogo} src={localSearchLogo} alt="localseach logo"  />
             </div>
             <div>
             <img className={classes.localsearchLogos} src={advertiseOneLogo} alt="advertise on logo" style={{height:'46px',width:'182px'}}  />
             </div>
          </div>
          <div className={classes.loginContainer}>
          <div className={classes.welcomeBox}>
                <Typography variant="body1" className={classes.heading}>{this.state.select_lang.name[0]} !</Typography>
                <Typography variant="body1" className={classes.subHeading} >{this.state.select_lang.name[1]}</Typography>
                <Button 
                className={classes.loginButton}
                data-test-id={"azure-ad-login-btn"}
                onClick={this.azureSAMLLogin}
                >
                <img src={buttonLogo} alt="microsoft logo" className={classes.btnLogo}/>
                <Typography className={classes.btnText}>&nbsp;{this.state.select_lang.name[2]}</Typography>
                </Button>
          </div>
          </div>
        </div>   
      </ThemeProvider>
      // Merge Engine - render - End
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle = (theme:Theme)=>createStyles({
  // custom style definitions
  mainContainer:{
  width:"100%",
  height:"100%",
  background:"#c3dbdf",
  "text-align":"center",
  backgroundRepeat:"no-repeat",
  backgroundPosition: "bottom",
  backgroundSize: "contain",
  backgroundImage:`url(${landingPageImage})`,
  boxSizing:"border-box",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: `url('${landingPageImagembl}')`, // image for screens below 600px
  },
  '@media (max-width:500px)':{
    backgroundImage:`url('${landingPageImagembl}')`
  }
  },

  logoContainer:{ 
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center",
    padding: "36px 58px 0px 70px",
    boxSizing:"border-box",
   '@media (max-width:960px)':{
    padding: "36px 58px 0px 70px",
   },
   '@media (max-width:500px)':{
    padding: "64px 58px 0px 67px",
    display:'flex',
    flexDirection:'column',
    gap:'13px',
   },
   '@media (max-width:325px)':{
    padding: "14px 30px 0px 30px !important",
   }
  },
  localsearchLogo:{
    height:'52px',
    width:'235px',
    [theme.breakpoints.down("sm")]:{
      width:"80%"
    },
    '@media(max-width:500px)':{
      height:'57px',
      width:'256px'
    }
  },
  localsearchLogos:{
    [theme.breakpoints.down("sm")]:{
      width:"80%"
    },
    '@media(max-width:500px)':{
      height:'57px !important',
      width:'256px !important'
    }
  },
  headerLogo:{
    maxWidth:"23%",
    "& img":{
      width:"100%"
    }
  },
  loginContainer:{
    boxSizing:"border-box",
    margin: "0 auto",
    maxWidth: "448px",
    position: "absolute",
    right: "0",
    left: "0",
    top: "25%"
  },
  welcomeBox:{
    "text-align":"center",
  },
  heading:{
    marginTop:"8.75rem",
    fontFamily: "Panton Narrow Bold",
    fontSize: "30px",
    "font-weight": "700",
    "text-align": "center",
    color: "#0F172A",
    '@media(max-width:1450px)':{
      marginTop:"6rem",
    },
    '@media(max-width:1370px)':{
      marginTop:"1.75rem",
    },
    '@media(max-width:500px)':{
      marginTop:"85px",
    },
    '@media(max-width:355px)':{
      marginTop:"15px",
    },
  },
  btnText:{
    fontFamily: "Panton Narrow Bold",
    marginLeft:'10px',
    color:"#003A5C",
    fontSize: "20px",
    "font-weight": "700",
    "text-transform":"none",
    "&>p":{
      fontFamily: "Panton",
    }
  },
  btnLogo:{
    
  },
  subHeading:{
    fontFamily: "Panton Narrow Regular",
    fontSize: "24px",
    "font-weight": "400",
    "text-align": "center",
    color:" #0F172A",
    marginTop:"6px",
    lineHeight:"32px",
    '@media (max-width:500px)':{
      marginTop:'1px'
    }
  },
  loginButton:{
    width:"100%",
    marginTop:"64px",
    padding: "14px 0px 13.0074462890625px 0px",
    borderRadius: "56px",
    background: "#FFFFFF",
    "&:hover":{
      backgroundColor:"white",
    },
    "&:focus":{
      backgroundColor:"white"
    },
    '@media(max-width:500px)':{
      width:'73%',
    },
    '@media(max-width:325px)':{
      marginTop:"34px",
    },
  },
})
// Customizable Area End
export default withStyles(useStyle)(EmailAccountLoginBlock as any) as any
