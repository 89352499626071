import React from "react";
// Customizable Area Start
import { Button } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import ProjectNotesController, {
  Props,
  configJSON,
  ProjectNote,
} from "./ProjectNotesController.web";
import ProjectNoteModal from "../../../components/src/common/Modal/ProjectNoteModal";
import NotesCard from "../../../components/src/common/Card/NoteCard";
// Customizable Area End

export class ProjectNotes extends ProjectNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { projectNotes, projectNoteDetail } = this.state;
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <div className={classes.projectNotesContainer}>
        {projectNotes &&
          projectNotes.map((notes: ProjectNote) => (
            <NotesCard
              data-test-id="project-note-card"
              key={`projectNote-${notes.id}`}
              noteDetails={notes}
              handleModal={this.handleModal}
              setEditNoteValue={this.setEditNotevalue}
              deleteNote={this.deleteNoteFromProject}
            />
          ))}

        <div className={classes.projectNotesContainer}>
          <Button
            data-test-id={"add-note-modal-button"}
            className={classes.addNoteBtn}
            variant="contained"
            color="secondary"
            onClick={this.handleModal}
          >
            Add Note
          </Button>
        </div>
        <ProjectNoteModal
          isOpen={this.state.isOpen}
          closeModal={this.handleCloseModal}
          noteDetail={projectNoteDetail}
          createNote={this.createNoteForProject}
          editNote={this.updateProjectNote}
          data-test-id="project-note-modal"
        />
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

export const useStyle = {
  projectNotesContainer: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
  },
  addNoteBtn: {
    width: "350px",
    "text-transform": "none",
    backgroundColor: "yellow",
    padding: "10px",
    borderRadious: "10px",
    "&:hover": {
      backgroundColor: "yellow",
    },
    "& .MuiButton-label": {
      fontSize: "large",
      fontWeight: "600",
      color: "white",
    },
  },
};

export default withStyles(useStyle)(ProjectNotes as any) as any;
