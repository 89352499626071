import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Card,
  Zoom,
  Tooltip,
  CardContent,
  CardMedia
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withRouter } from "react-router-dom";
import {imgInfos} from './assets';
import { createTheme, ThemeProvider, withStyles,
  createStyles, } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    },
  }
});
const contentManagementWhiteOnBlackTooltip = withStyles((theme) => ({
  tooltip: {
    color: "black",
    // left: "40px",
    height: "auto",
    padding: "15px 20px 15px 20px",
    marginTop: "20px",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    maxWidth: "231px",
    borderRadius: "12px",
    borderTopRightRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    position: "relative",
    
  },
  arrow: {
    content: '""',
    color:"#E9E9E9"
  },
}))(Tooltip);

// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";

export class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const WhiteOnBlackTooltipView = contentManagementWhiteOnBlackTooltip as any;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={webStyles.mainContainer}>
         {/* <Card style={this.state.contentData?.imageURL != "" ? webStyles.contentRootImage : webStyles.contentRoot } variant="outlined"> */}
         <Card style={webStyles.contentRoot } variant="outlined">
          <div style={{display:"flex", flexDirection:"row", marginTop:'10px', marginBottom:"10px"}}>
            <Typography style={webStyles.contentTitle}>
              Explanation
            </Typography>
            <WhiteOnBlackTooltipView
                              TransitionComponent={Zoom}
                              title="Lörem ipsum toneda hyposkapet divide tifåsk pronärera de putyling."
                              arrow
                              // open={true}
                              placement="bottom-start"
                            >
                              <img
                                src={imgInfos}
                                style={{height:'12px', width:"12px", marginLeft: "5px",flexShrink: 0, alignSelf:"center" }}
                                alt="Info"
                              />
                            </WhiteOnBlackTooltipView>
          </div>
          {this.state.contentData?.imageURL != "" &&
        <CardMedia
        style={webStyles.contentMedia}
        image= {this.state.contentData.imageURL}
        />
    }
      <CardContent style={webStyles.cardContentRoot}>
        {this.state.readMoreVisibility ?  <Typography style={webStyles.description}> 
      {this.state.isReadMore ?  this.state.contentData?.description.slice(0, 150) : this.state.contentData.description} 
      <span data-test-id="btnReadMoreLess" onClick={this.toggleReadMore} style={webStyles.readOrHide}> 
        {this.state.isReadMore ? "...read more" : " show less"} 
      </span> 
    </Typography> : <Typography style={webStyles.description}> 
     {this.state.contentData.description}
    </Typography>
  } 
      </CardContent>
         </Card>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
  const webStyles ={
    mainContainer:{flex:1},
    contentRoot: {
      // flex:1,
      height: "auto" ,
      borderRadius: "24px",
      backgroundColor:"#FFF",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
      padding: 15
    },
    contentRootImage:{
      flex:1,
      height: "40%" ,
      borderRadius: "24px",
      backgroundColor:"#FFF",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
      padding: 15
    },
    contentTitle: {
      fontSize: "16px",
      color:"#000",
      //fontStyle:"normal",
      fontWeight: 400,
      marginLeft:"10px",
      fontFamily: "Panton Narrow Regular",
    },
    description:{
      fontSize: "16px",
      color:"#000",
      fontStyle:"normal",
      fontWeight: 400,
      fontFamily: "Panton"
    },
    cardContentRoot:{
      // overflowY:"scroll",
      height:"100%",
      maxHeight:"120px"
    } as const,
    contentMedia: {
      width: "100%",
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    readOrHide:{
      color:"#005E6E",
      fontSize: "16px",
      fontStyle:"normal",
      fontWeight: 400,
      fontFamily: "Panton"
    },
    avatar:{height:"12px", width:"12px", alignSelf:"center", marginLeft:"10px"}
  }
export default withRouter(ContentManagement as any) as any;
// Customizable Area End