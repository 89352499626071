     // Customizable Area Start
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
export const testNavi = (props:any) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationPayLoadMessage),{callApi: true});
    navigation.addData(getName(MessageEnum.NavigationPropsMessage),props);
    return navigation;
} 


           // Customizable Area End