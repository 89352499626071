import React from "react";
// Customizable Area Start
import {
  Theme,
  Box,
  Typography,
  Slider,
  Tooltip,
  Button,
  Zoom,
  Modal
} from "@material-ui/core";

import {
  withStyles,
  createStyles,
  ThemeProvider,
  createTheme,
  styled
} from "@material-ui/core/styles";
import BudgetController, {
  Props
} from "./BudgetController.web";
import Sidebar from "../../../components/src/SideBar.web";
import { withRouter } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    },
  }
});



const budgetWhiteOnBlackTooltip = withStyles((theme) => ({
  tooltip: {
    color: "black",
    height: "auto",
    padding: "15px 20px 15px 20px",
    marginTop: "20px",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    maxWidth: "231px",
    borderRadius: "12px",
    borderTopRightRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    position: "relative",

  },
  arrow: {
    content: '""',
    color: "#E9E9E9"
  },
}))(Tooltip);

import {
  info, cancelImage
} from "./assets";

const PrettoSlider = withStyles({
  root: {
    height: "35px",
    borderRadius: "55px",
  },
  thumb: {
    width: "36px",
    height: "36px",
    marginTop: "-1px",
    flexShrink: 0,
    marginLeft: "-5px", 
    transform: "translateX(-50%)",
    strokeWidth: "5px",
    backgroundColor: '#fff',
    border: '3px solid #285D6D',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {
    color: '#285D6D',
  },
  valueLabel: {
    // left: 'calc(-50% + 4px)',
  },
  track: {
    height: "35px",
    borderRadius: "5px",
    color: '#285D6D',
    borderTopLeftRadius: "55px",
    borderBottomLeftRadius: "55px",
    borderTopRightRadius: "55px",
    borderBottomRightRadius: "55px",
  },
  rail: {
    height: "35px",
    borderRadius: "55px",
    background: 'rgba(255, 255, 255, 0.8)',
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.6)",
  },
})(Slider);

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#005E6E',
    fontWeight: 700,
    fontSize: '14px',
  },
}))(Tooltip);
interface ValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: ValueLabelProps) {
  const { children, open, value } = props;

  return (
    <CustomTooltip open={open} enterTouchDelay={0} placement="bottom" title={`${value} CHF`}>
      {children}
    </CustomTooltip >
  );
}
// Customizable Area End

export class Budget extends BudgetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const BoxView = Box as any;
    const PrettoSliderView = PrettoSlider as any;
    const WhiteOnBlackTooltipView = budgetWhiteOnBlackTooltip as any;
    const maxCharacters = 202;
    const truncatedText = this.state.budgetData?.body ? this.state.budgetData.body.substring(0, maxCharacters) : '';
    const shouldShowReadMore = this.state.budgetData?.body && this.state.budgetData.body.length > maxCharacters;
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Sidebar navigation={this.props.navigation} activePath={""} onDashboardSave={this.onDashboardSave}>
          <BoxView className={classes.mainBudgetContainerBox}>
            <div className={classes.budgetRoot} >
              <div className={classes.budgetInnerRoot}>
                <Typography className={classes.typoBudget}>
                  {this.state?.budgetHeadingTooltip?.data?.name}
                  <WhiteOnBlackTooltipView
                    TransitionComponent={Zoom}
                    title={this.state?.budgetHeadingTooltip?.data?.description}
                    arrow
                    // open={true}
                    placement="bottom-start"
                    open={this.state.tooltipOpenBudgetHeadingTooltip}
                    onClose={this.handleTooltipCloseChanelsDatas}
                  >
                    <img
                    data-test-id='handleHeading'
                      src={info}
                      style={{ marginLeft: "15px", width: '17px', position: 'relative' }}
                      alt="Info"
                      onMouseLeave={()=>this.handleImageImageMouseOver()}
                      onMouseEnter={()=>this.handleImageImageMouseHover()}
                       onClick={() => this.handleMouse()}
                    />
                  </WhiteOnBlackTooltipView>
                </Typography>
              </div>
              <div className={classes.CHFRoot}>
                <Typography className={classes.typoCHF}>CHF</Typography>
                <Typography className={classes.typoPrice}>
                  {this.state.marks.length > 0 &&
                    (this.state.marks[0].value === this.state.marks[1].value
                      ? this.state.marks[1].value
                      : this.state.selectedBudget)}
                </Typography>
              </div>

              {this.state.marks.length > 0 && (
                <div className={classes.rangeMainRoot}>
                  {this.state.marks[0].value !== this.state.marks[1].value ? (
                    <PrettoSliderView
                      data-test-id="budgetSlider"
                      valueLabelDisplay="auto"
                      ValueLabelComponent={ValueLabelComponent}
                      aria-label="ios slider"
                      defaultValue={this.state.selectedBudget}
                      min={this.state.marks[0].value}
                      max={this.state.marks[1].value}
                      disabled={this.state.viewData}
                      className={this.state.viewData && classes.viewDataClass}
                      step={1}
                      onChange={(event: any, value: any) => this.onSliderChange(value)}
                    />
                  ) : null}
                  {this.state.marks[0].value !== this.state.marks[1].value && (
                    <div className={classes.rangeRoot}>
                      <Typography className={classes.typoLeftRange}>{this.state.marks[0].value}<span style={{ marginLeft: 4 }}>CHF</span></Typography>
                      <Typography className={classes.typoRightRange}>{this.state.marks[1].value}<span style={{ marginLeft: 4 }}>CHF</span></Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={classes.sideContainerBox}>
              <div className={classes.innerSideContainerBox} >
                <div className={classes.subBox}>
                  <Typography style={webStyle.title}>
                    <span>
                      {this.state.budgetData?.heading}
                    </span>
                    <span>
                      <WhiteOnBlackTooltipView
                        arrow={true}
                        TransitionComponent={Zoom}
                        placement="bottom-start"
                        title={this.state.budgetData?.tooltip}
                        open={this.state.tooltipOpenBudgetDataTooltip}
                        onClose={this.handleTooltipCloseBudgetData}
                      >
                        <img src={info}
                         alt="Info"
                          style={{ height: "12px", width: "12px" }} 
                          data-test-id='handleExplain'
                          onMouseLeave={()=>this.handleExplaniBUdgetMouseOver()}
                          onMouseEnter={()=>this.handleExplaniBUdgetMouseHover()}
                           onClick={() => this.handleExplaniBUdget()}
                          />
                      </WhiteOnBlackTooltipView>
                    </span>
                  </Typography>
                  <img className={classes.img} src={this.state.budgetData?.image_url} />
                  <BoxView testID="readMoreText">
                  <Typography style={{
                    marginTop: "20px",
                    lineHeight: '19.38px',
                    wordBreak: 'break-all',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontFamily: 'panton narrow regular'
                  }} >
                    {truncatedText}{" "}
                    {shouldShowReadMore && (
                      <span className={classes.readMore} onClick={this.toggleModal}>
                        {this.state.budgetData?.read_more}
                      </span>
                    )}
                  </Typography>
                  </BoxView>
                  <Modal
                    open={this.state.isModalOpen}
                    onClose={this.toggleModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{
                      style: {
                        backgroundColor: 'transparent',
                      }
                    }}
                  >
                    <BoxView className={classes.budgetModalStyle} >
                      <div className={classes.budgetModalStyles} >
                        <Typography className={classes.bodyText }>
                          {this.state.budgetData?.body}
                        </Typography>
                        <div className={classes.budgetCancelImageWrapper }>
                          <img src={cancelImage} alt="Close" className={classes.budgetCancelImage }  onClick={this.toggleModal} />
                        </div>
                      </div>
                    </BoxView>
                    
                  </Modal>
                </div>
              </div>
              <BoxView className={classes.budgetButtonBox}>
              <Button variant="contained" data-test-id="Quote_page" className={classes.budgetButton} disabled={this.state.viewData} onClick={this.handleQuote}>
              {this.state.select_lang.name[0]}
                </Button>
              
                <Button variant="contained" className={classes.budgetButton} disabled={this.state.viewData}  data-test-id="Budget_page" onClick={this.handleAddonNav}>
                 {this.state.select_lang.name[1]}
                </Button>
              </BoxView>
            </div>
          </BoxView>
        </Sidebar>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    )
  }
}

const ButtonContainer :any= styled(Button)({
  width: "194px",
  borderRadius: "43px",
  color: "#FFFFFF",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  textTransform: "capitalize" as const,
  "&:hover": { 
    backgroundColor: "#005E6E",
  },
});

export const useStyle = (theme: Theme) =>
  createStyles({
    mainBudgetContainerBox:
    {
      [theme.breakpoints.down("xl")]: {
        //marginTop:"1%"
      },
      [theme.breakpoints.down("lg")]: {
        //marginTop:"2%"
      },
      [theme.breakpoints.down("md")]: {

      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "8%",
        flexDirection: "column",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10%",
        flexDirection: "column",
      },

      display: "flex",
      flex: 1,
      // height:"97.78%",
      height: "calc(100vh - 40px)", 
      marginBottom:'40px',
      boxSizing:"border-box",
      minHeight:'860px',
      padding:"40px 4px 0px 16px",
      gap:"16px",
      
      // minHeight:'890px',
      "@media (max-width: 960px)": {
        padding: "20px",
      },
      "@media (max-width: 500px)": {
        padding: "51px 30px 0px 20px",
      },
      "@media (max-width: 375px)": {
        padding: "51px 12px 0px 12px",
      },
      "@media(min-width:1900px)": {
        gap: "23px",
        paddingLeft: '21px',
      },
    },
    sideContainerBox: {

      width: '30.8%',
      minHeight: '100',

      "@media (max-width: 960px)": {

        width: '100%'
      },
      "@media (max-width: 500px)": {

        width: '102.7%'
      },
      "@media (max-width: 375px)": {

        width: '100%'
      },
    },
    innerSideContainerBox:{
      height: "94.6%",
      
      "@media screen and (width: 1440px) and (height: 900px)": {
        height: "93.7%",
      },
      "@media (max-width: 1380px)": {
        height: "93.5%",
      },

     
      
      [theme.breakpoints.down("md")]:{
       
        },
      [theme.breakpoints.down("sm")]:{
          marginTop:"10px"
          },
      [theme.breakpoints.down("xs")]:{
        marginTop:"10px"
        },
        // '@media(max-width:500px)':{
        //   display:'none',
        // },
    },
    headingContainer: {
      width: "100%",
      marginTop: "32px",
    },
    mainContainer: {
      boxSizing: "border-box",
      padding: "0px 16px",
      // backgroundColor:"#900000"
    },
    leftContainer: {
      width: "100%",
      height: "50%",
      backgroundColor: "#900000"
    },
    root: {
      width: "346px",
      height: "400px"
    },
    title: {
      fontSize: 14,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    mainHeading: {
      fontSize: "36px",
      "font-weight": 700,
      marginLeft: "40px",
      fontFamily: "Panton Narrow Bold",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "25px",
      }
    },
    subHeading: {
      fontSize: "16px",
      "font-weight": 400,
      marginLeft: "40px",
      fontFamily: "Panton Narrow Regular",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "25px",
      }
    },
    dashboardContainer: {
      width: "100%",
      "box-sizing": "border-box",
      marginTop: "24px",
      borderRadius: "32px",
      background: "#FFF",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
      padding: "42px 26px 58px 14px",
    },
    dashboardFilterContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: '18px',
      marginBottom: "40px",
      width: "98%",
      paddingLeft: "14px",
      [theme.breakpoints.down("sm")]: {
        "flex-wrap": "wrap"
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "start",
      }


    },
    toggleAndDateFilter: {
      width: "410px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "space-between"

      }
    },
    filterButton: {
      padding: "12px 24px",
      borderRadius: "48px",
      border: "1px solid #373838",
      "& span": {
        fontFamily: "Panton Narrow Regular",
      },
      [theme.breakpoints.down("lg")]: {
        padding: "8px 16px",
        fontSize: "small",
        textTransform: "none",
      }
    },
    budgetRoot: {

      width: '66.8%',
      flexShrink: 0,
      height: "100%",
      borderRadius: "38px",
      background: "#FFF",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",

      // minHeight:'890px',
      "@media (max-width: 960px)": {

        width: '100%'
      },
      "@media (max-width: 500px)": {
        borderRadius: 'unset',
        boxShadow:'none',
        minHeight:'unset',
        height:"57.5%",
       }
  },
  budgetInnerRoot:{
    padding: "27px 164px 0px 41px",
    '@media(max-width:500px)':{
      padding:'unset',
    },
    budgetInnerRoot: {
      padding: "27px 164px 0px 41px",
      '@media(max-width:500px)': {
        padding: 'unset',
      },

    },
  },
    typoBudget: {
      color: "#000",
      fontFamily: ' Panton Narrow Bold !important',
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: "-0.12px",
      display: 'flex',
      "@media(max-width:500px)": {
        fontSize: "16px !important",
        lineHeight: '26px',
        fontFamily: ' Panton Narrow Regular !important',
        marginLeft: '12px',
      }
    },
    CHFRoot: {
      display: "flex",
      flexDirection: "row",
      width: "128px",
      height: "52px",
      flexShrink: 0,
      borderRadius: "14px",
      border: "1px solid var(--neutrals-cool-gray-500, #B4B4B4)",
      justifyContent: "center",
      marginLeft: "42px",
      marginTop: '48px',
      "@media(max-width:500px)": {
        marginLeft: "0px",
      },
    },
    typoCHF: {
      color: "#000",
      fontFamily: "Panton",
      fontSize: "18px",
      fontStyle: "normal",
      fontSeight: 400,
      alignSelf: "center",
      marginRight: "10px",
      // "lineHeight": "26px", /* 144.444% */
    },
    typoPrice: {
      color: "#000",
      fontFamily: "Panton",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      //    line-height: 32px; /* 133.333% */
      letterSpacing: "-0.12px",
      alignSelf: "center",
    },
    rangeMainRoot: {
      width: '80%',
      marginLeft: "42px",
      marginTop: '15px',
      '@media(max-width:500px)':{
        marginLeft:'unset',
        width:'100%'
      },
      '@media(max-width:414px)':{
        width:'90%'
      },
    },
    viewDataClass:{
"& .MuiSlider-thumb.Mui-disabled":{
 height:"36px",
 width:"36px",
 marginTop:"-1px",
 marginLeft:"-5px"
}
    },
    rangeRoot: {
      display: 'flex',
      flexDirection: "row",
      marginTop: "-20px",
    },
    typoLeftRange: {
      fontSize: "14px",
      color: "#000",
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "Panton",
      flex: 1,
      marginTop: "22px"
    },
    typoRightRange: {
      fontSize: "14px",
      color: "#000",
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "Panton",
      flex: 1,
      textAlign: "right",
      marginTop: "22px",
    },
    actionIcon: {
      "&:hover": {
        cursor: "not-allowed"
      }
    },
    actionIconsHover: {
      color: "white",
      "&:hover": {
        backgroundColor: "inherit"
      }
    },
    campaignStatus: {
      marginRight: "6px"
    },
    profileimage: {
      width: "24px",
      height: "24px",
      borderRadius: "24px",
      marginRight: "8px"
    },
    btnLogo: {
      marginLeft: "10px"
    },
    tableContainer: {
      "box-sizing": "border-box",
      width: "100%",
      height: "68%",
      overflow: "auto",
      paddingRight: "10px",
      "&::-webkit-scrollbar": {
        width: "8px",
        [theme.breakpoints.down("lg")]: {
          width: "5px"
        }
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        borderRadius: "8px",
        backgroundColor: "#CCDFE2",
        [theme.breakpoints.only("lg")]: {
          width: "5px",
        }
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#005E6E",
        borderRadius: "8px",
        width: "6px",
        [theme.breakpoints.down("lg")]: {
          width: "4px"
        }
      },
      [theme.breakpoints.only("lg")]: {
        height: "52%",
        width: "97%"
      },
      [theme.breakpoints.down("md")]: {
        height: "48%"
      },

    },
    tableHeader: {
      fontSize: "20px",
      "font-weight": 700,
      border: "none",
      fontFamily: "Panton Narrow Bold",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px"
      }
    },
    tableRowHoverEffect: {
      "&:hover": {
        backgroundColor: "#005E6E",
        borderRadius: "56px",
        cursor: "pointer",
        "& > .MuiTableCell-body": {
          color: "white"
        }
      }
    },
    tableCell: {
      border: "none",
      fontFamily: "Panton Narrow Regular",
      "&:firstChild": {
        borderRadius: "56px 0 0 56px"
      },
      "&:lastChild": {
        borderRadius: "0 56px 56px 0"
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "small",
        padding: "1px"
      }
    },
    tableRowFirstCell: {
      borderRadius: "56px 0 0 56px",
      fontFamily: "Panton Narrow Regular",
      border: "none",
      [theme.breakpoints.down("lg")]: {
        fontSize: "small",
      }
    },
    tableRowLastCell: {
      borderRadius: "0 56px 56px  0 ",
      padding: "auto 0px",
      marginRight: "10px",
      width: "58px",
      fontFamily: "Panton Narrow Regular",
      border: "none",
      [theme.breakpoints.down("lg")]: {
        fontSize: "small",
        padding: "4px"
      }
    },
    tableScroll: {
      overflow: "auto"
    },
    tableRowDisable: {
      opacity: "0.40",
      backgroundColor: "#FFFFFF",
      cursor: "not-allowed"
    },
    budgetButtonBox: {
      display: "flex",
      justifyContent: "space-around",
      "@media (max-width: 1440px)": {
        gap:"10px",
      },
      "@media (max-width:960px)": {
        paddingBottom:'37px',
      },
      [theme.breakpoints.down("md")]: {

      },
      [theme.breakpoints.down("sm")]: {

      },
      [theme.breakpoints.down("xs")]: {

      },
      alignItems: "center",
    
       "@media (max-width: 500px)": {
        marginTop:'-12px',
         paddingBottom:'37px !important',
        gap:'17px',
        marginBottom:'unset',
       },
    },

    budgetDefaultBUtton: {
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      textTransform: "capitalize" as const,
      fontSize: "16px",
      textDecoration: "none",
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: "176px",
      borderRadius: "43px",
      color: "#353232",
      backgroundColor: "#e0e0e0",
      height: "52px",
      "&:hover": { 
        backgroundColor: "#005E6E",
        color: "#FFFFFF",
      },
    },
    budgetButton: {
      width: "176px",
      borderRadius: "43px",
      color: "#353232",
      backgroundColor: "#e0e0e0",
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      height: "52px",
      fontSize: "100%",
      boxShadow: "none",
      textTransform: "capitalize" as const,
      "&:hover": { 
        backgroundColor: "#005E6E",
        color: "#FFFFFF",
        boxShadow: "none",
      }, 
      '@media(max-width:500px)':{
        width:'176px'
      },
      '@media(max-width:390px)':{
        width:'167px'
      },
      '@media(max-width:350px)':{
        width:'142px'
      }
    },
    subBox: {
      backgroundColor: "#FFFFFF",
      height: "auto",
      boxShadow: "0px 4px 16px 0px #00000040",
      padding: "33px 29.4px 24px 24px",
      borderRadius: "24px",
    },
    title1: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: "Panton Narrow Regular",
      marginBottom: "19px",
      display: "flex",
      alignItems: "center",
    },
    img: {
      width: "100%",
      height: '164px',
    },
    readMore: {
      fontFamily: "Panton Narrow Bold",
      fontSize: "14px",
      fontWeight: 700,
      color: "#005E6E",
      cursor: "pointer"
    },
    budgetModalStyles:{
      display: 'flex',
       justifyContent: 'space-between',
       "@media(max-width:500px)":{
        display: 'unset',
        justifyContent:'unset',
       }
    },
    budgetModalStyle: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: '50%',
      transform: 'translate(-50%, -50%)',
      width: '573px',
      height: 'auto',
      borderRadius: '12px',
      background: '#FFFFFF',
      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
     
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: '80vh',
      '@media(max-width:500px)':{
        width: '290px',
       
        top: '52%',
        overflowY: 'auto',
        
      }
    },
    bodyText: {
      wordBreak: 'break-all',
       flex: '1',
       
      fontFamily: 'panton narrow regular',
      fontSize: '16px',
      lineHeight: '19.38px',
      fontWeight: 400,
      padding:"48px"
      
    },
    budgetCancelImageWrapper: {
      alignSelf: 'flex-start',
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
      position:'absolute',
      top:'18px',
      right:'18px',
      "@media(max-width:500px)":{
       
        top:18,
       
        right:'18px',
      }
    },
    budgetCancelImage: {
      cursor: 'pointer',
      width: '10.96px',
      height: '11px',
      marginLeft: '15px',
    },
  });
const webStyle = {
  title: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Panton Narrow Regular",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    gap: '12px',
  },
}
export default withRouter(withStyles(useStyle)(Budget as any) as any) as any;
