import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  BoxProps,
  Tooltip,
  Zoom,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled, withStyles, } from "@material-ui/core/styles";
import { info, landingPageImage, backIcon } from "./assets";
export const configJSON = require("./config");

const WhiteOnBlackTooltip = withStyles((theme) => ({
  tooltip: {
    height: "auto",
    color: "black",
    marginTop: "20px",
    position: "relative",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    padding: "15px 20px 15px 20px",
    fontWeight: 400,
    maxWidth: "231px",
    fontFamily: "Panton Narrow Regular",
    borderRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    '@media (max-width: 480px)': {
      padding: 0
    }
  },
  arrow: {
    color:"#E9E9E9",
    content: '""',
  },
}))(Tooltip);
// Customizable Area End

import QuoteController, { Props } from "./QuoteController.web";

export default class Quote extends QuoteController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
  //stanbul ignore next
  render() {
    const BoxView = Box as React.FC<BoxProps>;
    const WhiteOnBlackTooltipViews = WhiteOnBlackTooltips as any;
    return (
      // Customizable Area Start
      <MainBox >
        <QuoteBox>
          <QuoteData>
            <QuoteBckeDiv
              data-testid="quoteback"
              onClick={this.backArrowQuote}
            >
              <QuoteBckeImage src={backIcon} />
              <QuoteBckeText>{this.state.select_lang?.name[0]}</QuoteBckeText>
            </QuoteBckeDiv>
          <BoxView sx={webStyle.scrollView}>
            <QuoteTittleDiv>
              <span style={{ display:'flex' }}>
              <QuoteTittle>{this.state.quoteHeadingTooltip?.data?.name}</QuoteTittle>
              
              
              <WhiteOnBlackTooltipViews
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.quoteHeadingTooltip?.data?.description}
                         open={this.state.tooltipOpenQuoteHeading}
                         onClose={this.handleTooltipCloseQuoteHeading}
                        >
              <QuotetittleImage src={info} 
              data-test-id="quoteMouse"
  onMouseLeave={()=>this.handleImageImageMouseOver()}
  onMouseEnter={()=>this.handleImageImageMouseHover()}
   onClick={() => this.handleMouse()}
              alt="Info" />
              </WhiteOnBlackTooltipViews>
              </span>
            </QuoteTittleDiv>
           <div>
              <QuotedataValue>
                <QuotedataValueone>
                  {this.state.addData.ad_budget_name}
                </QuotedataValueone>
                <QuotedataValuetwo>
                {configJSON.chf} {this.state.addData.ad_budget_value}/ {this.state.select_lang?.name[1]}
                </QuotedataValuetwo>
              </QuotedataValue>
              <QuotedataBorder />
              {this.state.addData?.add_ons?.map((ele, index) => {
                return (
                  <React.Fragment key={ele.name}>
                    <QuotedataValue>
                      <QuotedataValueone>{ele.name}</QuotedataValueone>
                      <QuotedataValuetwo>
                        {configJSON.chf} {ele.price}/ {ele.price_type}
                      </QuotedataValuetwo>
                    </QuotedataValue>
                    {index === this.state.addData?.add_ons.length-1 ? "" : <QuotedataBorder />} 
                  </React.Fragment>
                );
              })}
            </div>
            <QuoteDetailButton
              data-testid="campaingPage"
              onClick={this.campaingPage}
              disabled={this.state.viewData}
            >
              <CampaignButton >{this.state.select_lang.name[2]}</CampaignButton>
            </QuoteDetailButton>
          </BoxView>
          </QuoteData>
        </QuoteBox>
      </MainBox>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainBox : any= styled("div")({
  display: "flex",
  justifyContent: "center",
  backgroundImage: `url(${landingPageImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  backgroundSize: "contain",
   height: '100%',
  width: '100%',
  backgroundColor: "#c3dbdf",
  "& .MuiTypography-body1": {
    lineHeight: "normal !important",
  },
  "@media (max-width: 767px)": {
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
  },
});
const WhiteOnBlackTooltips = withStyles((theme) => ({
  arrow: {
    content: '""',
    color:"#E9E9E9"
  },
  tooltip: {
    color: "black",
    height: "auto",
    padding: "15px 20px 15px 20px",
    marginTop: "20px",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    maxWidth: "231px",
    borderRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    position: "relative",
    
  },
 
}))(Tooltip);
const QuoteBox : any= styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "744px",
  backgroundColor: "white",
  borderRadius: "54px",
  marginTop: "35px",
  height: 'calc(100vh - 65px) !important',
  boxShadow: "0px 4px 12px 2px #84848480" as "0px 4px 12px 2px #84848480",
  "@media (min-width: 1900px)": {
    width: "40% !important",
  },
  "@media (max-width: 1440px)": {
    width: "50% !important",
  },
  
  "@media (max-width: 1366px)": {
    width: "41% !important",
  },
  "@media (max-width: 1025px)": {
    width: "72% !important",
  }, 
 

  "@media (max-width: 960px)": {
    width: "85% !important",
    height: 'calc(100vh - 56px) !important',
    marginTop:'33px',

  },
  "@media (max-width: 500px)": {
    width: "99% !important",
    margin: "33px 0px 23px 0px",
    height: 'calc(100vh - 57px) !important',

  },
});

const QuoteData : any= styled(Box)({
   height: "89.3%",
  width: "552px",
  marginTop: "105px",
  "@media (max-width: 1920px)": {
    width: "80%",
  },
  "@media (max-width: 767px)": {
    width: "100%",
  marginTop: "60px",
  paddingInline: '20px'
  },
  "@media (max-width: 1370px)": {
    height: "85.3%",
  },
  
});

const QuoteBckeDiv: any = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  gap: "15px",
  alignItems: "center",
});

const QuoteBckeImage : any= styled("img")({
  width: "11px",
  height: "21px",
  cursor: "pointer",
});

const QuoteBckeText : any= styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  cursor: "pointer",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
});

const QuoteTittleDiv: any = styled("div")({
  display: "flex",
  alignItems: "center",
  margin: "62px 0px 72px 0px",
  "@media (max-width: 1366px)": {
  margin: "54px 0px 20px 0px",
   },
});

const QuoteTittle: any = styled(Typography)({
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  fontSize: "24px",
  '@media(max-width:500px)':{
    fontSize: "20px",
  },
});

const QuotetittleImage: any = styled("img")({
  paddingLeft: "16px",
});

const QuotedataValue: any = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Panton Narrow Regular",
  fontSize: "18px !important",
  
});

const QuotedataValueone: any = styled(Typography)({
  fontWeight: 400,
  fontFamily: "Panton Narrow Regular",
  fontSize: "18px !important",
  '@media(max-width:500px)':{
    fontSize: "16px !important",
  }
});

const QuotedataValuetwo: any = styled(Typography)({
  fontWeight: 700,
  fontSize: "18px !important",
  fontFamily: "Panton Narrow Bold",
  textAlign: "end",
  "@media (max-width: 1366px)": {
   textAlign: "end"
  },
  '@media(max-width:500px)':{
    fontSize: "16px !important",
  }
});

const QuotedataBorder: any = styled(Typography)({
  borderBottom: "1px solid rgb(180, 180, 180)",
  margin: "21px 0px 25px 0px",
});

const QuoteDetailButton : any= styled("div")({
  display: "flex",
  justifyContent: "center",
   marginTop: "40%",
  "@media (max-width: 767px)": {
    marginTop: 80
  },
  "@media (max-width: 1380px)": {
    marginTop: "30%",
  },
  "@media (max-width: 960px)": {
    marginTop: "70%",
  },
  "@media (max-width: 350px)": {
    marginTop: "30% !important",
  },
  "@media (max-width: 500px)": {
    marginTop: "50%",
  },
  
});

const CampaignButton : any= styled("button")({
  width: "228px",
  borderRadius: "43px",
  backgroundColor: "#005E6E",
  color: "#FFFFFF",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  fontSize: "20px",
  border: "none",
  cursor: "pointer",
});

const webStyle = {

  scrollView:{
    height: "calc(-100px + 82vh)" as const,
    overflowY:"scroll" as const,
    overflowX: "hidden",
    scrollbarWidth: "thin",
    paddingRight:'15px',
    paddingBottom:"0px",
    '@media(max-width:500px)':{
      marginInline:'15px',
    },
  
  },
}
// Customizable Area End
