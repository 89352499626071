import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoutes from "./PrivateRoutes";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import Budget from "../../blocks/customform/src/Budget.web";
import SetUp from "../../blocks/customform/src/SetUp.web";
import Configuration from "../../blocks/customform/src/Configurations.web";
import Channels from "../../blocks/customform/src/Channels.web";
import Objective from "../../blocks/customform/src/Objective.web";
import Addons from "../../blocks/customform/src/Addons.web"




//const history = createHistory();
import ProjectNotes from '../../blocks/ProjectNotes/src/ProjectNotes.web';
import Settings from "../../blocks/Settings/src/Settings.web"
import Congratulations from "../../blocks/customform/src/Congratulations.web"
import Recommendation from '../../blocks/customform/src/Recommendation.web';
import Location from '../../blocks/customform/src/Location.web';
import Quote from '../../blocks/customform/src/Quote.web';


class Routes extends Component {
  render() {
    return (
      <Switch>
        <Redirect exact to="/" from="/" />
        <Route exact path="/EmailAccountLoginBlock" component={EmailAccountLoginBlock} />
        <Route exact path="/Settings" component={Settings} />
        <Route exact path="/Dashboard" component={Dashboard} />
        <Route exact path="/ProjectNotes" component={ProjectNotes} />
        <Route exact path="/Objective" component={Objective} />
        <PrivateRoutes exact path="/SetUp" component={SetUp} />
        <PrivateRoutes exact path="/Channels" component={Channels} />
        <PrivateRoutes exact path="/Congratulations" component={Congratulations} />
        <PrivateRoutes exact path="/Recommendation" component={Recommendation} />
        <PrivateRoutes exact path="/RolesPermissions" component={RolesPermissions} />
        <PrivateRoutes exact path="/Budget" component={Budget} />
        <PrivateRoutes exact path="/Configuration" component={Configuration} />
        <PrivateRoutes exact path="/Configuration/:id" component={Configuration} />
        <PrivateRoutes exact path="/Addons" component={Addons}/>
        <PrivateRoutes exact path="/Location" component={Location}/>
        <PrivateRoutes exact path="/Quote" component={Quote}/>
        <PrivateRoutes exact path="/Quote/:id" component={Quote}/>
      </Switch>
    );
  }
}
export default Routes;
