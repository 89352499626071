import React,{useEffect, useState} from 'react';
import Modal from '@material-ui/core/Modal';
import ReactQuill from "react-quill";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Theme,Typography,Button } from '@material-ui/core';
import { ProjectNote } from "../../../../blocks/ProjectNotes/src/ProjectNotesController.web";

interface ProjectNotesModal {
  isOpen?: boolean;
  closeModal: () => void;
  noteDetail:ProjectNote | null;
  createNote:(note:string,refrence_id:string)=>void;
  editNote:(note:string,noteId:string,refrence_id:number)=>Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
  ({
    root: {

      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)',
      // The position fixed scoping doesn't work in IE 11.
      // Disable this demo to preserve the others.
      '@media all and (-ms-high-contrast: none)': {
        display: 'none',
      },
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: "400",
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      boxShadow:"0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)" as const,
      padding: "16px 32px 24px",
    },
    quilBox: {
      width: '100%',
      height: '200px',
    },
    modalHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    modalBtnContainer: {
      display: 'flex',
      justifyContent: 'end'
    },
    modalBtn: {
      margin: "5px"
    },
    modalFooter: {
      display: 'flex',
      "justify-content": 'flex-end',
    },
  }),
);

export default function ProjectNoteModal(props: ProjectNotesModal){

  const {
    isOpen, 
    closeModal,
    noteDetail,
    createNote,
    editNote
  } = props;

  const [editorText,setEditorText] = useState<null | string>(null)
  const classes = useStyles();
  const rootRef = React.useRef<HTMLDivElement>(null);

  const modules = {
    toolbar: [
      ["bold", "underline"],
      [{ list: "ordered" }],
      [{ list: "bullet" }],
      ['link'],
      [{ 'font': [] }],
      ["blockquote"],
      [{ 'align': [] }]
    ]
  }

  const style = {
    "background-color": "#fafafa",
    "height": "150px",
    "width": "100%"
  }

  useEffect(() => {
    if(noteDetail?.attributes.note_content){
     setEditorText(noteDetail?.attributes.note_content)
    }

  },[noteDetail?.attributes?.note_content])

 const handelClose = () => {
  setEditorText(null)
  closeModal()

 }

  return (   
  <div className={classes.root} ref={rootRef}>
    <Modal
      open={isOpen !== undefined ? isOpen : false}
      className={classes.modal}
        onClose={handelClose}
      >
        <div className={classes.paper}>
          <div className={classes.modalHead}>
            <Typography variant="h4">Notes</Typography>
            <div >
              <CloseIcon onClick={handelClose} style={{ cursor: 'pointer' }} />
            </div>
          </div>
          <div className={classes.quilBox}>
            <ReactQuill 
             theme="snow" 
             style={style} 
             modules={modules} 
             value={editorText || ''} 
             onChange={setEditorText}
             />
           </div>
          <div className={classes.modalBtnContainer} >
            <Button className={classes.modalBtn} variant="contained" onClick={handelClose}>Cancel</Button>
            {
              noteDetail ? 
              <Button 
                className={classes.modalBtn} 
                variant="contained"
                onClick={() => editNote(editorText || '', noteDetail?.id, noteDetail?.attributes.reference_id)}
                >Edit Note
                </Button>  :
                <Button 
                className={classes.modalBtn} 
                variant="contained"
                onClick={() =>{
                  setEditorText('')
                  createNote(editorText || '', '1')
                }}
              >
                Add Note
              </Button>
            }
          </div>
        </div>
      </Modal>
    </div>
  );
}            
