import React from 'react';
import parse from 'html-react-parser';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
    Typography,
    Card,
    CardContent,
    CardActions,
    makeStyles

} from "@material-ui/core";

import { ProjectNote } from '../../../../blocks/ProjectNotes/src/ProjectNotesController.web';

interface ProjectNotesCard {
    noteDetails: ProjectNote,
    handleModal: () => void
    setEditNoteValue: (noteDetails: ProjectNote) => void,
    deleteNote: (noteId: string) => void
}



const useStyles = makeStyles({
    notesCardContainer: {
        width: "500px",
        margin: '10px',
        display: "flex",
        justifyContent: "space-between",
    },
    actionButton: {
        cursor: "pointer"
    },
    textOverFlow:{
        "&>p":{
            overflow: 'hidden',
            fontSize: '15px',
            textOverflow: 'ellipsis',
            boxOrient: 'vertical',
            lineClamp: 1,
            maxWidth: '200px',
            maxHeight: '20px',
        }
   
    },
});

const NoteCard = ({ noteDetails, handleModal, setEditNoteValue, deleteNote }: ProjectNotesCard) => {
    const classes = useStyles();
    return (
        <Card className={classes.notesCardContainer}>
            <CardContent>
                <Typography className={classes.textOverFlow}  gutterBottom variant="body1" component="p">
                    {parse(noteDetails.attributes.note_content)}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" component="p">
                    {noteDetails.attributes.created_at}
                </Typography>
            </CardContent>
            <CardActions>
                <EditIcon
                    className={classes.actionButton}
                    onClick={() => {
                        handleModal()
                        setEditNoteValue(noteDetails)
                    }}
                />
                <DeleteIcon
                    className={classes.actionButton}
                    onClick={() => deleteNote(noteDetails.id)}
                />
            </CardActions>
        </Card>)

}

export default NoteCard;
