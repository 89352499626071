export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const imageBanner = require("../assets/image_Banner.png");
export const cardImage = require("../assets/skip_get new client.png");
export const info = require("../assets/image_Info.svg");
export const dropdownArrow = require("../assets/Vector (1).svg");
export const infoIcon = require("../assets/Vector (2).svg");
export const expandIcon = require("../assets/image_dropdown06.svg");
export const checked = require("../assets/image_Selectedtick.svg");
export const unchecked = require("../assets/view_Tickbox.svg");
export const local = require("../assets/image_Localads.svg");
export const serchads = require("../assets/image_Serchads.svg");
export const insta = require("../assets/image_Insta.svg");
export const fb = require("../assets/image_Fb.svg");
export const google = require("../assets/image_Image.svg");
export const bannerad = require("../assets/image_Bannerad.svg");
export const printads = require("../assets/image_Printads.svg");
export const mapIcon = require("../assets/Maps and Flags (1).svg");
export const downaerrow = require("../assets/arrow-down.svg");
export const calendoricon = require("../assets/image_.png");
export const success = require("../assets/Group.png");
export const chcekoBox = require("../assets/tick-square.png");
export const copyIcon = require("../assets/copy.svg")
export const landingPageImage = require("../assets/landingpage.png")
export const batch = require("../assets/badge.png");
export const checkout = require("../assets/checkout.png")
export const cancelImage = require("../assets/cancel.png")
export const campaignCheck = require("../assets/campaigncheck.jpg")
export const campaignDone = require("../assets/campaignDone.jpg")
