Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";
exports.budgetRange = "bx_block_custom_form/budget_ranges/get_budget_range";
exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";
exports.campaignsAPIMethod = "DELETE"

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";

exports.getObjectiveApiEndPoint ="bx_block_custom_form/objectives";
exports.AddonsApiEndpoint ="bx_block_custom_form/addons";
exports.postCreateCampaingEndPoint ="bx_block_custom_form/campaigns";
exports.getSerchIndustryEndPoint="bx_block_elasticsearch/search";
exports.getRoasEndPoint="bx_block_content_management/tooltip";
exports.getRadiusEndPoint="bx_block_custom_form/radius";
exports.postRecommendationsEndPoint="bx_block_custom_form/recommendations";
exports.createNoteApiMethod = "POST";
exports.apiContentType="application/json"
exports.getYourIndustryEndPoint="bx_block_content_management/tooltip";
exports.getYourLocationEndPoint="bx_block_content_management/tooltip";
exports.getObjectiveTooltipEndPoint="bx_block_content_management/tooltip";
exports.getBudgetEndPoint="bx_block_content_management/tooltip";
exports.getChannelsEndPoint="bx_block_content_management/tooltip";
exports.getDurationEndPoint="bx_block_content_management/tooltip";
exports.getConversionTrackingEndPoint="bx_block_content_management/tooltip";
exports.getChannelsSectionEndPoint="bx_block_custom_form/configurations/get_channels";
exports.putCampaingChannelsSection="bx_block_custom_form/campaigns";
exports.getIndustryBenchmarkEnd="bx_block_custom_form/fetch_data";
exports.postCheckoutEndPoint="bx_block_automaticcheckoutcalculation2/checkouts";
exports.putCampaing_id="bx_block_custom_form/user_progress";
exports.postQuoteEndPoint="bx_block_automaticcheckoutcalculation2/quote";
exports.potQuote_id="POST";
exports.campaigndetails="Campaign details";
exports.chf="CHF";
exports.monthly="Monthly";
exports.back="Back";
exports.quote="Quote";
exports.setUpContent="bx_block_content_management/pages"
exports.tooltipHeading="bx_block_content_management/tooltips"
exports.deletecampaignsEndPoint="bx_block_custom_form/campaigns"
exports.ConfigurationDataEndPoint="bx_block_custom_form/get_campaign?id="
exports.PostCampaignCheckoutEndPoint="bx_block_automaticcheckoutcalculation2/update_status"
exports.putCampaignApiEndPoint="bx_block_custom_form/update_campaign_details?id="


// Customizable Area End