import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";

// Customizable Area Start
import { testNavi } from "../../../../packages/blocks/utilities/src/CommonUtilities";
import {
  ClassNameMap,
  ClassKeyOfStyles,
} from '@material-ui/styles';
import { ChangeEvent } from 'react';
import { logoutUser } from "../../../framework/src/Utilities";
interface UpdateCampaignBudgetResponseData {
  data: {
      attributes: {
        user: any
          company_name: string,
          industry: string,
          location: string,
          channel_mixes: any,
          objective_id: number,
          budget: string,
          radius_id: number,
          duration: string,
          created_at: string,
          updated_at: string,
          status: string,
          user_photo_link: any,
          addons: any,
      },
      type: string,
      id: string,
      relationships: {
        addons: {
          data: any
      },
          channel_mixes: {
              data: any
          },      
      }
  }
}
interface ResponseData {
  data: {
    id: number;
    type: string;
    attributes:{
      min_value: string;
      max_value: string;
    }
  }
}
interface IToggle {
  toggleCampaignList:()=>void
}
export interface Addon {
  id: number;
  name: string;
  price_before_discount: number;
  price: string;
  price_type: string;
  discount_description: string;
  tooltip: string;
  recommended: boolean;
  campaign_info:any
}

interface AddonsContentData{
  data:{
    tooltip: string,
    heading: string,
    body: string,
    image_url: string
  }
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props extends RouteComponentProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  navigation: any;
  id: string;
  
  // Customizable Area Start
  history:any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  getAddonsContentCallID:string;
  AddonsData:Addon[]
  addonsTooltipId:number|null,
  isAuthenticated:boolean 
  loading: boolean;
  selectedBudget: number;
  getBudgetRangeMessageId: string;
  marks: any;
  selectedObjectives:number;
  checkedAddOns: any;
  mendetoryAddon:any;
  CheckedAddonsData:any;
  checked:boolean,
  getAddonsApiCallID:string,
  AddonData: any,
  tooltipOpenCompanyName:boolean,
  isModalOpen: boolean,
  addonsData:object,
  addonsHeadingData:boolean,
  addonsHeadingTooltip:any,
  tooltipOpen: boolean,
  tooltipTitle: string,
  tooltipOpenAddonsHeadingTooltip: boolean,
  tooltipTitleAddonsHeadingTooltip: string,
  tooltipOpenAddonDataTooltip: boolean,
  tooltipTitleAddonsDataTooltip: string,
  select_lang: any,
  addOnsToggle:boolean
  toggleAddon:any,
  recommendationTooltipOpen:boolean,
  showTooltip:any,
  viewData:boolean,
  budgetApiVariable:any,
  QuoteNavigate:boolean,
  ConfigureNavigate:boolean,
  visitedQuoteKey:any,
  active_key:string,
  initialAddonVisit:boolean,
  updatedCheckedAddon:boolean,
  loaderTrue:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class AddonsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
   getAddonsApiCallID:string = ""
   sellerDetailsAPIMethodPUT:string = ""
   updateCampaignBudgetMessageId:string=""
   getAddonsHeadingTooltipCallID:string=""
   getCampaignObjectiveCallID:string=" "
   getCampaignAddonCallID:string=" "
   getAddonsContentCallID:string=" "
   putAddonDisabledApiCallID:string=" "
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    this.state = {
      getAddonsContentCallID:"",
      selectedObjectives:0,
      getBudgetRangeMessageId:"",
      AddonsData:[],
      addonsTooltipId:null,
      CheckedAddonsData: [],
      getAddonsApiCallID:'string',
      isAuthenticated:false,
      loading: false,
      addonsHeadingData:false,
      selectedBudget:0,
      checked:false,
      marks:[],
      AddonData: {},
      isModalOpen: false,
      checkedAddOns: [],
      mendetoryAddon:{},
      addonsData:{},
      addonsHeadingTooltip:{},
      tooltipOpen: false,
       tooltipTitle: '',
       tooltipOpenCompanyName:false,
       tooltipOpenAddonsHeadingTooltip: false,
      tooltipTitleAddonsHeadingTooltip: '',
      tooltipOpenAddonDataTooltip: false,
      tooltipTitleAddonsDataTooltip: '',
      select_lang: {name: []},
      addOnsToggle:false,
      toggleAddon:[],
      recommendationTooltipOpen:false,
      showTooltip:[],
      viewData:false,
      budgetApiVariable:{},
      QuoteNavigate:false,
      ConfigureNavigate:false,
      visitedQuoteKey:{},
      active_key:"",
      initialAddonVisit:false,
      updatedCheckedAddon:false,
      loaderTrue:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const lang : any = {
      italian: {name:["Citazione","Prossima"]},
      german: { name:["Zitat", "Nächste"]},
      french: {name:["Citation","Suivante"]},
    };
    const selectedCountry = localStorage.getItem("selectedCountry");
    this.getAddons();
    this.getBudgetRange();
    this.getAddonsContentDetails()
    this.addOnsHeadingTooltip()
 
    const selected_Language = lang[selectedCountry as any] || { name: []};

    this.setState({select_lang:selected_Language})
    const viewOption:any=localStorage.getItem("visitedView")
    const showOption:any=localStorage.getItem("showData")
    if(viewOption && showOption==="true"){
      this.setState({viewData:true})
    }
    const persistId=localStorage.getItem("persistId");
    const visitedAddon=localStorage.getItem("visitedAddon")
    if(persistId || visitedAddon){
      this.getCampaignRecommendationData();
    }
    // Customizable Area End
  }
   
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

  if (apiRequestCallId && responseJson) {
    if(apiRequestCallId === this.getAddonsApiCallID){
      this.handleAddonsApiResponse(responseJson);
    }else if(apiRequestCallId === this.updateCampaignBudgetMessageId){    
     this.addonsResponce(responseJson)
    }else if (apiRequestCallId === this.state.getBudgetRangeMessageId) {    
     this.budgetSuccessData(responseJson);
    }else if(apiRequestCallId === this.getAddonsContentCallID){
     this.getAddonContentReceiver(responseJson);
    }else if(apiRequestCallId === this.getAddonsHeadingTooltipCallID){
     this.getAddonsHeadingReceiver(responseJson);
    }else if(apiRequestCallId ===this.getCampaignAddonCallID){
      this.getAddonData(responseJson)
    }
    
  }

  if(responseJson && responseJson.statusCode === 401){
    logoutUser();          
   this.props.history.push("/EmailAccountLoginBlock");
 }
} 
// Customizable Area End
 }

  // Customizable Area Start
     //istanbul ignore next
  handleAddonsApiResponse(responseJson: any) {
    this.getCampaignRecommendationData();
    let newResponse: any[] = [];
    responseJson.forEach((item: any) => {
        if (responseJson[responseJson.length - 1].campaign_info.addons.includes(item.id)) {
            newResponse.push({ ...item, recommended: true });
            if (item?.recommended) {
                this.setState({ mendetoryAddon: item });
            }
        } else {
            newResponse.push(item);
        }
    });

    const addonsChecked = newResponse
        .filter((addon: { recommended: any }) => addon.recommended)
        .map((addon: { id: any }) => addon.id);

    this.setState({
        AddonsData: newResponse,
        checkedAddOns: [...addonsChecked],
        CheckedAddonsData: responseJson
    },()=>{
      const filteredAddData = [];
     const newDta= responseJson[responseJson.length-2]
      for(const item in newDta)
      {
      if(newDta[item]===false)
      {
        filteredAddData.push(item)
      }
      }
      this.setState({showTooltip:filteredAddData})
    const persistId=localStorage.getItem("persistId");
const visitedAddon=localStorage.getItem("visitedAddon");
      if(!persistId || !visitedAddon){
        this.setState({toggleAddon: filteredAddData});
      }
    });

}
handleSwitchChange = (addon: any) => {
    const addonsValue=this.state.CheckedAddonsData && this.state.CheckedAddonsData[this.state.CheckedAddonsData.length-2]

    if(addonsValue && !addonsValue[addon])
    {
      return;
    }
    this.setState({})
    let arr = [...this.state.toggleAddon]
    if(!this.state.toggleAddon.includes(addon))
    {
      arr.push(addon)
      this.setState({toggleAddon: arr})
    } else {
      arr = arr.map((item) => {
          if(item !== addon)
          {
            return item;
          }
        })
      this.setState({toggleAddon: arr})
    }
    this.setState({updatedCheckedAddon:true})
    const  persistId= localStorage.getItem("persistId")
    const visitedAddon=localStorage.getItem("visitedAddon");
    if((visitedAddon|| persistId) && !this.state.loaderTrue){
    let activeItemss = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
    let index_a = activeItemss.indexOf('addons');
    let activeItemsTrimmed = activeItemss.slice(0, index_a + 1);
    let CampaignUpdate =  {
      "campaign": {
        "active_page":"addons",
        "visited_key":activeItemsTrimmed
      }, 
       "progress": {
       "current_page": "addons"
   }
  }  
     this.setState({budgetApiVariable:CampaignUpdate},()=>{
       this.updateCampaignAddonDetails()
     })
     this.putAddonDisabledDataApi()
     localStorage.removeItem('configSelectDate');
    }
   
     
    
  };

  getCampaignRecommendationData=()=>{
    const visitedSetup=localStorage.getItem("visitedAddon");
    const  persistId= localStorage.getItem("persistId")
    let Camping_Id = localStorage.getItem('campaignID')
    const rowId=persistId?persistId:Camping_Id
 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaignAddonCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_custom_form/get_campaign?id="}${rowId}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
   }
   getAddonData=(responseJson:any)=>{
      const idd=responseJson.add_ons.map((i:any)=>i.id.toString())
      this.setState({toggleAddon: idd});
      const visitedSetup=localStorage.getItem("visitedAddon");
      const persistId=localStorage.getItem("persistId");
      if(visitedSetup || persistId){
        let key=responseJson.visited_key;
        this.setState({visitedQuoteKey:responseJson.visited_key,active_key:responseJson.active_page})
        if(!key.includes(responseJson.active_page) )
        {
           key.push(responseJson.active_page)
        }
        let CampaignUpdate =  {
         "campaign": {
           "active_page":"addons",
           "visited_key":key
         }, 
          "progress": {
          "current_page": "addons"
      }
     }  
        this.setState({budgetApiVariable:CampaignUpdate,initialAddonVisit:true},()=>{
          this.updateCampaignAddonDetails()
        })
      }
    
 
   }
   onDashboardSave=()=>{
    if(this.state.updatedCheckedAddon){
    let formDataCampaign = {
      "campaign": {
          "addon_ids": this.state.toggleAddon,
              },
          "progress": {
              "current_page": "addons"
          }
     }
    this.setState({budgetApiVariable:formDataCampaign},()=>this.updateCampaignAddonDetails())
     }
     if(!this.state.updatedCheckedAddon){

       this.props.history.push("/Dashboard")
     
     }
     }
  getBudgetRange = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setState({
        getBudgetRangeMessageId: requestMessage.messageId,
      });
      const {objectiveID} : any = this.props.location.state

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.budgetRange}?objective_id=${objectiveID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  budgetSuccessData = (responseJson:ResponseData) => {
    const maxValue = Math.round(parseInt(responseJson.data.attributes.max_value));
    const minValue = Math.round(parseInt(responseJson.data.attributes.min_value));
    let budget = [{
      value : minValue
    },
    {
      value : maxValue
    }
  ]
    this.setState({
      marks: budget,
    });
  }

  handleTooltipToggleObjectiveData = () => {
    this.setState({ tooltipOpenAddonDataTooltip: !this.state.tooltipOpenAddonDataTooltip });
  };
  
  handleTooltipCloseAddonsData = () => {
    this.setState({ tooltipOpenAddonDataTooltip: false });
  };
  

  handleImageImageMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenAddonsHeadingTooltip:true});
    }
  };
   handleImageImageMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenAddonsHeadingTooltip:false});}
  }
   handleMouse=()=>{
    

      this.setState({tooltipOpenAddonsHeadingTooltip:!this.state.tooltipOpenAddonsHeadingTooltip});
    
  }

  handletooltipMouseHover=(id:number)=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenCompanyName:true,addonsTooltipId:id});
    }
  };
   handletooltipMouseOver=(id:number)=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenCompanyName:false,addonsTooltipId:id});}
  }
   handletooltip=(id:number)=>{

      this.setState({tooltipOpenCompanyName:!this.state.tooltipOpenCompanyName,addonsTooltipId:id});
      setTimeout(() => {
        this.setState({ tooltipOpenCompanyName: false });
      }, 2000);
    
  }
  handleobjectivesMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({addonsHeadingData:true});
    }
  };
   handleobjectivesMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({addonsHeadingData:false});}
  }
   handleobjectives=()=>{
    if(window.innerWidth<=500){

      this.setState({addonsHeadingData:!this.state.addonsHeadingData});
    }
  }

  handleDataTooltipMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenAddonDataTooltip:true});
    }
  };
   handleDataTooltipMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenAddonDataTooltip:false});}
  }
   handleDataTooltip=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenAddonDataTooltip:!this.state.tooltipOpenAddonDataTooltip});
    }
  }

  handleTooltipToggleObjectiveDatas = () => {
    this.setState({ tooltipOpenAddonsHeadingTooltip: !this.state.tooltipOpenAddonsHeadingTooltip });
   };
  
  handleTooltipCloseAddonsHeadingData = () => {
    this.setState({ tooltipOpenAddonsHeadingTooltip: false });
  };
  

  
  getAddons = () => {
   
    const selectedCountry = localStorage.getItem('selectedCountry');
    const authToken = localStorage.getItem('accessToken');
    let Camping_Id = localStorage.getItem('campaignID')
    const objective_id = localStorage.getItem('objective_id');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AddonsApiEndpoint}?language=${selectedCountry}&campaign_id=${Camping_Id}&objective_id=${objective_id}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );  
     this.getAddonsApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // alert("ggg")
  }
  updateCampaignAddonDetails = async (): Promise<void> => {
    const persistId=localStorage.getItem("persistId")
    const campaignId = localStorage.getItem("campaignID")
    const rowID=persistId?persistId:campaignId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
  
      this.updateCampaignBudgetMessageId = requestMessageList.messageId
  
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
     );
  
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCreateCampaingEndPoint}/${rowID}`
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.budgetApiVariable)
    );
  
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
  
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  postDataApi=()=>{
    localStorage.setItem("visitedAddon","visitedAddon")
    let formDataCampaign = {
      "campaign": {
          "addon_ids": this.state.toggleAddon,
          "visited_key":["setup","recommendation","objective","channels","location","budget","addons"],
            "active_page":"configuration",
              },
          "progress": {
              "current_page": "configration"
          }
     }
    this.setState({budgetApiVariable:formDataCampaign},()=>this.updateCampaignAddonDetails())
  }
  handleQuote=()=>{
    localStorage.setItem("mycurrentPage","visitAddons")
    const persistId=localStorage.getItem("persistId");
    const visitedSetup=localStorage.getItem("visitedAddon");
    const MycPage = localStorage.getItem("mycurrentPage")
    let key=this.state.visitedQuoteKey;
    if(visitedSetup || persistId){
      if( !key.includes(this.state.active_key)){
        key.push(this.state.active_key)
      }else if(MycPage){        
        key = ["setup","recommendation","objective","channels","location","budget"]
      }
    }
    let formDataCampaign = {
      "campaign": {
        "active_page":"addons",
          "addon_ids": this.state.toggleAddon,
      "visited_key":visitedSetup?key:["setup","recommendation","objective","channels","location","budget"]
              },
          "progress": {
              "current_page": "Quote"
          }
     }

    this.setState({QuoteNavigate:true})
    this.setState({budgetApiVariable:formDataCampaign},()=>this.updateCampaignAddonDetails())
    localStorage.setItem("visitedAddon","visitedAddon")
    
  }
  handleConfigNav=()=>{
    this.setState({ConfigureNavigate:true})
this.postDataApi()
  }
  addonsResponce=(responseJson:any)=>{
    if(this.state.initialAddonVisit){
      this.send(testNavi(this.props))
    }
    if(this.state.updatedCheckedAddon){
      this.setState({loaderTrue:true})
    }
    const navigate= localStorage.getItem("navigate")
    if(navigate){
     this.props.history.push("/Dashboard")
    }
    if(this.state.ConfigureNavigate){
      this.props.history.push("/Configuration")
    } else if(this.state.QuoteNavigate){
      this.props.navigation.navigate("Quote")
    }        
  }

  putAddonDisabledDataApi=async (): Promise<void> => {
    const campaignId = localStorage.getItem("campaignID")
      const persistId=localStorage.getItem("persistId")
      const rowID=persistId?persistId:campaignId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };   
    
     const requestMessageAddonList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
     requestMessageAddonList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
     );
  
     requestMessageAddonList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_custom_form/update_recommendation_campaign"}?id=${rowID}&page=addons`
     );
     requestMessageAddonList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
     this.putAddonDisabledApiCallID=requestMessageAddonList.messageId
     runEngine.sendMessage(requestMessageAddonList.id, requestMessageAddonList);
  }
 getAddonsContentDetails = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "add_ons";

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAddonsContentCallID = requestMessageList.messageId


    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.setUpContent}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
};

  getAddonContentReceiver=(responseJson:AddonsContentData)=>{
      this.setState({
      AddonData: responseJson
    })
  };
  
  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  addOnsHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Addons";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAddonsHeadingTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  handleToggleTooltipClose=()=>{
    this.setState({recommendationTooltipOpen :false})
  }
  isTooltipOpen = (id:any) => {
    const { showTooltip, recommendationTooltipOpen, addonsTooltipId } = this.state;

    if (
      showTooltip.includes(id.toString()) &&
      recommendationTooltipOpen &&
      addonsTooltipId === id
    ) {
      return true;
    } else {
      return false;
    }
  }; 
  handleToggleMouseOvers=(id:any)=>{
    if(window.innerWidth>500){
      this.setState({recommendationTooltipOpen :true,addonsTooltipId:id});
    }
  }
  handleToggleMouseHover=(id:any)=>{
    if(window.innerWidth>500){
      this.setState({recommendationTooltipOpen :true,addonsTooltipId:id}); 
      }
  }

  handletoggleTooletipShow =(id:any)=>{
    this.setState({recommendationTooltipOpen :true,addonsTooltipId:id}); 
  }

  getAddonsHeadingReceiver=(responseJson:any)=>{
    this.setState({
      addonsHeadingTooltip: responseJson
    })
  };
  
  

  // Customizable Area End

}