
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { logoutUser } from "../../../framework/src/Utilities";
import JSConfetti from "js-confetti";

// Customizable Area Start
interface StateType {
  [key: string]: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  location:any
  history:any;
  // Customizable Area End
}
interface MyInterface {
  data: {
    product_id: string;
    budget: string;
    start_date: string;
  };
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  checkout:{
    productId: string,
    budget: string,
    startDate: string,
  },
  tooltipOpen: boolean,
  tooltipTitle: string,
  tooltipOpenConfigurationDate:boolean,
      tooltipTitleConfigurationDate:string,
  tooltipOpenQuoteHeading:boolean,
  tooltipTitleQuoteHeading:string,
  copiedProductId: boolean,
  copiedBudget: boolean,
  tooltipOpenBudget:boolean,
  tooltipTitleBudget:string,
  tooltipOpenProduct:boolean,
  tooltipTitleProduct:string,
  copiedStartDate: boolean,
  isComplete: boolean,
  campaing:boolean,
  budget:boolean,
  startDate:boolean,
  campaignDetailscxfTooltip:any,
  productIdTooltip:any,
  campDetailsBudgetHeadingTooltip:any
  campDetailsStartdate:any
  select_lang: any,
  startdateWithFlow:any,
  viewData:boolean,
  start_flow:boolean,
  showStartDate:boolean,
getApiShow:boolean,
campaignApiData:any
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface PremiumAds  {
  img: string;
  title: string;
}



export default class CongratulationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postCheckoutEndPoint:string=""
  putCampaing_idCallID:string=""
  putBudgetCallID:string=""
  putstart_dateCallID:string=""
  getCampaignDetailsTooltipCallID:string=""
  getProductIdTooltipCallID:string=""
  getCampDetailsHeadingTooltipCallID:string=""
  getCampDetailsStartDateCallID:string=""
  postCampaignCheckoutCallID:string=" "
  getCampaignCongratulationCallID:string=" "
  putCampaignDetailCallID:string=" "

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      checkout:{
        productId:"",
        budget:"",
        startDate:"",
      },
      tooltipOpen:false,
      tooltipTitle:'',
      tooltipOpenBudget:false,
      tooltipTitleBudget:'',
      tooltipOpenProduct:false,
      tooltipTitleProduct:'',
      tooltipOpenConfigurationDate:false,
      tooltipTitleConfigurationDate:'',
      tooltipOpenQuoteHeading:false,
      tooltipTitleQuoteHeading:'',
      copiedProductId: false,
      copiedBudget: false,
      copiedStartDate: false,    
      isComplete: false,
      campaing:false,
      budget:false,
      startDate:false,
      campaignDetailscxfTooltip:{},
      productIdTooltip:{},
      campDetailsBudgetHeadingTooltip:{},
      campDetailsStartdate:{},
      select_lang: {name: []},
      startdateWithFlow:"",
      viewData:false,
      start_flow:false,
      showStartDate:false,
      getApiShow:false,
      campaignApiData:{}
      // Customizable Area End
    };
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


    // Customizable Area Start
    async receive(from: string, message: Message) {
      if (!this.isValidResponseMessage(message)) {
          return;
      }
  
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
      if (!apiRequestCallId || !responseJson) {
          return;
      }
  
      if (this.isAuthenticationFailure(responseJson)) {
          this.handleAuthenticationFailure();
          return;
      }
  
      this.handleApiResponse(apiRequestCallId, responseJson);
      runEngine.debugLog("Message Received", message);
  }
  
  isValidResponseMessage(message: Message): boolean {
      return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }
  
  isAuthenticationFailure(responseJson: any): boolean {
      return responseJson.statusCode === 401;
  }
  
  handleAuthenticationFailure() {
      logoutUser();
      this.props.navigation.navigate('EmailAccountLoginBlock')
  }
  
  handleApiResponse(apiRequestCallId: string, responseJson: any) {
      const handlers = {
          [this.postCheckoutEndPoint]: this.postCheckoutReciver,
          [this.putCampaing_idCallID]: this.putCampaing_idReciver,
          [this.putBudgetCallID]: this.putBudgetReciver,
          [this.putstart_dateCallID]: this.putstartDateReciver,
          [this.getCampaignDetailsTooltipCallID]: this.getCampaignDetailsReceiver,
          [this.getProductIdTooltipCallID]: this.getProductIdReceiver,
          [this.getCampDetailsHeadingTooltipCallID]: this.getCampDetailsBudgetHeadingReceiver,
          [this.getCampDetailsStartDateCallID]: this.getCampDetailsStartDateReceiver,
      [this.postCampaignCheckoutCallID]:this.postCheckoutUpdateStatus,
      [this.getCampaignCongratulationCallID]:this.campaignFlowReceiver,
      };
  
      const handler = handlers[apiRequestCallId];
      if (handler && responseJson) {
          handler.call(this, responseJson);
      }
  }
  
  
  
    // Customizable Area End
  

  // web events

  async componentDidMount(): Promise<void> {
    this.getCampaignData()
    const configurationStartDate = localStorage.getItem('configSelectDate')
    const viewOption:any=localStorage.getItem("visitedView")
    const showOption:any=localStorage.getItem("showData")
    if(viewOption && showOption==="true"){
      this.setState({viewData:true})
    }
    this.setState({startdateWithFlow : configurationStartDate})
if(configurationStartDate ){
  this.setState({ start_flow : true}) 
}else{
    this.getCampaignData()
    this.setState({getApiShow:true})
}
    const lang : any = {
      german: {
               name:["Zurück", "Kampagnendetails", "Bitte erstellen Sie einen Auftrag in SalesForce mit folgenden Details","Produkt ID", "Kampagnen-ID kopieren", "Vollständig", "monatlich", "Kopiert"],
              },
      french: {
               name:["Retour", "Détails de la campagne","Veuillez créer une commande dans SalesForce avec les détails suivants", "Identifiant du produit", "Copier l'ID de campagne", "Complet", "mensuel", "Copié"],
              },
      italian: {
               name:["Indietro", "Dettagli campagna", "Crea un ordine in SalesForce con i seguenti dettagli", "Codice prodotto", "Copia ID campagna", "Completare", "Mensile", "Copiato"],
              },
    };
    
    await Promise.all([this.getCampaignDetailsTooltip() , this.getProductIdTooltip(),this.postCreateCampaing(),
      this.getCampDetailsBudgetlHeadingTooltip(), this.getCampDetailsStartDateTooltip()]);
      
      const selectedCountry = localStorage.getItem("selectedCountry");
      const selected_Language = lang[selectedCountry as any] || { name: []};
      this.setState({select_lang:selected_Language})

     

    const frompage = localStorage.getItem("frompage");   
    if(frompage !== "Dashboard"){    
     
      const jsConfetti = new JSConfetti();
     jsConfetti
     .addConfetti({
         confettiColors: [
             "#74c7e2",
             "#ea5053",
             "#0073a6",
             "#821e3e",
             "#709faf",
         ],
         confettiRadius: 7,
         confettiNumber: 1000,
         
     })     
    }else{
     localStorage.removeItem("frompage")
    }
   
  
  }
  // Customizable Area Start

  postCreateCampaing = () => {
    const authToken = localStorage.getItem('accessToken')
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const selectedCountry = localStorage.getItem('selectedCountry');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let formDataCampaign = {
      "data": {
          "campaign_id": rowID
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCheckoutEndPoint}?language=${selectedCountry}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign)
    );
    this.postCheckoutEndPoint = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCampaignData=()=>{
    const  persistId= localStorage.getItem("persistId")
    let Camping_Id = localStorage.getItem('campaignID')
    const rowId=persistId?persistId:Camping_Id
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaignCongratulationCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ConfigurationDataEndPoint}${rowId}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
   }
  postCampaignCheckout = () => {
    const authToken = localStorage.getItem('accessToken')
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let formDataCampaign = {
      "data": {
          "campaign_id": rowID,
          "automatic_flow":this.state.start_flow
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PostCampaignCheckoutEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign)
    );
    this.postCampaignCheckoutCallID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //istanbul ignore next
  postCheckoutReciver =(responseJson:MyInterface)=>{
    const { product_id, budget } = responseJson.data;
    this.setState({
      checkout: {
        productId: product_id,
        budget: "CHF" + budget +"/" +this.state.select_lang?.name[6], 
        startDate: this.state.startdateWithFlow
      }
    });
  }
  postCheckoutUpdateStatus=(responseJson:any)=>{
if(responseJson){
  this.props.navigation.navigate("Dashboard")
}
  }
  campaignFlowReceiver=(responseJson:any)=>{
    if(this.state.getApiShow){
      const dateString = responseJson.start_date;
      const reversedDate = dateString && dateString.split("-").reverse().join("-");
      const res=responseJson?.campaign?.attributes
      this.setState(prevState=>({ checkout: {
        ...prevState.checkout, 
        startDate:reversedDate
    },start_flow:res?.automatic_flow}))
    }
  
    let key=responseJson.visited_key;
    if(!key.includes(responseJson.active_page) )
    {
       key.push(responseJson.active_page)
    }
    const campaignData={
      "campaign": {
        "visited_key":key,
        "active_page":"campaign details"
      },
      "progress": {
        "current_page": "campaign details"
      }  
  }
  this.setState({campaignApiData:campaignData},()=>this.putCongratulationUpdate())

  }
  putCampingId = () => {
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const authToken = localStorage.getItem('accessToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let CampingId =  {
      "data": {
          "step_name": "campaign_id"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.putCampaing_id}/${rowID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(CampingId)
    );
    this.putCampaing_idCallID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  putCampaing_idReciver=(responseJson:any)=>{
    this.setState({campaing:responseJson})
    this.updateIsComplete(responseJson.checkout_status);
  }

  putBudget = () => {
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const authToken = localStorage.getItem('accessToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let Budget=  {
      "data": {
          "step_name": "budget"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.putCampaing_id}/${rowID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Budget)
    );
    this.putBudgetCallID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  putBudgetReciver=(responseJson:any)=>{ 
   this.setState({budget:responseJson})
   this.updateIsComplete(responseJson.checkout_status);
  }

  putStartDate = () => {
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const authToken = localStorage.getItem('accessToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let StartDate=  {
      "data": {
          "step_name": "start_date"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.putCampaing_id}/${rowID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(StartDate)
    );
    this.putstart_dateCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  putstartDateReciver=(responseJson:any)=>{
    this.setState({startDate:responseJson})
    this.updateIsComplete(responseJson.checkout_status);
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;    
    this.setState({
      checkout : {...this.state.checkout,[name]:value}
    });
    
  };
  
  handleTooltipToggleBudget= () => {
    this.setState({ tooltipOpenBudget: !this.state.tooltipOpenBudget });
  };
  
  handleTooltipCloseBUdget = () => {
    this.setState({ tooltipOpenBudget: false });
  };
  handleTooltipToggleConfigurationDate = () => {
    this.setState({ tooltipOpenConfigurationDate: !this.state.tooltipOpenConfigurationDate });
  };
  
  handleTooltipCloseConfigurationDate = () => {
    this.setState({ tooltipOpenConfigurationDate: false });
  };
  
  handleTooltipOpenConfigurationDate = (title: string) => {
    this.setState({ tooltipTitleConfigurationDate: title, tooltipOpenConfigurationDate: true });
  };
  
  handleTooltipOpenBudget = (title: string) => {
    this.setState({ tooltipTitleProduct: title, tooltipOpenBudget: true });
  };
  handleTooltipToggleProduct= () => {
    this.setState({ tooltipOpenProduct: !this.state.tooltipOpenProduct });
  };
  
  handleTooltipCloseProduct = () => {
    this.setState({ tooltipOpenProduct: false });
  };
  
  handleTooltipOpenProduct = (title: string) => {
    this.setState({ tooltipTitleProduct: title, tooltipOpenProduct: true });
  };
  handleTooltipToggleQuoteHeading = () => {
    this.setState({ tooltipOpenQuoteHeading: !this.state.tooltipOpenQuoteHeading });
  };
  
  handleTooltipCloseQuoteHeading = () => {
    this.setState({ tooltipOpenQuoteHeading: false });
  };
  
  handleTooltipOpenQuoteHeading = (title: string) => {
    this.setState({ tooltipTitleQuoteHeading: title, tooltipOpenQuoteHeading: true });
  };
  handleImageImageMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenQuoteHeading:true});
    }
  };
   handleImageImageMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenQuoteHeading:false});}
  }
   handleMouse=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenQuoteHeading:!this.state.tooltipOpenQuoteHeading});
    }
  }

  updateIsComplete = (checkoutStatus: string | boolean) => {
    if (checkoutStatus === true || checkoutStatus === "Completed") {
      this.setState({ isComplete: true });
    }
  }
  handleproductIdTooltipMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenProduct:true});
    }
  };
   handleproductIdTooltipMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenProduct:false});}
  }
   handleproductIdTooltip=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenProduct:!this.state.tooltipOpenProduct});
    }
  }
  handleBudgetHeadingMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenBudget:true});
    }
  };
   handleBudgetHeadingMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenBudget:false});}
  }
   handleBudgetHeading=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenBudget:!this.state.tooltipOpenBudget});
    }
  }

  handleConfigurationDateMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationDate:true});
    }
  };
   handleConfigurationDateMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationDate:false});}
  }
   handleConfigurationDate=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenConfigurationDate:!this.state.tooltipOpenConfigurationDate});
    }
  }
  
  //istanbul ignore next
  handleCopyToClipboard = async (value: string, inputIdentifier: string) => {
    if(!this.state.viewData){
    try {
      await navigator.clipboard.writeText(value);

      switch (inputIdentifier) {
        case 'copiedProductId':
          this.putCampingId();
          this.setState({ campaing: true });
          break;
        case 'copiedBudget':
          this.putBudget();
          this.setState({ budget: true });
          break;
        case 'copiedStartDate':
          this.putStartDate();
          this.setState({ startDate: true });
          break;
        default:
          break;
      }
      const newState: StateType = {
        [inputIdentifier]: true,
      };
      this.setState((prevState) => {
        return {...prevState, ...newState}
      });

      setTimeout(() => {
        const falseState: StateType={
          [inputIdentifier]: false,
        }
        this.setState((prevState)=>{
          return{...prevState,...falseState}
        });
      }, 2000);
      
    } catch (error) {
    }
  }
  };

  backArrow=()=>{
    localStorage.setItem("frompage","Dashboard")    
    this.props.navigation.navigate("Quote")    
  }

  getCampaignDetailsTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "CampaignDetails";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampaignDetailsTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getCampaignDetailsReceiver=(responseJson:any)=>{
    this.setState({
      campaignDetailscxfTooltip: responseJson
    })
  };

  getProductIdTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "ProductId";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductIdTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getProductIdReceiver=(responseJson:any)=>{
    this.setState({
      productIdTooltip: responseJson
    })
  };

  getCampDetailsBudgetlHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Budget";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampDetailsHeadingTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getCampDetailsBudgetHeadingReceiver=(responseJson:any)=>{
    this.setState({
      campDetailsBudgetHeadingTooltip: responseJson
    })
  };

  getCampDetailsStartDateTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "StartDate";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampDetailsStartDateCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getCampDetailsStartDateReceiver=(responseJson:any)=>{
    this.setState({
      campDetailsStartdate: responseJson
    })
  };
  
  //istanbul ignore next
  handleClearCampaignDetails = () => {
    const campaignDetailsKey = 'campaign_Details';
    localStorage.removeItem(campaignDetailsKey);
    localStorage.removeItem('configSelectDate');
    localStorage.setItem("activeKey", "setup")

    const locationSearchDataQuery = 'searchInput'
    const locationSelectItem = 'selectedItem'
    localStorage.removeItem(locationSearchDataQuery);
    localStorage.removeItem(locationSelectItem);
  };
  putCongratulationUpdate = () => {
    const persistId=localStorage.getItem("persistId")
    const campaignId = localStorage.getItem("campaignID")
    const rowID=persistId?persistId:campaignId
    const authToken = localStorage.getItem('accessToken')
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.putCampaingChannelsSection}/${rowID}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(this.state.campaignApiData)
    );
    this.putCampaignDetailCallID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

}

  handleButtonClick = () => {
    localStorage.setItem("mycurrentPage","visitDashboard")
    localStorage.removeItem('configSelectDate')
    localStorage.removeItem('setUpCampaignID')
    localStorage.removeItem('selectBenchmarklanguage')
    localStorage.removeItem("duraa")
    this.postCampaignCheckout()
    this.handleClearCampaignDetails();
  };
  
 
  // Customizable Area End
}