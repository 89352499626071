import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ClassNameMap, ClassKeyOfStyles } from "@material-ui/styles";
import { RouteComponentProps } from "react-router";
import { getStorageData ,logoutUser,removeStorageData} from "../../../framework/src/Utilities";

interface EditBody {
  [key: string]: string | number[] | number;
}

interface Body {
  data: EditBody;
}
interface ApiData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Body | EditBody;
  type?: string;
}

interface Role {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  role_id: number;
}

interface AccountAttributes {
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  area: string;
  role: Role;
}

interface AccountData {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: ClassNameMap<ClassKeyOfStyles<string>> | any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLogout: boolean;
  userProfileDetails: AccountData | null;
  openModel:boolean,
  select_lang: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RolesPermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileDetailsApiId: string = "";
  logoutUserApiId: string = "";
  campaignsDeleteCallID:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLogout: false,
      userProfileDetails: null,
      openModel:false,
      select_lang: {name: []},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id){
      if(responseJson?.statusCode === 401){
        logoutUser();
        this.props.history.push("/EmailAccountLoginBlock")     
       }
      if (
        this.getUserProfileDetailsApiId != null &&
        this.getUserProfileDetailsApiId ===
        apiCallId
      ) {
        if (responseJson !== undefined && responseJson.errors === undefined) {
          this.setState({ userProfileDetails: responseJson.data });         
        } else {
          if (responseJson.errors.message === "Unauthorized") {
            
            this.setState({ userProfileDetails: null });
            removeStorageData("accessToken");
            this.setState({ isLogout: false });
            this.props.history.push("/EmailAccountLoginBlock");
          }
        }
      }
      if (
        this.logoutUserApiId != null &&
        this.logoutUserApiId ===
        apiCallId
      ) {
  
        if (responseJson !== undefined && responseJson.errors === undefined) {
          removeStorageData("accessToken")
          this.setState({isLogout:false})
          this.props.history.push("/EmailAccountLoginBlock")     
         } 
         }
      if(this.campaignsDeleteCallID != null &&
        this.campaignsDeleteCallID ===
        apiCallId ){
          if(responseJson.message){
            this.props.history.push("/Dashboard")
          }
        }
      }
    // Customizable Area End
  }


  // Customizable Area Start

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };
  
  txtInputProps = this.isPlatformWeb()
  ? this.txtInputWebProps
  : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  // Customizable Area Start

  async componentDidMount() {
    const visited =  localStorage.getItem('visitedProfile')
    if(visited){
      this.setState({openModel:true})
     }
    this.getUserProfileDetails();
    localStorage.setItem("visitedProfile","visited")

    const selectedCountry = localStorage.getItem("selectedCountry");
    const lang : any = {
      german: {
               name:["Mein Profil", "Profildetails", "Sind Sie sicher, dass Sie sich abmelden möchten?", "Name","Email", "Rolle", "Telefon","Bereich","Ausloggen", "Ja","Stornieren",
              "Möchten Sie die Kampagne vor dem Auszug speichern ?", "NEIN","Ja, Speichern"],
              },
      french: {
               name:["Mon profil", "Détails du profil","Êtes-vous sûr de vouloir vous déconnecter?","Nom","E-mail", "Rôle","Téléphone","Zone","Déconnecter","Oui","Annuler", 
              "Avant de déménager, souhaitez-vous sauvegarder la campagne ?","Non","Oui, enregistrer"],
              },
      italian: {
               name:["Il mio profilo", "Dettagli profilo", "Sei sicuro di voler fare il logout?","Nome","E-mail", "Ruolo", "Telefono","la zona", "Disconnettersi","SÌ","Annulla", 
              "Prima di uscire vuoi salvare la campagna ?", "No", "Sì, salva"],
              },
    };

    const selected_Language = lang[selectedCountry as any] || { name: []};
    this.setState({select_lang:selected_Language})
  }

  openLogoutConfirmationModal = () => {
    this.setState({ isLogout: true });
  };

  closeLogoutConfirmationModal = () => {
    this.setState({ isLogout: false });
  };

  handelLogout = async () => {
    const authToken = (await getStorageData("accessToken")) || "";
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logoutUserApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutUser
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.logoutUserRestMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserProfileDetails = async () => {
    const authToken = (await getStorageData("accessToken")) || "";
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userProfileRestMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  openDialog = () => {
    this.setState({ openModel: true });
  };
//istanbul ignore next
  closeModal = () => {
    this.setState({ openModel: false });
    localStorage.removeItem("visitedProfile");
    localStorage.removeItem("closeModal");
    this.props.navigation.goBack();
  };
  // Customizable Area End
}
