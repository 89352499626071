import React from 'react';
import { Route, Redirect, RouteProps,  } from 'react-router-dom';


interface IRouteProps extends RouteProps{

}

const PrivateRoutes = ({...rest}:IRouteProps) => {

  const isAuthenticated = localStorage.getItem('accessToken') ? true : false;

  return (
    <>
   {
    isAuthenticated ? <Route {...rest}/> : <Redirect to="/EmailAccountLoginBlock" />
   }
  
    </>
  );
};

export default PrivateRoutes;
