import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import JSConfetti from "js-confetti";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, logoutUser } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
interface StateType {
  [key: string]: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  location: any;
  history: any;
  // Customizable Area End
}

interface ResponseData {
  ad_budget_name: string;
  ad_budget_value: string;
  add_ons: {
    id: string;
    name: string;
    price: string;
    price_type: string;
    discount_description: string;
    tooltip: string;
    recommended: boolean;
  }[];
}

interface S {
  // Customizable Area Start
  addData:ResponseData,
  quoteHeadingTooltip: any,
  select_lang: any,
  tooltipOpen: boolean,
  tooltipTitle: string,
  tooltipOpenQuoteHeading:boolean,
  tooltipTitleQuoteHeading:string,
  viewData:boolean,
  campaignApiData:any,
  congraShow:boolean
  
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuoteController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postQuoteEndPoint: string = "";
  getQuoteHeadingTooltipCallID:string= ""
  putQuoteCallID:string=" "
  getCampaignQuoteCallID:string=" "
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      addData:{
        ad_budget_name: "",
        ad_budget_value: "",
        add_ons: []
      },
      tooltipOpen: false,
      tooltipTitle: '',
      tooltipOpenQuoteHeading:false,
      tooltipTitleQuoteHeading:'',
      quoteHeadingTooltip:{},
      select_lang: {name: []},
      viewData:false,
      campaignApiData:{},
      congraShow:false
    }
    // Customizable Area Start

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area End
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
        );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {      
        if(apiRequestCallId === this.postQuoteEndPoint){
          this.quoteDataGet(responseJson);
        }
        if(apiRequestCallId === this.putQuoteCallID){
          this.updateQuoteReceiver(responseJson);
        }
        else if(apiRequestCallId === this.getCampaignQuoteCallID){
          this.getUpdatedApi(responseJson)
        }
        else if(apiRequestCallId === this.getQuoteHeadingTooltipCallID){
          this.getQuoteHeadingReceiver(responseJson);
        }

        if(responseJson.statusCode === 401){
          this.props.navigation.navigate("EmailAccountLoginBlock");
          logoutUser();          
        }
        

      }
    } 
  }

  async componentDidMount() {
   await this.getSellerDetail();
     this.getCampaignData();
    this.getQuoteHeadingTooltip()
   const frompage = localStorage.getItem("frompage");   
   if(frompage !== "Dashboard"){ 
    const jsConfetti = new JSConfetti();
    jsConfetti
    .addConfetti({
        confettiColors: [
            "#74c7e2",
            "#ea5053",
            "#0073a6",
            "#821e3e",
            "#709faf",
        ],
        confettiRadius: 7,
        confettiNumber: 1000,
        
    })    
   }  
    
   const viewOption:any=localStorage.getItem("visitedView")
    const showOption:any=localStorage.getItem("showData")
    if(viewOption && showOption==="true"){
      this.setState({viewData:true})
    }
    const selectedCountry = localStorage.getItem("selectedCountry");
    const lang : any = {
      german: {
               name:["Zurück","monatlich", "Details zur Kampagne"],
              },
      french: {
               name:["Retour","mensuel", "Détails de la campagne"],
              },
      italian: {
               name:["Indietro","Mensile", "Dettagli della campagna"],
              },
    }

    const selected_Language = lang[selectedCountry as any] || { name: []};
    this.setState({select_lang:selected_Language})
  }

  // Customizable Area End

  // web events

  // Customizable Area Start
  quoteDataGet = (responseJson: ResponseData) => {
    const QuoteDataDisplay = responseJson;
    this.setState({addData:QuoteDataDisplay})
  };


backArrowQuote = () => {
  const persistId=localStorage.getItem("persistId")
  const routes:any = {
    'visitrecom': 'Recommendation',
    'visitObjective': 'Objective',
    'visitChannel': 'Channels',
    'visitLocation': 'Location',
    'visitBudget': 'Budget',
    'visitAddons': 'Addons',
    'visitconfig': 'Configuration',
    'visitDashboard': 'Configuration'
  };
  
  const recomm:any = localStorage.getItem('mycurrentPage');
  const route:any = routes[recomm];
  
  if (route) {
    this.props.navigation.navigate(route);
  }  
  if(persistId && !recomm){
    this.props.navigation.navigate("Configuration")
  }
 
};

  getCampaignData=()=>{
    const  persistId= localStorage.getItem("persistId")
    let Camping_Id = localStorage.getItem('campaignID')
    const rowId=persistId?persistId:Camping_Id
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaignQuoteCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ConfigurationDataEndPoint}${rowId}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
   }
   getUpdatedApi=(responseJson:any)=>{
    let key=responseJson.visited_key;
    if(!key.includes(responseJson.active_page) )
    {
       key.push(responseJson.active_page)
    }
    const campaignData={
      "campaign": {
        "visited_key":key,
        "active_page":"quote"
      },
      "progress": {
        "current_page": "quote"
      }  
  }
  this.setState({campaignApiData:campaignData},()=>this.putQuoteUpdate())
  
   }
  putQuoteUpdate = () => {
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const authToken = localStorage.getItem('accessToken')
    const campaignData={
        "campaign": {
          "visited_key":['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration","quote"],
          "active_page":"campaign details"
        },
        "progress": {
          "current_page": "campaign_details"
        }  
    }
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken
    };
    const requestMessagelist = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagelist.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessagelist.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.putCampaingChannelsSection}/${rowID}`
    );
    requestMessagelist.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessagelist.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(this.state.campaignApiData)
    );
    this.putQuoteCallID = requestMessagelist.messageId;

    runEngine.sendMessage(requestMessagelist.id, requestMessagelist);

}
  handleTooltipToggleQuoteHeading = () => {
    this.setState({ tooltipOpenQuoteHeading: !this.state.tooltipOpenQuoteHeading });
  };
  handleImageImageMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenQuoteHeading:true});
    }
  };
   handleImageImageMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenQuoteHeading:false});}
  }
   handleMouse=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenQuoteHeading:!this.state.tooltipOpenQuoteHeading});
    }
  }

  handleTooltipCloseQuoteHeading = () => {
    this.setState({ tooltipOpenQuoteHeading: false });
  };
  
  handleTooltipOpenQuoteHeading = (title: string) => {
    this.setState({ tooltipTitleQuoteHeading: title, tooltipOpenQuoteHeading: true });
  };
updateQuoteReceiver=(responseJson:any)=>{
  if(this.state.congraShow){
    this.props.navigation.navigate("Congratulations");   

  }
}
  campaingPage = () => {
   this.setState({congraShow:true})
    if(!this.state.viewData){
      this.putQuoteUpdate()
     
    }

  };

  getSellerDetail = async (): Promise<void> => {
    const token = await getStorageData("accessToken");
    const language = await getStorageData("selectedCountry");
    const campaignID = await getStorageData("campaignID");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    let formDataCampaign = {
      data: {
        campaign_id: campaignID,
      },
    };   
    
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postQuoteEndPoint = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.potQuote_id
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postQuoteEndPoint}?language=${language}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getQuoteHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Quote";

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

const requestMessageList = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
);

this.getQuoteHeadingTooltipCallID = requestMessageList.messageId;

requestMessageList.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
);

requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
);

requestMessageList.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);

runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };


  getQuoteHeadingReceiver=(responseJson:any)=>{
    this.setState({
      quoteHeadingTooltip: responseJson
    })
  };
  // Customizable Area End
}
