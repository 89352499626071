import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  ClassNameMap,
  ClassKeyOfStyles,
} from '@material-ui/styles';
// Customizable Area End

export const configJSON = require("./config");

export interface LoginProps {
  navigation: Object;
  id: string;
  classes:ClassNameMap<ClassKeyOfStyles<string>>;
}

export interface S {
  // Customizable Area Start
  select_lang: any;
  lang:any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}  

export default class EmailAccountLoginControllerBlock extends BlockComponent<LoginProps, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: LoginProps) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      select_lang: {name: []},
       lang  :{
        german: {
                 name:["Willkommen", "Melden Sie sich bei Ihrem Azure-Konto an", "Anmelden"],
                },
        french: {
                 name:["Bienvenue", "Connectez-vous à votre compte Azure", "Se connecter"],
                },
        italian: {
                 name:["Benvenuta", "Accedi al tuo account Azure", "Login"],
                },
      }
    };

    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    const selectedCountry = localStorage.getItem("selectedCountry")??'german';
    
   

    const selected_Language =this.state. lang[selectedCountry as any] || { name: []};
    this.setState({select_lang:selected_Language})
    // Customizable Area End
  }

  azureSAMLLogin = ()=>{
    window.location.replace(`${configJSON.redirectURL}`);
  }

  // Customizable Area End
}
