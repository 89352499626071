import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles, Theme, Typography, Button } from "@material-ui/core";

interface IConfirmationProps {
  isOpen: boolean;
  closeModal: () => void;
  confirmationText: string;
  handleConfirmation: () => void;
  yes: string;
  cancel : string
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    width: "585px",
    maxWidth: '95%',
    height: "340px",
    borderRadius: "24px",
    background: "#FFF",
    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {
    color: "#000",
    textAlign: "center",
    fontFamily: "Panton Narrow semibold",
    fontSize: "24px",
    fontWeight: 700, // Corrected "fonWeight" to "fontWeight"
    letterSpacing: "-0.12px",
    marginBottom: "24px",
  },
  modalConfirmationButton: {
    width: "393px",
    maxWidth: '90%',
    padding: "12px 0px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "48px",
    background: "#005E6E",
    color: "white",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 700, // Corrected "fonWeight" to "fontWeight"
    marginBottom: "16px",
    textTransform: "none", // Corrected "text-transform" to "textTransform"
    "&:hover": {
      background: "#005E6E",
    },
    "&>span": {
      fontFamily: "Panton Narrow semibold",
    },
  },
  modalRejectionButton: {
    width: "393px",
    maxWidth: '90%',
    padding: "12px 0px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "48px",
    border: "1px solid #005E6E",
    background: "white",
    color: "black",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 700, // Corrected "fonWeight" to "fontWeight"
    fontFamily: "Panton Narrow semibold",
    textTransform: "none", // Corrected "text-transform" to "textTransform"
    "&:hover": {
      background: "white",
    },
    "&>span": {
      fontFamily: "Panton Narrow semibold",
    },
  },
}));

function ConfirmationModal(props: IConfirmationProps) {
  const { isOpen, closeModal, confirmationText, handleConfirmation, yes, cancel } = props;
  const ModalView = Modal as any;
  const classes = useStyles();

  return (
    <ModalView open={isOpen} onClose={closeModal} className={classes.modal}>
      <div className={classes.modalContainer}>
        <Typography className={classes.heading}>{confirmationText}</Typography>
        <Button
          className={classes.modalConfirmationButton}
          onClick={handleConfirmation}
        >
          {yes}
        </Button>
        <Button className={classes.modalRejectionButton} onClick={closeModal}>
          {cancel}
        </Button>
      </div>
    </ModalView>
  );
}

export default ConfirmationModal as any;
