import React, { RefObject } from "react";
import {
  Typography,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Box,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Hidden,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter, RouteComponentProps } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { MenuProps as MuiMenuProps } from '@material-ui/core/Menu';
export const configJSON = require("../../blocks/dashboard/config.json");
const images = require("../../blocks/dashboard/src/assets");

import { testNavi } from "../../../packages/blocks/utilities/src/CommonUtilities";
// Customizable Area End

interface NavigationProps {
  menuItems: string[];
  activeMenuItem: string[];
}

type MenuProps = {
  anchorEl: HTMLElement | null | React.MouseEventHandler<HTMLElement> | undefined;
  open: boolean;
  onClick: () => void;
  onClose: () => void;
} & MuiMenuProps;


type Menu = {
  route: string;
  title: string;
  icon: string;
  activeIcon: string;
  increase: string;
  increaseActive: string;
  subMenu?: subMenu[];
  decrease?: string;
  decreaseActive: string,
  contentKey: string
}

type subMenu = {
  route: string,
  title: string,
  icon: string,
  activeIcon: string,
  contentKey: string
};
interface ApiData {
  photo_url: string
};
interface UserData {
  data: {
    attributes: {
      email: string;
      first_name: string;
      full_phone_number: string;
      last_name: string;
      created_at: string;
      updated_at: string;
      device_id: string | null;
      unique_auth_id: string;
      area: string;
      role: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
        role_id: number;
      };
    };
  };
  first_name: string;
  role: {
    name: string;
  };
  id: string;
  type: string;
}

interface UserDetails {
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  area: string;
  role: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    role_id: number;
  };
}
interface Country {
  name: string;
  visible: boolean;
  img: string;
  flag: string;
}

interface S {
  openModel: boolean,
  openModel1: boolean,
  campaign:any,
  fromDasboard: boolean,
  activeItem: string,
  selectedItem: string[],
  campaignsMenuOpen: boolean,
  dashboardActive: boolean,
  campaignsActive: boolean,
  mobileOpen: boolean,
  select_lang: any,
  sidebarMenuList: {
    Dashboard: boolean,
    Campaigns: boolean,
    [key: string]: boolean,
  },
  userDetails: UserDetails,
  anchorEl: null,
  ProfilePhoto: string,
  subMenuOpen: boolean,
  setUpActive: boolean,
  activeSubMenu: string,
  isPopupVisible: boolean;
  countryList: any[];
  selectedCountry: string,
  navigationData: any,
  active:any,
  visited:any,
  sidebarRendered:boolean,
  elementWidth: number | null;
  sidebarSubdata:any,
  openLoader:boolean
}
interface NavigationData {
  menu: [
    {
      id: number
      content_key: string
      active_image: string
      inactive_image: string
      name: string
    },
  ]
}

interface Submenu {
  id: number,
  content_key: string,
  active_image: string,
  inactive_image: string,
  name: string,
}
interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface Props extends RouteComponentProps {
  classes: Record<string, string>;
  children?: React.ReactNode;
  navigation: NavigationProps;
  setItem?:any;
  navigate?:any
  postCreateCampaign?:any;
  onDashboardSave?:any;
  onUpdateDashboardClick?:any;
  ShowModel?:boolean;
}

class Sidebar extends BlockComponent<Props, S, SS> {
  getUserDetailsID: string = ""
  getAllCampaignListApiCallId: string = ''
  userProfilePhotoCallId: string = ""
  getNavigationApicallId: string = ""
  myElementRef: RefObject<HTMLDivElement>;
  campaignsDeleteCallID: string = ""
  getCampaignConfigurationCallID:string=""
  updateCampaignBudgetMessageId:string=""
  constructor(props: Props) {
    super(props);
    this.myElementRef = React.createRef<HTMLDivElement>();
    this.state = {
      activeItem: "",
      selectedItem: ["setup"],
      campaignsMenuOpen: false,
      mobileOpen: false,
      dashboardActive: false,
      campaignsActive: false,
      sidebarMenuList: {
        Dashboard: false,
        Campaigns: false,
      },
      select_lang: { name: [] },
      openModel: false,
      openModel1: false,
      campaign:'',
      fromDasboard: false,
      userDetails: {} as UserDetails,
      anchorEl: null,
      ProfilePhoto: '',
      setUpActive: true,
      subMenuOpen: true,
      activeSubMenu: '',
      isPopupVisible: false,
      countryList: [
        { name: "german", visible: true, img: "", flag: images.germanFlag },
        { name: "french", visible: false, img: "", flag: images.frenchFlag },
        { name: "italian", visible: false, img: "", flag: images.italiaFlag }
      ],
      selectedCountry: 'german',
      navigationData: {},
      active:false,
      visited:false,
      sidebarRendered: false,
      elementWidth: null,
      sidebarSubdata:{
        activeKey:"setup",
        visitedKey:["setup"]
      },
      openLoader:false
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const hasLoadedBefore = localStorage.getItem('hasLoadedBefore');
    if (!hasLoadedBefore) {
      localStorage.setItem('hasLoadedBefore', "true");
      window.location.reload();
    }

    await Promise.all([this.getUserListDetails(), this.updateUserDetails(), this.getNavigationApi()]);
    const storedLanguage = localStorage.getItem('selectedCountry');
    const defaultLanguage = storedLanguage || 'german';
    this.handleCountryClick(defaultLanguage);
    const route = this.props.location.pathname
const setUpCampaignID=localStorage.getItem("setUpCampaignID")
const persistId=localStorage.getItem("persistId")
if(setUpCampaignID || persistId){
  this.getCampaignConfigurationData()
}
    const visited = localStorage.getItem('visitedProfile')
    if (visited) {
      // this.openModel = true;
      // this.setState({openModel:true})
    }
    // this.getUserProfileDetails();
    localStorage.setItem("visitedProfile", "visited")

    const selectedCountry = localStorage.getItem("selectedCountry");
    const lang: any = {
      german: {
        name: ["Mein Profil", "Profildetails", "Sind Sie sicher, dass Sie sich abmelden möchten?", "Name", "Email", "Rolle", "Telefon", "Bereich", "Ausloggen", "Ja", "Stornieren",
          "Möchten Sie die Kampagne vor dem Auszug speichern ?", "NEIN", "Ja, Speichern","Die Änderungen werden nicht gespeichert, wenn nicht alle Felder ausgefüllt sind."],
      },
      french: {
        name: ["Mon profil", "Détails du profil", "Êtes-vous sûr de vouloir vous déconnecter?", "Nom", "E-mail", "Rôle", "Téléphone", "Zone", "Déconnecter", "Oui", "Annuler",
          "Avant de déménager, souhaitez-vous sauvegarder la campagne ?", "Non", "Oui, enregistrer","Les modifications ne seront pas enregistrées si tous les champs ne sont pas remplis."],
      },
      italian: {
        name: ["Il mio profilo", "Dettagli profilo", "Sei sicuro di voler fare il logout?", "Nome", "E-mail", "Ruolo", "Telefono", "la zona", "Disconnettersi", "SÌ", "Annulla",
          "Prima di uscire vuoi salvare la campagna ?", "No", "Sì, salva","Le modifiche non verranno salvate se non vengono compilati tutti i campi."],
      },
    };

    const selected_Language = lang[selectedCountry as any] || { name: [] };
    this.setState({ select_lang: selected_Language })

    const sideBarOpen = localStorage.getItem("sideBarOpen");
   
    
    if (sideBarOpen === "true" ) {     
      let activeMenuItem = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaigndetails'];
      this.setState({
        selectedItem: [...activeMenuItem],    
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true,    
      });

     localStorage.removeItem('configuration')
    }

    if (route === "/Dashboard" || route === "/DashBoard") {
      console.log(window.location.pathname, "inside ");
      this.setState({
        dashboardActive: true,
        campaignsActive: false,
        campaignsMenuOpen: false,
      });
    } else if (route === "/SetUp") {
      console.log(route, "SetUproute");
      this.setState({
        campaignsActive: true,
        campaignsMenuOpen: true,
        dashboardActive: false,
        selectedItem: ['setup']
      });
    }
    else if (window.location.pathname === "/RolesPermissions") {
      this.setState({
        dashboardActive: false,
        campaignsActive: false,
        campaignsMenuOpen: false,
      });
    }
    else if (window.location.pathname === "/SetUp") {
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      })
    } else if (window.location.pathname === "/Objective") {
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      });
    } else if (window.location.pathname === "/Channels") {
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      });
    } else if (window.location.pathname === "/Budget") {
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      });
    }
    else if (window.location.pathname === "/Configuration" || window.location.pathname.split("/")[1] === "Configuration") {
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      });
    }
    else if (window.location.pathname === "/Addons") {
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      });
    }
    else if (window.location.pathname === "/Recommendation") {
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      });
    }
    else if (window.location.pathname === "/Congratulations") {
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      });
    }
    else if (window.location.pathname === "/Location") {
     
      this.setState({
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen: true
      });
    }
    else {
      this.setState({
        dashboardActive: true,
        campaignsActive: true,
      });
    }

    const storedCountry = localStorage.getItem('selectedCountry');
    if (storedCountry) {
      this.handleCountryClick(storedCountry);
    }
    if (this.state.navigationData) {
      this.updateElementWidth();
    }
    window.addEventListener('resize', this.updateElementWidth);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateElementWidth);
  }

  async componentDidUpdate() {
    setTimeout(() => {
      this.updateElementWidth();
    }, 100);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.callApi) {
      this.getCampaignConfigurationData()
    }
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    if (getName(MessageEnum.AccoutLoginSuccess) === message.id) {
      this.updateUserDetails();
    }    
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.getUserDetailsID && responseJson) {
      this.getUserReciver(responseJson)
    } else if (apiRequestCallId === this.userProfilePhotoCallId && responseJson) {
      this.profilePhotoReciver(responseJson)
    } else if (apiRequestCallId === this.getNavigationApicallId) {
      this.navigationReceiver(responseJson)
    }else if(apiRequestCallId===this.getCampaignConfigurationCallID){
      this.getCampaignSideBarActive(responseJson)
    }
    if (this.campaignsDeleteCallID != null &&
      this.campaignsDeleteCallID ===
      apiRequestCallId) {
        localStorage.removeItem("editDashboard");
        localStorage.removeItem("campaignID")
      if (responseJson.message) {      
        this.getAllCampaignList();
        this.setState({
          openModel: false
        })
      }
    }

    runEngine.debugLog("Message Recived", message);
    // Customizable Area End

  }

  getCampaignListEndPoint = () => {   

    let queryParameters = [];
    const selectedCountry = localStorage.getItem('selectedCountry');

    if (selectedCountry) {
      queryParameters.push(`language=${selectedCountry}`);
    }
  
    const queryString = queryParameters.length > 0 ? '?' + queryParameters.join('&') : '';

    return `${"/bx_block_custom_form/campaigns"}${queryString}`;
  }

  getAllCampaignList = async () => {

    const authToken = localStorage.getItem('accessToken') || '';

    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const endPoint = this.getCampaignListEndPoint()

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCampaignListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getNavigationApi = async (): Promise<void> => {
    const token = localStorage.getItem("accessToken");
    const language = localStorage.getItem('selectedCountry');
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNavigationApicallId = requestMessageList.messageId;

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    const endpoint = `bx_block_custom_form/navigations?token=${token}&language=${language}`;
    console.log("Endpoint:", endpoint);

    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }


  navigationReceiver = (responseJson: any, isReload?:boolean) => {

    if (responseJson.menu && responseJson.menu.length > 0) {
      const menuItems = responseJson.menu.map((menu: any) => {
        let subMenuData = [];
        if (menu.content_key === 'campaigns' && menu.sub_menu) {
          subMenuData = menu.sub_menu.map((submenu: any) => ({
            title: submenu.name,
            icon: submenu.inactive_image,
            activeIcon: submenu.active_image,
            contentKey: submenu.content_key
          }));
        }

        return {
          title: menu.name,
          icon: menu.inactive_image,
          activeIcon: menu.active_image,
          contentKey: menu.content_key,
          subMenu: subMenuData
        };
      });

      this.setState({
        navigationData: {
          menu: menuItems
        }
      }, () => {
        if(isReload){
          window.location.reload()
        }
      });
    }
  };
  onSideBarClick = (menuItem: any) => {
    // this.send(testNavi(this.props))
    
    localStorage.removeItem("frompage")
    if (menuItem.contentKey === "dashboard") {
      this.setState({
        dashboardActive: true,
        campaignsActive: false,
        campaignsMenuOpen: false,
      });
      localStorage.removeItem("closeModal");
      if (window.location.pathname === "/RolesPermissions" || window.location.pathname === "/Dashboard") {
        this.setState({
          openModel: false,
        });
        this.setState({ fromDasboard: false })
        this.props.history.push("/Dashboard")
      } else {
        // if(location.pathname === "/SetUp"){
        //   let iscomingFromDashboard = "true";
        //   this.props.postCreateCampaign(iscomingFromDashboard)       

        // }
              
        this.setState({
          openModel: true,  
          campaignsMenuOpen: true,        
        });
        this.setState({ fromDasboard: true })

      }
         } else if (menuItem.contentKey === "campaigns") {
      this.setState((prevState) => ({
        campaignsActive: true,
        // campaignsMenuOpen: !prevState.campaignsMenuOpen,
        dashboardActive: false,
        selectedItem:["setup"]
      }))
      let campaign_Details = localStorage.getItem("campaign_Details");  
      if(!campaign_Details){     
         localStorage.removeItem("sidebarData")
      }
      this.props.history.push("/SetUp")
    } else {
      this.setState({
        campaignsActive: true,
        campaignsMenuOpen: true,
      })
    }

    setTimeout(() => {
      this.updateElementWidth();
    }, 0);
  }
  getCampaignConfigurationData=()=>{
    this.setState({openLoader:true})
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const header = {
      "Content-Type":"application/json",
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaignConfigurationCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       "GET"
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_custom_form/get_campaign?id="}${rowID}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
   
   }
   getCampaignSideBarActive=(responseJson:any)=>{
    this.setState({openLoader:false})
    const obj={
      activeKey:responseJson.active_page,
      visitedKey:responseJson.visited_key
    }
    this.setState({sidebarSubdata:obj})
   }
  handleSidebarSubmenuClick = (subMenu: any) => {
    localStorage.setItem("selectedItem", subMenu.contentKey);
    this.setState({ activeSubMenu: subMenu.activeIcon });
    let activeItems = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaigndetails']
    let activeSubMenu = activeItems.indexOf(localStorage.getItem("selectedItem") || "setup")
    let activeMenuItem = activeItems.filter((value, index) => index < activeSubMenu + 1)
    
    if (subMenu.contentKey === "setup") {
      this.setState({
        selectedItem:[...activeMenuItem],
        dashboardActive: false,
        campaignsActive: true,
        campaignsMenuOpen:true,
      });
      this.props.history.push("/SetUp")
    }
    else if (subMenu.contentKey === "objective") {
      this.setState({
        selectedItem:[...activeMenuItem],
        campaignsActive: true,
        campaignsMenuOpen:true,
        dashboardActive: false,
      })
      this.props.history.push("/Objective")
    }
    else if (subMenu.contentKey === "channels") {
      this.setState({
        selectedItem: [...activeMenuItem],
        campaignsActive: true,
        campaignsMenuOpen: true,
        dashboardActive: false,
      })
      this.props.history.push("/Channels")
    }
    else if (subMenu.contentKey === "location") {
      this.setState({
        selectedItem:[...activeMenuItem],
        campaignsActive: true,
        campaignsMenuOpen: true,
        dashboardActive: false,
      })
      this.props.history.push("/Location")
    }
    else if (subMenu.contentKey === "budget") {
      this.setState({
        selectedItem:[...activeMenuItem],
        campaignsActive: true,
        campaignsMenuOpen:true,
        dashboardActive: false,
      })
      this.props.history.push("/Budget")
    }
    else if (subMenu.contentKey === "addons") {
      this.setState({
        selectedItem: [...activeMenuItem],
        campaignsActive: true,
        campaignsMenuOpen: true,
        dashboardActive: false,
      })
      this.props.history.push("/Addons")
    }
    else if (subMenu.contentKey === "configuration") {
      this.setState({
        campaignsActive: true,
        selectedItem:[...activeMenuItem],
        campaignsMenuOpen:true,
        dashboardActive: false,
      })
      this.props.history.push("/Configuration")
    }
    else if (subMenu.contentKey === "quote") {
      this.setState({
        selectedItem:[...activeMenuItem],
        campaignsActive: true,
        campaignsMenuOpen: true,
        dashboardActive: false,
      })
      this.props.history.push("/Quote")
    }
    else if (subMenu.contentKey === "campaign details") {
      this.setState({
        selectedItem:[...activeMenuItem],
        campaignsActive: true,
        campaignsMenuOpen: true,
        dashboardActive: false,
      })
      this.props.history.push("/Congratulations")
    } else {
      this.setState({
        selectedItem: [...activeMenuItem],
        dashboardActive: true,
        campaignsActive: true,
      });
    }
  }

  updateElementWidth = () => {
    const element = this.myElementRef.current;
    if (element) {
      const elementWidth = element.offsetWidth;
      this.setState({ elementWidth });
    }else {
      setTimeout(this.updateElementWidth, 100);
    }
  };

  renderSidebarList() {
  
    const data = this.state.sidebarSubdata && this.state.sidebarSubdata
    const { activeItem, selectedItem, campaignsMenuOpen, dashboardActive, campaignsActive, sidebarMenuList } = this.state;
    const currentRoute = this.props.location.pathname;
    const { classes } = this.props;

    if (!this.state.navigationData || !this.state.navigationData) {
      return null;
    }

      const items = (this.state.navigationData.menu || [])?.map((menuItem: any, index: number) => {
      const includesPath = menuItem.contentKey.toLowerCase().includes(currentRoute.toLowerCase());
      const isCampaigns = menuItem.contentKey === "campaigns";
      const isDashboard = menuItem.contentKey === "dashboard";
      const menuPath = menuItem.subMenu && menuItem.subMenu.length > 0;
      const activeIcon = menuItem.activeIcon;
      const inactiveIcon = menuItem.icon;
      const ActiveCampaigns = campaignsActive
      const ActiveDashboard = dashboardActive
      

      return (
        <Box key={`${index}-${menuItem.contentKey}-${index}`} >
          <ListItem
            data-test-id={"sidebar-list-item"}
            button
            className={`${(includesPath || (menuPath && ActiveCampaigns && isCampaigns)) ? classes.listItemActive : classes.listItem} 
            ${ActiveCampaigns && isCampaigns && (includesPath || menuPath) && classes.removeBg}
            ${(ActiveDashboard && isDashboard)&& classes.dashboredcolor} 
            `}
            onClick={() => {
              this.onSideBarClick(menuItem)
            }}
            onMouseEnter={() => this.handleMouseOverEventNavigationSideBar(menuItem.contentKey)}
            onMouseLeave={() => this.handleMouseLeaveEventNavigationSideBar(menuItem.contentKey)}
            ref={this.myElementRef}
          >
            <Box>
              <img
                style={{ height: '24px', width: '24px' }}
                src={
                  menuItem.contentKey === "dashboard"
                    ? (dashboardActive ? activeIcon : inactiveIcon)
                    : (campaignsActive ? activeIcon : inactiveIcon)
                }
                alt={menuItem.title}
                width='31.4px'
                height='32px'
              />
            </Box>
            <ListItemText data-testid={"nav_add_item"} >
              <Typography   className={`${(ActiveDashboard && isDashboard)? classes.dashboredcolors: classes.textColor} `} >{menuItem.title}</Typography>
            </ListItemText>
            <Box style={{ display: 'flex',width:24 }}>
              <img
                
                src={
                  menuItem.contentKey === "dashboard"
                    ? (dashboardActive ? images.decreaseActive : images.blckplus)
                    : (campaignsActive && campaignsMenuOpen ? images.decreaseActive : images.blckplus)
                }
              />
            </Box>
          </ListItem>
          {campaignsMenuOpen && menuItem.subMenu && menuItem.subMenu.length > 0 && (
            <Box className={classes.subMenu} sx={{ marginBottom: "10px" }}>
               {menuItem.subMenu.contentKey}
              {menuItem.subMenu.map((subItem: any, subIndex: number) => (
                <div
                key={subItem.contentKey}
                className={`${classes.subMenuItem} ${subIndex === 7 ? classes.divider : ""} ${selectedItem.includes(subItem.contentKey) && classes.activeSubMenuItem}`}
                onClick={() => data?.activeKey === subItem.contentKey ? this.handleSidebarSubmenuClick(subItem) : data?.visitedKey.includes(subItem.contentKey) ? this.handleSidebarSubmenuClick(subItem) : null }
                >
                  <ListItemIcon style={{alignSelf:'center'}}>
                    <img
                   
                      // src={selectedItem.includes(subItem.contentKey) ? subItem.activeIcon : subItem.icon}
                      src={data?.activeKey === subItem.contentKey ? subItem.activeIcon : data?.visitedKey.includes(subItem.contentKey) ? subItem.activeIcon : subItem.icon}
                      alt={subItem.title}
                    />
                  </ListItemIcon>
                  <ListItemText data-testid={"nav_add_item"} style={{ marginLeft: "-20px" }}>
                  <Typography
  style={{
    color: data?.activeKey === subItem.contentKey ? "#005E6E" : data?.visitedKey.includes(subItem.contentKey) ? "#005E6E" : "#B4B4B4",
    fontWeight: data?.activeKey === subItem.contentKey ? 700 : data?.visitedKey.includes(subItem.contentKey) ? 400 : 400,
    fontFamily: data?.activeKey === subItem.contentKey ? 'panton Narrow Bold' : 'panton Narrow Regular'
  }}     
  >               {subItem.title}
                  </Typography>
                  </ListItemText>
                </div>
              ))}
            </Box>
          )}
        </Box>
      );
    });
    return items;
  }
  renderCountryList = () => {
    const { classes } = this.props
    const { countryList, isPopupVisible, elementWidth } = this.state;
    if (elementWidth === null) {
      return null; // Return null if the popup is not visible or elementWidth is null
    }
    return (
      isPopupVisible && (
        <div className={classes.countyPopup} style={{width: `${elementWidth - 33}px`}}>
          <div className="popup">
            {countryList.filter((item: any) => !item.visible).map((country, index) => (
              <div className={classes.countryName} key={index} onClick={() => this.handleCountryClick(country.name,true)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: 27, marginLeft: '-0.1%', marginTop: '-5px', cursor: 'pointer' }}>
                <div className={classes.countryNameText} style={{
                  color: '#94A3B8',
                  fontSize: '14px',
                  fontWeight: 400,
                  fontFamily: 'Panton Narrow Regular'
                }}> {country.name.charAt(0).toUpperCase() + country.name.slice(1).toLowerCase()}</div>
                <div>
                  <img src={country.flag} alt={`${country.name} Flag`} style={{ width: '30px', height: '20px', padding: '16px 2px 12px 0px' }} />
                </div>
              </div>
            ))}
          </div>
          <div className={classes.arroDiv} style={{  }}></div>
        </div>

      )
    )
  }

  renderUserProfileComponent = () => {
    const { classes } = this.props
    const { countryList,elementWidth } = this.state;
    const displayedCountries = countryList.filter(country => country.visible);
    

    return (
      <Box component="div"   className={`${classes.userInfoBox} ${
        this.props.location.pathname.includes("/RolesPermissions") ? classes.skyBlueBackground : ""
      }`} >
        <Box component="div" onClick={this.handelNavigateUserProfile} style={{ cursor: 'pointer' }}>

          {this.state.ProfilePhoto ? (
            <img
              src={this.state.ProfilePhoto}
              alt="User Profile"
              style={{ width: '24px', height: '24px' }}
            />
          ) : (
            <img
              src={this.props.location.pathname.includes("/RolesPermissions") ? images.roleicon:images.userProfileLogo}
              alt="User Profile"
              style={{ width: '24px', height: '24px' }}
            />
          )}
        </Box>
        <Box component="div" onClick={this.handelNavigateUserProfile}>
          <Typography 
          className={`${classes.userName} ${
            this.props.location.pathname.includes("/RolesPermissions") ? classes.skyBluecolor : ""
          }`}
          >{this.state.userDetails?.first_name}</Typography>
          <Typography 
           className={`${classes.userPosition} ${
            this.props.location.pathname.includes("/RolesPermissions") ? classes.skyBluecolors : ""
          }`}
          >{this.state.userDetails?.role?.name}</Typography>
        </Box>
        <Box component="div">
        </Box>
        <div onClick={this.handleClicks}>
          {displayedCountries.map(country => (
            <img src={country.flag} alt={`${country.name} Flag`} style={{ cursor: 'pointer' }} />
          ))}
        </div>
      </Box>
    );
  };
  getUserListDetails = async (): Promise<void> => {
    const token = localStorage.getItem("accessToken")
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDetailsID = requestMessageList.messageId;

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/profile?token=" + token
    );

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getUserReciver = (responseJson: UserData) => {
    if (responseJson?.data) {
      this.setState({ userDetails: responseJson.data.attributes }, () => {
        this.PostProfilePhoto(responseJson.data.attributes.email)
        this.forceUpdate();
      })
    }
  }

  updateUserDetails = async () => {
    await this.getUserListDetails();
  };

  PostProfilePhoto = async (data: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accessToken")
    };

    let formData = {
      "data": {
        "email": data
      }
    };

    const requestMessage1
      = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    requestMessage1
      .addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    requestMessage1
      .addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "account_block/profile_photo"

      );
    requestMessage1
      .addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
    requestMessage1
      .addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(formData)
      );
    this.userProfilePhotoCallId = requestMessage1.messageId;

    runEngine.sendMessage(requestMessage1.id, requestMessage1
    );
  }

  profilePhotoReciver = (responseJson: ApiData) => {
    if (responseJson.photo_url) {
      this.setState({ ProfilePhoto: responseJson.photo_url })
    }
  }

  navigateToRoute = (path: { route: string }) => {
    this.props.history.push(path.route);
  };

  handleMouseEnter = (menuItem: any) => {
    this.setState({ activeItem: menuItem.contentKey });
  };

  handleMouseLeave = (menuItem: any) => {
    this.setState({ activeItem: "" });
  };

  handleMouseOverEventNavigationSideBar = (target: string) => {
    if (!this.state.sidebarMenuList[target]) {
      this.setState((prevState) => ({
        sidebarMenuList: {
          ...prevState.sidebarMenuList,
          [target]: true,
        },
      }));
    }
  };

  handleMouseLeaveEventNavigationSideBar = (target: string) => {
    this.setState((prevState) => ({
      sidebarMenuList: {
        ...prevState.sidebarMenuList,
        [target]: false,
      },
    }));
  };

  handelNavigateUserProfile = () => {
     localStorage.setItem("rolesandpermssion","true");

    this.setState({ fromDasboard: false })

    if (window.location.pathname === "/Dashboard" || window.location.pathname === "/RolesPermissions") {
      this.setState({ openModel: false })   

      this.props.history.push("/RolesPermissions")
    } else {
      this.setState({ openModel: true })
     
    }
   
  };

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  handleClick = (event: { currentTarget: null }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    localStorage.removeItem('setUpCampaignID');
    localStorage.removeItem("visitedView")
    localStorage.removeItem("showData")
    localStorage.removeItem("duraa")
    localStorage.removeItem("persistId")
  };
  handleClicks = (event: any) => {
    event.preventDefault()
    this.setState((prevState) => ({
      isPopupVisible: !prevState.isPopupVisible,
    }));

  };



  handleClearCampaignDetails = () => {
    const campaignDetailsKey = 'campaign_Details';
    localStorage.removeItem(campaignDetailsKey);
    localStorage.removeItem('configSelectDate');
    localStorage.setItem("activeKey", "setup")
    localStorage.removeItem("myObject")
    localStorage.removeItem('setUpCampaignID');
    localStorage.removeItem("persistId")
    const locationSearchDataQuery = 'searchInput'
    const locationSelectItem = 'selectedItem'
    localStorage.removeItem(locationSearchDataQuery);
    localStorage.removeItem(locationSelectItem);   
    
  };

  campaignSave = async() => {

    if (this.props.ShowModel ) {
      setTimeout(() => {
        this.setState({
          openModel1: false,
        });       
      }, 300);       
            
    } else {
      setTimeout(() => {
        this.setState({
          openModel1: true,
        });
      }, 300); 
    }   
 
    
    localStorage.removeItem('configSelectDate');   
   
  
    let Camping_Id = localStorage.getItem('setUpCampaignID');
    let visitedsetup=localStorage.getItem("visitedSetup")
    let persistId=localStorage.getItem("persistId")
    if(this.props.onUpdateDashboardClick || this.props.postCreateCampaign){      
      if (!Camping_Id  && !persistId) {
        localStorage.setItem("navigate","dashboardNav")
      await this.props.postCreateCampaign();
      }else if(visitedsetup || persistId){
        localStorage.setItem("navigate","dashboardNav")
        await this.props.onUpdateDashboardClick()
      }  
    }
    if( this.props.onDashboardSave){
      localStorage.setItem("navigate","dashboardNav")
     await this.props.onDashboardSave()
    }
    this.setState({ openModel: false });
    let keysToRemove = ['selectBenchmarklanguage', 'visitedrecomm','visitedBudget', "persistId",'visitedObjective',"visitedLocation","visitedChannel","visitedAddon","visitedConfigure","duraa","showData","visitedView","setUpCampaignID"];
      keysToRemove.forEach(function(key) {
    localStorage.removeItem(key);
    });
  
    localStorage.setItem('closeModal', 'true')
    let editDashboardKey = localStorage.getItem("isComingFromDashboard");
    if(editDashboardKey !== "true"){
      this.handleClearCampaignDetails();
    }    


     
  }

  closeModal = () => {
    
    this.setState({ openModel: false });
    this.setState({ dashboardActive:false });  
    this.setState({ campaignsActive:true});   
    localStorage.removeItem("visitedProfile");
    localStorage.removeItem("closeModal");
 
  };

  closeModal1 = () => {   
    this.setState({ openModel1: false });
    this.setState({ dashboardActive:false });    
    // window.location.reload();  
    // localStorage.removeItem("visitedProfile");
    localStorage.removeItem("closeModal1");
 
  };

  campaignNo = async () => {
    localStorage.removeItem('configSelectDate')
    let editDashboardKey = localStorage.getItem("editDashboard");
    let keysToRemove = ['visitedSetup','visitedrecomm','selectBenchmarklanguage','visitedBudget', "persistId",'visitedObjective',"visitedLocation","visitedChannel","visitedAddon","visitedConfigure","duraa","showData","visitedView","setUpCampaignID"];
    keysToRemove.forEach(function(key) {
  localStorage.removeItem(key);
});
    if(editDashboardKey !== "true"){
      await this.deletecampaigns();
    }
  

    setTimeout(() => {
      if (this.state.fromDasboard) {
        this.props.history.push("/Dashboard");  
      } else {
        this.props.history.push("/RolesPermissions");
      }
  
    }, 500);
    this.handleClearCampaignDetails();
    localStorage.removeItem('InputFiled1');
    localStorage.removeItem('InputFiled2');
    localStorage.removeItem('InputFiled3');  
    localStorage.removeItem('clickObjectiveName'); 
  }

  deletecampaigns = () => {
    let Camping_Id = localStorage.getItem('campaignID')
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.campaignsDeleteCallID = requestMessageList.messageId;  


    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/campaigns?id=${Camping_Id}`
    );
   

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
    this.setState({ openModel: false }, () => {
      localStorage.removeItem("visitedProfile");
     
    });


  };

  handleCountryClick = (clickedCountry: string,isReload?:boolean): void => {
    const updatedCountryList = this.state.countryList.map(country => ({
      ...country,
      visible: country.name === clickedCountry,
    }));

    this.setState({
      ...this.state,
      countryList: updatedCountryList,
      selectedCountry: clickedCountry,
      isPopupVisible: false
    });
    localStorage.setItem('selectedCountry', clickedCountry.toLocaleLowerCase())
    if(isReload){
      window.location.reload()
    }
  };
  render() {
    const { classes, children } = this.props;
    const { mobileOpen,campaignsMenuOpen } = this.state;
    const BoxView = Box as any;
    const HiddenView = Hidden as any;
    return (
      <>
        <BoxView component={"div"} sx={{ minHeight: "100%", display: "flex" }} className={classes.root}>
          {/* App Bar */}
          <Backdrop className={classes.backdropCss} open={this.state.openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
          <HiddenView mdUp implementation="css">
            <AppBar position="fixed" color="transparent" style={{boxShadow: 'unset', borderBottom: '0.5px solid rgb(0,59,90,0.20)'}}>
              <Toolbar className={classes.toolAppbar}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={this.handleDrawerToggle}
                  className={classes.menuButton}
                >
                  {mobileOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
                <Typography className={classes.searchLogo} >
                  <img src={images.localSearchLogo} alt="Local Search" />
                </Typography>

              </Toolbar>
            </AppBar>
          </HiddenView>
          <HiddenView mdUp implementation="css">
            < Drawer
              variant="temporary"
              anchor="left"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              BackdropProps={{ style: { background: "transparent" } }}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <List className={classes.sidebarContainerList} >{this.renderSidebarList()}</List>
              {campaignsMenuOpen &&
              <div  className={classes.sidebarline} ></div>}
        
              <Box style={{ width: '222px', position: 'relative' }}>
              <Box className={classes.sidebarConntry} style={{}}>
                {this.renderCountryList()}
              </Box>
                {this.renderUserProfileComponent()}
              </Box>

            </Drawer>
          </HiddenView>
          <HiddenView smDown implementation="css">
            <BoxView className={classes.sidebarContainer}>
              <List style={{ flex: 1 }}>
              <ListItem   className={classes.sidebarContan}  >
                {window.innerWidth >=1900?
                 <img src={images.localSearchLogo}  className={classes.logoContan} style={{width:'250px'}}  />
                 : <img src={images.localSearchLogo}  className={classes.logoContan} />
                }
                </ListItem>
                {this.renderSidebarList()}</List>
              {/* <Divider variant="middle"  style={{width:'70%'}}/> */}

              {campaignsMenuOpen &&
              <div  className={classes.sidebarline} ></div>}
              <Box style={{ width: '222px', position: 'relative' }}>
              <Box className={classes.sidebarConntry} >
                {this.renderCountryList()}
              </Box>
                {/* {this.renderUserProfileComponent()} */}
              </Box>
              {/* {this.renderCountryList()} */}
              {this.renderUserProfileComponent()}
              <Menu
                id="long-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                PaperProps={{
                  style: {
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    width: 210,
                    left: 30
                  }
                }}
                PopoverClasses={{
                  paper: classes.menu
                }}
              >
                <MenuItem className={classes.menuItem}>
                  <ListItemText primary="Italian" className={classes.text} />
                  <BoxView>
                    <img src={images.germanFlag} width="30px" height="20px" />
                  </BoxView>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <ListItemText primary="French" className={classes.text} />
                  <BoxView>
                    <img src={images.germanFlag} width="30px" height="20px" />
                  </BoxView>
                </MenuItem>
              </Menu>
            </BoxView>
          </HiddenView>
          <BoxView component={"div"} className={classes.childContainer}>
            {children}
          </BoxView>
          <Dialog
            open={this.state.openModel} style={{ borderRadius: "50px" }}
            PaperProps={{
              style: {
                borderRadius: "24px"
              },
            }}
          >
            <Box
              display={"flex"}
              style={{ justifyContent: "flex-end", margin: "3%", marginBottom: "0px", cursor: "pointer" }}
              onClick={this.closeModal}>
              <CloseIcon />
            </Box>
            <DialogContent
              style={{
                color: "#000000", lineHeight: "26px", textAlign: "center", padding: "22px", fontFamily: "Panton Narrow Bold",
                fontWeight: 700, fontSize: "24px"
              }}
            >
              {this.state.select_lang?.name[11]}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <button
                data-test-id='modalClose'
                onClick={this.campaignNo}
                style={{
                  fontFamily: "Panton Narrow Bold",
                  fontWeight: 700, fontSize: "16px", color: "#005E6E",
                  backgroundColor: "#FCFCFC", width: "194px", height: "56px", cursor: "pointer", borderRadius: "43px", border: "1px solid #005E6E"
                }}>
                {this.state.select_lang?.name[12]}
              </button>
              <button
                onClick={this.campaignSave}
                style={{
                  backgroundColor: "#005E6E", border: "none", width: "194px", height: "56px", borderRadius: "50px", cursor: "pointer",
                  fontFamily: "Panton Narrow Bold",
                  fontWeight: 700, fontSize: "16px", color: "#fff", marginLeft: "0px"
                }}>
                {this.state.select_lang?.name[13]}
              </button>

            </DialogActions>
          </Dialog>

<Dialog
            open={this.state.openModel1} style={{ borderRadius: "50px" }}
            PaperProps={{
              style: {
                borderRadius: "24px"
              },
            }}
          >

          <Box
              display={"flex"}
              style={{ justifyContent: "flex-end", margin: "3%", marginBottom: "0px", cursor: "pointer" }}
              onClick={this.closeModal1}>
              <CloseIcon />
            </Box>

            <DialogContent
              style={{
                color: "#000000", lineHeight: "26px", textAlign: "center", padding: "22px", fontFamily: "Panton Narrow Bold",
                fontWeight: 700, fontSize: "24px",marginBottom:'40px'
              }}
            >
              {this.state.select_lang?.name[14]}            
            </DialogContent>            

</Dialog>

        </BoxView>
      </>
    );
  }
}

const styles: any = {
  root: {
    display: "flex" as string,
    "& .MuiDrawer-paperAnchorTop": {
      top: "60px !important" as string,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent" as string,
    },
    "& .MuiTypography-root": {
      fontFamily: "Panton Narrow Regular"
    }
  },
  menuItem: {
    color: "#94A3B8",
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#005E6E',
      "& span": {
        fontWeight: 700,
      }
    },
  },
  dialogActions: {
    flexWrap: "wrap",
    margin: "15px 13px 15px",
    display: "flex",
    justifyContent: "space-evenly",
    "@media (max-width: 501px)": {
      justifyContent: "center",
      gap: "10px"
    }
  },
  text: {
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
  },
  drawerPaper: {
    top: "68px" as string,
    padding: "24px" as string,
    width:'100%',
   maxWidth: '290px',
   "@media (max-width:960px)":{
    top: "65px" as string,
    boxShadow:'unset',
    height: 'calc(100vh - 95px)',
    display: 'flex',
    justifyContent: 'space-between'
   },
   "@media (max-width:500px)":{
    height:'calc(100vh - 114px)',
    overflow: 'auto'
 },
//  "@media (max-width:375px)":{
//   // height:'calc(100vh - 110px)',
//   overflow: 'auto'
// },
"@media (max-width:375px)":{
  height:'calc(100vh - 95px)',
  overflow: 'auto'
},
   '@media(max-width:365px)':{
    padding: "16px 24px" as string,
   },
   '@media(max-width:500px)':{
     maxWidth: '240px',
     display: 'flex', justifyContent: 'space-between'
   },
  },
  tooltip: {
    backgroundColor: 'transparent',
    borderRadius: '20px',
    top: '10px',
  },
  sidebarContan:{
    marginBottom: "35px",
     marginTop: "24px",
  },
  sidebarContainer: {
    height: '100%',
    background: "#FFF",
    width: "270px",    
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
    "align-items": "center",
    display: 'flex',
    "flex-direction": 'column',
    overflow: "auto",
    '@media (min-width:1900px)':{
      width: "370px", 
    }
  },
  menuButton: {
    marginRight: 16,
  },
  toolAppbar:{
    background: 'white',
     justifyContent: 'space-between',
     "@media (max-width: 500px)":{
      height:'67px'
     },
  },
  backdropCss:{
color:"black",
backgroundColor:"#8080804d !important",
zIndex:9999
  },
  searchLogo:{
    width: "100%",
    height: "35px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '30px',

  },
  "@media (max-width: 500px)":{
    searchLogo:{
    width:'106px',
    position:'absolute',
    marginLeft:'160px',
    },
  },
  "@media (max-width: 450px)":{
    searchLogo:{
    width:'106px',
    position:'absolute',
    marginLeft:'140px',
    },
  },
  "@media (max-width: 400px)":{
    searchLogo:{
    width:'106px',
    position:'absolute',
    marginLeft:'110px',
    },
  },
  countryName: {
    "&:hover div:first-child": {
      fontWeight: '700!important',
      color: "#005E6E!important",
      fontFamily: 'Panton Narrow Bold!important',
    }
  },
  arroDiv:{
    width: '32px',
     height: '33px',
      borderRadius: '9px', 
      backgroundColor: 'white',
       transform: 'rotate(46deg)', 
       marginLeft: "82.6%", 
       marginTop: '-19px',
       "@media(min-width:1900px)": {
        marginLeft: "87.6%", 
       }
  },
  headerButton: {
    "@media(max-width:960px)": {
      height: '67px !important',
    },
  },
  countyPopup: {
    height: '102px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: 16,
    marginLeft: '1.7%',
    padding: '0px 13px 2px 16px',
    "@media(max-width:960px)": {
      width: '193px',
      height: '102px',
    },
    "@media(min-width:1900px)": {
      marginLeft:'-12.5%'
    },
  },
  divider: {
    borderTop: '1px solid #ccc',
    marginTop: '28%',
    marginBottom: '0px',
    paddingTop: '36px !important',
    '@media(min-width:1900px)':{
      marginTop:'94px'
    },
    '@media(max-width:960px)':{
      marginTop:'100px'
    },
  }, 
  subMenuItem: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 16.42px 8px 10px',
    width:'175px',
    cursor: 'pointer',
    color: '#B4B4B4',
    transition: 'color 0.3s ease, transform 0.3s ease',
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    "&:hover": {
      color: "#005E6E",
      "& p": {
        fontWeight: 600,
      }
    },
    '@media (min-width:1900px)':{
      width:'250px',
    }
  },
  listItemContainer: {
    display: "flex",
    "align-items": "center"
  },
  listItem: {
   width: "220px",
    height: "52px",
    borderRadius: "24px",
    background: "#F1F0F0",
    padding: "14px 14px 14px 16px",
    marginBottom: "24px",
    color: "#000",
    "&:hover": {
      // border:"1px solid #005E6E",
      background: "#CCDFE2",
      color: "#005E6E",
    },
    '@media (min-width:1900px)':{
      width: "285px", 
      height:'58px'
    }
  },
  listItemActive: {
    width: "220px",
    height: "52px",
    borderRadius: "24px",
    background: "#CCDFE2 !important",
    color: "#005E6E",
    padding: "14px 14px 14px 16px",
    marginBottom: "24px",
    '& .MuiListItemText-root p': {
      fontWeight:'700 !important',
      fontFamily:"Panton Narrow bold !important",
    },
    "&:hover": {
      // border:"1px solid #005E6E",
      background: "#CCDFE2",
      fontWeight:'700 !important',
      fontFamily:"Panton Narrow bold !important",
    color: "#005E6E",
    },
    "@media (min-width:1900px)": {
      width:'285px',
      height:'58px'
    }
  },
  removeBg: {
    // background: "white !important",
    border:"1px solid #005E6E !important"
  },
  dashboredcolor:{
    background: "rgba(204, 223, 226, 1) !important",
    color:'rgba(0, 94, 110, 1)',
    fontWeight:'700 !important',
    fontFamily:"Panton Narrow bold !important",
  },
  dashboredcolors:{
    fontSize: 16,
    padding: "0px 16px",
     fontWeight:'700 !important',
     fontFamily:"Panton Narrow Bold !important",
  },
  removeBoder: {
    border: 'none !important',
  },
  addBg: {
    background: "#F1F0F0 !important",
    border: "none !important",
  },
  addText: {
    color: "black !important"
  },
  menuList: {
    background: "none",
    height: "52px",
    width: "220px",    
    padding: "14px 14px 14px 16px",
    marginBottom: "24px",
    '@media (min-width:1900px)':{
      width: "285px", 
      height:'58px'
    }
  },
  sidebarline:{
    borderBottom:'1px solid rgba(180, 180, 180, 1)',
    width:'77%',
    marginBottom:'30.6%',
    paddingTop:'6%',
    marginRight:'13px',
    '@media(min-width:1900px)':{
      marginBottom:'100px'
    },
    '@media(max-width:960px)':{
      marginBottom:'100px'
    },
  },
  sidebarConntry: {
    '@media(max-width:960px)':{
      top:'-125px',
    },
    '@media(max-width:500px)':{
      top:'-50px',
    }
  },
  sidebarContainerList:{
    // height:'82%',
    "@media (max-width:500px)":{
      height:'inherit',
   },
   "@media (max-width:450px)":{
    height:'inherit',
 },
 "@media (max-width:325px)":{
    height: 'auto'
},

  },
  activeMenu: {
    width: "220px",
    height: "52px",
    border: "1px solid #005E6E",
    padding: "14px 14px 14px 16px",
    marginBottom: "24px",
    fontWeight: '900 !important'
  },
  listItemSelectedRoute: {
    width: "220px",
    height: "52px",
    borderRadius: 24,
    background: "#CCDFE2",
    padding: "14px 14px 14px 16px",
    marginBottom: "24px",
    '@media (min-width:1900px)':{
      width: "285px", 
      height:'58px'
    }
  },
  listLastMenu: {
    marginBottom: "25px"
  },
  textColor: {
    fontFamily:'panton narrow regular',
    fontSize: 16,
    fontWeight: 400,
    padding: "0px 16px"
  },
  toolTipContainer: {
    background: '#fff',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    maxWidth: '222px',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '11px',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      right: ' 10px',
      marginLeft: '-5px',
      borderWidth: '13px',
      borderStyle: 'solid',
      borderColor: '#f1f1f1 transparent transparent transparent',
    }
  },
  toolTipBoxLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    cursor: 'pointer',
  },
  skyBlueBackground:{
    background:' #CCDFE2 !important ',
    color:'#005E6E  !important'
  },
  skyBluecolor:{
    color:'#005E6E !important',
    fontWeight: '700 !important',
    fontFamily: "Panton Narrow Bold !important",
  },
  skyBluecolors:{
    color:'#005E6E !important',
    fontWeight: 700,
    fontFamily: "Panton Narrow Regular !important",
  },
  userInfoBox: {
    display: 'flex',
    "box-sizing": "border-box",
    "justify-content": 'space-between',
    alignItems: 'center',
    background: '#F1F0F0',
    padding: '10px 17px 10px 16px',
    borderRadius: '24px',
    width: '220px',
    // minWidth : '214px',
    marginBottom: '40px',
    height: "52px",
    '@media(max-width:500px)':{
    marginTop:'20px',
    },
    '@media (min-width:1900px)':{
      width: "285px", 
      height:'58px',
    }
  },
  userName: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Panton Narrow Bold",
    color: "#3C3A38",
    cursor: 'pointer'
  },
  popupContainer: {
    background: '#F1F0F0',
    height: '104px',
    width: '222px',
    bottom: '80px'
  },
  userPosition: {
    fontSize: "10px",
    color: "#373838",
    fontWeight: 400,
    fontFamily: 'Panton Narrow SemiBold',
    cursor: 'pointer'
  },
  childContainer: {
    width: "100%",
    "box-sizing": "border-box",
    overflowY: "auto" as const,
    height: "calc(100vh - 0px)",
  },
  menu: {
    top: "745px !important",
    left: "30px !important"
  },
  activeSubMenuItem: {
    color: "#005E6E",
    fontWeight: "bold" as "bold",
    fontFamily: "Panton Narrow Bold",
    "& p": {
      fontWeight: 600,
    }
  }
};

export default withRouter(withStyles(styles)(Sidebar as any) as any) as any;