export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const localSearchLogo = require("../assets/advertiseONE_Logo_darkBlue.svg");
export const campaignsLogo = require("../assets/a1_Icon_Campaigns.svg");
export const campaignsActiveLogo = require("../assets/a1_Icon_Campaigns-01.svg");
export const dashboardLogo = require("../assets/a1_Icon_Dashboard.svg");
export const dashboardActiveLogo = require("../assets/a1_Icon_Dashboard-01.svg");
export const userProfileLogo = require("../assets/a1_Icon_Profile-01.svg");
export const germanFlag = require("../assets/image_Flag.png");
export const frenchFlag =require("../assets/French_Flag_by_Adam_Stanislav.png");
export const italiaFlag =require("../assets/tobias-Flag-of-Italy 1.png");
export const frame = require("../assets/Frame.svg");
export const addIcon = require("../assets/add_icon.svg");
export const statusComplete = require("../assets/complete.svg");
export const statusIncomplete = require("../assets/incomplete.svg")
export const profileImage = require("../assets/profile.png")

export const setup = require("../assets/Vector (1).svg");
export const Objective = require("../assets/image_Image (2).png");
export const Channels = require("../assets/image_Image (1).png");
export const Location = require("../assets/Locations.svg");
export const Budget = require("../assets/budget.svg");
export const blckplus =require("../assets/image_Minus (copy 1).png");
export const roleicon =require("../assets/image_User.png");
export const Addons = require("../assets/Addon.svg");
export const Configuration = require("../assets/image_Image.png");
export const Checkout = require("../assets/checkout.svg");
export const CampaignDetails = require("../assets/document.svg");

export const setupActive = require("../assets/Vector.svg");
export const ObjectiveActive = require("../assets/image_Image06.svg");
export const ChannelsActive = require("../assets/image_Image05.png");
export const LocationActive = require("../assets/Locations(1).svg");
export const BudgetActive = require("../assets/budget(1).svg");
export const AddonsActive = require("../assets/Addon(1).svg");
export const ConfigurationActive = require("../assets/image_Image01.svg");
export const CheckoutActive = require("../assets/checkout(1).svg");
export const CampaignDetailsActive = require("../assets/document(1).svg");

export const increase  = require("../assets/image_Dash.svg");
export const decrease  = require("../assets/view_Uniuon09.svg");
export const increaseActive  = require("../assets/view_Uniuon07.svg");
export const decreaseActive = require("../assets/image_Minus.svg");

export const dmcUser = require("../assets/image_User_00000.svg")
export const dmcUserActive = require("../assets/dmcUserProfileActive.svg");
export const editCampaign = require("../assets/Edit.svg")
export const editCampaignActive = require("../assets/image_Edit_005E6E.png")
export const copyCampaign = require("../assets/image_User2.svg")
export const copyCampaignActive = require("../assets/image_User2_005E6E.png")
export const userDashboardHover = require("../assets/image_User.png")

export const filterCampaignArrow = require("../assets/image_Arrows.svg")
export const filterCampaignArrowActive = require("../assets/image_Arrowactive.svg")
export const arrowCalenderRight = require("../assets/arrowRight.svg")
export const closeCircle = require("../assets/closecircle.svg")

export const arrowDateFilterLeft = require("../assets/arrow-circle-left.svg")
export const arrowDateFilterRight = require("../assets/arrow-circle-right.svg")

export const assignSearchIcon = require("../assets/AssignSearch.svg")

export const viewCampaignIcon = require("../assets/View.svg")
export const viewCampaignActiveIcon = require("../assets/viewActive.svg")

export const filterActive = require("../assets/filterActive.svg")