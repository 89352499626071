Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.createNoteApiMethod = "POST";
exports.getProjectNotesDetailsMehtod="GET";
exports.getAllProjectNotesMehtod= "GET";
exports.updateNoteMethod="PUT";
exports.deleteNotesMethod="DELETE";
exports.apiHeaderContentType = "application/json";
exports.labelTitleText = "ProjectNotes";
exports.labelBodyText = "ProjectNotes Body";
exports.projectNotesApiEndPoint = "bx_block_projectnotes/notes/"
exports.addNotesButton = "Add Notes";
exports.mockApiCreateNoteForProject = 
 {
        "id": "404",
        "type": "note",
        "attributes": {
            "id": 404,
            "note_content": "test 5555 for campaigne 3",
            "reference_id": 5,
            "is_deleted": false,
            "is_updated": false,
            "created_at": "2023-06-23T12:07:46.481Z",
            "updated_at": "2023-06-23T12:07:46.481Z",
            "created_time": "12:07:46",
            "created_date": "2023-06-23"
        }
}
exports.mockApiResponseGetAllNotes = [
  {
      "id": "333",
      "type": "note",
      "attributes": {
          "id": 333,
          "note_content": "<div>ejhbfhbefhvbefvhfdbhjvbdfjhvbdfbvjdfhbvfdjhvbjdfbvjdfhjvdfbjvbdfjhvbjdfbvjdfhvjdfhbj</div>",
          "reference_id": 1,
          "is_deleted": false,
          "is_updated": false,
          "created_at": "2023-06-15T08:14:59.923Z",
          "updated_at": "2023-06-15T08:14:59.923Z",
          "created_time": "08:14:59",
          "created_date": "2023-06-15"
      }
  },
]
exports.mockApiResponseGetNoteDetails = [
    {
        id: "396",
        type: "note",
        attributes: {
            id: 396,
            note_content: "<p>;lfjl;sjdf;lkhs;lnj;skdhflksd</p>",
            reference_id: 1,
            is_deleted: false,
            is_updated: false,
            created_at: "2023-06-20T09:14:54.662Z",
            updated_at: "2023-06-20T09:14:54.662Z",
            created_time: "09:14:54",
            created_date: "2023-06-20"
        }
    }
]
 exports.mockResponseUpdateNote = 
        {
            "id": "389",
            "type": "note",
            "attributes": {
                "id": 389,
                "note_content": "this is scdasdasdasdasd note",
                "reference_id": 5,
                "is_deleted": false,
                "is_updated": true,
                "created_at": "2023-06-20T06:46:31.911Z",
                "updated_at": "2023-06-20T06:47:07.221Z",
                "created_time": "06:46:31",
                "created_date": "2023-06-20"
            }
        }

 exports.mockResponseDeleteNote = {
    "message": "Note deleted successfully."
}
// Customizable Area End