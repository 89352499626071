import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import React from 'react';

// Customizable Area Start
import { testNavi } from "../../../../packages/blocks/utilities/src/CommonUtilities";
interface LocationContentData {
    data: {
        tooltip: string,
        heading: string,
        body: string,
        image_url: string
    }
}

interface RadiusDataItem {
    name: string;
    id: string;

}

//Customizable Area End


// Customizable Area Start
import {
    ClassNameMap,
    ClassKeyOfStyles,
} from '@material-ui/styles';
import { logoutUser } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props extends RouteComponentProps {
    navigation: any;
    id: string;
    classes: ClassNameMap<ClassKeyOfStyles<string>>;
    // Customizable Area Start
    history: any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    getLocationContentCallID: string,
    radiusData: any,
    position: any,
    searchValue: string,
    error: string,
    selectedRadius: string,
    mapRef: any
    locationData: any,
    isModalOpen: boolean,
    LocationDetails: object,
    locationHeadingTooltip: any,
    select_lang: any,
    tooltipOpen: boolean,
    tooltipTitle: string,
    tooltipOpenChanelsDatas: boolean,
    tooltipTitleChanelsDatas: string,
    tooltipOpenchannelHeadingTooltip:boolean,
    tooltipTitlechannelHeadingTooltip:string,
    validationErrorMessages: {
        searcchInputError: any,
        selectRangeError: any
    }
    error_lang: any,
    viewData:boolean,
    LocationApiVariable:any,
    BudgetNavigate:boolean,
    QuoteNavigate:boolean,
    responseGetData:boolean,
    visitedQuoteKey:any,
    active_key:string,
    initialLocVisited:boolean,
    updatedLocationChecked:boolean,
    loaderTrue:boolean
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class LocationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getLocationRadiusCallID: string = ""
    putLocationToBUdgetCallID: string = ""
    getLocationHeadingTooltipCallID: string = ""
    getCampaignLocationCallID:string=""
    putLocationDisabledApiCallID:string=""
    putLocationDataApiCallID:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage),
        ];

        this.state = {
            getLocationContentCallID: "",
            position: null,
            error: " ",
            searchValue:  '',
            selectedRadius: " ",
            mapRef: React.createRef(),
            locationData: {},
            isModalOpen: false,
            radiusData: [],
            LocationDetails: {},
            tooltipOpen: false,
            tooltipTitle: '',
            tooltipOpenChanelsDatas: false,
            tooltipTitleChanelsDatas: '',
            tooltipOpenchannelHeadingTooltip: false,
            tooltipTitlechannelHeadingTooltip: '',
            locationHeadingTooltip: {},
            validationErrorMessages: {
                searcchInputError: "",
                selectRangeError: ""
            },
            select_lang: {name: []},
            error_lang : {name:[]},
            viewData:false,
            LocationApiVariable:{},
            BudgetNavigate:false,
            QuoteNavigate:false,
            responseGetData:false,
            visitedQuoteKey:{},
            active_key:"",
            initialLocVisited:false,
            updatedLocationChecked:false,
            loaderTrue:false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
    
        const viewOption:any=localStorage.getItem("visitedView")
        const showOption:any=localStorage.getItem("showData")
        if(viewOption && showOption==="true"){
          this.setState({viewData:true})
        }
        localStorage.setItem("sidebarData",JSON.stringify(location))  
        this.getLocationContentDetails();
        this.getLocationRdiusDetails();
        this.getLocationlHeadingTooltip();

        const selectedCountry = localStorage.getItem("selectedCountry");
        const lang : any = {
          german: { name:["Geben Sie den Standort ein" , "Wählen Sie Ihr Sortiment aus", "Zitat", "Nächste"]},
          french: {name:["Entrez le lieu","Sélectionnez votre gamme", "Citation","Suivante"]},
          italian: {name:["Inserisci la posizione", "Seleziona la tua gamma", "Citazione","Prossima"]},
        };
    
        const selected_Language = lang[selectedCountry as any] || { name: []};
        this.setState({select_lang:selected_Language})

        const selectedCountryError = localStorage.getItem("selectedCountry");
        const errorLang : any = {
          german: { name:["Bitte geben Sie einen Ort ein", "Bitte wählen Sie einen Bereich aus", 'Standort nicht gefunden']},
          french: {name:["Veuillez entrer un emplacement", "Veuillez sélectionner une plage", 'emplacement introuvable']},
          italian: {name:["Inserisci una località", "Seleziona un intervallo", 'posizione non trovata']},
        };
    
        const selected_Language_error = errorLang[selectedCountryError as any] || { name: []};
        this.setState({error_lang:selected_Language_error})

        const campaignsData = localStorage.getItem("campaign_Details");
        
        if (campaignsData) {
          const campaignsDatas = JSON.parse(campaignsData);
          if (campaignsDatas && campaignsDatas.radius_id && campaignsDatas.radius_id !== undefined) {
                this.setState({ selectedRadius: campaignsDatas.radius_id });
            }
        }
        const persistId=localStorage.getItem("persistId");
         const visitedLocation=localStorage.getItem("visitedLocation")
        if(persistId || visitedLocation){
          this.getCampaignRecommendationData();
        }
        
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {

                if (apiRequestCallId === this.state.getLocationContentCallID) {
                    this.getLocationContentReceiver(responseJson);
                }
                else if (apiRequestCallId === this.getLocationRadiusCallID) {
                    this.getLocationRadiusReceiver(responseJson);
                }
                else if (apiRequestCallId === this.putLocationToBUdgetCallID) {
                    this.putChannelsReceiver(responseJson)
                }
                else if (apiRequestCallId === this.getLocationHeadingTooltipCallID) {
                    this.getLocationHeadingReceiver(responseJson)
                }
                else if(apiRequestCallId=== this.getCampaignLocationCallID){
                    this.getLocationData(responseJson)
                }else if(apiRequestCallId=== this.putLocationDataApiCallID){
                    this.getLocationDataUpdatedReceiver(responseJson)
                }
                if (responseJson.statusCode === 401) {
                    this.props.history.push("/EmailAccountLoginBlock");
                    logoutUser();
                }
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    getLocationContentDetails = async (): Promise<void> => {
        const selectedCountry = localStorage.getItem('selectedCountry');
        const name = "location";

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("accessToken")
        };

        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.setState({
            getLocationContentCallID: requestMessageList.messageId
        })

        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.setUpContent}?language=${selectedCountry}&name=${name}`
        );

        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    getLocationContentReceiver = (responseJson: LocationContentData) => {
        this.setState({
            locationData: responseJson
        })
    };

    toggleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleSearch = async () => {
      
        try {
            const encodedSearchValue = encodeURIComponent(this.state.searchValue===null?"":this.state.searchValue);
            const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodedSearchValue}`);
            const data = await response.json();
            if (data.length > 0) {
                const { lat, lon } = data[0];
                const position = [lat, lon];
                this.setState({ position, error: "" });
                const map = this.state.mapRef.current.leafletElement;
                if (map) {
                    map.flyTo(position, 12, { duration: 1 });
                }
            } else {
                this.setState({ position: null, error: this.state.error_lang.name[2] });
            }
        } catch (error) {
            this.setState({ position: null, error: 'Error fetching location. Please try again.' });
        }
    };

     handleTooltipToggleObjectiveData = () => {
        this.setState({ tooltipOpenchannelHeadingTooltip: !this.state.tooltipOpenchannelHeadingTooltip });
      }; 
      
      handleChanelsDataMouseHover=()=>{
        if(window.innerWidth>500){
    
        this.setState({tooltipOpenChanelsDatas:true});
        }
      };
       handleChanelsDataMouseOver=()=>{
        if(window.innerWidth>500){
    
        this.setState({tooltipOpenChanelsDatas:false});}
      }
       handleChanelsData=()=>{
        if(window.innerWidth<=500){
    
          this.setState({tooltipOpenChanelsDatas:!this.state.tooltipOpenChanelsDatas});
        }
      }
      handleImageImageMouseHover=()=>{
        if(window.innerWidth>500){
    
        this.setState({tooltipOpenchannelHeadingTooltip:true});
        }
      };
       handleImageImageMouseOver=()=>{
        if(window.innerWidth>500){
    
        this.setState({tooltipOpenchannelHeadingTooltip:false});}
      }
       handleMouse=()=>{
        if(window.innerWidth<=500){
    
          this.setState({tooltipOpenchannelHeadingTooltip:!this.state.tooltipOpenchannelHeadingTooltip});
        }
      }
      handleTooltipCloseChanelsData = () => {
        this.setState({ tooltipOpenchannelHeadingTooltip: false });
      };
      handleTooltipOpenChanelsData = (title: any) => {
        this.setState({ tooltipTitlechannelHeadingTooltip: title, tooltipOpenchannelHeadingTooltip: true });
      };
      handleTooltipToggleObjectiveDatas = () => {
        this.setState({ tooltipOpenChanelsDatas: !this.state.tooltipOpenChanelsDatas });
      };
      
      handleTooltipCloseChanelsDatas = () => {
        this.setState({ tooltipOpenChanelsDatas: false });
      };
      
      handleTooltipOpenChanelsDatas = (title: string) => {
        this.setState({ tooltipTitleChanelsDatas: title, tooltipOpenChanelsDatas: true });
      };

    handleInputChange = (e: any) => {
        this.setState({ searchValue: e.target.value, validationErrorMessages:{
            searcchInputError: "",
            selectRangeError: this.state.validationErrorMessages.selectRangeError,
        }});
        this.setState({updatedLocationChecked:true})
        this.updateLocationNavigation()     
    };
    getCampaignRecommendationData=()=>{
        const visitedSetup=localStorage.getItem("visitedLocation");
        const  persistId= localStorage.getItem("persistId")
        let Camping_Id = localStorage.getItem('campaignID')
        const rowId=visitedSetup?Camping_Id:persistId
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("accessToken")
         };
      
         const requestMessageList = new Message(
             getName(MessageEnum.RestAPIRequestMessage)
         );
      
         this.getCampaignLocationCallID = requestMessageList.messageId;
      
         requestMessageList.addData(
           getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
         );
         requestMessageList.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
         requestMessageList.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.ConfigurationDataEndPoint}${rowId}`
         );
         runEngine.sendMessage(requestMessageList.id, requestMessageList);
       }
       getLocationData=(responseJson:any)=>{
       
        const res=responseJson.campaign.attributes
       
        this.setState({
            selectedRadius: res.radius_id,
            searchValue:res.location
        });
        const visitedSetup=localStorage.getItem("visitedLocation");
        const persistId=localStorage.getItem("persistId");
        if(visitedSetup || persistId){
          let key=responseJson.visited_key;
      this.setState({visitedQuoteKey:responseJson.visited_key,active_key:responseJson.active_page})

          if(!key.includes(responseJson.active_page)){

              key.push(responseJson.active_page)
          }
          let CampaignUpdate =  {
           "campaign": {
             "active_page":"location",
             "visited_key":key
           },
           "progress": {
            "current_page": "location"
        }
       }  
          this.setState({LocationApiVariable:CampaignUpdate,initialLocVisited:true},()=>{
            this.putLocationToBudget()
          })
       }
    }
    onDashboardSave=()=>{
        if(this.state.updatedLocationChecked){
            let Campaign = {
                "campaign": {
                    "location": this.state.searchValue,
                    "radius_id":this.state.selectedRadius,
                },
                "progress": {
                    "current_page": "location"
                }
            }
       
            this.setState({LocationApiVariable:Campaign},()=> this.putLocationToBudget())
        }
        if(!this.state.updatedLocationChecked){
            this.props.history.push("/Dashboard")
        }

    }
    putLocationDisabledDataApi=async (): Promise<void> => {
        const campaignId = localStorage.getItem("campaignID")
        const objective_Id = localStorage.getItem("objective_id")
          const persistId=localStorage.getItem("persistId")
          const rowID=persistId?persistId:campaignId
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("accessToken")
         };   
        
         const requestMessageLocationList = new Message(
             getName(MessageEnum.RestAPIRequestMessage)
         );
         requestMessageLocationList.addData(
           getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.sellerDetailsAPIMethodPUT
         );
      
         requestMessageLocationList.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${"bx_block_custom_form/update_recommendation_campaign"}?id=${rowID}&page=location&campaign_objective=${objective_Id}&campaign_radius=${this.state.selectedRadius}`
         );
         requestMessageLocationList.addData(
             getName(MessageEnum.RestAPIRequestHeaderMessage),
             JSON.stringify(header)
         );
         this.putLocationDisabledApiCallID=requestMessageLocationList.messageId
         runEngine.sendMessage(requestMessageLocationList.id, requestMessageLocationList);
      }
  //istanbul ignore next
    handleSelectValueChnage = (e: any) => {
        const selectedRadius = e.target.value as string;
        this.setState({ selectedRadius: e.target.value});
        this.setState({ selectedRadius, validationErrorMessages:{
            searcchInputError: this.state.validationErrorMessages.searcchInputError,
            selectRangeError: "",
        } }, () => {
            this.getLocationRdiusDetails();
        });
        
        this.setState({updatedLocationChecked:true})
        this.updateLocationNavigation()
       
    }

    updateLocationNavigation=()=>{
        const visitedSetup=localStorage.getItem("visitedLocation");
        const  persistId= localStorage.getItem("persistId")
        if((visitedSetup|| persistId) && !this.state.loaderTrue){
            let activeItemss = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
        let index = activeItemss.indexOf('location');
        let activeItemsTrimmed = activeItemss.slice(0, index + 1);
        let CampaignUpdate =  {
            "campaign": {
              "active_page":"location",
              "visited_key":activeItemsTrimmed
            },
            "progress": {
             "current_page": "location"
         }
        }  
        this.setState({LocationApiVariable:CampaignUpdate }, async () => {
            try {
                await this.putLocationToBudget()
                await this.putLocationDisabledDataApi() 
            } catch (error) {
                alert(error);
            }
        });
            localStorage.removeItem('configSelectDate');
    
    }
}

    getLocationRdiusDetails = () => {
        const selectedCountry = localStorage.getItem('selectedCountry');
        const campaignId = localStorage.getItem('campaignID')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("accessToken")
        };

        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getLocationRadiusCallID = requestMessageList.messageId;

        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getRadiusEndPoint}?language=${selectedCountry}&campaignId=${campaignId}`
        );

        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessageList.id, requestMessageList);

    }

    getLocationRadiusReceiver = (responseJson: any) => {

        this.setState({
            radiusData: responseJson
        })
    }


    putLocationToBudget = async() => {
        const campaignId = localStorage.getItem("campaignID")
        const persistId=localStorage.getItem("persistId")
        const rowID=persistId?persistId:campaignId
        const authToken = localStorage.getItem('accessToken')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.putCampaingChannelsSection}/${rowID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.sellerDetailsAPIMethodPUT
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(this.state.LocationApiVariable)
        );
        this.putLocationToBUdgetCallID = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    putLocationCampaignApi=async (): Promise<void> => {
        const campaignId = localStorage.getItem("campaignID")
          const persistId=localStorage.getItem("persistId")
          const rowID=persistId?persistId:campaignId
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("accessToken")
         };   
        
         const requestMessageList = new Message(
             getName(MessageEnum.RestAPIRequestMessage)
         );
         requestMessageList.addData(
           getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.sellerDetailsAPIMethodPUT
         );
      
         requestMessageList.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${"bx_block_custom_form/update_campaign_budget"}?id=${rowID}&radius_id=${this.state.selectedRadius}`
         );
         requestMessageList.addData(
             getName(MessageEnum.RestAPIRequestHeaderMessage),
             JSON.stringify(header)
         );
         this.putLocationDataApiCallID=requestMessageList.messageId
         runEngine.sendMessage(requestMessageList.id, requestMessageList);
      }
    putChannelsReceiver = async(responseJson: any) => {
        if(this.state.initialLocVisited){
            this.send(testNavi(this.props))
        }
        const navigate= localStorage.getItem("navigate")
        if(this.state.updatedLocationChecked){
            this.setState({loaderTrue:true})
          }
        if(navigate){
         this.props.history.push("/Dashboard")
        }
        if (!responseJson.error) {
            
            this.setState({
                LocationDetails: responseJson.data,responseGetData:true
            });
        }
        if(this.state.updatedLocationChecked){
            this.putLocationCampaignApi()
        }else{
            if(this.state.BudgetNavigate){
                localStorage.setItem("visitedLocation","visitedLocation")
               
                this.props.history.push("/Budget")
            }else if (this.state.QuoteNavigate){
                this.props.navigation.navigate("Quote")
            }
        }
      
    };
    getLocationDataUpdatedReceiver=(responseJson:any)=>{
        if(this.state.BudgetNavigate){
            localStorage.setItem("visitedLocation","visitedLocation")
            this.props.history.push("/Budget")
        }else if (this.state.QuoteNavigate){
            this.props.navigation.navigate("Quote")
        }
    }
    //istanbul ignore next
    handleNext = () => {
     
        const validationErrorMessages: any = {
            searcchInputError: (this.state.searchValue===null ||!this.state.searchValue.trim())? this.state.error_lang.name[0] : '',
            selectRangeError: !this.state.radiusData.find((item: any) => item.id === this.state.selectedRadius)?.name ? this.state.error_lang.name[1] : '',

        };
        if (this.state.searchValue === null || this.state.searchValue === undefined || this.state.searchValue.trim() === "") {
            this.setState({ validationErrorMessages });
            return;
        }
        

        const isAnyItemSelected = this.state.radiusData.find((item: any) => item.id === this.state.selectedRadius)?.name;
        
        if (!isAnyItemSelected) {
            this.setState({ validationErrorMessages });
            return;
        }
        if (Object.values(validationErrorMessages).every(error => !error)) {
            this.setState({BudgetNavigate:true})
            let Campaign = {
                "campaign": {
                    "location": this.state.searchValue,
                    "radius_id":this.state.selectedRadius,
                    "active_page":"budget",
                    "visited_key":["setup","recommendation","objective","channels","location"]
                },
                "progress": {
                    "current_page": "budget"
                }
                
         
            }
        
            this.setState({LocationApiVariable:Campaign},()=> this.putLocationToBudget())
                  }
              }

              handleQuote=()=>{
                localStorage.setItem("mycurrentPage","visitLocation")
                const visitedSetup=localStorage.getItem("visitedLocation");
                const persistId=localStorage.getItem("persistId");
                const MycPage = localStorage.getItem("mycurrentPage")
                let key=this.state.visitedQuoteKey;
                if(visitedSetup || persistId){
                    if(!key.includes(this.state.active_key)){
                        key.push(this.state.active_key)                        
                    }else if(MycPage){
                    key = ["setup","recommendation","objective","channels"]
                  }
                }
                this.setState({QuoteNavigate:true})
                let Campaign = {
                    "campaign": {
                        "location": this.state.searchValue,
                        "radius_id":this.state.selectedRadius,
                        "active_page":"location",
                        "visited_key":visitedSetup?key:["setup","recommendation","objective","channels"]
                    },
                    "progress": {
                        "current_page": "location"
                    }
                    
             
                }
                this.setState({LocationApiVariable:Campaign},()=>this.putLocationToBudget())
            localStorage.setItem("visitedLocation","visitedLocation");
            }         
        
    getLocationlHeadingTooltip = async (): Promise<void> => {
        const selectedCountry = localStorage.getItem('selectedCountry');
        const name = "Location";

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("accessToken")
        };

        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getLocationHeadingTooltipCallID = requestMessageList.messageId;

        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
        );

        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };

    getLocationHeadingReceiver = (responseJson: any) => {
        this.setState({
            locationHeadingTooltip: responseJson
        })
    };
    
    //istanbul ignore next
    handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            this.handleSearch();
        }
    };


    // Customizable Area End

}