import React from "react";
// Customizable Area Start
import {
  TableCell,
  TableRow,
  Theme,
  Box,
  Typography,
  Grid,
  Zoom,
  Tooltip,
  Button,
  TableContainer,
  Table,
  TableBody,
  Modal
} from "@material-ui/core";

import {
  withStyles,
  createStyles,
  ThemeProvider,
  createTheme,
  styled,
} from "@material-ui/core/styles";
import ObjectiveController, {
  Props
} from "./ObjectiveController.web";
import Sidebar from "../../../components/src/SideBar.web";
import { withRouter } from "react-router-dom";
import { ContentManagement } from "../../ContentManagement/src/ContentManagement.web";
import {
  info,
  infoIcon,
  cancelImage,
} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    },
  }
});

const CustomBoxView = styled(Box)({
  borderRadius: "24px !important",
  padding: "31px 20px !important",
   backgroundColor: "#FFFFFF",
  height: "auto",
  boxShadow: "0px 4px 16px 0px #00000040",
  '@media (max-width: 500px)': {
    width:'95.2%',
    marginLeft:'4px'
  },
  '@media (max-width: 400px)': {
    width:'95.33%',
    marginLeft:'-1px'
  },
  '@media (max-width: 350px)': {
    width:'93.5%'
  },
})
const CustomBoxViewSubBox=styled(Box)({
  
    backgroundColor: "#FFFFFF",
    height: "auto",
    boxShadow: "0px 4px 16px 0px #00000040",
     padding:"35.5px 29.4px 35.5px 24px",
    //  padding: "35px  24px !important",

    borderRadius: "24px !important",
    marginTop:"25%",
    '@media (min-width: 500px)': {
      marginLeft:'1px !important'
    },
    '@media (max-width: 1050px)': {
      marginTop:"20px",
    },

    '@media (max-width: 1370px)': {
      marginTop:"30%",
    },
    '@media (max-width: 500px)': {
      // display:'none'
      margin:'0px 19px 0px 19px',
    },
    '@media (max-width: 375px)': {
      // display:'none'
      margin:'0px 15px 0px 14px',
    },
    
})
const ObjectGrid= styled(Grid)({
  marginTop:'40px',
  '@media (max-width: 960px)': {
    marginTop:'16px',
  }
})

const WhiteOnBlackTooltip = withStyles((theme) => ({
  tooltip: {
    color: "black",
    // left: "40px",
    height: "auto",
    padding: "15px 20px 15px 20px",
    marginTop: "20px",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    maxWidth: "231px",
    borderRadius: "12px",
    borderTopRightRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    position: "relative",
    
  },
  arrow: {
    content: '""',
    color:"#E9E9E9"
  },
}))(Tooltip);
interface ObjectiveResponse {
  id: number;
  name: string;
  duration: number;
  description: string;
  photo_url:string;
  clicked:boolean;
  tool_tip:string;
}
// Customizable Area End

export class Objective extends ObjectiveController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderIndustryBenchmark = () => {
    
    const BoxView = Box as any;
    const apiData = this.state.industryBenchmarkData.data || []   
      
     return (

      <div data-test-id="industry_data" style={{padding:'24px 8px 24px 10px'}}>
                 
                    {(this.state.select_lang?.name || []).map((name:any, index:any) => (
                              
                              <BoxView>
                          <BoxView  style={{display:'flex',justifyContent:'space-between',}}>
                          
                       
                        <BoxView style={webStyle.names}>
                          
                       {name}
                        </BoxView>
                         <BoxView  style={webStyle.times}>
                         {apiData && (
                                   <>
                                     {index == 0 && <span  style={webStyle.time} >{ apiData[0]?.Leads } {this.state.select_lang && this.state.select_lang.time && this.state.select_lang.time[0]}</span>}
                                     {index == 1 && <span style={webStyle.time}>{ apiData[0]?.Clicks } {this.state.select_lang && this.state.select_lang.time && this.state.select_lang.time[1]}</span>}
                                     {index == 2 && <span  style={webStyle.time}>{ apiData[0]?.Impressions } {this.state.select_lang && this.state.select_lang.time && this.state.select_lang.time[2]}</span >}
                                    {index == 3 && <span  style={webStyle.time}>{ apiData[0]?.TotalSpent } {this.state.select_lang && this.state.select_lang.time && this.state.select_lang.time[3]}</span >}
                                   </>
                                )}
                         </BoxView> 
                        

                         </BoxView>
                          <div style={{ width: '100%', borderTop: '1px solid #B4B4B4' }}></div> 
                         </BoxView>
                            ))}
                   
                </div>
        
      );
  };

 // Customizable Area End

  //istanbul ignore next
   render() {
    // Customizable Area Start
    const { classes } = this.props;
    const BoxView = Box as any;
    const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as any
    const maxCharacters = 202;
    const truncatedText = this.state.objectiveData?.body ? this.state.objectiveData.body.substring(0, maxCharacters) : '';
    const shouldShowReadMore = this.state.objectiveData?.body && this.state.objectiveData.body.length > maxCharacters
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Sidebar navigation={this.props.navigation} activePath={""} navigate={this.state.obj} onDashboardSave={this.onDashboardSave}>
        <BoxView className={classes.mainContainerBox} >
        <div className={classes.objectiveRoot}>
              <div className={classes.objectiveInnerRoot}>
              <Grid item>
                <Typography className={classes.lable}>
                <span className={classes.lableSetup}>
                            Your
                            </span> 
                            {this.state.objectiveHeadingTooltip?.data?.name}
                            
                            <WhiteOnBlackTooltipView
                              placement="bottom-start"
                              TransitionComponent={Zoom}
                              title={this.state.objectiveHeadingTooltip?.data?.description}
                              arrow
                              // open={true}
                              open={this.state.tooltipOpenObjectiveHeading}
                             onClose={this.handleTooltipCloseObjectiveHeading}
                            >
                              <img
                                src={info}
                                data-test-id='tooltipobjective'
                                style={{ marginLeft: "10px",position:'relative',width:'17px', height:'17px', alignSelf:'center'}}
                                alt="Info"
                                onMouseLeave={()=>this.handleImageImageMouseOver()}
                                onMouseEnter={()=>this.handleImageImageMouseHover()}
                                 onClick={() => this.handleMouse()}                              />
                            </WhiteOnBlackTooltipView>
                </Typography> 
                <ObjectGrid container style={{marginLeft:'-10px',width:'103.73%'}} >
                            {this.state.objectivesDetails.map(
                              (objectiveData: ObjectiveResponse) => (
                                <Grid item sm={6} xs={12} key={objectiveData.id} style={{padding:'9px 10px 8px 10px'}}>
                                  <BoxView style={{ position: "relative"}}>
                                    <img
                                      src={objectiveData.photo_url}
                                      className={objectiveData.id == this.state.selectedObjective ? classes.imgGroup :classes.imgGrayGroup}  
                                      alt="Card"
                                      data-test-id={`objective-${objectiveData.id}`}
                                      id='objective-details'
                                      onClick={() =>
                                        {
                                    
                                      
                                        this.handleObjectiveClick(objectiveData)
                                        }
                                      }
                                    />
                                    <BoxView
                                     style={{
                                      position: "absolute",
                                      bottom: "36px",
                                      left: "18px"
                                    }}
                                    >
                                      <BoxView 
                                      style={{
                                        borderRadius: "12px",
                                        // backgroundColor: 'rgba(52, 52, 52, 0.7)'
                                    }}>
                                      <Typography className={classes.text}>
                                        {objectiveData?.name}
                                        <WhiteOnBlackTooltipView
                                          title={objectiveData.tool_tip}
                                          arrow={true}
                                          placement="bottom-start"
                                          open={this.state.tooltipOpenObjectiveData&&this.state.objectiveId===objectiveData.id}
                                          onClose={this.handleTooltipCloseObjectiveData}
                                          // open={true}
                                        >
                                          <img
                                            src={infoIcon}
                                            style={{
                                              marginLeft: "10px",
                                              height: "12px",
                                              width:'12px'
                                            }}
                                            alt="Info"
                                            onMouseLeave={()=>this.handleobjectiveDataMouseOver(objectiveData.id)}
                                            onMouseEnter={()=>this.handleobjectiveDataMouseHover(objectiveData.id)}
                                             onClick={() => this.handleobjectiveData(objectiveData.id)}
                                     
                                          />
                                         
                                        </WhiteOnBlackTooltipView>
                                      </Typography>
                                      <Typography className={classes.smalltext}>
                                        {objectiveData.description}
                                      </Typography>
                                      </BoxView>
                                    </BoxView>
                                  </BoxView>
                                </Grid>
                              )
                              )}
                  </ObjectGrid>
              </Grid>
              </div>
          </div> 
          <div className={classes.sideContainerBox}>
            <div className={classes.innerSideContainerBox}>
            <BoxView className={classes.industy} >
              <CustomBoxView>
                <Typography style={webStyle.titles}>
                {this.state?.objectiveindustrybBenHeadingTooltip?.data?.name}
                  <WhiteOnBlackTooltipView
                    arrow={true}
                    placement="bottom-start"
                    TransitionComponent={Zoom}
                    title={this.state?.objectiveindustrybBenHeadingTooltip?.data?.description}
                    open={this.state.tooltipOpenObjectiveIndustryBenefit}
                    onClose={this.handleTooltipCloseObjectiveIndustryBenefit}
                  >
                    <img  alt="Info"
                    data-test-id='benifit'
                    onMouseLeave={()=>this.handleBenefitMouseOver()}
                    onMouseEnter={()=>this.handleBenefitMouseHover()}
                    onClick={() => this.handleBenefit()}
                    src={info} style={{ marginLeft: "15px",height:"12px",width:"12px" }} />
                  </WhiteOnBlackTooltipView>
                </Typography>
                
                {this.renderIndustryBenchmark()}
               
                <BoxView style={webStyle.miniBox}>
                  <Typography style={webStyle.roras}>
                  {this.state?.objectiveRoasHeadingTooltip?.data?.name}
                    <WhiteOnBlackTooltipView
                      placement="bottom-start"
                      TransitionComponent={Zoom}
                      title={this.state?.objectiveRoasHeadingTooltip?.data?.description}
                      arrow={true}
                      open={this.state.tooltipOpenObjectiveRoas}
                      onClose={this.handleTooltipCloseObjectiveRoas}
                    >
                      <img
                        alt="Info"  
                        src={info}
                        style={{ marginLeft: "15px" }}
                        data-test-id='roras'
                        onMouseLeave={()=>this.handleObjectiveRoasMouseOver()}
                        onMouseEnter={()=>this.handleObjectiveRoasMouseHover()}
                         onClick={() => this.handleObjectiveRoas()}
                      />
                    </WhiteOnBlackTooltipView>
                  </Typography>
                  <Typography style={webStyle.rora}>x3.5</Typography>
                </BoxView>
              </CustomBoxView>
            </BoxView>
            <CustomBoxViewSubBox >
                <Typography style={webStyle.title}>
                        <span>
                          {this.state.objectiveData?.heading}
                        </span>
                        <span>
                        <WhiteOnBlackTooltipView
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.objectiveData?.tooltip}
                          open={this.state.tooltipOpenObjectiveDatas}
                         onClose={this.handleTooltipCloseObjectiveDatas}
                        >
                          <img src={info} 
                          data-test-id='datas'
                          onMouseLeave={()=>this.handleobjectiveDatasMouseOver()}
                           onMouseEnter={()=>this.handleobjectiveDatasMouseHover()}
                          onClick={() => this.handleobjectiveDatas()}
                          alt="Info" style={{height: "12px", width: "12px" }} />
                        </WhiteOnBlackTooltipView>
                        </span>
                      </Typography>
                <img src={this.state.objectiveData?.image_url} style={webStyle.bannerImage}/>
                 <Typography style={{
                        marginTop: "20px",
                        lineHeight: '19.38px',
                        wordBreak: 'break-all',
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'panton narrow regular'
                    }} >
                        {truncatedText}{" "}
                        {shouldShowReadMore && (
                            <span style={webStyle.readMoreText} onClick={this.toggleModal}>
                                {this.state.objectiveData?.read_more}
                            </span>
                        )}
                    </Typography>
                <Modal
                          open={this.state.isModalOpen}
                          onClose={this.toggleModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          BackdropProps={{
                            style: {
                              backgroundColor: 'transparent',
                            }
                          }}
                        >
                        <BoxView className={classes.modalBoxStyleObjective} >
                            <div className={classes.modalBoxStyleObjectives} >
                                <Typography  className={classes.bodyText}
                                 style={{  wordBreak: 'break-all', flex: '1'}}>
                                    {this.state.objectiveData?.body}
                                </Typography>
                                <div  className={classes.objectiveCancelImageWrapper} >
                                    <img src={cancelImage} alt="Close"  className={classes.cancelImageObjective}  onClick={this.toggleModal} />
                                </div>
                            </div>
                        </BoxView>
                        </Modal>
              </CustomBoxViewSubBox>
           </div>
            <BoxView className={classes.buttonBox}>
            <Button variant="contained" disabled={this.state.viewData}  className={classes.button}  data-test-id="Quote_page" onClick={this.handleQuote}>
            {this.state.select_lang.buttons_lang[0]}
                                    </Button>
             
    
              <Button variant="contained" disabled={this.state.viewData} className={classes.button} onClick={!this.state.clicked? this.RedirectPage : this.updateData} data-test-id="objective_page" >
              {this.state.select_lang.buttons_lang[1]}

              </Button>
            </BoxView>
            </div>
        </BoxView>
               </Sidebar>
           </ThemeProvider>
            //Merge Engine End DefaultContainer
        )
    }
}

const ButtonContainer = styled(Button)({
  width: "194px",
  borderRadius: "43px",
  color: "#FFFFFF",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  textTransform: "capitalize" as const,
  "&:hover": { 
    backgroundColor: "#005E6E",
  },
  
});

export const useStyle = (theme: Theme) =>
  createStyles({
    //Objective CSS
    text: {
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      color: "#FFFFFF",
      fontSize: "16px"
    },
    modalBoxStyleObjectives:{
      display: 'flex',
       justifyContent: 'space-between',
       "@media(max-width:500px)":{
        display: 'unset',
        justifyContent:'unset',
       }
    },
    modalBoxStyleObjective: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: '50%',
      transform: 'translate(-50%, -50%)',
      width: '573px',
      height: 'auto',
      borderRadius: '12px',
      background: '#FFFFFF',
      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
      
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: '80vh',
      '@media(max-width:500px)':{
        width: '290px',
        
        top: '52%',
        overflowY: 'auto',
        
      }
  },
  objectiveCancelImageWrapper: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
    
},
  bodyText: {
    flex: 1,
    fontFamily: 'panton narrow regular',
    fontSize: '16px',
    lineHeight: '19.38px',
    fontWeight: 400,
    padding:'48px',
   
  },
  cancelImageObjective: {
    cursor: 'pointer',
    width: '10.96px',
    height: '11px',
   position:'absolute',
   top:"18px",
   right:"18px",
   "@media (max-width: 500px)":{
    top:'18px',
    right:'18px'
  },
  },
    smalltext: {
      fontFamily: "Panton Narrow Regular",
      fontSize: "12px",
      fontWeight: 400,
      color: "#F8FAFC",
      marginTop:'-4px'
    },
    img: {
      width: "100%",
    },
    imgGroup: {
      width: "100%",
      cursor:"pointer",
       height:"175px",
      borderRadius: "12px",
      
    },
    "@media (max-width: 500px)":{
      searchLogo:{
       width:'106px',
      },
    },
    imgGrayGroup: {
      width: "100%",
      filter: "grayscale(100%)",
      cursor:"pointer",
      height:"175px",
      borderRadius: "12px",
      
    },
    headingContainer: {
      width: "100%",
      marginTop: "32px",
    },
    mainContainer:{
      boxSizing:"border-box",
      padding:"0px 16px"
      // backgroundColor:"#900000"
    },
    mainContainerBox:
    {
      display:"flex",
      flex:0.95, 
      
      boxSizing:"border-box",
      
      padding:'40px 16px 0px 16px',
      
      gap:"16px",
      [theme.breakpoints.down("xl")]:{
        //marginTop:"1%",
       },
       [theme.breakpoints.down("lg")]:{
        //marginTop:"2%",
        },
        [theme.breakpoints.down("md")]:{
          //marginTop:"5%",
          flexDirection:"row" ,
          },
        [theme.breakpoints.down("sm")]:{
            // marginTop:"10%",
            flexDirection:"column" , 
            padding:'30px 32px 0px 28px'
            },
        [theme.breakpoints.down("xs")]:{
  
          flexDirection:"column" ,
          },
          '@media (min-width: 1900px)': {
            gap:'21px',
            paddingLeft:'20px',
             
           },
           '@media (max-width: 500px)': {
            padding:'unset',
             
           },
    },
    industy:{
      '@media (max-width: 500px)': {
        // background:'red',
         width:'85.3%',
         marginLeft:'15px',
        marginBottom:'25px'
       },
       '@media (max-width: 375px)': {
        // background:'red',
         width:'85.5%',
         marginLeft:'15px',
        
       },
    },
    sideContainerBox:{ 
     
      width:'31.1%',
       minHeight:"890px",
      position:'relative',
      '@media (max-width: 960px)': {
        width:"100%",
        minHeight:"810px",
      },
      '@media (max-width: 500px)': {
        minHeight:"unset",
        // background:'red',
      },
    },
    innerSideContainerBox:{
     
      width:"100%",
     
      '@media (min-width: 600px)': {
        
      },
      [theme.breakpoints.down("md")]:{
       
        },
      [theme.breakpoints.down("sm")]:{
        
          },
      [theme.breakpoints.down("xs")]:{
       
        },
        '@media (min-width: 500px)': {
          
        },

    },
  objectiveRoot:{
    flexShrink: 0,
    overflow:'auto',
    borderRadius: "38px",
    background: "#FFF",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
    minHeight:'944px',
    marginBottom:'40px',
    height: "calc(100vh - 80px)",
    '@media (min-width: 960px)': {
     
      width:'67.5%'
    },
    '@media (max-width: 959px)': {
      marginTop: 50,
      height: "unset",
      minHeight:'520px',
    },
    '@media (max-width: 500px)': {
      boxShadow:'none',
      borderRadius:'unset',
      minHeight:'872px'
    },
  },
  objectiveInnerRoot:{
    padding: "27px 164px 0px 41px",
    '@media (max-width: 888px)': {
      padding: "22px 80px 0px 39px",
    },
    '@media (max-width: 700px)': {
      padding: "22px 50px 0px 39px",
    },
    '@media (max-width: 500px)': {
      padding: "44px 37px 0px 32px",
    },
  },
    actionIcon: {
      "&:hover": {
        cursor: "not-allowed"
      }
    },
    actionIconsHover: {
      color: "white",
      "&:hover": {
        backgroundColor: "inherit"
      }
    },
    campaignStatus: {
      marginRight: "6px"
    },
    profileimage: {
     width: "24px",
     height: "24px",
     borderRadius: "24px",
     marginRight: "8px"
   },
   lableSetup: {
    fontFamily: "Panton Narrow Bold !important",
    fontSize: "16px !important",
    fontWeight: 700,
    color: "#000000",
    lineHeight:'32px',
    marginRight:'4px',
    '@media(min-width:500px)':{
      display:'none !important',
    },
  },
     lable: {
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
      
      "@media(max-width:500px)":{
        fontSize: "16px !important",
        lineHeight:'32px',
        fontFamily: "Panton Narrow Bold !important",
        display:'flex'
      }
    },
    MainButtonBox:{
      marginTop:'32px !important'
    },
    buttonBox: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      position:'absolute',
      width:'100%',
      bottom:'40px',
      "@media (max-width: 1440px)": {
          gap:"10px",
       },
      "@media (max-width: 960px)": {
       
        marginTop: "40px",
        bottom:'unset',
        justifyContent:'space-around',
        paddingBottom:'37px'
       },
       "@media (max-width: 500px)": {
         paddingBottom:'18px !important',
        marginTop: "20px",
        width:'93%',
        marginLeft:'14px',
        justifyContent:'space-around',
        
       },
       "@media (max-width: 400px)": {
        paddingBottom:'18px !important',
       marginTop: "20px",
       width:'92%',
       marginLeft:'10px',
        justifyContent:'space-around',
      
      },
      
    },
    defaultBUtton: {
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      textTransform: "capitalize" as const,
      fontSize: "16px",
      textDecoration: "none",
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: "52px",
      width: "176px",
      borderRadius: "43px",
      color: "#353232",
      backgroundColor: "#e0e0e0",
      boxShadow: "none",
      "&:hover": { 
        backgroundColor: "#005E6E",
        color: "#FFFFFF",
      },
    },
    button: {
      width: "176px",
      borderRadius: "43px",
      color: "#353232",
      backgroundColor: "#e0e0e0",
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      height: "52px",
      fontSize: "16px",
      boxShadow: "none",
      textTransform: "capitalize" as const,
      "&:hover": { 
        backgroundColor: "#005E6E",
        color: "#FFFFFF",
        boxShadow: "none",
      },
      '@media(max-width:500px)':{
        width:'176px'
      },
      '@media(max-width:390px)':{
        width:'167px'
      },
      '@media(max-width:350px)':{
        width:'142px'
      }
    },
  });
  const webStyle = {
    roras: {
      fontWeight: 700,
      fontFamily: "Panton Narrow Bold",
      fontSize: "24px",
      textTransform: "uppercase" as "uppercase",
    },
    rora: {
      fontWeight: 700,
      fontFamily: "Panton Narrow Bold",
      fontSize: "24px",
      marginRight:"35px"
    },
    titles: {
      alignItems:"center",
      fontSize: "16px",
      color: "#000000",
      fontFamily: "Panton Narrow Regular",
     
      display:"flex",
      fontWeight: 400,
    },
    names: {
       lineHeight:"19px",
      fontWeight: 400,
      fontFamily: "Panton Narrow Regular",
      fontSize: "16px",
       paddingTop:"5px",
      paddingBottom:"4px",
  
    },
    times: {
       lineHeight:"19px",
     
      fontFamily: "Panton Narrow Regular",
      fontSize: "14px",
      fontWeight: 400,
      paddingTop:"5px",
      paddingBottom:"4px",
     
    },
    miniBox: {
      display: "flex",
      justifyContent: "space-between",
     
    },

    subBox: {
      backgroundColor: "#FFFFFF",
      height: "auto",
      boxShadow: "0px 4px 16px 0px #00000040",
      padding: "21px 27px 21px 20px",
     
      borderRadius: "24px",
      marginTop:"77px",
      '@media (min-width: 500px)': {
        marginLeft:'1px !important'
      },
      '@media (max-width: 1050px)': {
        marginTop:"30px",
      },
    },
    title: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: "Panton Narrow Regular",
      marginBottom: "20px",
      display:"flex",
      alignItems:"center",
     
      gap:'12px'
    },
    bannerImage: {
      width: "100%",
     height:'164px',
    },
    readMoreText: {
      fontFamily: "Panton Narrow Bold",
      fontSize: "14px",
      fontWeight: 700,
      color: "#005E6E",
      cursor:'pointer'
    },
    imageStyles:{
      marginLeft: "15px",
      height:"13px",
      width:"13px"
    },
    descriptionText:{
      marginTop: "30px" ,
      lineHeight:'19.38px',
    },
  
    name: {
      fontFamily: "Panton Narrow Regular",
      fontSize: "16px",
      fontWeight: 400,
      paddingTop:"10px",
      paddingBottom:"10px",
      lineHeight:"24px",
      paddingLeft:"0px"
  
    },
    time: {
      fontFamily: "Panton Narrow Regular",
      fontSize: "14px",
      fontWeight: 400,
      paddingTop:"5px",
      paddingBottom:"4px",
      lineHeight:"19.38px",
    },
   
  }
export default withRouter(withStyles(useStyle)(Objective as any) as any) as any;
