import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SettingsController, {
  Props,
  configJSON,
} from "./SettingsController";

export default class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={webStyle.mainContainer} >
            <Button 
              data-test-id="email-login-button"
              style={webStyle.loginButton} 
              onClick={this.redirectLoginPage}
            >
              Dev Log In
            </Button>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer:{
   display:"flex",
   justifyContent:"center",
   alignItems:"center",
   height:"100%",
   background: "linear-gradient(180deg, rgba(255, 255, 255, 0.80) 13.67%, rgba(0, 94, 110, 0.80) 100%)"
 },
 loginButton:{
  width: "200px ",
  padding: "14px 0px 13.007px 0px",
  borderRadius: "56px",
  boxShadow: "4px 7px 35px 0px rgba(0, 0, 0, 0.25)",
  backgroundColor:"white",
  fontFamily: "Panton Narrow Bold",
  marginLeft:'10px',
  color:"#003A5C",
  fontSize: "20px",
  "font-weight": "700",
  "text-transform":"none",
 }

};
// Customizable Area End
