import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Theme,
  Popover,
  Menu,
  MenuItem,
  Divider,
  Input,
  createStyles,
  Grid,
  Box,
  styled,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import {
  withStyles,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import DashboardController, {
  Props,
  CampaignList,
  IRolesAndPermissionOptions,
  USER_ROLES,
  UserRolesList,
  ROLES_PERMISSION_OPTIONS,
  AccountData
} from "./DashboardController.web";
import Sidebar from "../../../components/src/SideBar.web";
import SearchIcon from '@material-ui/icons/Search';
import Toggle from "../../../components/src/common/Toggle";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import {
  addIcon,
  statusComplete,
  statusIncomplete,
  dmcUser,
  dmcUserActive,
  editCampaign,
  copyCampaign,
  filterCampaignArrow,
  filterCampaignArrowActive,
  closeCircle,
  arrowDateFilterLeft,
  arrowDateFilterRight,
  assignSearchIcon,
  viewCampaignIcon,
  editCampaignActive,
  copyCampaignActive,
  viewCampaignActiveIcon,
  userDashboardHover,
  filterActive
} from "./assets";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { withRouter } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { ClassNameMap, ClassKeyOfStyles } from "@material-ui/styles";
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from '@material-ui/lab/Skeleton';


const DASBOARD_TABLE_HEADING = [
  "DMC",
  "Company Name",
  "Campaign ID",
  "Status",
  "Creation date",
  "Action"
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const CustomTable  = styled(Table)({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor:'white',
  },
  '& .MuiTableCell-stickyHeader ':{
    top:'0px'
  },
  "@media(max-width:960px)":{
    '& .MuiTableCell-stickyHeader ':{
      top:'-1px'
    }
    // fontSize: "20px !important",
  },
})

const getRowClassame = (
  data: CampaignList,
  classes: ClassNameMap<ClassKeyOfStyles<string>>,
  selectedCampaign: string | null
) => {
  if (!selectedCampaign) {
    return classes.tableRowHoverEffect;
  } else if (selectedCampaign == data.id) {
    return "";
  } else {
    return classes.tableRowDisable;
  }
};

const getRowIconHoverClassname = (
  data: CampaignList,
  classes: ClassNameMap<ClassKeyOfStyles<string>>,
  selectedCampaign: string | null,
  isTableRowHover: string | null
) => {
  if (selectedCampaign === data.id) {
    return "";
  }
  if (selectedCampaign && selectedCampaign !== data.id) {
    return classes.actionIcon;
  } else if (isTableRowHover === data.id) {
    return classes.actionIconsHover;
  } else {
    return "";
  }
};

const getActiveMenuClass = (
  isFilterByDate: boolean,
  filterByDateAnchorEl:HTMLLIElement |  null,
  classes: ClassNameMap<ClassKeyOfStyles<string>>
) => {
  if (isFilterByDate || filterByDateAnchorEl) {
    return classes.filterMenuListTxtActive;
  }
  else {
    return classes.filterMenuListTxt;
  }
};

const getActionHoverClasses = (
  campainActionHover:ROLES_PERMISSION_OPTIONS, 
  classes: ClassNameMap<ClassKeyOfStyles<string>>
)=>{

   const rolesAndPermissionOption:IRolesAndPermissionOptions = {
     edit:classes.popoverTxt,
     assign:classes.popoverTxt,
     view:classes.popoverTxt,
     duplicate:classes.popoverTxt,
   }  

   rolesAndPermissionOption[campainActionHover] = classes.popoverTxtActive

   return  rolesAndPermissionOption;
  
}

 const showFilterByDistrictIcon = (isFilterByDistrict:boolean)=>{
   return isFilterByDistrict ? filterCampaignArrowActive : filterCampaignArrow
 }

 const showFilterByDateIcon = (isFilterByDate:boolean,fiterItemsAnchorEl:HTMLLIElement|null)=>{
   return  (isFilterByDate|| fiterItemsAnchorEl) ? filterCampaignArrowActive : filterCampaignArrow
 }

 const showEditOptionToAdmin = (
  userDetails:AccountData| null,
  selectedCampaign:string  |  null
 )=>{
  if (userDetails?.attributes?.role?.role_id === USER_ROLES.ADMIN_AND_EDITOR || userDetails?.id === selectedCampaign){
    return true
  }
  else{
    return false
  }
 }

 const getActionHoverEditIcon = (campainActionHover:ROLES_PERMISSION_OPTIONS)=>{
   if(campainActionHover === ROLES_PERMISSION_OPTIONS.EDIT){
    return editCampaignActive
   }
   else{
     return editCampaign
  }
     
 }
 const getActionHoverDuplicateIcon = (campainActionHover:ROLES_PERMISSION_OPTIONS)=>{
  if(campainActionHover === ROLES_PERMISSION_OPTIONS.DUPLICATE){
    return copyCampaignActive
  }
  else {
   return copyCampaign
 }
    
}
const getActionHoverViewIcon = (campainActionHover:ROLES_PERMISSION_OPTIONS)=>{
  if(campainActionHover === ROLES_PERMISSION_OPTIONS.VIEW){
   return viewCampaignActiveIcon
  }
  else{
   return viewCampaignIcon
 }
    
}
const getActionHoverAssignIcon = (campainActionHover:ROLES_PERMISSION_OPTIONS)=>{
  if(campainActionHover === ROLES_PERMISSION_OPTIONS.ASSIGN){
   return dmcUserActive
  }
  else{
    return dmcUser
 }
    
}

const getDMCUserProfile = (dmcProfileLink:string | null,isTableRowHover:string|null,campaignId:string)=>{
  if(dmcProfileLink){
    return dmcProfileLink
  }
  else if(isTableRowHover === campaignId){
   return userDashboardHover 

  }
  else{
    return dmcUser
  }
}

const getFilterActiveIcon = (isFilterActive:boolean,fiterItemsAnchorEl:HTMLButtonElement | null)=>{
  return (isFilterActive || fiterItemsAnchorEl ) ? filterActive : addIcon
}

const getFilterActiveClasses = (isFilterActive:boolean, fiterItemsAnchorEl:HTMLButtonElement | null,classes: ClassNameMap<ClassKeyOfStyles<string>>)=>{
  return (isFilterActive || fiterItemsAnchorEl) ? classes.filterButtonActive : classes.filterButton 
}

// Customizable Area End

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
   // Customizable Area Start
   renderTableHead = () => {
     
    const { classes } = this.props;
  return (
    <>
        <Backdrop className={classes.backdropCss} open={this.state.showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableHead  >
    
    <TableRow>
      {DASBOARD_TABLE_HEADING.map(
        (head: string, index: number) => (
          <TableCell
            data-test-id={"dashboard-table-heading"}
            key={`cell-${index}-${head}-${index}`}
            className={classes.tableHeader}
          >
            {this.state.select_lang?.name[index]}
          </TableCell>
        )
      )}
    </TableRow>
  </TableHead> 
    </>
   
  );
}

//istanbul ignore next
renderEditView = () => {
  const { classes } = this.props;
  const { userProfileDetails, campainActionHover, campaignList, results} = this.state
  const userRole = campaignList.map((campaign : any) => campaign?.data?.attributes?.user?.role_id);
  const getuserProfileRoleId = userProfileDetails?.attributes?.role?.role_id;
  const isUser = userProfileDetails?.attributes?.role?.role_id === USER_ROLES.USER;

  campaignList.map((index : any) => {
    let result = userRole[index] === getuserProfileRoleId;
    results.push(result);
  });

  localStorage.setItem('campaignResults', JSON.stringify(results));

  const storedResults : any = localStorage.getItem('campaignResults');
  const resultsArray = JSON.parse(storedResults);

  const trueIndexes = resultsArray
  .map((result: any, index: any) => result ? index : -1)

  trueIndexes.forEach((index: any) => {
    if (index !== -1) {
      this.setState({
        shouldRenderEdit: true
      })
    }
  });
 
  return (
    userProfileDetails?.attributes?.role?.role_id === USER_ROLES.USER &&
    isUser &&
    <div>
      {this.state.shouldRenderEdit &&  (
        <div
          data-test-id="roles-and-permission-list"
          className={classes.txtContainer}
          onMouseEnter={() => this.handelPopoverMouseHover(ROLES_PERMISSION_OPTIONS.EDIT)}
          onMouseLeave={this.handelPopoverMouseLeave}
          onClick={this.handleEdit}
        >
          <img src={getActionHoverEditIcon(campainActionHover)} alt="Edit" />
          <Typography className={getActionHoverClasses(campainActionHover, classes).edit}>
            {this.state.select_lang?.name[6]}
          </Typography>
        </div>
       )} 
       {!this.state.shouldRenderEdit && ( 
        <div
          data-test-id="roles-and-permission-view"
          className={classes.txtContainer}
          onMouseEnter={() => this.handelPopoverMouseHover(ROLES_PERMISSION_OPTIONS.VIEW)}
          onMouseLeave={this.handelPopoverMouseLeave}
          onClick={this.handleViewClick}
        >
             <img src={getActionHoverViewIcon(campainActionHover)} alt="View" />
          <Typography className={getActionHoverClasses(campainActionHover, classes).view}>
            {this.state.select_lang?.name[8]}
          </Typography>
        </div>
       )}
    </div>
  );
};

renderDiv= ()=>{
  const { classes } = this.props;
  const { userProfileDetails, selectedCampaign, campainActionHover, userAssignList } = this.state;
  const isUser = userProfileDetails?.attributes?.role?.role_id === USER_ROLES.USER;
  const userPadding = "26px 24px 0px";
  const adminPadding = window.innerWidth < 500 ? " 24px 20px 19px 20px":"24px 24px 19px";
  const userWidth = window.innerWidth < 500 ? "250px" : "200px";
  
  const adminWidth = window.innerWidth < 500 ? "295px" : "340px";

  const padding = isUser ? userPadding : adminPadding;
  const width = isUser ? userWidth : adminWidth;  
 
  return(
    <div className={classes.actionPopover} ref={this.assests} style={{ padding, width }}>

            { 
              showEditOptionToAdmin(userProfileDetails,selectedCampaign) 
              && 
              <div
               data-test-id="roles-and-permission-list"
               className={classes.txtContainer}
               onMouseEnter={() => this.handelPopoverMouseHover(ROLES_PERMISSION_OPTIONS.EDIT)}
               onMouseLeave={this.handelPopoverMouseLeave}
               onClick = {this.handleEdit} 
              >
               <img src={getActionHoverEditIcon(campainActionHover)} />
               <Typography
                 className={getActionHoverClasses(campainActionHover,classes).edit}
               >
                {this.state.select_lang?.name[6]}
               </Typography>
             </div>
            } 
            <div
              data-test-id="roles-and-permission-list"
              className={classes.txtContain}
              onMouseEnter={() => this.handelPopoverMouseHover(ROLES_PERMISSION_OPTIONS.DUPLICATE)}
              onMouseLeave={this.handelPopoverMouseLeave}
            >
              <img src={getActionHoverDuplicateIcon(campainActionHover)} />
              <Typography
                 className={getActionHoverClasses(campainActionHover,classes).duplicate}
                 onClick={() => {
                  this.duplicateCampaign(selectedCampaign);
                  this.handelClosePopover();
                }}
                 >
                {this.state.select_lang?.name[7]}
              </Typography>
            </div>
           {this.renderEditView()}
           {

            userProfileDetails?.attributes?.role?.role_id === USER_ROLES.ADMIN_AND_EDITOR && 
           
            <div
              className={classes.txtContainer}
              style={{ marginBottom: "0px" }}
              onMouseEnter={() => this.handelPopoverMouseHover(ROLES_PERMISSION_OPTIONS.ASSIGN)}
              onMouseLeave={this.handelPopoverMouseLeave}
            >
             <img src={getActionHoverAssignIcon(campainActionHover)} />
             <Typography
               className={getActionHoverClasses(campainActionHover,classes).assign}
             >
                {this.state.select_lang?.name[9]}
             </Typography>
             <div 
               className={ campainActionHover ? classes.searchBoxActive : classes.searchBox } 
              >
               <Input
                 data-test-id="user-auto-suggestion-search-bar"
                 className={classes.inputBox}
                 placeholder={this.state.select_lang?.name[10]}
                 value={this.state.userAssignListSearchQuery}
                 onChange={this.handelSearchUserQuery}
                 disableUnderline
               />
               <img  className={classes.imageassign} src={assignSearchIcon} alt="search icon" />
             </div>
             {
                  
                  userAssignList && userAssignList.length > 0 &&
                  <div style={{position:'relative'}} >
                      {
                         !this.state.listTop && <div className={classes.typeAheadContainerArrow}></div>
                        }
                  <div className={classes.typeAheadContainer} style={{ marginTop: this.state.listTop ? '-190px' : 0 , marginLeft: this.state.listTop ? '100px' : '' }}>
                    {
                      userAssignList.map((user: UserRolesList) =>
                        <li
                          data-test-id="user-role-list"
                          key={`${user.id}`}
                          className={classes.typeAheadText}
                          style={{marginTop:'-6px'}}
                          onClick={() => {
                            this.assingCampaingToAnotherUser(selectedCampaign, user.id)
                            this.handelClosePopover();
                          }}
                        >
                          {user.attributes.first_name} {user.attributes.last_name}
                        </li>
                      )
                    }
                  </div>
                  {
                  this.state.listTop && <div className={classes.typeAheadContainerArr} style={{ position: 'absolute', top: '-100px',right:'15px !important', borderRadius:'10px',boxShadow:'0px 0px -5px #00000010'}}></div>
                   }
                  </div>
                }
           </div>
           }
          </div>
  )
}
renderTablDate = () => {
     
  const { classes } = this.props;
  const{isFilterActive,fiterItemsAnchorEl,isFilterByDate,filterByDateAnchorEl, isFilterByDistrict, openLocaton, radiusData}=this.state;
   
return (
<>
  <Toggle 
  data-test-id="campaign-toggle-filter" toggleCampaignList={this.filterCampaignCompleteAndIncomplete} text={this.state.select_lang?.name[17]} />
                  <div>
                    <Button
                      data-test-id="filter-campiagn-btn"
                      className={getFilterActiveClasses(isFilterActive,fiterItemsAnchorEl,classes)}
                      onClick={this.handelClickFilterCampaigns}
                      onMouseEnter={this.filterBtnActiveOnMouseEnter}
                      onMouseLeave={this.filterBtnActiveOnMouseLeave}
                    > 
                      {this.state.select_lang?.name[18]} <img className={classes.btnLogo} src={getFilterActiveIcon(isFilterActive,fiterItemsAnchorEl)} />
                    </Button>
                    <Menu
                      data-test-id="filter-campaign-menu"
                      className={classes.filterCampaignMenuContainer}
                      anchorEl={fiterItemsAnchorEl}
                      keepMounted
                      open={Boolean(fiterItemsAnchorEl)}
                      onClose={this.closeFilterCampaignsMenu}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                    >
                       <MenuItem
                        data-test-id="fitler-campaign-by-date"
                        className={classes.filterMenuList}
                        onClick={this.handelDatePickePopover}
                        onMouseEnter={this.handelDateFilterHover}
                        onMouseLeave={this.handelDateFilterOnMouseLeave}
                      >
                        <img  src={showFilterByDateIcon(isFilterByDate,filterByDateAnchorEl)}/>
                        <Typography
                          className={getActiveMenuClass(
                            isFilterByDate,
                            filterByDateAnchorEl,
                            classes
                          )}
                        >
                          {this.state.select_lang?.name[14]}

                        </Typography>
                      </MenuItem> 
                      <Divider />
                      <MenuItem
                        data-test-id="filter-by-district"
                        className={classes.filterMenuList} 
                        onClick={this.handleClick}
                        onMouseEnter = {this.handelDistrictFilterHover}
                        onMouseLeave ={this.handelDistrictFilterOnMouseLeave}>
                            <img
                              src={showFilterByDistrictIcon(isFilterByDistrict)}
                            />
                            <Typography aria-describedby={openLocaton ? "simple-popover" : undefined} 
                            className={ isFilterByDistrict? classes.filterMenuListTxtActive: classes.filterMenuListTxt}
                            >
                            {this.state.select_lang?.name[15]}

                            </Typography>

                            <Popover 
                            id={openLocaton ? "simple-popover" : undefined}
                            open={Boolean(openLocaton)}
                            className={classes.location}
                            anchorEl={openLocaton} 
                             anchorOrigin={{
                              vertical: "top",
                              horizontal: "left"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}
                            onClose={this.closeByDistrictPopover}
                            >
                              {
                                (radiusData || [])?.map((item, index) => (
                                    <Box data-test-id="redius_item" sx={{ border: 1, p: 1, bgcolor: 'background.paper' }} key={index} 
                                      onClick={() =>this.handleRadiusDataId(item)}>
                                      {item.name}
                                    </Box>
                                ))
                              }
                            </Popover>
                      </MenuItem>
                      </Menu>
                  </div>
                  </>
 
);
}
renderDateRangePickerPopover = () => {
  const { classes } = this.props;
  const{filterByDateAnchorEl,filterCampaignFromDate,filterCampaignToDate} =this.state;
  const selectionRange = {
    startDate: filterCampaignFromDate,
    endDate: filterCampaignToDate,
    key: "selection"
  };
  return(
    <Popover
    id={filterByDateAnchorEl ? "simple-popover" : undefined}
    open={Boolean(filterByDateAnchorEl)}
    anchorEl={filterByDateAnchorEl}
    onClose={this.closeDatePickerPopover}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    style={{ borderRadius: "10px" }}
    className={classes.boxs1}
  >
    <div className={classes.dateRangePickerPopoverContainer}>
      <div className={classes.filteDateFieldsContainer}>
        <Input
          className={classes.filteDateInputFields}
          value={moment(filterCampaignFromDate).format("DD/MM/YY")}
          disableUnderline
        />
        <Typography className={classes.popoverTxt}>to</Typography>
        <Input
          className={classes.filteDateInputFields}
          value={moment(filterCampaignToDate).format("DD/MM/YY")}
          disableUnderline
        />
      </div>
      <DateRangePicker
        data-test-id="date-range-picker"
        ranges={[selectionRange]}
        onChange={this.handelFilterCampaignDate}
        className={classes.dateRangePicker}
        showDateDisplay={false}
        showMonthArrow={true}
        showPreview={false}
        weekdayDisplayFormat={"EEEEE"}
        rangeColors={["#005E6E"]}
        editableDateInputs={true}
        maxDate={new Date()}
      /> 
      <div
        className={classes.filteDateFieldsContainer}
        style={{ paddingTop: "10px" }}
      >
        <Button
          data-test-id="cancel-date-filter"
          className={classes.filterCampaignsBtnCancel}
          variant="outlined"
          onClick={this.closeDatePickerPopover}
        >
         {this.state.select_lang?.name[12]}
        </Button>
        <Button
          data-test-id="set-date-filter"
          className={classes.filterCampaignsBtnSetDate}
          onClick={this.handelSetDateRange}
        >
          {this.state.select_lang?.name[13]}
        </Button>
      </div>
    </div>
  </Popover>
  )
}
renderTableRows = (filteredCampaignList:any,sortedCampaignList:any, filterCampaignDetails : any) => {  
  const{classes} = this.props;  
  const {selectedCampaign,isTableRowHover,expandedCompany,flagSelectedCountry,flagSelectedItalian}=this.state;
  const selectedCountry = localStorage.getItem("selectedCountry");
  if (this.state.Norecord && !this.state.showLoader  && this.state.Norecord.length === 0) {
    return (
      <Grid container justifyContent="center" alignItems="center" className={classes.noRecardPop}>
        <Grid item>
          <Typography variant="body1" style={{
            marginTop: "10px",
            fontFamily: "panton narrow semibold",
            fontSize: "18px"
          }}>
            No records found.
          </Typography>
        </Grid>
      </Grid>
    );

  }
  const selectedCampaignList : any = filterCampaignDetails.length !== 0 ? filterCampaignDetails : sortedCampaignList;


    return (
      <TableBody   >
     {(selectedCampaignList).map((item: CampaignList, index: number) => {
      const campaign = item.data;
          return (
            <TableRow
              data-test-id={"dashboard-table-row"}
              className={`${getRowClassame(campaign, classes, selectedCampaign)} ${classes.tableRowHover}`}
              key={`row-${campaign.id}`}
              id={`row-${campaign.id}`}
              onMouseEnter={() => this.selectTableRowHoverId(campaign.id)}
              onMouseLeave={this.removeTableRowHoverId}
              onClick={() => this.navigate(campaign.id,campaign)}             
              
              //onClick={this.handleEdit} 
            >
              <TableCell className={classes.tableRowFirstCell} align="left">
              <div style={{ display: "flex", alignItems: "center", fontSize:"16px", fontWeight:400, whiteSpace:"nowrap"}}>
                  <img
                    className={classes.profileimage}
                    src={getDMCUserProfile(campaign.attributes.user_photo_link, isTableRowHover, campaign.id)}
                    alt="user profile image"
                  />
                  {campaign?.attributes?.user?.first_name} {campaign?.attributes?.user?.last_name}
                </div>
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
  <div style={{ maxWidth: '200px',display:'flex',justifyContent:'flex-start' }}>
    {expandedCompany === campaign.attributes.company_name ? (
      campaign.attributes.company_name
    ) : (
      <>
        {campaign.attributes.company_name.length > 14? (
          <>
            {`${campaign.attributes.company_name.substring(0, 14)}`}
            {console.log("attributes---",)}
            <span
              data-test-id={"dashboard-table-morezableIcon"}
              style={{ cursor: 'pointer'}}
            >
              ....
            </span>
          </>
        ) : (
          campaign.attributes.company_name
        )}
      </>
    )}
  </div>
</TableCell>
              <TableCell className={classes.tableCell} >
              {this.renderUniqueCampaignId(campaign)}
              </TableCell>
              <TableCell className={classes.tableCell} align="left" data-test-id="languageSWitchBtn" >
                {campaign.attributes.status === "Completed" ||
                  campaign.attributes.status === "Completato" ||
                  campaign.attributes.status === "Abgeschlossen" ||
                  campaign.attributes.status === "Terminé" ? (
                  <img
                    src={statusComplete}
                    alt="status Complete"
                    className={classes.campaignStatus}
                  />
                ) : (
                  <img
                    src={statusIncomplete}
                    alt="status incomplete"
                    className={classes.campaignStatus}
                  />
                )}
                {campaign.attributes.status}
              </TableCell>
              <TableCell className={classes.tableCell} style={{ width: "160px" }}>
                {moment(campaign.attributes.created_at).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell className={`${(selectedCountry ===flagSelectedCountry) ? classes.tableRowLastCell : classes.tableRowLastC}
                ${(selectedCountry ===flagSelectedItalian) ? classes.tableRowLastCellitalian :''}`
               }
              
              >
                <IconButton
                  data-test-id={"dashboard-table-action-icon"}
                  className={getRowIconHoverClassname(
                    campaign,
                    classes,
                    selectedCampaign,
                    isTableRowHover
                  )}
                  id={`${index}`}
                  color="inherit"
                  onClick={event => {
                    event.stopPropagation(); // Stop propagation here
                    this.handleSeletedRowAction(event.currentTarget, campaign.id,campaign);
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
    );
  
};

    // Customizable Area End


  render() {
    // Customizable Area Start
     
    const { classes } = this.props;
    const {
      selectedCampaign,
      anchorEl,
      campainActionHover,
     
      filterCampaignFromDate,
      filterCampaignToDate,
      isShowSelectedDateRange,
      userAssignList,
      userProfileDetails,
      campaignList,
      campaignListSearchQuery,
      RediusItems
    } = this.state;
    

    const filteredCampaignList = (campaignList ?? []).filter((item: { data: any; })  => {

      const campaign = item.data;
      const dmcName = `${campaign?.attributes?.user?.first_name} ${campaign?.attributes?.user?.last_name} ${campaign?.attributes?.company_name} ${campaign.attributes.unique_campaign_id} `;
      return dmcName.toLowerCase().includes(campaignListSearchQuery.toLowerCase());
        
    });
    const sortedCampaignList = filteredCampaignList
    ? [...filteredCampaignList].sort((a: any, b: any) => {
        const dateA = new Date(a.data.attributes.created_at).getTime();
        const dateB = new Date(b.data.attributes.created_at).getTime();
        return dateB - dateA;
      })
    : [];

    const filterCampaignDetails : any = [...sortedCampaignList]?.filter((item : any)=> item.data.attributes.radius_id === this.state.campaignListLocationId)

  
    // Customizable Area End
    //istanbul ignore next
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Sidebar navigation={this.props.navigation} activePath={""}>
          <div className={classes.mainContainer} >
            <div className={classes.headingContainer}>
              <Typography className={classes.mainHeading} variant="h2">
              {this.state.select_lang?.name[19]}
              </Typography>
              <Typography className={classes.subHeading} variant="body1">
              {this.state.select_lang?.name[11]}
              </Typography>
            </div>
        
            <div className={classes.dashboardContainer}>
              <div className={classes.dashboardFilterContainer}>
              <div className={classes.searchbarConatiner}>
                <SearchIcon />
                <Input
                data-test-id="campaign-search-filter"
                placeholder={this.state.select_lang?.name[16]}      
                onChange={(e) => this.handelCampaignListSearchQuery(e)}
                disableUnderline
                value={this.state.campaignListSearchQuery}
                className={classes.searchbar}
                />
              </div>
                  <div className={classes.toggleAndDateFilter}>
                  {this.renderTablDate()}
                </div>
              </div>
              {isShowSelectedDateRange && (
                <div className={classes.dateContainer}>
                  <Typography className={classes.showSelectedDateRangeTxt}>
                    {moment(filterCampaignFromDate).format("DD/MM/YYYY")} -{" "}
                    {moment(filterCampaignToDate).format("DD/MM/YYYY")}
                    <img
                      data-test-id="close-selected-date-range"
                      src={closeCircle}
                      onClick={this.handelRemoveDateRange}
                      className={classes.closeDateRangeSelect}
                    />
                  </Typography>
                </div>
              )}
               {
                RediusItems &&
                <div data-test-id="by-location" className={classes.showSelectedDateRangeContainers}>
                <Typography className={classes.showSelectedDateRangeTxt}>
                {RediusItems}
                  <img
                    data-test-id="close-selected-date-range"
                    src={closeCircle}
                    onClick={this.handelRemoveLocation}
                    className={classes.closeDateRangeSelect}
                  />
                </Typography>
              </div>
               }

              <InfiniteScroll
              dataLength={sortedCampaignList.length} 
              next={() => this.getAllCampaignList()}
              hasMore={true} 
              scrollThreshold={0.9}
              className={classes.tableContainer}
              height={650}
              loader={""}
              endMessage={"No more items"}
              scrollableTarget="table-scrollable-area" 
            >
                <CustomTable   stickyHeader style={{minWidth:'1000px',overflowX: 'auto'}}  >
                {this.renderTableHead()}
                  {this.renderTableRows(filteredCampaignList,sortedCampaignList,filterCampaignDetails)}
                </CustomTable >
              </InfiniteScroll>
              
            </div>
          </div>
          {this.renderDateRangePickerPopover()}
          <Popover
            data-test-id="roles-permission-popover"
            id={anchorEl ? "simple-popover" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={this.handelClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{ borderRadius: "10px" }}
            className={classes.box1}
          >
            <div>
            {this.renderDiv()}
              
            </div>
            <div className={classes.typeAheadContainerArrows} style={{ position: 'absolute', top: '-7px',right:'15px !important',zIndex:0 , borderRadius:'10px'}}></div>
           
          </Popover>
          
         



          </Sidebar>

          </ThemeProvider>
  )
}

    // Customizable Area End


}

export const useStyle = (theme: Theme) =>
  createStyles({
    dateContainer: {
      padding: "0px 20px",
      justifyContent: "flex-end",
      display: "flex",
      marginLeft:"-38px",
      marginBottom:"25px",
      width: "100%",
      marginTop: "-58px",

      '@media(max-width:1920px)':{ marginLeft:"-52px"},
     
      '@media(max-width:1441px)':{marginLeft:"-39px"},
     
      '@media(max-width:1370px)':{marginLeft:"-36px"},
      '@media(max-width:1280px)':{marginLeft:"-34px" },
      
      '@media(max-width:1170px)':{marginLeft:"-31px"},

      '@media(max-width:1025px)':{marginLeft:"-26px"},
      
      '@media(max-width:960px)':{marginTop:'13px',
        marginLeft:"-66px",
      },
      '@media(max-width:912px)':{marginTop:'13px',
        marginLeft:"-42px",
      },
      '@media(max-width:855px)':{ marginTop:'13px',
        marginLeft:"-36px",
      },
     
      '@media(max-width:820px)':{marginTop:'13px',
        marginLeft:"-32px",
      },
      '@media(max-width:811px)':{ marginTop:'13px',
        marginLeft:"-32px",
      },
       '@media(max-width:768px)':{marginTop:'13px',
        marginLeft:"-27px",
      },
      '@media(max-width:720px)':{marginTop:'13px',
        marginLeft:"-21px",
      },
      '@media(max-width:641px)':{marginTop:'13px',
        marginLeft:"-15px",
      },
    
      
      '@media(max-width:430px)':{ marginTop:'13px',
        position:'relative',
        right:"14px",
        top:'13px',
      
      },
      '@media(max-width:415px)':{ marginTop:'13px',
        position:'relative',
        right:"7px",
        top:'13px',
      
      },
      '@media(max-width:391px)':{ marginTop:'13px',
      top:'13px',
      position:'relative',
        right:"-6px"
      },

      '@media(max-width:384px)':{ marginTop:'13px',
      top:'13px',
      position:'relative',
        right:"-9px"
      },

  
      '@media (max-width:376px)':{position:'relative',
      right:"-14px",
      top:'13px',
      },
      '@media(max-width:361px)':{ marginTop:'13px',
        position:'relative',
        right:"-22px",
        marginBottom:'13px',
        top:'13px',
      },
      
    },
    box1: {
      "& .MuiPopover-paper": {
        overflow: "visible !important",
        borderRadius: "12px",
        marginLeft:'9px',
      }
    },
    backdropCss:{
      color:"black",
      backgroundColor:"#8080804d !important",
      zIndex:9999
        },
    boxs1: {
      "& .MuiPopover-paper": {
        overflow: "visible !important",
        borderRadius: "12px",
        marginLeft:'-10px',
      }
    },
    location : {
      '& .MuiPaper-root':{
      padding : '25px 15px !important',
      cursor : 'pointer',
      fontFamily: 'Panton Narrow Regular'
    },
    '& .MuiPaper-rounded':{
      borderRadius: "12px !important",
      marginLeft:'-10px',
    }
    },
    headingContainer: {
      width: "100%",
      disaplay:'flex',
    },
    mainContainer: {
      boxSizing: "border-box",
      padding: "0px 16px",
      // minHeight:'890px',
      "@media(max-width:500px)":{
        marginTop: "79px !important",
      },
    },
    mainHeading: {
      fontSize: "36px",
      fontWeight: 700,
       marginTop:"46px",
      fontFamily: "Panton Narrow Bold !important",
      lineHeight:'44px',
      letter:'-1%',
      [theme.breakpoints.down("lg")]: { 
        
      },
      "@media(max-width:960px)":{
        fontSize: "20px !important",
      },
      "@media(max-width:500px)":{
        marginLeft: "24px"
      },
      "@media(min-width:1900px)":{
        fontSize: "44px !important",
        marginTop:'60px',
      },
    },
    subHeading: {
      fontSize: "15.5px",
      fontWeight: 400,
      // marginLeft: "43px",
       marginTop:'2px',
      fontFamily: "Panton Narrow Regular",
      [theme.breakpoints.down("lg")]: {
        // marginLeft: "28px"
      },
      "@media(max-width:500px)":{
        marginLeft: "24px"
      },
    }, 
    dashboardContainer: {
      width: "100%",
      boxSizing: "border-box",
      marginTop: "18px",
      // marginBottom:'27px',
      borderRadius: "38px",
      background: "#FFF",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
      padding: "52px 19px 31px  26px",
      // minHeight:799,
      height: 'calc(100vh - 175px)',
      "@media (max-width:500px)":{
        boxShadow:'none',
        marginTop: "24px",
        padding: "0px 10px 31px 10px",
      },
      "@media (max-width:1720px)":{
        height: 'calc(100vh - 173px)',
      },
      "@media (min-width:1900px)":{
        height: 'calc(100vh - 187px)',
      },
    },
    
    "@media (min-width:1440px)":{
      dashboardContainer:{
        overflowY:'hidden',
        marginBottom:'40px'
      },
      
    },
    dashboardFilterContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "18px",
      marginBottom: "64px",
      width: "97%",
      paddingLeft: "14px",
      "@media (max-width:500px)":{
        paddingLeft: "0px",
      },
    },
    searchbarConatiner:{
      width:"470px",
      display:"inline-flex",
      padding:"9px 24px 8px 24px",
      alignItems:"center",
      gap:"8px",
      borderRadius:"48px",
      border:"1px solid #373838",
      fontFamily:"Panton",
      fontSize:"16px",
      fontWeight:400,
      marginRight:"14px",
      [theme.breakpoints.down("lg")]:{
        // padding:"8px 16px" 
      },
      [theme.breakpoints.down("md")]:{
        width:"40%",
        marginRight:"0px",
        padding:"8px 16px",
        marginLeft: 'auto'
      },
      [theme.breakpoints.down("sm")]:{
        width:"150px",
      },
      
    },
    searchbar:{
     paddingRight: '8px',
     width:"100%" , 
     color: "#373838",
     fontSize: "16px",
     fontWeight: 400,
     fontFamily:"Panton Narrow Regular",
     '@media(max-width:1380px)':{
      fontSize:'12px'
     },
     '@media(max-width:800px)':{
      fontSize:'11.5px'
     },
     '@media(max-width:730px)':{
      fontSize:'10px'
     },
     '@media(max-width:500px)':{
      paddingRight: '0px',
      fontSize:"11.2px !important",
      lineHeight:'24px',
     },
     '@media(max-width:390px)':{
      paddingRight: '0px',
      fontSize:"10px !important",

     },
     '@media(max-width:380px)':{
      paddingRight: '0px',
      fontSize:"9.5px !important",

     },
     '@media(max-width:365px)':{
      paddingRight: '0px',
      fontSize:"9.2px !important",

     },
     '@media(max-width:355px)':{
      paddingRight: '0px',
      fontSize:"8.7px !important",

     },
    },
  
    toggleAndDateFilter: {
      width: "532px",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      gap: 50,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
        width: "265px",
        gap: 20,
      }
    },
    filterButton: {
      textTransform: "capitalize",
     height:'42px',
     width:'120px',
      padding: "15.5px 23.5px",
      borderRadius: "48px",
      border: "1px solid #373838",
      backgorundColor:"white",
    
      "&:hover":{
        textTransform: "capitalize",
       backgroundColor:"white",
       fontWeight:'400 !important'
      },
      "& span": {
        fontFamily: "Panton Narrow Regular",
        fontSize:"16px",
        fontWeight:'400 !important'
      },
      [theme.breakpoints.down("lg")]: {
        textTransform: "capitalize",
        fontWeight:'400 !important'
      },
      [theme.breakpoints.down("sm")]: {
        padding: "5px",
        fontWeight:'400 !important',
        textTransform: "capitalize",
      }
    },
    filterButtonActive:{
      textTransform: "capitalize",
      height:'42px',
     width:'120px',
     padding: "15.5px 23.5px",
      borderRadius: "48px",
      backgroundColor:"white",
      color:"#005E6E",
      border: "1px solid #005E6E",
      fontFamily:"Panton Narrow Bold",
      backgorundColor:"white",
      fontWeight:400,
      "&:hover":{
        background:"white",
          textTransform: "capitalize",
      },
      "& span": {
        fontFamily: "Panton Narrow Regular",
        fontSize:"16px"
      },
      [theme.breakpoints.down("lg")]: {
      
        textTransform: "none"
      },
      '@media(max-width:960px)':{
        padding: "5px",
        marginLeft:'-8px'
      },
      '@media(max-width:500px)':{
        padding: "5px",
        marginLeft:'10px'
      },
    },
    tableRowHover: {
      '&:hover': {
        padding: '16px !important',
      },
    },
    noRecardPop :{
    display:'flex',
    position:'absolute',
    paddingRight:'350px',
    '@media(max-width:500px)':{
      paddingRight:'48px'
    },
    '@media(max-width:960px)':{
      paddingRight:'100px'
    },
    },
    tableContainer: {
      // height: "82%",
      boxSizing: "border-box",
      width: "100%",
      overflowX: "initial",
      overflow: "auto",
      paddingRight: "10px",
      // marginBottom:'23px',
      height:'calc(100vh - 363px)!important',
      '@media(max-width:1720px)':{
      },
  
      "&::-webkit-scrollbar": {
        width: "8px",
        [theme.breakpoints.down("lg")]: {
          width: "5px"
        }
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        borderRadius: "8px",
        backgroundColor: "#CCDFE2",
        [theme.breakpoints.only("lg")]: {
          width: "5px"
        }
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#005E6E",
        borderRadius: "8px",
        width: "6px",
        [theme.breakpoints.down("lg")]: {
          width: "4px"
        }
      },
      [theme.breakpoints.down("md")]: {
        height: "48%"
      },
      '@media(max-width:500px)':{
        minHeight:'689px',
      height: 'calc(100vh - 250px)!important',
      },
    },
    campaignStatus: {
      marginRight: "6px"
    },
    tableHeader: {
      width: "160px",
      fontSize: "20px",
      fontWeight: 700,
      border: "none",
      fontFamily: "Panton Narrow Bold",
      whiteSpace:"nowrap",
      "&:last-child": {
        width: "60px"
      },
      "&:first-child": {
        minWidth: "210px"
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "16px"
      }
    },
    tableRowHoverEffect: {
      "&:hover": {
        backgroundColor: "#005E6E",
        borderRadius: "56px !important",
        cursor: "pointer",
        "& > .MuiTableCell-body": {
          color: "white"
        }
      }
    },
    profileimage: {
      width: "24px",
      height: "24px",
      borderRadius: "24px",
      marginRight: "8px"
    },
    tableCell: {
      width: "150px",
      padding: '4px 16px!important',
      border: "none",
      fontFamily: "Panton Narrow Regular",
      fontSize:"16px",
      fontWeight:400,
      whiteSpace:"nowrap"
    },
    tableRowFirstCell: {
     minWidth: "210px",
      padding: "4px 16px!important",
      borderRadius: "56px 0 0 56px",
      fontFamily: "Panton Narrow Regular",
      border: "none",
      fontSize:"16px",
      fontWeight:400,
    },
    tableRowLastCell: {
      borderRadius: "0 56px 56px  0 ",
      padding: "4px 16px!important",
      marginLeft: "5px",
      width: "58px",
      display:'flex',
      textAlign:'center',

      fontFamily: "Panton Narrow Regular",
      border: "none",
      [theme.breakpoints.down("lg")]: {
        fontSize:  "16px!important",
        display:'flex',
        textAlign:'center',
      },
      "@media(max-width:1370px)":{
        marginLeft: "6px",
      },
      "@media(min-width:1900px)":{
        marginLeft: "14px",
      },
    },
    tableRowLastCellitalian:{
      borderRadius: "0 56px 56px  0 ",
      padding: "4px 16px!important",
      marginLeft: "0px",
      width: "58px",
      display:'flex',
      textAlign:'center',
      fontFamily: "Panton Narrow Regular",
      border: "none",
      [theme.breakpoints.down("lg")]: {
        fontSize:  "16px!important",
        display:'flex',
        textAlign:'center',
      },
      '@media(min-width:1900px)':{
        marginLeft: "7px",
      },
      '@media(max-width:1370px)':{
        marginLeft: "-1px",
      },
    },
    tableRowLastC: {
      borderRadius: "0 56px 56px  0 ",
      padding: "4px 16px!important",
      marginLeft: "0px",
      width: "58px",
      display:'flex',
      textAlign:'center',
      fontFamily: "Panton Narrow Regular",
      border: "none",
      [theme.breakpoints.down("lg")]: {
        fontSize:  "16px!important",
        display:'flex',
        textAlign:'center',
      },
      '@media(min-width:1900px)':{
        marginLeft: "5px",
      },
      '@media(max-width:1370px)':{
        marginLeft: "-2px",
      },
    },
    btnLogo: {
      marginLeft: "10px",
      width:'20px',
    },
    tableScroll: {
      overflow: "auto"
    },
    actionIcon: {
      "&:hover": {
        cursor: "not-allowed"
      }
    },
    actionIconsHover: {
      color: "white",
      "&:hover": {
        backgroundColor: "inherit"
      }
    },
    tableRowDisable: {
      opacity: "0.40",
      backgroundColor: "#FFFFFF",
      cursor: "not-allowed"
    },
    actionPopover: {
      width: "340px",
      maxHeight:"200px",
      padding: "24px 24px 19px",
      cursor: "pointer",
      position: "relative",
      background: "white",
      boxSizing: "border-box",
      zIndex:5000000000000,
      borderRadius: "48px",
      
    },

    txtContainer: {
      display: "flex",
      flexWrap:"wrap",
      gap: "15px",
      marginBottom: "28px",
      alignItems: "center",
      '& img': {
        width: '24px !important',
        height: 24
      }
    },
    txtContain: {
      display: "flex",
      flexWrap:"wrap",
      gap: "15px",
      marginBottom: "22px",
      alignItems: "center",
      '& img': {
        width: '24px !important',
        height: 24
      }
    },
  
    popoverTxt: {
      color: "black",
      fontFamily: "Panton Narrow Regular",
      fontSize: "16px",
      fontWeight: 400
    },
    popoverTxtActive: {
      fontFamily: "Panton Narrow Bold",
      fontSize: "16px",
      fontWeight: 700,
      color: "#005E6E",
    },
    
    searchBox: {
      boxSizing: "border-box",
      display: "flex",
      width: "163px",
      padding: "13px 16px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "48px",
      border: "2px solid #000000"
    },
    searchBoxActive: {
      boxSizing: "border-box",
      display: "flex",
      width: "163px",
      padding: "13px 16px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "48px",
      border: "2px solid #005E6E",
      '@media(max-width:500px)':{
        width: "128px", 
      },
      '@media(max-width:390px)':{
        width: "126px", 
        padding: "13px 8px",
      },
    },
    filterCampaignMenuContainer: {
      "& .MuiMenu-paper ": {
        width: "211px",
        height: "144px",
        borderRadius: "12px",
        marginLeft:'7px',
        background: "#FFF",
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.25)",
        marginTop: "11px",
        "& .MuiList-padding": {
          padding: "0"
        },
        "&:hover": {
          backgroundColor: "none"
        },
      }
    },
    filterMenuList: {
      padding: "32px 16px 15px 21px",
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: "white"
      }
    },
    filterMenuSearchBar: {
      padding: "8px 10px 15px 16px",
      "&:hover": {
        backgroundColor: "white"
      }
    },
    filterMenuListTxt: {
      color: "#B4B4B4",
      textAlign: "right",
      fontFamily: "Panton Narrow Bold",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      "line-height": "24px",
      "&:hover": {
        backgroundColor: "white"
      }
    },
    filterMenuListTxtActive: {
      color: "#005E6E",
      textAlign: "right",
      fontFamily: "Panton Narrow Bold",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      "line-height": "24px",
      "&:hover": {
        backgroundColor: "white"
      }
    },
    dateRangePickerPopoverContainer: {
      padding: "25px 15px",
      "& .rayDays": {
        width: '100%',
      }
    },
    dataRangePickerPopover: {
      width: "288px",
      height: "490px",
      padding: "15px"
    },
    dateRangePicker: {
      boxSizing: "border-box",
      fontFamily: "Panton Narrow Regular",
      "& .rdrCalendarWrapper": {
        width: "268px",
        padding: "10px"
      },
      "& .rdrDefinedRangesWrapper": {
        display: "none"
      },
      "& .rdrMonthAndYearPickers select": {
        background: "none",
        pointerEvents: "none"
      },
      "& .rdrPprevButton::before": {
        background: "white",
        content: `url(${arrowDateFilterLeft})`
      },
      "& .rdrNextPrevButton": {
        backgroundColor: "white"
      },
      "& .rdrPprevButton i": {
        display: "none"
      },
      "& .rdrNextButton i": {
        display: "none"
      },
      "& .rdrNextButton::before": {
        background: "white",
        content: `url(${arrowDateFilterRight})`
      },
      "& .rdrMonthAndYearPickers": {
        position: "relative"
      },
      "& .rdrMonthPicker": {
        position: "absolute",
        left: "1px"
      },
      "& .rdrYearPicker": {
        position: "absolute",
        right: "0px"
      },
      "& select": {
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        "-o-appearance": "none",
        appearance: "none",
        outline: "none",
        fontFamily: "Panton Narrow Bold",
        color: "black"
      },
      "& select::-ms-expand": {
        display: "none"
      },
      "& select:hover": {
        backgroundColor: "white"
      },
      "& .rdrWeekDay": {
        fontFamily: "Panton Narrow Bold",
        color: "black",
        flexBasis: "calc(100% / 7)"
      },
      "& .rdrDays": {
        width: "100%",
        '& .rdrDay': {
          width: '35px',
          border: 'none',
          background: 'white',
          color: 'black',
          textAlign: 'center'
        },
      },
      "& .rdrMonth": {
        padding: "0px",
        width: '100%',
      },
      "& .rdrMonthAndYearWrapper": {
        padding: "0px"
      }
    },
    filteDateFieldsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly"
    },
    filteDateInputFields: {
      textAlign:"center",
      color: "#B4B4B4",
      fontFamily: "Panton Narrow Regular",
      fontSize: "13px",
      width: "100px",
      border: "1px solid #B4B4B4",
      padding: "6px 12px",
      borderRadius: "8px",
      "&>input":{
        textAlign:"center"
      }
    },
    filterCampaignsBtnSetDate: {
      width: "115px",
      padding: "6px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "48px",
      border: "none",
      background: "#005E6E",
      color: "white",
      textAlign: "center",
      fontSize: "16px",
      fonWeight: 700,
      fontFamily: "Panton Narrow Bold",
      "text-transform": "none",
      "&:hover": {
        background: "#005E6E"
      },
      "&>span": {
        fontFamily: "Panton Narrow SemiBold"
      }
    },
    filterCampaignsBtnCancel: {
      width: "115px",
      padding: "6px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "48px",
      border: "1px solid  #005E6E",
      background: "white",
      color: "#005E6E",
      textAlign: "center",
      fontSize: "16px",
      fonWeight: 700,
      fontFamily: "Panton Narrow Bold",
      "text-transform": "none",
      "&:hover": {
        background: "white"
      },
      "&>span": {
        fontFamily: "Panton Narrow SemiBold"
      }
    },
  
    showSelectedDateRangeContainers: {
      justifyContent: "flex-end",
      width: "100%",
      padding: "0px 20px",
      marginTop: "-20px",
      marginLeft:"-38px",
      marginBottom:"8px",
      
      display: "flex",
    
      '@media(max-width:1920px)':{
        marginLeft:"-52px",
      },
     
      '@media(max-width:1441px)':{
        marginLeft:"-39px",
      },
     
      '@media(max-width:1370px)':{
        marginLeft:"-36px",
      },
      '@media(max-width:1280px)':{
        marginLeft:"-34px",
      },
      
      '@media(max-width:1170px)':{
        marginLeft:"-31px",
      },
      '@media(max-width:1025px)':{
        marginLeft:"-26px",
      },
      '@media(max-width:960px)':{
        marginLeft:"-66px",
        marginTop:'13px'
      },
      '@media(max-width:912px)':{
        marginLeft:"-42px",
        marginTop:'13px'
      },
      '@media(max-width:855px)':{
        marginLeft:"-36px",
        marginTop:'13px'
      },
     
      '@media(max-width:820px)':{
        marginLeft:"-32px",
        marginTop:'13px'
      },
      '@media(max-width:811px)':{
        marginLeft:"-32px",
        marginTop:'13px'
      },
       '@media(max-width:768px)':{
        marginLeft:"-27px",
        marginTop:'13px'
      },
      '@media(max-width:720px)':{
        marginLeft:"-21px",
        marginTop:'13px'
      },
      '@media(max-width:641px)':{
        marginLeft:"-15px",
        marginTop:'13px'
      },
    
      
      
      '@media(max-width:430px)':{ marginTop:'13px',
        position:'relative',
        right:"14px",
        top:'13px',
        marginBottom:'13px'
      },
      '@media(max-width:415px)':{
        marginTop:'13px',
        position:'relative',
        top:'13px',
        right:"7px",
        marginBottom:'13px'
      },
      '@media(max-width:391px)':{
       
        marginTop:'13px',
        position:'relative',
        top:'13px',
        right:"-6px",
        marginBottom:'13px'
      },

      '@media(max-width:384px)':{ 
        marginTop:'13px',
      top:'13px',
      position:'relative',
        right:"-9px"
      },

      '@media (max-width:376px)':{
       
        // marginTop:'13px',
        position:'relative',
        top:'13px',
        right:"-14px",
        marginBottom:'13px'
      },
      '@media(max-width:361px)':{
       
        marginTop:'13px',
        position:'relative',
        top:'13px',
        right:"-22px",
        marginBottom:'13px'
      },
      
    },
    
    showSelectedDateRangeTxt: {
      display: "inline-flex",
      padding: "8px 8px 8px 10px",
      borderRadius: "48px",
      border: "1px solid #434242",
      color: "#434242",
      fontFamily: "Panton Narrow Regular",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "normal",
      height:'16px',
      '@media(max-width:500px)':{
        padding:'4px 11px !important',
        marginRight:'16px'
      }
    },
    closeDateRangeSelect: {
      cursor: "pointer",
      marginLeft: "6px"
    },
    searchByDistrictsearchBar: {
      width: "190px",
      height: "48px",
      borderRadius: "48px",
      border: "2px solid #005E6E"
    },
    imageassign:{
      '@media(max-width:500px)':{
      width: "16.5px !important",
      height: "16.5px !important",
      },
    },
    inputBox: {
      fontFamily: "Panton Narrow Bold",
      fontSize: "16px",
      fontWeight: 700,
      color: "#005E6E",
      lineHeight: "24px",
      boxSizing: "border-box",
      "&>input": {
        padding: "0px"
      },
      '@media(max-width:500px)':{
        fontSize: "14px",
      },
    },
    typeAheadContainer: {
      position: "relative",
      zIndex: 999999,
      marginLeft: "111px",
      marginTop: "15px",
      backgroundColor: "white",
      width: "190px",
      padding: "31px 16px 22px",
      right: "0px",
      "box-sizing": "border-box",
      borderRadius: "10px",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
      display: 'flex',
      flexDirection: 'column',
      gap: 28,
      '@media(max-width:500px)':{
        marginLeft: "67px !important", 
      },
    },
    typeAheadText: {
      color: "#000",
      marginTop: "15px",
      "list-style": "none",
      fontFamily: "Panton Narrow Regular",
      fontSize: "14px",
      "font-weight": "400",
      lineHeight: "normal",
    },
    typeAheadContainerArrow: {
      width: '32px',
      height: '33px', 
      borderRadius: '10px',
       backgroundColor: 'white', 
       transform: 'rotate(46deg)',
     position: "absolute",
     top: "0px",
     right: "20px",
     zIndex: 99999999,
     marginTop:'-10px',
     boxShadow: "-7px -7px 6px #00000010",
    },
    typeAheadContainerArr: {
      width: '32px',
      height: '33px', 
      borderRadius: '10px',
       backgroundColor: 'white', 
       transform: 'rotate(46deg)',
     position: "absolute",
     top: "0px",
     right: "20px",
     zIndex: 99999999,
     marginTop:'-10px',
     boxShadow: "0px 0px -5px #00000010",
    },

    typeAheadContainerArrows: {
      transform: "rotate(-45deg)",
      height: "32px",
      width: "50px",
      position: "absolute",
      top: "0px",
      right: "14px",
      borderRadius: "6px",
      zIndex: 0,
      boxShadow: "0px -7px 13px rgba(0, 0, 0, 0.10)",
    },
    "@media (Max-width:390px)":{
      headingContainer:{
        marginLeft: "-10px"
      },
    },
    "@media (Max-width:500px)":{
      headingContainer:{
         marginLeft: "-18px"
      },
    },
    "@media (Max-width:460px)":{
      headingContainer:{
        // marginLeft: "12%"
      },
    },
    "@media (Max-width:1080px)":{
      searchContainer:{
        width:'30%'
      },
    },
    "@media (max-width: 1400px)": {
      searchbarConatiner:{
       
        marginLeft: 'unset',

      },
      searchbar:{
        // fontSize:"15px"
      }
    },
    "@media (max-width: 1100px)": {
      searchbarConatiner:{
        width:"400px",
        marginLeft: 'unset',
      },
    },
    "@media (max-width: 1404px)": {
      searchbarConatiner:{
        width:"510px",
        marginLeft: 'unset',
      },
    },
  
    "@media (max-width: 600px)": {
      dashboardFilterContainer: {
        flexDirection: 'column',
        // alignItems: 'end'
      },
      searchbarConatiner:{
        width:"97%",
        marginRight:'30px',
      },
    },
    "@media (max-width: 500px)": {
      searchbarConatiner:{
         width:"92%",
        marginRight:'0px',
      },
    },
    "@media (max-width: 420px)": {
      searchbarConatiner:{
         width:"96%",
        marginRight:'0px',
      },
    },
    "@media (max-width: 350px)": {
      searchbarConatiner:{
      width:"100% !important",
      gap:2,
      }
    },
   
    "@media (max-width: 960px)": {
      mainContainer: {
        marginTop: "5rem",
        marginBottom:'3px'
      },
     
      dashboardFilterContainer: {
        marginBottom: "5px",
        width:'90%'
         
      },
     
      showSelectedDateRangeTxt:{
        padding:"4px 24px"
      }
    },
    dialogActions: {      
      margin: "15px 13px 15px",
      flexWrap: "wrap", 
      display:"flex",
      justifyContent:"space-evenly" ,
      "@media (max-width: 501px)":{
        justifyContent: "center",
        gap: "10px"
      }
    },
  });
  const useStyles = makeStyles({
    customTableContainer: {
      backgroundColor: 'white',
      padding: '10px',
    },
   
    stickyHeader: {
      top: -3,
      left: -3,
      zIndex: 2,
      position: 'sticky',

      backgroundColor: 'white', 
      '@media (max-width: 960px)': {
        stickyHeader: {
          top: -3,
          left:-3,
        },
        '@media (max-width: 1280px)': {
          stickyHeader: {
            top: -5,
            left:-3,
          },
        },
      },
    }
  });
const StyledDashboard: any = withStyles(useStyle)(
  withRouter(Dashboard as any)
) as React.ComponentType<any>; // Explicitly annotate the type

export default StyledDashboard;
function retun(arg0: JSX.Element) {
  throw new Error("Function not implemented.");
}