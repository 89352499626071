import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React,{ RefObject } from "react";
import { logoutUser, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

import { testNavi } from "../../../../packages/blocks/utilities/src/CommonUtilities";
type CustomMouseEvent = {
  currentTarget: {
    style: {
      fontWeight: string;
      color: string;
    };
  };
};

interface IndustryItem  {
  id: number;
  localsearch_rubrikname_english: string;
  localsearch_schema_category_110: string;
};

type MouseEvent ={
  currentTarget: {
    style: {
      backgroundColor: string;
      color: string;
    };
  };
}
interface RadiusDataItem {
  name: string;
  id: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface CampaignData {
  data: {
    id: string;
    type: string;
    attributes: {
      company_name: string;
      industry: string;
      location: string;
      objective_id: number;
      radius_id:number;
      budget: number | null;
      radius: number | null;
      duration: number | null;
      created_at: string;
      updated_at: string;
      status: string;
      user_photo_link: string | null;
      user: string | null;
    };
  };
}
interface Objective {
  id: number;
  name: string;
  duration: number;
  description: string;
  photo_url:null;
  clicked: boolean,
  tool_tip:string,
}
interface ResponseData {
  data: Objective[] | any;
}

interface ResponseDataYourIndustry {
  data: {
    id: null,
    name: null,
    description?: string,
    original_keyword?: string,
    name_code: null,
  },
}

interface ResponseDataLocation{
    data: {
      id: null | number,
      name: null,
      description?: string,
      original_keyword?: string,
      name_code: null,
    },
}

interface ResponseDataObjective{
  data: {
    id: null,
    name: null,
    description?: string,
    original_keyword?: string,
    name_code: null,
  },
}

interface SetUpContentData{
  data:{
    tooltip: string,
    heading: string,
    body: string,
    image_url: string
  }
}

export const configJSON = require("./config");


export interface Props {
  navigation:any;
  id: string;
  // Customizable Area Start
  selectedRange:string
  history:any;
  // nextPage:SidebarProps
  // Customizable Area End
}


interface S {
  isComingFromDashboard:string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchText:string;
  searchResults:[],
  results: string[],
  dropdownVisible: boolean,
  hoverIndex:null,
  // location: [],
  anchorEl: null | EventTarget,
  objectivesDetails: any | number,
  selectedRange: string;
  showFullText:boolean;
  isComponentOpen:boolean;
  campaing:any,
  tooltipId:number |null,
  options:string[],
  filteredOptions: string[],
  displayedIndustry: string,
  industryData: IndustryItem[],
  campaignDetails:object,
  radiusData: RadiusDataItem[],
  error: string | null,
  responseData: ResponseDataYourIndustry,
  LocationData: ResponseDataLocation,
  ObjectiveData: ResponseDataObjective,
  errors: {
    companyName: string,
    industry: string,
    location: string,
    objectives:string
  },
  clickObjectiveName:string,
  setupData: any,
  isModalOpen: boolean,
  tooltipOpenobjeective:boolean,
  tooltipOpenIndustry: boolean,
  tooltipTitleIndustry: string,
  tooltipTitleObjective:string,
  tooltipOpenObjective: boolean,
  tooltipTitleData: string,
  tooltipOpenData: boolean,
  tooltipOpenCompanyName: boolean,
  tooltipOpenLocation: boolean,
  tooltipTitleCompanyName: string,
  tooltipTitleLocation: string,
  setupComapnyName: any,
  setupYourIndustry:any,
  setupYourLocation:any,
  setupYourObjective:any,
  openModel:boolean,
  deletecampaigns:any
  select_lang: any;
  error_lang: any,
  viewData:boolean,
  ApiVariable:any,
  activeState:any,
  RecommendationNavigate:boolean,
  initialVisit:boolean,
  updatedChecked:boolean,
  loaderTrue:boolean,
  ShowModel:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  classes: any;
  location:any
  // Customizable Area Start
  // Customizable Area End
}



export default class SetUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getObjectiveCallId: string = ""
  postCreateCampaingCallID:string = ""
  getSerchIndustryCallId:string =""
  getRadiusCallId:string=""
  getYourIndustryCallID:string=""
  getLocationCallID:string=""
  getObjectiveTooltipCallID:string=""
  getSetupContentCallID:string=""
  getSetupComapnyNameCallID:string=""
  getSetupYourIndusstryCallID:string=""
  getSetupYourLocationCallID:string=""
  getSetupYourObjectiveCallID:string=""
  campaignsDeleteCallID:string=""
  putRecommendationCallID:string=""
  getCampaignConfigurationCallID:string=""
  putsetDisabledApiCallID:string=""
  // Customizable Area End
  divRef: RefObject<HTMLDivElement | null>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.divRef = React.createRef();

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
     isComingFromDashboard:"",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchText:"",
      searchResults:[],
      results: [],
      dropdownVisible: false,
      hoverIndex:null,
      // location:[],
      tooltipId:null,
      anchorEl: null,
      objectivesDetails:[],
      selectedRange: "0-25",
      showFullText: false,
      tooltipOpenobjeective:false,
      isComponentOpen:false,
      campaing:{
        companyName: "",
        industry: "",
        location: "",
        selectedObjective: null,
      },
      options : [
        "Food",
        "Water supply",
        "Logistics",
      ],
      filteredOptions: [],
      displayedIndustry: '',
      industryData: [],
      campaignDetails:{},
      radiusData: [] ,
      error: null,
       responseData: {
      data:{
        id: null,
        name: null,
        name_code: null,
      },
    },
    LocationData: {
      data:{
        id: null,
        name: null,
        name_code: null,
      },
    },
    ObjectiveData:{
      data:{
        id: null,
        name: null,
        name_code: null,
      },
    },
    errors: {
      companyName: "",
      industry: "",
      location: "",
      objectives:"",
    },
    clickObjectiveName:'',
    setupData:{},
    isModalOpen: false,
    tooltipOpenIndustry: false,
  tooltipTitleIndustry: '',
    tooltipTitleObjective:'',
  tooltipOpenObjective: false,
    tooltipTitleData:'',
    tooltipOpenData: false,
    tooltipOpenCompanyName: false,
    tooltipOpenLocation: false,
     tooltipTitleCompanyName: '',
     tooltipTitleLocation: '',
    setupComapnyName: {},
    setupYourIndustry:{},
    setupYourLocation:{},
    setupYourObjective:{},
    openModel:false,
    deletecampaigns:{},
    select_lang: {name: []},
    error_lang: {name: []},
    viewData:false,
    ApiVariable:{},
    activeState:{},
    RecommendationNavigate:false,
    initialVisit:false,
    updatedChecked:false,
    loaderTrue:false,
    ShowModel:false
      // Customizable Area End
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.toggleDiv = this.toggleDiv.bind(this);
    this.divRef = React.createRef<HTMLDivElement | null>();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
  
    // Customizable Area End
  }
  getUpdatedResponse=(responseJson:any)=>{
  
   const navigateItem=localStorage.getItem("navigate")
   const visitedSetup=localStorage.getItem("visitedSetup")
   const persistId=localStorage.getItem("persistId")
    if(this.state.initialVisit){
      this.send(testNavi(this.props))
    }
    if(this.state.updatedChecked){
      this.setState({loaderTrue:true})
    }
    if(this.state.RecommendationNavigate){
      this.props.history.push("/Recommendation");
    }
    else if((visitedSetup || persistId) && navigateItem){
  this.props.history.push("/Dashboard");
  localStorage.removeItem("visitedSetup")
}

   }

  toggleDiv() {
    this.setState((prevState) => ({
      dropdownVisible: !prevState.dropdownVisible,
    }));
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if(responseJson && responseJson.statusCode === 401){
      logoutUser();          
      this.props.history.push("/EmailAccountLoginBlock");
    }
    if (!apiRequestCallId || !responseJson) {
      return;
    }
  
    switch (apiRequestCallId) {
      case this.getObjectiveCallId:
        this.getObjectiveReceiver(responseJson);
        break;
      case this.postCreateCampaingCallID:
        this.postCreateCampaingReceiver(responseJson);
        break;
      case this.getSerchIndustryCallId:
        this.getSerchIndustryReceiver(responseJson);
        break;
      case this.getRadiusCallId:
        this.getRadiusReceiver(responseJson);
        break;
      case this.getYourIndustryCallID:
        this.getYourIndustryReceiver(responseJson);
        break;
      case this.getLocationCallID:
        this.getLocationReceiver(responseJson);
        break;
      case this.getObjectiveTooltipCallID:
        this.getObjectiveTooltipReceiver(responseJson);
        break;
      case this.getSetupContentCallID:
        this.getContentReceiver(responseJson);
        break;
      case this.getSetupComapnyNameCallID:
        this.getSetupComapnyNameReceiver(responseJson);
        break;
      case this.getSetupYourIndusstryCallID:
        this.getSetupYourIndusstryReceiver(responseJson);
        break;
      case this.getSetupYourLocationCallID:
        this.getSetupYourLocationReceiver(responseJson);
        break;
        case this.getSetupYourObjectiveCallID:
          this.getSetupYourObjectiveReceiver(responseJson);
          break;
          case this.putRecommendationCallID:
            this.getUpdatedResponse(responseJson);
            break;
            case this.getCampaignConfigurationCallID:
              this.getCampaignSetUpReceiver(responseJson)
break;
        case this.campaignsDeleteCallID:
          this.setState({deletecampaigns:responseJson})
    }
  
    runEngine.debugLog("Message Received", message);
  }
  
    // Customizable Area Start

    async componentDidMount(): Promise<void> {
      const persistId=localStorage.getItem("persistId");
      const visitedSetup=localStorage.getItem("visitedSetup");
      if(persistId || visitedSetup){
        this.getCampaignSetUpData();
      }
   
        const viewOption:any=localStorage.getItem("visitedView")
      const showOption:any=localStorage.getItem("showData")
      if(viewOption && showOption){
        this.setState({viewData:true})
      }
        const campaignsData:any = localStorage.getItem("campaign_Details");
    document.addEventListener('mousedown', this.handleOutsideClick);
    await Promise.all([this.getObjectiveCallDetails(),this.getRadiusDetails(),this.getYourIndustryDetails(),
    this.getLocationDetails(),this.getObjectiveDetails(), this.getsetUpContentDetails()]);
    this.getSetupComapnyName(); this.getSetupYourIndustry(); this.getSetupYourLocation();this.getSetupYourObjective();





  
  const lang : any = {
    german: {
      name:["Kampagnenname", "Wählen Sie Ihr Sortiment","Wählen Sie Ihren Bereich","Nächste","Aufstellen"],
    },
    french: {
      name:["Nom de la campagne", "Sélectionnez une industrie","Sélectionnez votre gamme","Suivante", "Installation"],
    },
    italian: {
      name:["Nome della campagna","Seleziona un settore", "Selezionare l'intervallo", "Prossima","Impostare"],
    },
  };
  const selectedCountry = localStorage.getItem("selectedCountry");
  const selected_Language = lang[selectedCountry as any] || { name: []};
  this.setState({select_lang:selected_Language})

  const errorLang : any = {
    german: {
      name:["Bitte geben Sie einen Kampagnen-/Firmennamen ein.", "Bitte geben Sie eine Branche ein.", "Bitte wählen Sie einen Standort aus.", "Bitte wählen Sie mindestens ein Ziel aus.", "ungültige Branche"],
    },
    french: {
      name:["Veuillez saisir un nom de campagne/entreprise", "Veuillez entrer un secteur d'activité.", "Veuillez sélectionner un emplacement.", "Veuillez sélectionner au moins un objectif.", "industrie invalide"],
    },
    italian: {
      name:["Inserisci il nome di una campagna/azienda.", "Inserisci un settore." , "Seleziona una località.", "Seleziona almeno un obiettivo.", "settore non valido"],
    },
  };
  const selectedCountryLang = localStorage.getItem("selectedCountry");
  const selected_Language_Error = errorLang[selectedCountryLang as any] || { name: []};
  this.setState({error_lang:selected_Language_Error})
  }

  handleOutsideClick = (event: any) => {
    if (this.divRef.current && !this.divRef.current.contains(event.target as Node) && this.state.dropdownVisible) {
      this.setState({
        dropdownVisible: false, 
      });
    }
  };

  getCampaignSetUpData=()=>{
    const visitedSetup=localStorage.getItem("visitedSetup");
    const  persistId= localStorage.getItem("persistId")
    let Camping_Id = localStorage.getItem('campaignID')
    const rowId=visitedSetup?Camping_Id:persistId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaignConfigurationCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ConfigurationDataEndPoint}${rowId}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
   }
   getCampaignSetUpReceiver=(responseJson:any)=>{   
    const res=responseJson.campaign.attributes
    this.setState((prevState) => ({
      campaing: {
        ...prevState.campaing,
        companyName: res.company_name,
        industry: res.industry,
        location: res.radius_id,
        selectedObjective: res.objective_id,
      }
    }));
    this.getSerchIndustryDetails(res.industry)
    const visitedSetup=localStorage.getItem("visitedSetup");
    const persistId=localStorage.getItem("persistId");
    if(visitedSetup || persistId){
      let key=responseJson.visited_key;
      if(!key.includes(responseJson.active_page) )
      {
         key.push(responseJson.active_page)
      }
      let CampaignUpdate =  {
       "campaign": {
         "active_page":"setup",
         "visited_key":key
       }, 
        "progress": {
        "current_page": "setup"
    }
   }  
      this.setState({ApiVariable:CampaignUpdate,initialVisit:true},()=>{
        this.PutSetupData()
      })
    }
     
   }
   putSetupDisabledDataApi=async (): Promise<void> => {

    const campaignId = localStorage.getItem("campaignID")
      const persistId=localStorage.getItem("persistId")
      const rowID=persistId?persistId:campaignId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };   
    
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
     );
  
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_custom_form/update_recommendation_campaign"}?id=${rowID}&page=setup&campaign_objective=${this.state.campaing.selectedObjective}&campaign_radius=${this.state.campaing.location}`
     );
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
     this.putsetDisabledApiCallID=requestMessageList.messageId
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
    getObjectiveCallDetails = async (): Promise<void> => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
       };
       const language = localStorage.getItem('selectedCountry');
       const requestMessageList = new Message(
           getName(MessageEnum.RestAPIRequestMessage)
       );
   
       this.getObjectiveCallId = requestMessageList.messageId;
   
       requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
       );

       requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        // "account_block/profile?token=" + token 
        `${configJSON.getObjectiveApiEndPoint}?language=${language}`
       );
   
       requestMessageList.addData(
           getName(MessageEnum.RestAPIRequestHeaderMessage),
           JSON.stringify(header)
       );
   
       runEngine.sendMessage(requestMessageList.id, requestMessageList);
   }

   getObjectiveReceiver = (responseJson:ResponseData) => {
    this.setState({
      objectivesDetails: responseJson.data,
    });
  }

  onUpdateDashboardClick=()=>{
    if(this.state.updatedChecked){
      let formDataCampaign =  {
        "campaign": {
            "company_name": this.state.campaing.companyName,
            "industry": this.state.campaing.industry,
            "radius_id": this.state.campaing.location,
            "objective_id": this.state.campaing.selectedObjective,
            "active_page":"setup",
            "visited_key":["setup"]
              },
            "progress": {
                "current_page": "setup"
            }
        
    } 
    this.setState({ApiVariable:formDataCampaign},()=>this.PutSetupData()) 
    }
 if(!this.state.updatedChecked){
  this.props.history.push("/Dashboard")
  localStorage.removeItem("visitedSetup")
 }
  }         

  postCreateCampaing = () => {
    const rolespermission = localStorage.getItem("rolesandpermssion")

    if(!this.state.updatedChecked){
      if(rolespermission){
        this.props.history.push("/RolesPermissions")
      }else{
        this.props.history.push("/Dashboard")
      }
      
      localStorage.removeItem("visitedSetup")
     }else{
      const authToken = localStorage.getItem('accessToken')
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken
      };
  
      let formDataCampaign =  {
        "campaign": {
            "company_name": this.state.campaing.companyName,
            "industry": this.state.campaing.industry,
            "radius_id": this.state.campaing.location,
            "objective_id": this.state.campaing.selectedObjective,
            "active_page":"setup",
            "visited_key":["setup"]
            },
            "progress": {
               "current_page": "recommendation"   
        }
    }   
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postCreateCampaingEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(formDataCampaign)
      );
      this.postCreateCampaingCallID = requestMessage.messageId;
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
      const radius_id = formDataCampaign.campaign.radius_id;
      const objective_id = formDataCampaign.campaign.objective_id;
      setStorageData("campingIndustry", this.state.campaing.industry);
      localStorage.setItem('radius_id', radius_id?.toString() || '');
      localStorage.setItem('objective_id', objective_id?.toString() || '');
      localStorage.setItem('getIndustry',  this.state.campaing.industry);
     }
  }
  PutSetupData = async () => {

    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const authToken = localStorage.getItem('accessToken')
   const header = {
    "Content-Type": "application/json",
    token: authToken
  };
  localStorage.setItem('recData', JSON.stringify(this.state.ApiVariable) as any);
  const PutSetupMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  PutSetupMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  PutSetupMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   `${configJSON.putCampaingChannelsSection}/${rowID}`
  );
  PutSetupMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
  );
  PutSetupMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(this.state.ApiVariable)
  );
  this.putRecommendationCallID = PutSetupMessage.messageId;

  runEngine.sendMessage(PutSetupMessage.id, PutSetupMessage);
  localStorage.setItem('radius_id',  this.state.campaing.location?.toString() || '');
  localStorage.setItem('objective_id',this.state.campaing.selectedObjective?.toString() || '');
} 
postCreateCampaingReceiver=(responseJson:CampaignData)=>{

  if(responseJson.data){

  localStorage.setItem("allCampaigndata","true");
  localStorage.setItem('campaignID', responseJson.data.id?.toString() || '');
  localStorage.setItem("visitedSetup","setup")
  localStorage.setItem('setUpCampaignID', responseJson.data.id?.toString() || '');  
  localStorage.setItem('campaign_Details',JSON.stringify(responseJson.data.attributes) as any)
  const rolesandPermission = localStorage.getItem("rolesandpermssion")
  this.setState({
    campaignDetails: responseJson.data,
    ShowModel:true
  })
    const navigateItem=localStorage.getItem("navigate")
    console.log(navigateItem,"navigateItem")
    if(this.state.RecommendationNavigate){
      this.props.history.push("/Recommendation");
    }else if(navigateItem){  
if(rolesandPermission){
  this.props.history.push("RolesPermissions");
}else{
  this.props.history.push("/Dashboard");
}
      
      localStorage.removeItem("visitedSetup")
    }
  }
};

  getSerchIndustryDetails = async (value:any): Promise<void> => {
    setStorageData("InputFiled2", this.state.campaing.industry);
    setStorageData("InputFiled3", this.state.campaing.location);   
    const selectedCountry = localStorage.getItem('selectedCountry');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };

     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.getSerchIndustryCallId = requestMessageList.messageId;
 
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );

     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSerchIndustryEndPoint}?query=${value}&language=${selectedCountry}`
     );
 
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
 
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
 };


// -----------getSerchIndustryReceiver ------------------

getSerchIndustryReceiver=(responseJson:any)=>{
  if(!responseJson?.error){
    this.setState({ industryData: responseJson })
  }
}

getRadiusDetails = async (): Promise<void> => {
  const selectedCountry = localStorage.getItem('selectedCountry');
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getRadiusCallId = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType
   ); 

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRadiusEndPoint}?language=${selectedCountry}`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
};

getRadiusReceiver=(responseJson:any)=>{
  this.setState({
    radiusData: responseJson
  })
};

getYourIndustryDetails = async (): Promise<void> => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getYourIndustryCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getYourIndustryEndPoint}?name=YourIndustry`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

getYourIndustryReceiver=(responseJson:ResponseDataYourIndustry)=>{
this.setState({ responseData: responseJson}); 
}

getLocationDetails = async (): Promise<void> => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getLocationCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getYourLocationEndPoint}?name=Location`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

getLocationReceiver=(responseJson:ResponseDataLocation)=>{
this.setState({ LocationData: responseJson}); 
}

getObjectiveDetails = ()=> {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getObjectiveTooltipCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getObjectiveTooltipEndPoint}?name=Objective`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

getObjectiveTooltipReceiver=(responseJson:ResponseDataObjective)=>{
this.setState({ ObjectiveData: responseJson}); 
}
    // Customizable Area End

  // web events
  
  // Customizable Area Start

  showDropdown = () => {
    this.setState({ dropdownVisible: true});
  };
  handleTooltipToggleIndustry = () => {
    this.setState({ tooltipOpenIndustry: !this.state.tooltipOpenIndustry });
  };
  
  
  handleTooltipCloseIndustry = () => {
    this.setState({ tooltipOpenIndustry: false });
  };
  handleobjectiveMouseHover=(id:number)=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenobjeective:true,tooltipId:id});
    }
  };
   handleobjectiveMouseOver=(id:number)=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenobjeective:false,tooltipId:id});}
  }
   handleobjective=(id:number)=>{
    

      this.setState({tooltipOpenobjeective:!this.state.tooltipOpenobjeective,tooltipId:id});
      setTimeout(() => {
        this.setState({ tooltipOpenobjeective: false });
      }, 2000);
  }
  
  handleTooltipOpenIndustry = (title: any) => {
    this.setState({ tooltipTitleIndustry: title, tooltipOpenIndustry: true });
  };
  handleTooltipToggleObjective = () => {
    this.setState({ tooltipOpenObjective: !this.state.tooltipOpenObjective });
  };
  
  handleTooltipCloseObjective = () => {
    this.setState({ tooltipOpenObjective: false });
  };

  handleTooltipOpenObjective = (title: any) => {
    this.setState({ tooltipTitleObjective: title, tooltipOpenObjective: true });
  }; 
  handleTooltipToggleData = () => {
    this.setState({ tooltipOpenData: !this.state.tooltipOpenData });
  };
  
  handleTooltipCloseData = () => {
    this.setState({ tooltipOpenData: false });
  };
  
  handleTooltipOpenData = (title: any) => {
    this.setState({ tooltipTitleData: title, tooltipOpenData: true });
  };

  handleImageImageMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenCompanyName:true});
    }
  };
   handleImageImageMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenCompanyName:false});}
  }

   handleMouse=()=>{
      this.setState({tooltipOpenCompanyName:!this.state.tooltipOpenCompanyName});
      
  }
  handleImageImageMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenIndustry:true});
    }
  };
   handleImageImageMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenIndustry:false});}
  }
  handleMouseIndustyr=()=>{
   

      this.setState({tooltipOpenIndustry:!this.state.tooltipOpenIndustry});
    
  }
  handleLocationMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenLocation:true});
    }
  };
   handleLocationMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenLocation:false});}
  }
  handleMouseLocation=()=>{
   
      this.setState({tooltipOpenLocation:!this.state.tooltipOpenLocation});
    
  }
  handleObjectiveMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenObjective:true});
    }
  };
   handleObjectiveMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenObjective:false});}
  }
  handleMouseObjective=()=>{
   

      this.setState({tooltipOpenObjective:!this.state.tooltipOpenObjective});
    
  }
  handlesetupDataMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenData:true});
    }
  };
   handlesetupDataMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenData:false});}
  }
  handleMousesetupData=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenData:!this.state.tooltipOpenData});
    }
  }
  handleTooltipToggleCompanyName = () => {
    this.setState({ tooltipOpenCompanyName: !this.state.tooltipOpenCompanyName });
  };
  
  handleTooltipCloseCompanyName = () => {
    this.setState({ tooltipOpenCompanyName: false });
  };
  
  handleTooltipOpenCompanyName = (title: any) => {
    this.setState({ tooltipTitleCompanyName: title, tooltipOpenCompanyName: true });
  };
  
  handleTooltipToggleLocation = () => {
    this.setState({ tooltipOpenLocation: !this.state.tooltipOpenLocation });
  };
  
  handleTooltipCloseLocation = () => {
    this.setState({ tooltipOpenLocation: false });
  };
  
  handleTooltipOpenLocation = (title: any) => {
    this.setState({ tooltipTitleLocation: title, tooltipOpenLocation: true });
  };
   

  hideDropdown = () => {
    this.setState({ dropdownVisible: false});
  }
// istanbul ignore next
  nextPage = async () => {
  
    let Camping_Id = localStorage.getItem('setUpCampaignID')
    let persistId=localStorage.getItem('persistId')
    localStorage.setItem("visitedSetup","setup")
    const { campaing } = this.state;
    
    const errors = {
      companyName: !campaing.companyName.trim() ? this.state.error_lang.name[0] : '',
      industry: !campaing.industry.trim() ? this.state.error_lang.name[1] : '',
      location: !campaing.location ? this.state.error_lang.name[2] : '',
      objectives: !this.state.campaing.selectedObjective ? this.state.error_lang.name[3]:""
    };
    if (!campaing.industry.trim()) {
      this.setState({ errors }); 
      return;
    }
  
    const industryExists = this.state.industryData && this.state.industryData.some(
      item => item.localsearch_schema_category_110 === campaing.industry
    );
    
    if (!industryExists) {
      this.setState({ errors: { ...errors, industry: this.state.error_lang.name[4] } });
      return;
    }
      
    this.setState({ errors: { ...errors, industry: '' } });
    if (Object.values(errors).every(error => !error) && (!Camping_Id && !persistId)) {
      this.setState({RecommendationNavigate:true})
        await Promise.all([this.postCreateCampaing()]);
    }else if(Object.values(errors).every(error => !error) && (Camping_Id || persistId)){
      this.setState({RecommendationNavigate:true})
      let CampaignUpdate:any =  {
        "campaign": {
            "company_name":this.state.campaing.companyName,
            "industry":  this.state.campaing.industry,
            "radius_id": this.state.campaing.location,
            "objective_id":this.state.campaing.selectedObjective,
      "active_page":"setup",
      "visited_key":["setup"]
        },
      "progress": {
          "current_page": "setup"
      }
    }
      this.setState({ApiVariable:CampaignUpdate},()=>this.PutSetupData())
   
    }
  };
  
  onMouseover=(e:CustomMouseEvent)=>{
    e.currentTarget.style.fontWeight = '700'; 
    e.currentTarget.style.color = '#005E6E';  
  }

  onMouseout=(e:MouseEvent)=>{
    e.currentTarget.style.backgroundColor = 'transparent'; 
    e.currentTarget.style.color = '#B4B4B4';
  }

  handleChange = (event: React.ChangeEvent<{ name?: any; value: unknown }>) => {    

    localStorage.setItem("multipledata","false")    
    const { name, value } = event.target;
 
    if (name === 'industry') {
      this.setState((prevState: any, props: Readonly<Props>) => ({
        campaing: {
          ...prevState.campaing,
          [name]: value,
        },
        errors: { 
          ...prevState.errors,
          industry: '',
        },
      }), () => {
        this.getSerchIndustryDetails(value);
      
      });
    } else {
      this.setState((prevState: any, props: Readonly<Props>) => ({
        campaing: {
          ...prevState.campaing,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          [name]: '',
        },
      }));
    }
    this.handlenavigationUpdate()
    

  };
  
  handleResultClick = (selectedValue: string) => {
 
    this.setState({
      campaing: {
        ...this.state.campaing,
        industry: selectedValue,
      },
      dropdownVisible: false,
    });
  };
  handlenavigationUpdate=()=>{      
    this.setState({updatedChecked:true})
    const visitedSetup=localStorage.getItem("visitedSetup");
        const  persistId= localStorage.getItem("persistId")
        if((visitedSetup|| persistId) && !this.state.loaderTrue){
    let activeItemss = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
    let index = activeItemss.indexOf('setup');
    let activeItemsTrimmed = activeItemss.slice(0, index + 1);
    let CampaignUpdate:any =  {
      "campaign": {
    "active_page":"setup",
    "visited_key":activeItemsTrimmed
      },
    "progress": {
        "current_page": "setup"
    }
  }
  this.setState({ ApiVariable: CampaignUpdate }, async () => {
    try {
        await this.PutSetupData(); 
        await this.putSetupDisabledDataApi(); 
    } catch (error) {
        alert(error);
    }
});
      localStorage.removeItem('configSelectDate');   
}
  }
  handleObjectiveClick = (clickedObjective: Objective) => {
    if(!this.state.viewData){
    if(clickedObjective.id){
      this.setState({errors: {...this.state.errors,objectives: ''}})

    }
    this.setState((prevState) => ({
      campaing: {
        ...prevState.campaing,
        selectedObjective: clickedObjective.id,
      },
      clickObjectiveName:clickedObjective.name,
    }),()=>{
      setStorageData("clickObjectiveName", this.state.clickObjectiveName);     
    });

  this.handlenavigationUpdate()
  }
  };

  getsetUpContentDetails = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "set_up";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSetupContentCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.setUpContent}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getContentReceiver=(responseJson:SetUpContentData)=>{
    this.setState({
      setupData: responseJson
    })
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };


  getSetupComapnyName = () => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "CompanyName";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSetupComapnyNameCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getSetupComapnyNameReceiver=(responseJson:any)=>{
    this.setState({
      setupComapnyName: responseJson
    })
  };

  getSetupYourIndustry =  () => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "YourIndustry";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageLists = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSetupYourIndusstryCallID = requestMessageLists.messageId;

    requestMessageLists.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageLists.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageLists.addData(
      
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageLists.id, requestMessageLists);
  };

  getSetupYourIndusstryReceiver=(responseJson:any)=>{
    this.setState({
      setupYourIndustry: responseJson
    })
  };


  getSetupYourLocation = () => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "SetupLocation";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSetupYourLocationCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getSetupYourLocationReceiver=(responseJson:any)=>{
    this.setState({
      setupYourLocation: responseJson
    })
  };

  getSetupYourObjective = () => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "SetupObjective";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSetupYourObjectiveCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };


  getSetupYourObjectiveReceiver=(responseJson:any)=>{
    this.setState({
      setupYourObjective: responseJson
    })
  };
 //istanbul ignore next
  closeModal = () => {
    this.setState({ openModel: false });
  };

  campaignNo =() =>{
    this.deletecampaigns();
    localStorage.removeItem("campaign_Details")
    this.setState((prevState) => ({
      campaing: {
        ...prevState.campaing,
        companyName: "",
        industry: "",
        location: "",
        selectedObjective: "",
      },
      openModel: false
    }));

  }
  
//istanbul ignore next
  deletecampaigns = () => {    
    let Camping_Id = localStorage.getItem('campaignID')
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.campaignsDeleteCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.campaignsAPIMethod
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deletecampaignsEndPoint}?id=${Camping_Id}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  // Customizable Area End
}
