import React from "react";

import {
  Box,
  Input,
  Button,
  // Customizable Area Start
  Zoom,
  Tooltip,
  Grid,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  styled,
  Modal,
  Theme
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ReactQuil from "react-quill";
import moment from "moment"
import { withRouter } from "react-router-dom";
import DOMPurify from 'dompurify';
import {
  createTheme,
  ThemeProvider,
  withStyles,
  createStyles
} from "@material-ui/core/styles";
import Sidebar from "../../../components/src/SideBar.web";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  downaerrow,
  calendoricon,
  info,
  cancelImage,
  checked,
  unchecked,
} from "./assets";

import { Calendar } from 'react-date-range';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const Che = ({ ...other }) => {
  return <img src={checked} alt="images" width="24px" height="24px" />;
};
export const Unch = ({ ...other }) => {
  return <img src={unchecked} alt="images" width="24px" height="24px" />;
};

const QuilParent = styled('div')({
  '& .ql-tooltip.ql-editing': {
    left: '15px!important',
  },
})
const ConfigBoxView = styled(Box)({
  borderRadius: "38px",
  padding: "28px 8px 0px 41px",
  heighr: "970px",
 overflow: "auto",
  backgroundColor: "#FFFFFF",
 
  boxShadow: "0px 4px 16px 0px #00000040",
  marginBottom:'40px',
  minHeight:'855px',
   height: "calc(100vh - 109px)",
  "@media (max-width: 960px)": {
    marginleft:'6px',
    height:'unset',
  },
  "@media (max-width: 500px)": {
    borderRadius:'unset',
    boxShadow:'none',
    padding:'27px 40px 0px 40px',
    minHeight:'unset',
    height: 'unset !important',
  },
  "@media (max-width: 392px)": {
    padding:'27px 40px 0px 32px',
  },
  "@media (max-width: 380px)": {
    padding:'27px 12px 0px 12px',
  },
})

const CustomFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: '14px!important',
    color:'#B4B4B4'
  }
})
const ButtonBox = styled(Box)({
  display:'flex',
  alignItems:'center', 
  justifyContent:'flex-end',
  gap:60,
  "@media (max-width: 1176px)": {
    gap:20,
    marginLeft:'15px',
   },
  "@media (max-width: 960px)": {
   marginTop:'40px',
   justifyContent: 'center',
   gap:60,
  },
  "@media (max-width: 500px)": {
    gap:20,
    marginTop:'20px',
  },
  "@media (max-width: 768px)": {
    justifyContent: 'center'
  },
  "@media (min-width: 960px) and (max-width: 1560px)": {
    // flexDirection: 'column'
    // backgroundColor:'red',
    gap:'-20px',
    
    button:{
      width:100,
      backgroundColor:'red',
    },
    buttons:{
      width:100,
    },
  },
})
const CustomGrid = styled(Grid)({
  "@media (max-width: 960px)": {
  marginTop:"55px",
  marginRight:'10px',
  },
  '& > div': {
    "@media (max-width: 768px)": {
      padding: '20px !important',
    },
  }
})


const CustomButton = styled(Button)({
  "@media (max-width: 960px)": {
    width: '194px !important',
    display:'flex',
    justifyContent:'center',
    justifyItems:'center'
   },
})
const Custom4BoxView = styled(Box)({
  display:'flex',
  // width:'100%',
  padding: '40px 16px 0px 16px',
  gap:'17px',
  '@media (max-width: 961px)': {
    display:'flex',
    // background: 'red',
     flexDirection:"column" ,
     padding: '78px 22px 0px 20px',
  },
  '@media (max-width: 500px)': {
   paddingTop:"70px",
   padding:'unset'
  }
})

const CustomBoxViewRight = styled(Box)({
 
  width:'31.27%',
  '@media (max-width: 961px)': {
    width:'100%',
    
  }
})
const CustomBoxViewleftBox= styled(Box)({
  width:'67.93%',
  '@media (max-width: 961px)': {
    width:'100%',
    
  }
})


const WhiteOnBlackTooltips = withStyles((theme) => ({
  arrow: {
    content: '""',
    color:"#E9E9E9"
  },
  tooltip: {
    color: "black",
    height: "auto",
    padding: "15px 20px 15px 20px",
    marginTop: "20px",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    maxWidth: "231px",
    borderRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    position: "relative",
    
  },
 
}))(Tooltip);

const style = {
  
  
  "height": "150px",
  "width": "100%",
  "marginBottom":'50px',
  "@media screen and (max-width: 440px)": {
    "marginBottom": "55px"
  }
}
const dateChange ={
  paddingTop:'6px',
  
}; 
const quillStyles:any = {
  '.ql-tooltip': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
const customStyles = {
  listRoot: {
    padding: "12px 20px",
  },
  menuItem: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      color: "green",
      backgroundColor: "transparent",
    },
  },
};

const nextPath = "/Objective";
const theme = createTheme({
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiMenu: {
      paper: {
        borderRadius: "12px",
      },
      list: {
        padding: "24px",
      },
    },
    MuiListItem: {
      root: {
        "&:hover, &.Mui-selected": {
          backgroundColor: "transparent !important",
          color: "#005E6E !important",
          fontWeight: 700,
        },
      },
    },
    MuiGrid: {
      root: {
        "&.MuiGrid-spacing-xs-2": {
          "@media (max-width: 960px)": {
            marginTop:"65px"
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover, &.Mui-selected  &.Mui-selected:hover": {
          color: "#005E6E !important",
          fontWeight: 700,
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiSelect: {
      select: {
        fontFamily: "Panton Narrow Regular",
        fontWeight: 400,
      },
    },
   
  },
});

interface IndustryItem {
  localsearch_rubrikname_english: string;
  localsearch_schema_category_110: string;
  id: number;
}
interface Objective {
  tool_tip:string;
  name: string;
  clicked:boolean;
  duration: number;
  description: string;
  id: number;
  photo_url:null;
}
const text =
  "Lörem ipsum esm välfärdsbrott, örem ipsum esm välfärdsbrott,pseudobelLörem ipsum esm välfärdsbrott, örem ipsum esmvälfärdsbrott, pseudobel";
// Customizable Area End

import ConfigurationsController, { Props } from "./ConfigurationsController.web";
import { Divider } from "react-native-elements";

export  class Configuration extends ConfigurationsController {
  //timestamp: string;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getReceiveNoteStyle() {
    const {quillValue } = this.state;
    return (quillValue === ''|| quillValue=== null) ? defaultStyles : activeStyles;
  }
  renderProjectNotesTime = () => {
    const { projectNotesTime, quillValue,projectNotesDate} = this.state;
    const quillValueData:any=quillValue !==null && quillValue !== undefined && quillValue
  
    if (projectNotesTime && projectNotesDate&& quillValueData.length > 0) {
      const formattedDate = moment(projectNotesDate, 'yyyy/MM/DD').format('DD/MM/YYYY');
      return (
        <Typography style={{ marginTop: '8px',fontFamily:'pantton Narrow Regular',fontSize:'14px',color:'#B4B4B4', display:'flex',gap:'10px'}}>
          <Typography>
          Date:{formattedDate} 
          </Typography>
         
          <Typography>
          Time: {projectNotesTime}
          </Typography>
        </Typography>
      );
    }
  
    return null;
  };

  dateSelect = (date:any) => {
    const BoxView = Box as any;
    return(
      <>
      <BoxView style={{position:"relative"}}>
      <InputFiledStyle
  placeholder={this.state.select_lang?.name[0]}
  inputProps = {
    {
      sx: {
        '&::placeholder': {
          color: '#B4B4B4',
          fontWeight:'bold',
          paddingLeft:'20px',
          fontFamily:'Panton Narrow Regular',
        },
        "& .MuiInputBase-input":{
          marginLeft:'20px'
        }
      },
    }} 
  value={date}
  fullWidth
  disableUnderline
  name="startDate" 
  data-test-id="user-date"
  disabled={this.state.viewData}
  style={{...webStyle.profileDetailsInputContainer,}}
/>

{date.length===0 && (
  <span style={webStyle.errorMsg}>{this.state.errors.startDate}</span>
)}

  <img
      src={calendoricon} alt="Location Icon"
      style={{marginRight:"22px",position:'absolute',top:"28px",right:0, width: "24px", height: "24px",cursor:'pointer' }}
      onClick={this.openDatePicker}
     
   />
      {this.state.datePickerIsOpen && (
     <CalendarDiv style={{ position: 'absolute', top: 68, right: 0, zIndex: 100000000,boxShadow: "0px 4px 16px 0px #00000040",
      }}>
    <Calendar  onChange={this.handleChanges}  date={this.state.startDate }
              minDate={new Date()} />
     </CalendarDiv>
     )}
        
        
     </BoxView>
     </>
    )
  }

selectdur=()=>{
  const{classes}:any=this.props
  const filteredMenuItems = this.state.configurationsData.duration!== null && this.state.configurationsData.duration!== undefined
  ? this.state.menuItems.filter((item:any) => item.value >= this.state.configurationsData.duration)
  : this.state.menuItems;
  return(
    <>
    <SlectFiledStyle
            disableUnderline
            fullWidth
            style={{...webStyle.profileDetailsInputContainer,fontSize:'18px'}}
            name="duration"
            data-testid = "Location"
            value={this.state.configurationsData.duration} 
            onChange={(event)=>this.handleChange(event)} 
            displayEmpty
            renderValue={
              this.state.configurationsData.duration !== ""
                ? undefined
                : () => this.state.select_lang?.name[1]
            }
            className={classes.expandIcon}
            disabled={this.state.viewData}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
               style: {
                       marginTop: '8px',
                      zIndex: 9999,
               },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              
              getContentAnchorEl: null,
            }}
          >
          {filteredMenuItems.map((item:any, index:any) => {
             return(
            <MenuItem  value={item.value}  style={{...webStyle.Items,
              borderBottom: index < this.state.menuItems.length - 1 ? '1px solid #B4B4B4' : 'none'}} key={index}
            >{item.label} {this.state.select_lang.name[6]}</MenuItem>
             )
             })}
          </SlectFiledStyle>
    </>
  )
}

selectlang=()=>{
  const{classes}:any=this.props
  return(
      <>
      <SlectFiledStyle
          displayEmpty
          disableUnderline
          disabled={this.state.viewData}
            renderValue={
              this.state.CampaignLanguage.language !== ""
                ? undefined
                : () => this.state.select_lang?.name[2]
            }
            fullWidth
            value={this.state.configurationsData.language} 
            onChange={(event)=>this.handleChange(event)} 
            name="language"
            style={{...webStyle.profileDetailsInputContainer,fontSize:'18px',marginTop:'3px'}}
            className={classes.expandIcon}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              style: {
                marginTop: '8px',
               zIndex: 9999,
        },
              getContentAnchorEl: null,
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }}
          >
             {this.state.CampaignLanguage.map((item:any, index:any) => {
             return(
            <MenuItem  value={item.attributes.name.toLowerCase()}  style={{...webStyle.Items,
              borderBottom: index < this.state.CampaignLanguage.length - 1 ? '1px solid #B4B4B4' : 'none'}} key={index}
            >{item.attributes.name}</MenuItem>
             )
             })}
          </SlectFiledStyle>
          </>
  )
}
showError= () =>{
  return(
    <>
      {(this.state.quillValue !==undefined && this.state.quillValue !==null && this.state.quillValue.length == 0) && (this.state.textediterIsOpen===false) ? (
    <span style={{ position: 'absolute', top: 12, left: 22,fontFamily:'Panton Narrow Regular',fontWeight:400,fontSize:'18px',color:'#B4B4B4' }}
    onClick={() => this.setState({ textediterIsOpen: true })}>
    {this.state.select_lang?.name[5]}
   </span>
    ) :
    null
   }
    </>
  )

}
//istanbul ignore next
 CampaignConfiguration = () => {
  const BoxView = Box as any;
  const{classes}:any=this.props
  const WhiteOnBlackTooltipViews = WhiteOnBlackTooltips as any;
  const { isChecked,quillValue,diplayTime} = this.state;
  const htmlContent:any = (this.state.quillValue !== undefined && this.state.quillValue !==null) ? this.state.quillValue:  this.state.select_lang?.name[5];
  const labelStyle = {
    color: 'grey',
    fontSize: '14px!important'
  };
  const modules = {
    toolbar: [
      ["bold","italic", "underline", "strike"],
      [{ list: "ordered" }],
      [{ list: "bullet" }],
      ['link'],
      // [{ 'font': [] }],
      ["blockquote"],
      [{ 'align': [] }]
    ]
  }
  let selectedDate = '';
  if (!this.state.datePickerIsOpen) {
    selectedDate = this.state.configurationsData.startDate ? 
      moment(this.state.configurationsData.startDate).format("DD-MM-YYYY") : 
      '';
  }
  return (
    <CustomBoxViewleftBox  >
    <ConfigBoxView >
      <Grid container >
      <Grid item sm={10} xs={12}>
          <Typography className={classes.lablesHeading}>
          <span style={{ display :"flex"}}>
          {this.state.configurationHeadingTooltip?.data?.name}
          
          
          <WhiteOnBlackTooltipViews
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.configurationHeadingTooltip?.data?.description}

                         open={this.state.tooltipOpenConfigurationHeading}
                       onClose={this.handleTooltipCloseConfigurationHeading}
                        >
              <img
                src={info}
                data-test-id="userHeading"
                className={classes.headingIcon}
                style={{marginLeft:'16px'}}
                alt="Info"
                onMouseLeave={()=>this.handleImageImageMouseOver()}
                onMouseEnter={()=>this.handleImageImageMouseHover()}
                onClick={() => this.handleMouse()}
              />
              </WhiteOnBlackTooltipViews>

              </span>
          </Typography>
        </Grid>
      <Grid item sm={10} xs={12} >
          <Typography  className={classes.lables}>
            <span style={{display:'flex'}}>
          {this.state.configurationStartdate?.data?.name}
         
          <WhiteOnBlackTooltipViews
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.configurationStartdate?.data?.description}
                         open={this.state.tooltipOpenConfigurationDate}
                         onClose={this.handleTooltipCloseConfigurationDate}
                        >
              <img
               style={{ marginLeft: "8px",width:"15px",position:'unset' }}
                src={info}
                alt="Info"
                data-test-id='configDate'
                onMouseLeave={()=>this.handleConfigurationDateMouseOver()}
                onMouseEnter={()=>this.handleConfigurationDateMouseHover()}
                 onClick={() => this.handleConfigurationDate()}              />
              </WhiteOnBlackTooltipViews>
              </span>
          </Typography>
          {this.dateSelect(selectedDate)}
        </Grid>
        <Grid item sm={10} xs={12} >
          <Typography className={classes.lables}>
            <span style={{display:'flex'}}>
          {this.state.configurationDuration?.data?.name}
         
          <WhiteOnBlackTooltipViews
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.configurationDuration?.data?.description}
                         open={this.state.tooltipOpenConfigurationName}
                       onClose={this.handleTooltipCloseConfigurationName}
                        >
              <img
                src={info}
                style={{ marginLeft: "8px",width:"15px",position:'unset' }}
                alt="Info"
                data-test-id='durationTooltip'
                onMouseLeave={()=>this.handleconfigurationDurationMouseOver()}
                onMouseEnter={()=>this.handleconfigurationDurationMouseHover()}
                 onClick={() => this.handleconfigurationDuration()}
              />
              </WhiteOnBlackTooltipViews>
              </span>
          </Typography>
          {this.selectdur()}
          {this.state.errors.duration && (
              <span style={webStyle.errorMsg}>{this.state.errors.duration}</span>
          )}
        </Grid>
        <Grid item sm={10} xs={12} >
          <Typography className={classes.lables}>
            <span style={{display:'flex'}}>
          {this.state.configurationCampaignLanguage?.data?.name}
          
          <WhiteOnBlackTooltipViews
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.configurationCampaignLanguage?.data?.description}
                          open={this.state.tooltipOpenLanguage}
                       onClose={this.handleTooltipCloseConfigurationLanguage}
                        >
              <img
               style={{ marginLeft: "8px",position:'unset',width:'15px' }}
                src={info}
                alt="Info"
                data-test-id='configlangauge'
                onMouseLeave={()=>this.handleconfigurationLangaugeMouseOver()}
                onMouseEnter={()=>this.handleconfigurationLangaugeMouseHover()}
                 onClick={() => this.handleconfigurationLangauge()}
              />
              </WhiteOnBlackTooltipViews>
              </span>
          </Typography>
          <BoxView sx={{ position: "relative",marginTop:'16px' }}>
       {this.selectlang()}
          {this.state.errors.language && (
              <span style={webStyle.errorMsg}>{this.state.errors.language}</span>
          )}
          </BoxView>
        </Grid>
        <Grid item sm={10} xs={12} >
          <Typography className={classes.lables}>
            <span style={{display:'flex'}}>
          {this.state.configurationDestinationUrl?.data?.name}
         
          <WhiteOnBlackTooltipViews
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.configurationDestinationUrl?.data?.description}
                          open={this.state.tooltipOpenConfigurationDuration}
                       onClose={this.handleTooltipCloseConfigurationDuration}
                        >
              <img
              style={{ marginLeft: "8px",position:'unset',width:'15px' }}  
                src={info}
                alt="Info"
                data-test-id='configUrl'
                onMouseLeave={()=>this.handleDestinationUrlMouseOver()}
                onMouseEnter={()=>this.handleDestinationUrlMouseHover()}
                 onClick={() => this.handleDestinationUrl()}
              />
              </WhiteOnBlackTooltipViews>
              </span>
          </Typography>
          <Input
          disabled={this.state.InputDisabled || this.state.viewData}
            style={{
              fontWeight: 400,
              fontFamily:' panton Narrow Regular',}}
              className={classes.profileDetailsInputContainer}
            fullWidth
            data-test-id="user-email"
            name="destinationURL"
            value={this.state.configurationsData.destinationURL==="true"?"":this.state.configurationsData.destinationURL}
           
            onChange={(event)=>this.handleChange(event)}  
              disableUnderline
              placeholder={this.state.select_lang?.name[3]}
              inputProps = {
                {
                  sx: {
                    '&::placeholder': {
                      color: '#B4B4B4',
                      fontWeight:'bold',
                      fontFamily:'Panton Narrow Regular'
                    },
                  },
                }} 
          />
          {this.state.errors.destinationURL && (
              <span style={webStyle.errorMsg}>{this.state.errors.destinationURL}</span>
          )}
          <Grid>
          <CustomFormControlLabel
            control={
              <Checkbox
              name="checkedB"
              checked={isChecked}
              icon={<Unch />}
              checkedIcon={<Che />}
              onClick={this.handleCheckboxChange}
              disabled={this.state.isDisabled || this.state.viewData}
              data-test-id="checkedbox"
              />
            }
            label={this.state.select_lang?.name[4]}
            style={{...labelStyle,fontSize:"14px !important"}}
          />
          </Grid>
        </Grid>
        <Grid item sm={10} xs={12} onClick={this.displaytime} data-test-id='opentime'>
          <Typography className={classes.lables} style={{ marginBottom:'16px'}}>
            <span style={{display:'flex'}}>
          {this.state.configurationProjectNotes?.data?.name}
          
          <WhiteOnBlackTooltipViews
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.configurationProjectNotes?.data?.description}
                          open={this.state.tooltipOpenProjectNotes}
                       onClose={this.handleTooltipCloseProjectNotes}
                        >
              <img
              style={{ marginLeft: "8px",position:'unset',width:'15px' }} 
                src={info}
                alt="Info"
                data-test-id='configNote'
                onMouseLeave={()=>this.handleProjectNotesMouseOver()}
                onMouseEnter={()=>this.handleProjectNotesMouseHover()}
                 onClick={() => this.handleProjectNote()}
              />
              </WhiteOnBlackTooltipViews>
              </span>
          </Typography>  
          {this.state.errors.quillValue && (
            <span style={webStyle.errorMsg}>{this.state.errors.quillValue}</span>
          )}
          {this.state.textediterIsOpen ?(
            <QuilParent>
              
            <ReactQuil 
            theme="snow" 
            data-test-id="reactQuill"
            style={style  }
            value={quillValue}
              modules={modules} 
             onChange={this.handleQuillChange} 
             readOnly={this.state.viewData} 
            />
            <div style={dateChange} >

             {diplayTime && this.renderProjectNotesTime()}
            </div>
          </QuilParent>
          
          )
           :  (  
          <div style={{position: 'relative'}}>
            <div  contentEditable={true}
           dangerouslySetInnerHTML={{ __html: htmlContent}}                          
           style={this.getReceiveNoteStyle()}
           data-test-id="user-email"
           onClick={this.handleProjectNotes}
           >
            </div>
            <div>
            {this.showError()}
           {diplayTime && this.renderProjectNotesTime()}
            </div>
            {/* <span style={{position: 'absolute', top: 15, left: 16}}>write somthing here</span> */}
          </div>
          )
            }
        </Grid>
      </Grid>
    </ConfigBoxView>
  </CustomBoxViewleftBox>

    )
          }

  // Customizable Area End

  render() {
      const BoxView = Box as any;
      const{ classes }:any = this.props
      const WhiteOnBlackTooltipViews = WhiteOnBlackTooltips as any;
      const maxCharacters = 202;
      const truncatedText = this.state.configurationData?.body ? this.state.configurationData.body.substring(0, maxCharacters) : '';
      const shouldShowReadMore = this.state.configurationData?.body && this.state.configurationData.body.length > maxCharacters;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar navigation={this.props.navigation} classes={{}} onDashboardSave={this.onDashboardSave}>
          <BoxView style={{ ...webStyle.scrollableBoxs }}>
            <Custom4BoxView
             data-testid = "Conponent"
           
            >
              {!this.state.isComponentOpens && (
                <>
                {this.CampaignConfiguration()}
               
                  <CustomBoxViewRight>
                    <BoxView sx={webStyle.subcontainers} >
                      <BoxView className={classes.subBoxs}>
                 <Typography style={webStyle.titles}>
                        <span>
                          {this.state.configurationData?.heading}
                        </span>
                        <span>
                        <WhiteOnBlackTooltipViews
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.configurationData?.tooltip}
                          open={this.state.tooltipOpenConfigurationData}
                       onClose={this.handleTooltipCloseConfigurationData}
                        >
                          <img src={info} 
                          data-test-id="configHeadings"
 onMouseLeave={()=>this.handleconfigurationDataMouseOver()}
 onMouseEnter={()=>this.handleconfigurationDataMouseHover()}
  onClick={() => this.handleconfigurationData()}
                          alt="Info"
                           style={{height: "13px", width: "13px" }} />
                        </WhiteOnBlackTooltipViews>
                        </span>
                      </Typography>
                <img style={webStyle.img} src={this.state.configurationData?.image_url}/>
                    <Typography style={{
                        marginTop: "20px",
                        lineHeight: '19.38px',
                        wordBreak: 'break-all',
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'panton narrow regular'
                    }} >
                        {truncatedText}{" "}
                        {shouldShowReadMore && (
                            <span style={webStyle.readMore} onClick={this.toggleModal}>
                                {this.state.configurationData?.read_more}
                            </span>
                        )}
                    </Typography>
                   <Modal
                          open={this.state.isModalOpen}
                          onClose={this.toggleModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          BackdropProps={{
                            style: {
                              backgroundColor: 'transparent',
                            }
                          }}
                        >
                        <BoxView className={classes.confiurationModalBoxStyle}>
                            <div  className={classes.confiurationModalBoxStyles} >
                                <Typography className={{ ...classes.bodyText}} style={{ wordBreak: 'break-all',
                                 flex: '1',lineHeight:'19.38px', fontFamily: 'panton narrow regular'}}>
                                    {this.state.configurationData?.body}
                                    
                                </Typography>
                                <div  className={classes.configurationCancelImageWrapper} >
                                    <img src={cancelImage} alt="Close"   className={classes.confiurationCancelImage} onClick={this.toggleModal} />
                                </div>
                            </div>
                        </BoxView>
                        </Modal>
                    </BoxView>
                      
                    <ButtonBox>
                          <ButtonContainer
                            variant="contained"
                            data-test-id="configuration_page"
                            onClick={this.handleSaveClick}
                            disabled={this.state.viewData}
                          >
                          {this.state.select_lang.name[7]}
                          </ButtonContainer>
                        </ButtonBox>

                    </BoxView>
                  </CustomBoxViewRight>
                </>
              )}
            </Custom4BoxView>
          </BoxView>
        </Sidebar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SlectFiledStyle=styled(Select)({
  '& .MuiSelect-select.MuiSelect-select':{
    paddingLeft:"20px"
  },
  "& .MuiSelect-select":{
    backgroundColor:'transparent'
  }
});

const InputFiledStyle=styled(Input)({
  "& .MuiInputBase-input":{
    paddingLeft:'20px'
  }
})
const ButtonContainer :any = styled(Button)({
  width: "194px",
  borderRadius: "43px",
  color: "#353232",
  backgroundColor: "#e0e0e0",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  textTransform: "capitalize" as const,
  boxShadow: "none",
  "&:hover": { 
    backgroundColor: "#005E6E",
    color: "#FFFFFF",
    boxShadow: "none",
  },
  "@media (max-width: 960px)": {
    marginTop: "40px",
   },
   '@media(max-width:500px)':{
    width:'176px'
  },
  '@media(max-width:390px)':{
    width:'167px'
  },
  '@media(max-width:350px)':{
    width:'142px'
  }
});
const CalendarDiv = styled('div')({
      
  '@media (max-width: 325px)': {
    '& .rdrMonth': {
      width: '100%',
      padding: '0px !important'
    },
  }
})

const webStyle = {

  buttonStyle: {
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    width: "100%",
    height: "45px",
    marginTop: "40px",
  },
  font: {
     color: "#B4B4B4",
    cursor: "pointer",
    fontFamily: "Panton Narrow Regular",
    "& :hover": {
      fontWeight: 600,
      color: "#005E6E",
    },
    fontWeight: 400,
    fontSize: "14px",
  },
  smalltext: {
    color: "#F8FAFC",
    fontWeight: 400,
    fontFamily: "Panton Narrow Regular",
    fontSize: "12px",
  },
  inputStyle: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
  },
  mainBox: {
    borderRadius: "32px",
    padding: "24px 42px",
    heighr: "970px",
   overflow: "auto",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 16px 0px #00000040",
    marginRight:"-15px",

    minHeight:'855px'
  },
  root: {
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "transparent" as "transparent",
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "transparent" as "transparent",
    },
    "& .MuiTableCell-root": {
      backgroundColor: "#0000",
    },
    "& .MuiMenuItem-root:hover": {
      color: "green",
      backgroundColor: "transparent" as "transparent",
    },
    "& .MuiList-root": {
      padding: "12px 20px" as "12px 20px",
    },
  },
  mainWrapper: {
    paddingBottom: "30px",
    alignItems: "center",
    background: "#fff",
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
  },
  profileDetailsInputContainer: {
    marginTop: "16px",
    background: "#FFFFFF",
    borderRadius: "16px",
    border: "1px solid #B4B4B4",
    height:'48px',
  },
  profileDetailsInputContain: {
    marginTop: "12px",
    background: "#FFFFFF",
    borderRadius: "12px",
    border: "1px solid #B4B4B4",
     padding: "2px 0px 0px 0px ",
    height:'43px',
  },
 
  lables: {
    color: "#000000",
    fontFamily: "Panton Narrow Bold !important",
    fontWeight: 700,
    fontSize: "20px",
  },
 
  profileDetailsInputContainers: {
    background: "#FFFFFF",
    border: "1px solid #B4B4B4",
    padding: "4px 18px 157px 16px  ",
    marginTop: "16px",
    borderRadius: "12px",

  },
  divider:{
    boderBottom:"1px solid #B4B4B4"
  },
  subcontainers:{
    height: "96.38%",
    paddingBottom: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width: 1440px)": {
        height: "96.4%",
     },
     "@media (max-width: 960px)": {
     padding:"0px",
     width:"100%",
     paddingBottom: "36px",
    
    },
    "@media (max-width: 500px)": {
      paddingBottom: "37px",
    }
  },
  WhiteOnBlackTooltips: {
    borderRadius: "10px",
    width: "135px",
    padding: "10px",
    textAlign: "left",
    fontSize: "14px",
    position: "relative",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "#fff", 
    "&:after": {
      borderWidth: "10px 10px 0",
      borderColor: "rgba(0, 0, 0, 0.8) transparent transparent transparent",
      top: "-15px",
      left: "10px",
      borderStyle: "solid",
      content: "''",
      position: "absolute",
   
    },
  },
  text: {
    fontSize: "16px",
    color: "#FFFFFF",
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
  },
  scrollableBoxs: {
    height: "97%",
   
  },
  titles: {
    fontSize: "16px",
    marginBottom: "20px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Panton Narrow Regular !important",
    
    display:"flex",
    alignItems:"center",
    
    gap:'12px',
  },
  img: {
    width: "100%",
    height:'164px',
  },
  imgGroup: {
    cursor:"pointer",
    borderRadius: "12px",
    width: "100%",
  },
  card: {
    bordeRadius: "12px",
    border: "1px solid",
  },
  infoIcon: {
    marginleft: "15px",
  },
  border: {
    borderBottom: "none",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
  },
  buttonBoxs: {
    display: "flex",
    justifyContent: "start",
  },
  "@media screen and (max-width: 768px)": {
    buttonBox: {
      marginTop: "20px",
      width: 130,
      display: "block",
      justifyContent: "center",
    },
  },
  button: {
    height: "52px",
    borderRadius: "43px",
    color: "#FFFFFF",
    textTransform: "capitalize" as const,
    width: "194px",
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    backgroundColor: "#005E6E",
    boxShadow: "none",
  },
  buttons: {
    boxShadow: 'unset',
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    height: "52px",
    textTransform: "capitalize" as const,
    fontSize:'16px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: "176px",
    borderRadius: "43px",
    color: "#353232",
    backgroundColor: "#e0e0e0",
    "& :hover": { 
      backgroundColor: "#005E6E",
      color: "#FFFFFF",
    },
  },
  Items: {
    fontFamily: "Panton Narrow Regular",
    padding: "16px 20px",
    fontSize: "16px",
    "& :hover": {
      backgroundColor: "transparent",
      color: "#005E6E",
    },
    color: "#B4B4Be",
  },
  alert:{
      border: "1px solid red",
      borderLeftWidth: "3px",
      borderRightWidth: "3px",
      borderRadius: "10px",
  },
  serch: {
    zIndex: 999,
    background: "#FFFFFF",
    maxWidth: "100%",
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.4)" as "0px 4px 16px 0px rgba(0, 0, 0, 0.4)",
    borderRadius: "12px",
    position: "absolute" as const,
  },
  readMore: {
    fontWeight: 700,
    color: "#005E6E",
    fontFamily: "Panton Narrow Bold",
    fontSize: "14px",
    cursor:'pointer'
  },
  
  errorMsg:{
    color: '#005E6E',
    fontFamily:"panton Narrow Regular",
    fontWeight:600
  },
 
  bodyText: {
    flex: 1,
    fontFamily: 'panton narrow regular',
    fontSize: '16px',
    lineHeight: '19.38px',
    fontWeight: 400,
  },
  
};
const defaultStyles = {
  ...webStyle.profileDetailsInputContainers,
  color: '#B4B4B4',
};

const activeStyles = {
  ...webStyle.profileDetailsInputContainers,
  color: 'black', 
};
export const useStyle:any = (theme: Theme) =>
  createStyles({
   
    lablesHeading: {
      color: "#000000",
      fontFamily: "Panton Narrow Bold !important",
      fontWeight: 700,
      lineHeight:'32px',
      fontSize: "24px",
      letter:'-0.5%',
      display:'block',
      height:'17px',
      marginTop:'-7px',
      marginBottom:'40px',
      "@media(max-width:500px)":{
        fontSize: "16px !important",
        lineHeight:'32px',
        marginBottom:'10px',
        marginLeft:-8,
        fontFamily: "Panton Narrow Bold !important",
        // marginLeft:'12px',
      },
      
    },
    confiurationCancelImage: {
      cursor: 'pointer',
      width: '10.96px',
      height: '11px',
     position:"absolute",
     top:'18px',
     right:'18px',
     "@media(max-width:500px)":{
      position:'absolute',
      top:18,
     
      right:'18px',
    }
    },
    configurationCancelImageWrapper: {
      alignSelf: 'flex-start',
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
      "@media(max-width:500px)":{
        position:'absolute',
        top:18,
        marginRight:'0px',
        right:'18px',
      }
  },
    bodyText: {
      flex: 1,
      fontFamily: 'panton narrow regular',
      fontSize: '16px',
      lineHeight: '19.38px',
      fontWeight: 400,
      "@media(max-width:500px)":{
        marginTop:25,
      },
        },
    confiurationModalBoxStyles:{
      display: 'flex',
       justifyContent: 'space-between',
       padding:"48px",
       "@media(max-width:500px)":{
        display: 'unset',
        justifyContent:'unset',
       }
    },
    confiurationModalBoxStyle: {
      position: 'fixed',
          top: '50%',
          left: '50%',
          right: '50%',
          transform: 'translate(-50%, -50%)',
          width: '573px',
          height: 'auto',
          borderRadius: '12px',
          background: '#FFFFFF',
          boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
         
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          maxHeight: '80vh',
          '@media(max-width:500px)':{
            width: '290px',
            
            top: '52%',
            overflowY: 'auto',
            
          }
    },
    headingIcon :{
      width: "18px",
      //  marginLeft: "14px",
       position:'relative',
       "@media(max-width:500px)":{
        width: "17px",
        height:'17px',
        marginTop:'6px',
       },
    },
    lables: {
      color: "#000000",
      fontFamily: "Panton Narrow Bold !important",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight:'26px',
      marginTop:'17px',
      "@media(max-width:500px)":{
        fontSize: "14px !important",
        lineHeight:'26px',
        marginTop:'20px',
        fontFamily: "Panton Narrow Bold !important",
        // marginLeft:'12px',
      }
    },
    subBoxs: {
      backgroundColor: "#FFFFFF",
      padding: "33px 29.4px 24px 24px",
      borderRadius: "24px",
      boxShadow: "0px 4px 16px 0px #00000040",
      height: "auto",
      '@media(max-width:500px)':{
        margin:'0px 19px 0px 20px',
      },
      '@media(max-width:375px)':{
        margin:'0px 15px 0px 15px',
      }
    },
    profileDetailsInputContainer: {
      marginTop: "16px",
      background: "#FFFFFF",
      borderRadius: "12px",
      border: "1px solid #B4B4B4",
      padding: "12.5px 8px 11.7px 20px ",
      height:'48px',
      fontSize:'18px',
      "@media(max-width:500px)":{
        marginTop: "12px",
        fontSize:'14px'
      },
    },
    profileDetailsInputContaineres: {
      marginTop: "12px",
      background: "#FFFFFF",
      borderRadius: "12px",
      border: "1px solid #B4B4B4 !important",
      padding: "12.5px 8px 11.7px 20px ",
      height:'48px',
      fontSize:'18px',
      "@media(max-width:500px)":{
        marginTop: "12px",
        fontSize:'14px'
      },
    },
    profileDetailsInputContaineres2: {
      marginTop: "12px",
      background: "#FFFFFF",
      borderRadius: "12px",
      border: "1px solid #B4B4B4 !important",
      padding: "12.5px 8px 11.7px 20px ",
      height:'48px',
      fontSize:'18px',
      "@media(max-width:500px)":{
         marginTop: "-2px",
        fontSize:'14px'
      },
    },
    date:{
      marginTop: '8px',
      fontFamily:'pantton Narrow Regular',
      fontSize:'14px',color:'#B4B4B4',
      marginLeft:'10px', 
      display:'flex',
      gap:'10px',
      "@media (max-width: 440px)": {
        
          marginTop: "28px"
        
      }
    },
    expandIcon : {
      "& .MuiSelect-icon": {
        right: "22px",
        color: "rgb(180, 180, 180)",
        cursor: "pointer"
      }
    }
  })
export default withRouter(withStyles(useStyle)(Configuration as any) as any) as any;
// Customizable Area End