import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  ClassNameMap,
  ClassKeyOfStyles,
} from '@material-ui/styles';

// Customizable Area End

export const configJSON = require("./config");



 
interface EditBody {
  [key: string]: string | number[]  | number  
}

interface Body {  
  data:EditBody
}
interface ApiData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Body | EditBody;
  type?: string;
}

export interface ProjectNote {
  id: string;
  type: string;
  attributes: {
    id: string;
    note_content: string;
    reference_id: number;
    is_deleted: boolean;
    is_updated: boolean;
    created_at: Date;
    updated_at: Date;
    created_time: string;
    created_date: Date;
  }
}


export interface Props {
  navigation: any;
  id: string;
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
}

export interface S {
  // Customizable Area Start
  projectNotes: ProjectNote[] | null;
  projectNoteDetail: ProjectNote | null;
  projectNoteText: string;
  isOpen: boolean;
  isEdit:boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProjectNotesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createProjectNotesApiCallId: string = "";
  acquireAllProjectNotesApiId: string = "";
  editProjectNoteApiId: string = "";
  deleteProjectNoteApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      projectNotes: null,
      projectNoteDetail: null,
      projectNoteText: '',
      isOpen: false,
      isEdit:false,
    };

    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.acquireAllProjectNotesApiId != null
      && this.acquireAllProjectNotesApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.errors === undefined) {
        this.setState({ projectNotes: responseJson.data})
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.createProjectNotesApiCallId != null
      && this.createProjectNotesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.errors === undefined) {
        this.setState({isOpen:false})
        this.getAllNotesInProject('1')
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.editProjectNoteApiId != null
      && this.editProjectNoteApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.errors === undefined) {
        this.setState({isOpen:false})
        this.getAllNotesInProject('1')
      }
    }
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.deleteProjectNoteApiId != null
      && this.deleteProjectNoteApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson !== undefined && responseJson.errors === undefined) {
        this.setState({projectNoteDetail:null})
        this.getAllNotesInProject('1')
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getAllNotesInProject('1')
    // Customizable Area End
  }

  handleModal = () => {
    this.setState({ isOpen: true })
  }

  handleCloseModal = () => {
    this.setState({ projectNoteDetail: null, isOpen: false })
  }

  setEditNotevalue = (noteDetail:ProjectNote)=>{
    this.setState({ projectNoteDetail: noteDetail })
  }

  createNoteForProject = async (note:string,refrenceId: string) => {
    const body = {  
      data : {
        note,
        reference_id: refrenceId
      }
    }

    this.createProjectNotesApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.createNoteApiMethod,
      endPoint: configJSON.projectNotesApiEndPoint,
      body,
    })
  };

  getAllNotesInProject = async (refrenceId: string) => {
    
    this.acquireAllProjectNotesApiId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getProjectNotesDetailsMehtod,
      endPoint: `${configJSON.projectNotesApiEndPoint}?reference_id=${refrenceId}`,
    })
  };
  updateProjectNote = async (note:string,noteId:string,referenceId: number) => {
    const body = {
        note,
        reference_id: referenceId 
    }
    this.editProjectNoteApiId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.updateNoteMethod,
      endPoint:`${configJSON.projectNotesApiEndPoint}${noteId}`,
      body
    })
  }

  deleteNoteFromProject = async (noteId: string) => {
    this.deleteProjectNoteApiId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.deleteNotesMethod,
      endPoint:`${configJSON.projectNotesApiEndPoint}${noteId}`,
    })

  }

  apiCall = async (data: ApiData) => {

    const { contentType, method, endPoint, body, type } = data;
    const header = {
      'Content-Type': contentType,
    }
    const apiCallMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(apiCallMessage.id, apiCallMessage);
    return apiCallMessage.messageId;
  }


  // Customizable Area End
}
