import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  Zoom,
  Tooltip,
  Grid,
  Typography,
  AccordionSummary,
  Accordion,
  FormControlLabel,
  Checkbox,
  AccordionDetails,
  SwitchClassKey,
  SwitchProps,
  MenuItem,
  styled,
  Modal,
  Theme,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  withStyles,
  createStyles,
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Sidebar from "../../../components/src/SideBar.web";
import {
  cancelImage,
  info,
  expandIcon,
  checked,
  unchecked,
  google,
  bannerad,
  printads,
} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiGrid: {
      root: {
        "&.MuiGrid-spacing-xs-2": {
          "@media (max-width: 960px)": {
            marginTop: "44px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: "7px 0px",
        "&.Mui-expanded": {
          padding: "0px",
          margin: "0px",
        },
      },
      root: {
        '&[class*="Mui-expanded"]': {
          margin: "0px !important",
          minHeight: "48px",
        },
      },
    },
    MuiMenu: {
      list: {
        padding: "24px",
      },
      paper: {
        borderRadius: "12px",
      },
    },
    MuiDivider: {
      root: {
        borderColor: "#F8FAFC",
        borderWidth: "1px",
        borderStyle: "solid",
      },
    },
    MuiListItem: {
      root: {
        "&:hover, &.Mui-selected": {
          backgroundColor: "transparent !important",
         
        },
      },
    },
    MuiAccordion: {
      root: {
        '&[class*="Mui-expanded"]': {
          margin: "0px !important",
        },
      },
    },
  },
});
const Custom4BoxView = styled(Box)({
  display:'flex',
  // width:'100%',
  padding: '40px 16px 0px 16px',
  '@media (max-width: 961px)': {
    display:'flex',
     flexDirection:"column" ,
     padding: '78px 28px 0px 28px',
     height:'unset'
  },
  '@media (max-width: 500px)': {
  //  paddingTop:"94px"
   padding: '78px 19px 0px 24px',
   height:'calc(100vh - 100px)',
   justifyContent:'space-between'
  },
  '@media (max-width: 380px)':{
     padding: '78px 10px 0px 10px',
    },
  checkBox: {
    "@media(max-width:500px)":{
      width:'24px',
      height:'24px',
    }
  },
})

const CustomBoxViewRight = styled(Box)({
 
  width:'32.5%',
  '@media (max-width: 961px)': {
    width:'100%',
    
  }
})
const CustomAccordion = styled(Accordion)({
  borderBottom: '1px solid #b4b4b4',
  '& .MuiCollapse-entered': {
    marginBottom: 10
  }
})

const CustomBoxViewButton=styled(Box)({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "@media (max-width: 1440px)": {
      gap:"10px",
     },
    "@media (max-width: 960px)": {
     marginTop: "40px",
    },
    "@media (max-width: 500px)": {
      marginTop: "20px",
      marginBottom:'31px',
    }
})
const CustomBoxViewleftBox= styled(Box)({
  width:'67.5%',
  '@media (max-width: 961px)': {
    width:'100%',
  },
  '& .MuiAccordion-root::before': {
    backgroundColor:'unset'
  },
  // "& .MuiAccordion-rounded:first-child":{
  //   borderTopColor:'green'
  // },
  "& .MuiAccordion-rounded:last-child":{
    borderRadius:'0px',
    borderBottomColor:'#b4b4b4'
  },
 '& .MuiFormControlLabel-root':{
  marginLeft:'-22px',
  marginRight:'unset',
 },
 '& .MuiAccordionSummary-root':{
   padding:'0px 15px 0px 20px'
 },
 '& .MuiAccordionSummary-content':{
  margin:'10px 0px',
},
})
const Custom2BoxView = styled(Box)({
  '@media (min-width: 960) and (max-width: 1440px)': {
    maxWidth: '348px !important',
    width: '100%',
  },
  '@media(max-width: 960px)': {
    marginTop: 16,
  },
  '@media(max-width: 500px)': {
    marginLeft:-4,
  },
  '@media(max-width: 375px)': {
    marginLeft:0,
  },
})
const WhiteOnBlackTooltip:any = withStyles((theme) => ({
  tooltip: {
    color: "black",
    height: "auto",
    fontWeight: 400,
    marginTop: "20px",
    fontSize: "16px",
    maxWidth: "331px",
    borderRadius: "10px",
    padding: "15px 20px 15px 20px",
    backgroundColor: "#E9E9E9",
    fontFamily: "Panton Narrow Regular",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    position: "relative",
  },
  arrow: {
    color: "#E9E9E9",
    content: '""',
  },
  "& .MuiAccordion-root::before":{
    top:"55px !important",
    
  }
}))(Tooltip);

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Toggle extends SwitchProps {
  classes: Styles;
}

type SubChannel = {
  id: number;
  name: string;
  enabled: boolean;
  photo: string;
};
// Customizable Area End

import ChannelsController, { Props } from "./ChannelsController.web";

import { Divider } from "react-native-elements";
import { channel } from "diagnostics_channel";


export const Unch = ({ ...other }) => {
  return <img src={unchecked} alt="images" width="24px" height="24px" />;
};
export const Che = ({ ...other }) => {
  return <img src={checked} alt="images" width="24px" height="24px" />;
};


const BannerAdsRedAsterisk = (
  <span
    style={{
      color: "#000000",
      fontSize: "16px",
      alignItems: "center",
      fontFamily: "Panton Narrow Regular",
      fontWeight: 400,
      display: "flex",
    }}
  >
    Banner Ads <span style={{ color: "red" }}>*</span>
    <img src={bannerad} style={{ marginLeft: "20px" }} />
  </span>
);

const PrintAdsRedAsterisk = (
  <span
    style={{
      color: "#000000",
      display: "flex",
      fontSize: "16px",
      alignItems: "center",
      fontWeight: 400,
      fontFamily: "Panton Narrow Regular",
    }}
  >
    Print Ads <span style={{ color: "red" }}>*</span>
    <img src={printads} style={{ marginLeft: "20px" }} />
  </span>
);


const SearchAdsRedAsterisk = (
  <span
    style={{
      display: "flex",
      color: "#000000",
      fontWeight: 400,
      alignItems: "center",
      fontSize: "16px",
      fontFamily: "Panton Narrow Regular",
    }}
  >
    Search Ads <span style={{ color: "red" }}>*</span>
    <img src={google} style={{ marginLeft: "20px" }} />
  </span>
);

const accordionSummaryStyle = {
  transition: "border 0.3s ease",
  borderRadius: "8px",
  border: "none",
};

export class Channels extends ChannelsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  //istanbul ignore next
  renderTooltip = () => {
    const BoxView = Box as any;
    const maxCharacters = 202;
    const truncatedText = this.state.channelData?.body ? this.state.channelData.body.substring(0, maxCharacters) : '';
    const shouldShowReadMore = this.state.channelData?.body && this.state.channelData.body.length > maxCharacters;

    return (
      <BoxView testID="readMoreText">
        <Typography style={{
          marginTop: "20px",
          lineHeight: '19.38px',
          wordBreak: 'break-all',
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: 'panton narrow regular'
        }} >
          {truncatedText}{" "}
          {shouldShowReadMore && (
            <span style={webStyle.readMore} onClick={this.toggleModal}>
              {this.state.channelData?.read_more}
            </span>
          )}
        </Typography>
      </BoxView>
    )
  }


  // Customizable Area End
  //istanbul ignore next
  render() {
    const BoxView = Box as any;
    const screenWidth = window.innerWidth;
    const { classes }:any = this.props;
    const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as any;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar navigation={this.props.navigation} onDashboardSave={this.onDashboardSave}>
          <BoxView style={{ ...webStyle.scrollableBox }}>
            <Custom4BoxView>
              <CustomBoxViewleftBox>
                 <BoxView className={classes.mainBox}>
                  <Grid container >
                    <Grid item sm={10} xs={12}>
                      <Typography className={classes.lables}>
                        <span style={{display:'flex'}}>

                      
                      {this.state?.channelHeadingTooltip?.data?.name}
                        <WhiteOnBlackTooltip
                        style={{width:'full'}}
                        arrow={true}
                        TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state?.channelHeadingTooltip?.data?.description}
                          open={this.state.tooltipOpenchannelHeadingTooltip}
                          onClose={this.handleTooltipCloseChanelsData}
                        >
                          <img
                            src={info}
                            style={{ marginLeft: "15px" }}
                            data-test-id='budgetHeading'
                            alt="Info"
                            onMouseLeave={()=>this.handleHeadingMouseOver()}
                            onMouseEnter={()=>this.handleHeadingMouseHover()}
                             onClick={() => this.handleHeading()}                           />
                        </WhiteOnBlackTooltip>
                        </span>
                      </Typography>
                        {this.state.channelsList?.map(
                        (channel: any, index: any) => (
                        
                          <CustomAccordion
                          className="horigentallLine"
                            key={channel.channel_id}
                            elevation={0}
                            expanded={this.state.expandedPanel === `panel${channel.channel_id}`}
                             onChange={this.handleChangePanel(`panel${channel.channel_id}`)}
                          >
                            <AccordionSummary
                            
                              id={`panel${channel.channel_id}-header`}
                              aria-controls={`panel${channel.channel_id}-content`}
                              expandIcon={<img src={expandIcon} />}
                              style={{
                                ...accordionSummaryStyle,
                                ...(this.state.expandedPanel === `panel${channel.channel_id}` && {
                                  border: "1px solid var(--Neutrals-Cool-gray-500, #B4B4B4)",
                                  paddingLeft:'30px',
                                }),
                              }}
                            >
                              <FormControlLabel
                                control={
                                  
                                  <Checkbox
                                    className={classes.checkBox}
                                    style={{
                                      pointerEvents: channel.mandatory ? 'auto' : 'none',
                                      cursor: channel.mandatory ? 'default' : 'pointer',
                                      paddingLeft:2,
                                     
                                    }}
                                    icon={<Unch />}
                                    disabled={this.state.viewData}
                                    checkedIcon={<Che />}
                                    defaultChecked={true}
                                    checked={
                                        this.state.channelDataiD.includes(channel.channel_id)
                                    }
                                    onChange={(event:any) => this.handleCheckboxChange(channel, event)}
                                  />
                                }
                               
                                label={
                                  <span className={classes.labelStyle}>
                                    <div className={classes.chanelsName}>{channel.channel_name}{channel.mandatory && <span style={{ color: "red" }}>*</span>}</div>
                                      <div className={classes.subChanels}>
                                  <div className={classes.subChanes} >
                                  {channel.sub_channels.map((subChannel:SubChannel, index:any) => (
                                 ((screenWidth >= 650) || (screenWidth >= 390 && index < 3) || (screenWidth < 390 && index < 2)) && (
                                  this.state.expandedPanel === `panel${channel.channel_id}` ? null : (
                                <img
                                key={subChannel.id}
                                src={subChannel.photo}
                                 alt="Channel Image"
                                  //  style={{ marginBottom: '2px' }}
                                   />
                                   )
                                 )
                                  ))}
                                </div>
                                  </div>
                                  </span>
                                }
                              />
                            </AccordionSummary>
                            <AccordionDetails
                              style={{ position: "relative", padding: 0,  }}
                            >
                              <div style={webStyle.serch}>
                                <div style={{ padding: 8 }}>
                                  {channel.sub_channels.map(
                                    (subChannel: any) => (
                                      <div key={subChannel.id} style={{ cursor: 'alias' }}>
                                        <MenuItem style={webStyle.Items}>
                                          <img
                                          className={classes.subChannelImg}
                                           
                                            src={subChannel.photo}
                                            alt=""
                                          />
                                          {subChannel.name}
                                        </MenuItem>
                                        {index <
                                          this.state.channelsList.length -
                                            1 && (
                                          <Divider
                                            style={{
                                              backgroundColor: "#F8FAFC",
                                            }}
                                          />
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </AccordionDetails>
                          </CustomAccordion>
                        )
                      )}
                    </Grid>
                  </Grid>
                </BoxView> 
              </CustomBoxViewleftBox>
            
              < CustomBoxViewRight>
                <Custom2BoxView sx={webStyle.subcontainer} >
                  <BoxView className={classes.subBox}>

                    <Typography style={webStyle.title}>
                      <span>
                          {this.state.channelData?.heading}
                        </span>
                        <span>
                        <WhiteOnBlackTooltipView
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.channelData?.tooltip}
                          open={this.state.tooltipOpenChanelsDatas}
                          onClose={this.handleTooltipCloseChanelsDatas}
                        >
                          <img src={info}
                          data-test-id='chanelsData'
                           onMouseLeave={()=>this.handlechannelDataMouseOver()}
                          onMouseEnter={()=>this.handlechannelDataMouseHover()}
                           onClick={() => this.handlechannelData()}                     
                           alt="Info" style={{height: "13px", width: "13px" }} />
                        </WhiteOnBlackTooltipView>
                        </span>
                      </Typography>
                    <img style={webStyle.img} src={this.state.channelData?.image_url} />
                    {this.renderTooltip()}
                    <Modal
                          open={this.state.isModalOpen}
                          onClose={this.toggleModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          BackdropProps={{
                            style: {
                              backgroundColor: 'transparent',
                            }
                          }}
                        >
                        <BoxView className={classes.channelModalStyle} >
                            <div className={classes.channelModalStyles}>
                                <Typography  className={classes.bodyText}
                                style={{ wordBreak: 'break-all', flex: '1' }}>
                                    {this.state.channelData?.body}
                                </Typography>
                                <div className={classes.channelCancelImageWrapper} >
                                    <img src={cancelImage} alt="Close" className={classes.channelCancelImage} onClick={this.toggleModal} />
                                </div>
                            </div>
                        </BoxView>
                        </Modal>
                  </BoxView>
                  <CustomBoxViewButton >
                  <ButtonContainer
                variant="contained"
                disabled={this.state.viewData}
                data-test-id="Quote_page"
                onClick={this.handleQuote}
              >
                {this.state.select_lang.name[0]}
              </ButtonContainer>
              <ButtonContainer variant="contained" data-test-id="channels_page"
              disabled={this.state.viewData}
              onClick={this.handleLocationNav}>
                {this.state.select_lang.name[1]}
              </ButtonContainer>
            </CustomBoxViewButton>
                  </Custom2BoxView>
                  </ CustomBoxViewRight>
            </Custom4BoxView>
          </BoxView>
        </Sidebar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonContainer :any = styled(Button)({
  width: "176px",
  borderRadius: "43px",
  color: "#353232",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  textTransform: "capitalize" as const,
  boxShadow: "none",
  "&:hover": { 
    backgroundColor: "#005E6E",
    color: "#FFFFFF",
    boxShadow: "none",
  },
  '@media(max-width:500px)':{
    width:'176px'
  },
  '@media(max-width:390px)':{
    width:'167px'
  },
  '@media(max-width:350px)':{
    width:'143px'
  }
});
const webStyle = {
  readMore: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "14px",
    fontWeight: 700,
    color: "#005E6E",
    cursor:"pointer",
  },
  Items: {
    color: "black",
    fontFamily: "Panton Narrow Regular",
    fontSize: "16px",
    padding: "16px 0px 18px 15px",
    gap: "17px",
    cursor: 'alias',
    "& :hover": {
      backgroundColor: "transparent",
     
    },
  },
  mainBox: {
    backgroundColor: "#FFFFFF",
    // minHeight:'850px',
    boxShadow: "0px 4px 16px 0px #00000040",
    padding: "24px 39px",
    borderRadius: "38px",
    overflow: "auto",
     height: "calc(100vh - 110px)",
    "@media (max-width: 600px)": {
      height:"auto",
    },
    "& .Mui-expanded": {
      transition: "border 0.3s ease-in-out, border-radius 0.3s ease-in-out",
    },
    "&$expanded": {
      margin: 0,
      color: "red",
    },
    "& .MuiAccordionSummary": {
      margin: 0,
      minHeight: "0px",
    },
  },
  subcontainer: {
    
    // height: "calc(100vh - 100px)",
    height:'96%',
    marginLeft:"16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width: 1370px)": {
      height:'96%',
    },
    "@media (max-width: 960px)": {
      padding: "0px",
      width: "100%",
      paddingBottom: "36px",
      marginLeft:'0px',
      height:'unset'
    },
    "@media (max-width: 500px)": {
      paddingBottom: "unset !important",
    }

      
  },
  
  labelStyle: {
    fontFamily: "Panton Narrow Regular",
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    display: "flex",
    gap: "16px",
    width: "100%",
    minWidth: "80px",
  },
  
  lable: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    marginBottom: "40px",
  },
  subChannelImg: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: '1px solid lightgray'
  },
  img: {
    width:'100%',
    height:'164px',
  },
  title: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Panton Narrow Regular",
    marginBottom: "20px",
    display:"flex",
    alignItems:"center",
    gap:'12px'
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end !important",
    alignItems: "center",
    gap:"60px !important",
    "@media (max-width: 1250px)": {
      width:"100%",
      gap:"40px",
      
    },
  },
  scrollableBox: {
    overflowY: "auto" as "auto",
    height: "100%",
  },
  button: {
    width: "194px",
    borderRadius: "43px",
    backgroundColor: "#005E6E",
    color: "#FFFFFF",
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    height: "52px",
    fontSize: "16px",
    textTransform: "capitalize" as const,
  },
  serch: {
    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    zIndex: 999,
    width: "100%",
    background: "#FFFFFF",
  }
  /* YourComponent.css */
};
export const useStyle = (theme: Theme) =>
  createStyles({ 
    mainBox: {
      backgroundColor: "#FFFFFF",
       minHeight:'850px',
      boxShadow: "0px 4px 16px 0px #00000040",
      padding: "27px 41px",
      borderRadius: "32px",
      overflow: "auto",
      marginBottom:'40px',
       height: "calc(100vh - 134px)",
      //  marginBottom:'40px',
      "@media (max-width: 500px)": {
        boxShadow:'none',
        padding:'unset',
        height:'unset',
        borderRadius: 'unset',
        minHeight:'unset',
        
      },
      "@media (max-width: 960px)": {
        // minHeight:'410px !important',
         height:'unset',
       },
      "& .Mui-expanded": {
        transition: "border 0.3s ease-in-out, border-radius 0.3s ease-in-out",
      },
      "&$expanded": {
        margin: 0,
        color: "red",
      },
      "& .MuiAccordionSummary": {
        margin: 0,
        minHeight: "0px",
      },
    },
    channelModalStyle: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: '50%',
      transform: 'translate(-50%, -50%)',
      width: '573px',
      height: 'auto',
      borderRadius: '12px',
      background: '#FFFFFF',
      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
     
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: '80vh',
      '@media(max-width:500px)':{
        width: '290px',
       
        top: '52%',
        overflowY: 'auto',
        
      }
    },
    bodyText: {
      flex: 1,
      fontFamily: 'panton narrow regular',
      fontSize: '16px',
      lineHeight: '19.38px',
      fontWeight: 400,
      padding:'48px',
     
    },
    channelModalStyles:{
      display: 'flex',
       justifyContent: 'space-between',
       "@media(max-width:500px)":{
        display: 'unset',
        justifyContent:'unset',
       }
    },
    channelCancelImageWrapper: {
      alignSelf: 'flex-start',
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
      position:"absolute",
      top:'18px',
      right:"18px"
    },
   channelCancelImage: {
      cursor: 'pointer',
      width: '10.96px',
      height: '11px',
      marginLeft:'15px',
    },
    lables: {
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
      lineHeight:'32px',
      letter:'-0.5%',
      marginBottom:"26px",
      "@media(max-width:500px)":{
        fontSize: "16px !important",
        lineHeight:'26px',
        marginTop:'24px',
        marginLeft:'7px',
      }
    },
    subChannelImg: {
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      border: '1px solid lightgray',
      "@media(max-width:500px)":{
        width: "22px",
       height: "22px",
      }
    },
    chanelsName:{
    display:"flex",
    gap:"5px",
    alignItems: 'center',
    cursor: 'alias',
    "@media(max-width:500px)":{
      gap:"2px",
      fontSize:'14px',
    }
    },
    labelStyle: {
      fontFamily: "Panton Narrow Regular",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      display: "flex",
       gap: "16px",
      width: "100%",
      minWidth: "60px",
      marginLeft:7
    },
    subChanes:{
      display: 'flex',
       alignItems: 'center',
        gap: 16,
        cursor:'alias',
        '@media(max-width:380px)':{
          gap: 6, 
        },
    },
    subChanels:{
      display: "flex",
       gap: "16px",
       cursor: 'alias',
       "@media(max-width:500px)":{
        gap: "10px",
       },
    },
    subBox: {
      backgroundColor: "#FFFFFF",
        height: "auto",
        boxShadow: "0px 4px 16px 0px #00000040",
        padding: "34px 29.4px 24px 24px",
        borderRadius: "24px",
        '@media (min-width: 500px)': {
          marginLeft:'1px !important'
        },
        '@media (max-width: 500px)': {
          // display:'none',
          height: "unset",
        },
    },
   })
export default withRouter(withStyles(useStyle)(Channels as any) as any) as any;
// Customizable Area End
