import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { testNavi } from "../../../../packages/blocks/utilities/src/CommonUtilities";
const Data = [0, 1, 2, 3];
import moment from 'moment';
import { logoutUser } from "../../../framework/src/Utilities";
const options = [
   "Water supply",
  "Logistics",
  "Food",
];

type CustomMouseEvent = {
  currentTarget: {
    style: {
      color: string;
      fontWeight: string;
    };
  };
};

const location = [
  "Regional [25-50 km]",
  "National [Entire Switzerland]",
  "Local [0-25 km]",
];

interface IndustryItem  {
  localsearch_rubrikname_english: string;
  id: number;
  localsearch_schema_category_110: string;
}


interface Body {  
  data:EditBody
}
export interface ProjectNote {
  type: string;
  id: string;
  attributes: {
    note_content: string;
    created_date: Date;
    is_deleted: boolean;
    id: string;
    is_updated: boolean;
    created_at: Date;
    updated_at: Date;
    created_time: string;
    reference_id: number;
  }
}
type MouseEvent ={
  currentTarget: {
    style: {
      backgroundColor: string;
      color: string;
    };
  };
}
interface ApiData {
  body?: Body | EditBody;
  type?: string;
  contentType: string;
  method: string;
  endPoint: string;
}
interface EditBody {
  [key: string]: string | number[]  | number  
}
interface CampaignDatas {
  data: {
    type: string;
    id: string;
    attributes: {
      objective_id: number;
      budget: number | null;
      radius: number | null;
      duration: number | null;
      created_at: string;
      updated_at: string;
      company_name: string;
      industry: string;
      location: string;
      status: string;
      user_photo_link: string | null;
      user: string | null;
    };
  };
}
interface RadiusDataItem {
  attributes: {
    name: string;
    id: number;
  };
  id: string;
}

interface ApiResponse {
  data: {
    name: string;
    id: number;
    description: string;
    duration: number;
  }[];
}

interface ResponseData {
  data: Objective[];
}
interface Objective {
  name: string;
  duration: number;
  id: number;
  description: string;
  photo_url:null;
  clicked: boolean,
  tool_tip:string,
 
}

interface SidebarProps {
  navigation: any;
  nextPage: () => void;
}

interface ConfiurationContentData{
  data:{
    tooltip: string,
    heading: string,
    body: string,
    image_url: string
  }
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation:any;
  id: string;
  // Customizable Area Start
  selectedRange:string
   history:any;
  // nextPage:SidebarProps
  // Customizable Area End
}


interface S {
  getConfigurationContentCallID:string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchResults:[],
  results: string[],
  anchorEl: null | EventTarget,
  objectivesDetails: Objective[],
  selectedRange: string;
  showFullText:boolean;
  isComponentOpens:boolean;
  searchText:string;
  campaignDetails:object,
  radiusData: RadiusDataItem[],
  error: string | null,
  isChecked : boolean,
  startDate:any,
  history:string,
  datePickerIsOpen:boolean,
  textediterIsOpen:boolean,
  quillValue: string,
  originalValue: string,
  diplayTime:boolean,
  options:string[],
  dropdownVisible: boolean,
  hoverIndex:null,
  open:string,
  campaing:{
   companyName: string,
  industry: string,
  location: string,
  selectedObjective: null | number,
  },
  displayedIndustry: string,
  industryData: IndustryItem[],
  selected:any,
  projectNotesTime:string,
  projectNotesDate:string,
  displaySapn:boolean,
  configurationData: any,
  isModalOpen: boolean,
  confguration:object,
  configurationsData:any,
  menuItems:any,
  errors:any,
  CampaignLanguage:any
  configurationHeadingTooltip:any
  configurationStartdate:any
  configurationDuration:any
  configurationCampaignLanguage:any
  configurationDestinationUrl:any
  configurationProjectNotes:any
  select_lang:any,
  error_lang: any,
  tooltipOpen: boolean,
  tooltipTitle: string,
  tooltipOpenProjectNotes:boolean,
  tooltipTitleProjectNotes:string,
  tooltipOpenConfigurationDate:boolean,
      tooltipTitleConfigurationDate:string,
  tooltipOpenConfigurationLanguage:boolean,
  tooltipTitleConfigurationLangauage:string,
  tooltipOpenConfigurationDuration:boolean,
  tooltipTitleConfigurationDuration:string,
  tooltipOpenLanguage:boolean,
  tooltipOpenConfigurationName:boolean,
  tooltipTitleConfigurationName:string,
  tooltipOpenConfigurationHeading:boolean,
   tooltipTitleConfigurationHeading:string,
  tooltipOpenConfigurationData:boolean,
  tooltipTitleObjectiveDatas:string,
  selectedDuration:any
  isDisabled:boolean
  InputDisabled:boolean
  selectedLanguage:any,
  passConfiguration:boolean,
  viewData:boolean,
  hasErrorinQuill: boolean,
  configura:boolean,
  configureApiVariable:any,
  initialConfVisit:boolean,
 updateChecked:boolean,
 visitedArrayKey:any,
 loaderTrue:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  location:any
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}



export default class SetUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getObjectiveCallIds: string = ""
  getRadiusCallIds:string=""
  getSerchIndustryCallId:string =""
  postCreateCampaingCallID:string = ""
  createProjectNotesApiCallId: string = "";
  getCampaignLanguageCallIds:string = ""
  updateCampaignMessageId:string = ""
  getConfigurationHeadingTooltipCallID:string=""
  getStartDateCallID:string=""
  getDurationCallID:string=""
  getCampaignLanguageCallID:string=""
  getDestinationUrlCallID:string=""
  getProjectNotesCallID:string=""
  getCampaignConfigurationCallID:string=""
  putLocationToBUdgetCallID:string=""
  getCampaignDurationCallID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.handleChange = this.handleChange.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this)
    this.state = {
      getConfigurationContentCallID:"",
      enableField: false,
      txtSavedValue: "A",
      txtInputValue: "",
      // Customizable Area Start
      results: [],
      searchResults:[],
      displaySapn:true,
      searchText:"",
      hoverIndex:null,
      selected:"",
      open:"",
      campaing:{
        location: "",
        selectedObjective: null,
        companyName: "",
        industry: "",
      },
      dropdownVisible: false,
      diplayTime:false,
      selectedRange: "0-25",
      objectivesDetails:[] as Objective[],
      isChecked: false,
      startDate: "",
      datePickerIsOpen:false,
      textediterIsOpen:false,
      quillValue: "",
      originalValue: "",
      isComponentOpens:false,
      anchorEl: null,
      showFullText: false,
      error: null,
      history:"",
      industryData: [],
      radiusData: [] ,
      options : [
        "Logistics",
        "Food",
        "Water supply",
      ],
      displayedIndustry: '',
      campaignDetails:{},
      projectNotesTime: moment(new Date()).format('DD/MM/YYYY'),
      projectNotesDate:"",
      configurationData:{},
      isModalOpen: false,
      confguration:{},
      configurationsData:{
        duration:"",
        destinationURL : "",
        startDate:"",
        language:""
      },
      errors: {
        startDate: "",
        duration: "",
        destinationURL: "",
        quillValue:"",
        language:""
      },
      menuItems: [
        { label: "3 ", value: 3 },
        { label: "6 ", value: 6 },
        { label: "9 ", value: 9 },
        { label: "12 ", value: 12 },
      ],
      CampaignLanguage:[],
      configurationHeadingTooltip:{},
      configurationStartdate:{},
      configurationDuration:{},
      configurationCampaignLanguage:{},
      configurationDestinationUrl:{},
      configurationProjectNotes:{},
      select_lang: {name: []},
      error_lang: {error: []},
      tooltipOpen: false,
      tooltipTitle: '',
      tooltipOpenProjectNotes:false,
      tooltipTitleProjectNotes:'',
      tooltipOpenConfigurationDate:false,
      tooltipTitleConfigurationDate:'',
      tooltipOpenConfigurationLanguage:false,
      tooltipTitleConfigurationLangauage:'',
      tooltipOpenConfigurationDuration:false,
      tooltipTitleConfigurationDuration:'',
      tooltipOpenConfigurationName:false,
      tooltipOpenLanguage:false,
      tooltipTitleConfigurationName:'',
      tooltipOpenConfigurationHeading:false,
      tooltipTitleConfigurationHeading:'',
      tooltipOpenConfigurationData:false,
      tooltipTitleObjectiveDatas:'',
      selectedDuration:null,
      isDisabled:false,
      InputDisabled:false,
      selectedLanguage:"",
      passConfiguration:false,
      viewData:false,
      hasErrorinQuill: false,


      configura:false,
      configureApiVariable:{},
      initialConfVisit:false,
      updateChecked:false,
      visitedArrayKey:[],
      loaderTrue:false
      // Customizable Area End
    };
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

async receive(from: string, message: Message) {
  if (!this.isValidResponseMessages(message)) {
      return;
  }

const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

if (!apiRequestCallId || !responseJson) {
  return;
}

if (this.isAuthenticationFailures(responseJson)) {
  this.handleAuthenticationFailure();
  return;
}

this.handleApiResponse(apiRequestCallId, responseJson);
runEngine.debugLog("Message Received", message);
}

isValidResponseMessages(message: Message): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
}

isAuthenticationFailures(responseJson: any): boolean {
    return responseJson.statusCode === 401;
}

handleAuthenticationFailure() {
    logoutUser();
    this.props.history.push("/EmailAccountLoginBlock");
}

handleApiResponse(apiRequestCallId: string, responseJson: any) {

    const handlers = {
        [this.state.getConfigurationContentCallID]: this.getConfigurationContentReceiver,
        [this.postCreateCampaingCallID]: this.postCreateCampaingReceiver,
        [this.updateCampaignMessageId]: this.ConfigurationReceiver,
        [this.getConfigurationHeadingTooltipCallID]: this.getConfigurationHeadingReceiver,
        [this.getStartDateCallID]: this.getStartDateReceiver,
        [this.getDurationCallID]: this.getDurationReceiver,
        [this.getCampaignLanguageCallID]: this.getCampaignLanguageReceiver,
        [this.getDestinationUrlCallID]: this.getDestinationUrlReceiver,
        [this.getProjectNotesCallID]: this.getProjectNotesReceiver,
        [this.createProjectNotesApiCallId]: this.handleCreateProjectNotesResponse,
        [this.getCampaignLanguageCallIds]: this.CampaignLanguageReceiver,
        [this.putLocationToBUdgetCallID]:this.updateReceiver

    };

    if(this.getCampaignConfigurationCallID === apiRequestCallId)
    {
      const text = this.getTextFromHTML(responseJson.project_notes);

      this.setState((prevState) => ({
        configurationsData: {
          ...prevState.configurationsData,
          language: responseJson.language && responseJson.language.charAt(0).toLowerCase() + responseJson.language.slice(1),
          startDate:responseJson.project_notes && responseJson.start_date,
          destinationURL:responseJson.destination_url,
          duration:responseJson.duration
        
        }
      ,quillValue:responseJson.project_notes}));
      if(responseJson.destination_url==="true"){
        this.setState({isChecked:true,InputDisabled:true})
      }
      const visitedSetup=localStorage.getItem("visitedConfigure");
      const persistId=localStorage.getItem("persistId");
      if(visitedSetup || persistId){
        let key=responseJson.visited_key;
        if(responseJson.campaign.attributes.status ==="Completed"){
         key = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
        }else if(!key.includes(responseJson.active_page)){
           key.push(responseJson.active_page)
      }
        let CampaignUpdate =  {
         "campaign": {
           "active_page":"configuration",
           "visited_key":key
         },
         "progress": {
          "current_page": "configration"
      }
     }  
        this.setState({configureApiVariable:CampaignUpdate,initialConfVisit:true},()=>{
          
          this.putConfigurationApi()
        })
     }
    }
    if(this.getCampaignDurationCallID===apiRequestCallId){
      this.setState((prevState) => ({
        configurationsData: {
          ...prevState.configurationsData,
          duration:responseJson.duration  
        }}));
    }
    const handler : any= handlers[apiRequestCallId];
    if (handler && responseJson) {
      
        handler.call(this, responseJson);
    }
}
updateReceiver=()=>{
  const navigate= localStorage.getItem("navigate")
  if(this.state.initialConfVisit){
    this.send(testNavi(this.props))
  }
  if(this.state.updateChecked){
    this.setState({loaderTrue:true})
  }
 if(navigate){
  this.props.history.push("/Dashboard")
 }
}

handleCreateProjectNotesResponse(responseJson: any) {

    this.setState({
        projectNotesTime: responseJson.data.attributes.created_time,
        projectNotesDate: responseJson.data.attributes.created_date
    })
}


    // Customizable Area Start
  //istanbul ignore next
    async componentDidMount(): Promise<void> {
      document.body.addEventListener('click', this.handleScreenClick);
      const viewOption:any=localStorage.getItem("visitedView")
      const showOption:any=localStorage.getItem("showData")
      this.getCampaignDurationAPi();
      if(viewOption && showOption==="true"){
        this.setState({viewData:true})
      }
      const visitedSetup=localStorage.getItem("visitedConfigure");
      const persistId=localStorage.getItem("persistId");
      if(visitedSetup || persistId){
        this.getCampaignConfigurationData();
      }
      const lang : any = {
        german: {
          name:["TT - MM - JJJJ", "X Monate", "Wählen Sie eine Sprache","Geben Sie eine Ziel-URL ein", "Überprüfen Sie, ob keine Website vorhanden ist", "Geben Sie Notizen zur Kampagne ein", "Monate", "Zitat", "Nächste"],
        },
        french: {
          name:["JJ - MM - AAAA", "X mois","Sélectionnez une langue", "Saisissez une URL de destination", "Vérifiez si aucun site Web n'existe", "Saisir des notes sur la campagne", "mois", "Citation"],
        },
        italian: {
          name:["GG - MM - AAAA", "X mesi", "Selezionare una lingua", "Inserisci un URL di destinazione", "Controlla se non esiste un sito web", "Inserisci note sulla campagna","mesi","Citazione"],
        },
      };

      const errorLang : any = {
        german: {
                 name:['Bitte füllen Sie die erforderlichen Angaben aus'],
                },
        french: {
                 name:['Veuillez remplir les informations requises'],
                },
        italian: {
                 name:['Si prega di compilare le informazioni richieste'],
                },
      };

    


     const selectedCountry = localStorage.getItem("selectedCountry");
     this.setState((prevState) => ({
      configurationsData: {
        ...prevState.configurationsData,
        language: selectedCountry
      },
    }));

     if (selectedCountry) {
      this.setState((prevState) => ({
        configurationsData: {
          ...prevState.configurationsData,
          language: selectedCountry
        },
      }));
    }
    
  
      const selected_Language = lang[selectedCountry as any] || { name: []};
      this.setState({select_lang:selected_Language})


    const selectedLangCountry = localStorage.getItem("selectedCountry");

    const selected_Language_error = errorLang[selectedLangCountry as any] || { name: []};
    this.setState({error_lang:selected_Language_error})

    await Promise.all([this.getObjectiveCallDetail(),this.getRadiusDetails(),this.getSerchIndustryDetails(),this.getConfigurationContentDetails()
    ,this.getConfigurationlHeadingTooltip(), this.getStartDateTooltip(), this.getDurationTooltip(), this.getCampaignLanguageTooltip(),
    this.getDestinationUrlTooltip(), this.getProjectNotesTooltip(),this.getCampaignLanguageDetail()
    ]);    
  }

  getObjectiveReceiver = (responseJson:ResponseData) => {
    this.setState({
      objectivesDetails: responseJson.data,
    });
  }
    getObjectiveCallDetail = async (): Promise<void> => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
       };

       const requestMessageList = new Message(
           getName(MessageEnum.RestAPIRequestMessage)
       );
   
       this.getObjectiveCallIds = requestMessageList.messageId;
   
       requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
       );

       requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getObjectiveApiEndPOint
       );
   
       requestMessageList.addData(
           getName(MessageEnum.RestAPIRequestHeaderMessage),
           JSON.stringify(header)
       );
   
       runEngine.sendMessage(requestMessageList.id, requestMessageList);
   }
  onDashboardSave=async()=>{
    if(this.state.updateChecked){
      const { startDate } = this.state.configurationsData;
      const dateObject = new Date(startDate);
      dateObject.setHours(dateObject.getHours() + 5);
      dateObject.setMinutes(dateObject.getMinutes() + 30);
      
          let formDataCampaign = {
            "campaign": {
                "start_date":dateObject,
                "duration" : this.state.configurationsData.duration,
                "language": this.state.configurationsData.language.charAt(0).toUpperCase() + this.state.configurationsData.language.slice(1).toLowerCase(),
                "destination_url":this.state.configurationsData.destinationURL,
      
                },
            "progress": {
                "current_page": "configration"
            }
        } 
          this.setState({configureApiVariable:formDataCampaign},()=>{
            this.putConfigurationApi()
          })
          await this.createNoteForProject("", "");
    }
    if(!this.state.updateChecked){
  this.props.history.push("/Dashboard")
    }
  }
   putConfigurationApi = () => {
     const persistId=localStorage.getItem("persistId")
    const campaignId = localStorage.getItem("campaignID")
    const rowID=persistId?persistId:campaignId
    const authToken = localStorage.getItem('accessToken')
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.putCampaingChannelsSection}/${rowID}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(this.state.configureApiVariable)
    );
    this.putLocationToBUdgetCallID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

}

   getCampaignLanguageDetail = async (): Promise<void> => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };

     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.getCampaignLanguageCallIds = requestMessageList.messageId;
 
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );

     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_language_options/campaign_languages"
     );
 
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
 
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }

 CampaignLanguageReceiver=(responseJson:any)=>{
  
  if(!responseJson.error){
    this.setState({CampaignLanguage:responseJson.data})
  }

 }



// ----------Camping reciver -----------------
postCreateCampaingReceiver=(responseJson:CampaignDatas)=>{
  this.setState({
    campaignDetails: responseJson.data,
  })
}
  getSerchIndustryDetails = async (): Promise<void> => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };

     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.getSerchIndustryCallId = requestMessageList.messageId;
 
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSerchIndustryEndPoint}?query=${this.state.campaing.industry}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }
 getCampaignDurationAPi=()=>{

  const rowId = localStorage.getItem("persistId")
  const campaignID = localStorage.getItem("campaignID")
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getCampaignDurationCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );
   requestMessageList.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);
   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.ConfigurationDataEndPoint}${rowId?rowId:campaignID}`
   );
   runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }
 getCampaignConfigurationData=()=>{

  const rowId = localStorage.getItem("persistId")
  const campaignID = localStorage.getItem("campaignID")
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getCampaignConfigurationCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );
   requestMessageList.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);
   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.ConfigurationDataEndPoint}${rowId?rowId:campaignID}`
   );
   runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }
getRadiusDetails = async (): Promise<void> => {
  const Header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getRadiusCallIds = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(Header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

getRadiusReceiver=(responseJson:any)=>{
 
  this.setState({
    radiusData: responseJson.data
  })
}
    // Customizable Area End

  // web events
  
  // Customizable Area Start
 hideDropdown = () => {
    this.setState({ dropdownVisible: false});
  }
  displaytime=()=>{
    this.setState({ diplayTime: true});

  }
  showDropdown = () => {
    this.setState({ dropdownVisible: true});
  }; 
  handleTooltipToggleConfigurationData = () => {
    this.setState({ tooltipOpenConfigurationData: !this.state.tooltipOpenConfigurationData });
  };
  
  handleTooltipCloseConfigurationData = () => {
    this.setState({ tooltipOpenConfigurationData: false });
  };
  
  handleTooltipOpenConfigurationData = (title: string) => {
    this.setState({ tooltipTitleObjectiveDatas: title, tooltipOpenConfigurationData: true });
  }; 
  handleTooltipToggleConfigurationDate = () => {
    this.setState({ tooltipOpenConfigurationDate: !this.state.tooltipOpenConfigurationDate });
  };
  
  handleTooltipCloseConfigurationDate = () => {
    this.setState({ tooltipOpenConfigurationDate: false });
  };
  
  handleTooltipOpenConfigurationDate = (title: string) => {
    this.setState({ tooltipTitleConfigurationDate: title, tooltipOpenConfigurationDate: true });
  };
  handleTooltipToggleConfigurationHeading = () => {
    this.setState({ tooltipOpenConfigurationHeading: !this.state.tooltipOpenConfigurationHeading });
  };
  handleImageImageMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationHeading:true});
    }
  };
   handleImageImageMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationHeading:false});}
  }
   handleMouse=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenConfigurationHeading:!this.state.tooltipOpenConfigurationHeading});
    }
  }
  handleConfigurationDateMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationDate:true});
    }
  };
   handleConfigurationDateMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationDate:false});}
  }
   handleConfigurationDate=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenConfigurationDate:!this.state.tooltipOpenConfigurationDate});
    }
  }
  handleconfigurationDurationMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationName:true});
    }
  };
   handleconfigurationDurationMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationName:false});}
  }
   handleconfigurationDuration=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenConfigurationName:!this.state.tooltipOpenConfigurationName});
    }
  }

  handleconfigurationLangaugeMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenLanguage:true});
    }
  };
   handleconfigurationLangaugeMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenLanguage:false});}
  }
   handleconfigurationLangauge=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenLanguage:!this.state.tooltipOpenLanguage});
    }
  }
  updateConfigNavigation=()=>{
    const visitedConfigure=localStorage.getItem("visitedConfigure");
    const  persistId= localStorage.getItem("persistId")
    if((visitedConfigure|| persistId) && !this.state.loaderTrue){
    let activeItemss = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
    let index_a = activeItemss.indexOf('configuration');
    let activeItemsTrimmed = activeItemss.slice(0, index_a + 1);
    let CampaignUpdate =  {
      "campaign": {
        "visited_key":activeItemsTrimmed,
        "active_page":"configuration"
      },
      "progress": {
       "current_page": "configration"
   }
  } 
    this.setState({configureApiVariable:CampaignUpdate,initialConfVisit:true},()=>{
      this.putConfigurationApi()
    })
  }
  }
  handleDestinationUrlMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationDuration:true});
    }
  };
   handleDestinationUrlMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationDuration:false});}
  }
   handleDestinationUrl=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenConfigurationDuration:!this.state.tooltipOpenConfigurationDuration});
    }
  }
  handleProjectNotesMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenProjectNotes:true});
    }
  };
   handleProjectNotesMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenProjectNotes:false});}
  }
   handleProjectNote=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenProjectNotes:!this.state.tooltipOpenProjectNotes});
    }
  }
  handleconfigurationDataMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationData:true});
    }
  };
   handleconfigurationDataMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenConfigurationData:false});}
  }
   handleconfigurationData=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenConfigurationData:!this.state.tooltipOpenConfigurationData});
    }
  }
  handleTooltipCloseConfigurationHeading = () => {
    this.setState({ tooltipOpenConfigurationHeading: false });
  };
  
  handleTooltipOpenConfigurationHeading = (title: string) => {
    this.setState({ tooltipTitleConfigurationHeading: title, tooltipOpenConfigurationHeading: true });
  };
  handleTooltipToggleConfigurationName = () => {
    this.setState({ tooltipOpenConfigurationName: !this.state.tooltipOpenConfigurationName });
  };
  
  handleTooltipCloseConfigurationName = () => {
    this.setState({ tooltipOpenConfigurationName: false });
  };
  
  handleTooltipOpenConfigurationName = (title: string) => {
    this.setState({ tooltipTitleConfigurationName: title, tooltipOpenConfigurationName: true });
  };
  handleTooltipToggleConfigurationDuration= () => {
    this.setState({ tooltipOpenConfigurationDuration: !this.state.tooltipOpenConfigurationDuration });
  };
  
  handleTooltipCloseConfigurationDuration = () => {
    this.setState({ tooltipOpenConfigurationDuration: false });
  };
  
  handleTooltipOpenConfigurationDuration = (title: string) => {
    this.setState({ tooltipTitleConfigurationDuration: title, tooltipOpenConfigurationDuration: true });
  };
  handleTooltipToggleConfigurationLanguage= () => {
    this.setState({ tooltipOpenConfigurationLanguage: !this.state.tooltipOpenConfigurationLanguage });
  };
  
  handleTooltipCloseConfigurationLanguage = () => {
    this.setState({ tooltipOpenLanguage: false });
  };
  
  handleTooltipOpenConfigurationLanguage = (title: string) => {
    this.setState({ tooltipTitleConfigurationLangauage: title, tooltipOpenConfigurationLanguage: true });
  };
  handleTooltipToggleProjectNotes= () => {
    this.setState({ tooltipOpenProjectNotes: !this.state.tooltipOpenProjectNotes });
  };
  
  handleTooltipCloseProjectNotes = () => {
    this.setState({ tooltipOpenProjectNotes: false });
  };
  
  handleTooltipOpenProjectNotes = (title: string) => {
    this.setState({ tooltipTitleProjectNotes: title, tooltipOpenProjectNotes: true });
  };
  NextPage =()=>{
    this.setState({ isComponentOpens: true });
  }
  handleCheckboxChange = () => {
    this.setState({isChecked:!this.state.isChecked},()=>{
      if(this.state.isChecked ){
         this.setState(prevState => ({
            configurationsData: {
              ...prevState.configurationsData,
              destinationURL:"true"
            }}))
       this.setState({InputDisabled:true, errors: {...this.state.errors, destinationURL: ''}})
      }else{
        this.setState({InputDisabled:false})
        this.setState(prevState => ({
          configurationsData: {
            ...prevState.configurationsData,
            destinationURL:""
          }}))
      }
    });
    this.setState({updateChecked:true})
    this.updateConfigNavigation()
  }
  createNoteForProject = async (note:string,refrenceId: string) => {
    const rowId = localStorage.getItem("campaignID")
    const IdsUpdate=localStorage.getItem("rowId")
    const updatesConf=this.state.configura?IdsUpdate:rowId
    const body:any = {  
      data : {
        note:this.state.quillValue,
        campaign_id: updatesConf
      }
    }

    this.createProjectNotesApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.createNoteApiMethod,
      endPoint: "bx_block_projectnotes/notes",
      body:body
    })
  };
 
  openDatePicker() {
    if(!this.state.viewData){
      this.setState({
        datePickerIsOpen: !this.state.datePickerIsOpen,
      });
    }
  }
  handleSetDate = (date:any) => {

    this.setState({
      configurationsData: {
        ...this.state.configurationsData,
        startDate: date,
      },
      datePickerIsOpen: false

    });
    localStorage.setItem("configSelectDate", moment(date).format("DD-MM-YYYY"))
    localStorage.setItem("filledStartDate",date)
    this.setState({updateChecked:true})
    this.updateConfigNavigation()
  };
  handleProjectNotes=(value:any)=>{
    if(!this.state.viewData){
      this.setState({  textediterIsOpen: !this.state.textediterIsOpen, originalValue: this.state.quillValue });
    }
  }
  handleChanges=(date: any)=> {
    this.handleSetDate(date)  
    this.setState({ startDate: date });

  }

  handleScreenClick = () => {
    const currentDate = moment().format('YYYY/MM/DD');
    const currentTime = moment().format('HH:mm:ss');
    this.setState({
      projectNotesDate: currentDate,
      projectNotesTime: currentTime,
    });
  };
  
  handleSaveClick = async () => {
    localStorage.setItem("mycurrentPage","visitconfig")
    const { configurationsData } = this.state;
 
    let errors = {} as any;

    if (!configurationsData.startDate) {
      errors.startDate = this.state.error_lang?.name[0];
    }
    if ((!configurationsData.destinationURL || configurationsData.destinationURL=== undefined) && !this.state.isChecked) {
        errors.destinationURL = this.state.error_lang.name[0];
      this.setState({isDisabled:false})
    }
   

   if( this.state.quillValue===null ||!this.state.quillValue.trim() ||  this.state.quillValue.trim() === '<p><br></p>'){
      errors.quillValue = this.state.error_lang?.name[0];
    }

    if(this.state.configurationsData.destinationURL && this.state.configurationsData.destinationURL.trim() === '' && !this.state.isChecked ){
      errors.destinationURL = this.state.error_lang?.name[0];
    }
  
    if(this.state.hasErrorinQuill) 
    {return}
  
    

    if (Object.keys(errors).length > 0 ) {
      this.setState({ errors });
      return;
    }
 
  
    
    this.setState({ errors: {} });
    this.setState({ textediterIsOpen: false, originalValue: '' });
    this.setState({ displaySapn: false });
    this.setState({passConfiguration:true})

  
    await this.createNoteForProject("", "");

  localStorage.setItem("visitedConfigure","visitedConfigure")
    const rowId = localStorage.getItem("campaignID")
    const IdsUpdate=localStorage.getItem("rowId")
    const updatesConf=this.state.configura?IdsUpdate:rowId
const { startDate } = this.state.configurationsData;
const dateObject = new Date(startDate);
dateObject.setHours(dateObject.getHours() + 5);
dateObject.setMinutes(dateObject.getMinutes() + 30);

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
     let formDataCampaign = {
      "campaign": {
          "start_date":dateObject,
          "duration" : this.state.configurationsData.duration,
          "language": this.state.configurationsData.language.charAt(0).toUpperCase() + this.state.configurationsData.language.slice(1).toLowerCase(),
          "destination_url":this.state.configurationsData.destinationURL,
           "active_page":"quote",
           "visited_key":["setup","recommendation","objective","location","channels","budget","addons","configuration"]
    
          },
      "progress": {
          "current_page": "quote"
      }
  } 
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
  
     this.updateCampaignMessageId = requestMessageList.messageId
    
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
     );
  
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCreateCampaingEndPoint}/${updatesConf}`
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign)
    );
  
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
  
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
   
};

  ConfigurationReceiver=(responseJson:any)=>{ 
    this.props.history.push("/Quote")
  }

  
handleQuillChange = (value: string) => {
  const hasError = !value.trim() || value.trim() === '<p><br></p>' || /<p>\s*<\/p>/.test(value);
  if (hasError) {
      this.setState({
          hasErrorinQuill: true,
          errors: {
              ...this.state.errors,
              quillValue: this.state.error_lang?.name[0] || 'Default error message'
          },
          quillValue: value
      },
     
      );
  } else {
      
      this.setState({
          hasErrorinQuill: false,
          errors: {
              ...this.state.errors,
              quillValue: ''
          },
          quillValue: value
      });
  }
  this.setState({
    quillValue: value
});
this.setState({updateChecked:true})
this.updateConfigNavigation()

};



onMouseout=(e:MouseEvent)=>{
    e.currentTarget.style.backgroundColor = 'transparent'; 
    e.currentTarget.style.color = '#B4B4B4';
  }
  handleResultClick = (selectedValue: string) => {
 
    this.setState({
      campaing: {
        ...this.state.campaing,
        industry: selectedValue ,
      },
      dropdownVisible: false,
    });
  };
  onMouseover=(e:CustomMouseEvent)=>{
    e.currentTarget.style.fontWeight = '700'; 
    e.currentTarget.style.color = '#005E6E';  
  }
  getTextFromHTML = (html:any) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || '';
  };
  handleObjectiveClick = (clickedObjective: Objective) => {
    const { objectivesDetails } = this.state;
    const updatedObjectives = objectivesDetails.map((objective) => ({
      ...objective,
      clicked: objective.id === clickedObjective.id,
    }));
  
    this.setState((prevState) => ({
      campaing: {
        ...prevState.campaing,
        selectedObjective: clickedObjective.id,
      },
      objectivesDetails: updatedObjectives,
    }));
  };
    //istanbul ignore next
  apiCall = async (data: ApiData) => {

    const { contentType, method, endPoint, body, type } = data;console.log(body,"body-->");
    

    const header = {
      'Content-Type': contentType,
    }
    const apiCallMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    
    body && type != 'formData' ?
      apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(apiCallMessage.id, apiCallMessage);
    return apiCallMessage.messageId;
  }
  
 //istanbul ignore next
  handleChange = (event:any) => {
    const { name, value } = event.target;
    let errorMessage = "";
  
    if (name === "duration") {
          if (!value ) {
        errorMessage =this.state.error_lang?.name[0];
      }

      this.setState(prevState => ({
        configurationsData: {
          ...prevState.configurationsData,
          [name]: value
        },
        errors: {...prevState.errors,[name]: errorMessage}
      }));
  
    }
  

    if (name === "language") {
      if (!value) {
        errorMessage = this.state.error_lang?.name[0];
      }
      this.setState(prevState => ({
        configurationsData: {...prevState.configurationsData,
          [name]: value
        },
        errors: {
          ...prevState.errors,
          [name]: errorMessage
        }
      }));
    }

    if (name === "destinationURL") {
      if (!value) {
        errorMessage = this.state.error_lang?.name[0];
        this.setState({isDisabled:false})
      }
      this.setState(prevState => ({
        configurationsData: {...prevState.configurationsData,
          [name]: value
        },
        errors: {...prevState.errors,[name]: errorMessage
        }
      }));
       if(value.trim().length > 0 ){
       
        
        this.setState({isDisabled:true})
       }
    }
  
    if (name === "startDate") {
      if (!value) {
        errorMessage = this.state.error_lang?.name[0];
      }
      this.setState(prevState => ({
        configurationsData: {...prevState.configurationsData,startDate: value},
        errors: {
          ...prevState.errors,
          [name]: errorMessage
        }
      }));
    }
this.setState({updateChecked:true})
    this.updateConfigNavigation()
  };
  
  

  getConfigurationContentDetails = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "configuration";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };
  
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.setState({
      getConfigurationContentCallID: requestMessageList.messageId
     })
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.setUpContent}?language=${selectedCountry}&name=${name}`
    );
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  
  getConfigurationContentReceiver=(responseJson:ConfiurationContentData)=>{
    this.setState({
      configurationData: responseJson
    })
  };
  
  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  getConfigurationlHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Configuration";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getConfigurationHeadingTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getConfigurationHeadingReceiver=(responseJson:any)=>{
    this.setState({
      configurationHeadingTooltip: responseJson
    })
  };

  getStartDateTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "StartDate";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStartDateCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getStartDateReceiver=(responseJson:any)=>{
    this.setState({
      configurationStartdate: responseJson
    })
  };

  getDurationTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Duration";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDurationCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getDurationReceiver=(responseJson:any)=>{
    this.setState({
      configurationDuration: responseJson
    })
  };

  getCampaignLanguageTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "CampaignLanguage";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampaignLanguageCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getCampaignLanguageReceiver=(responseJson:any)=>{
    this.setState({
      configurationCampaignLanguage: responseJson
    })
  };

  getDestinationUrlTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "DestinationURL";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDestinationUrlCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getDestinationUrlReceiver=(responseJson:any)=>{
    this.setState({
      configurationDestinationUrl: responseJson
    })
  };

  getProjectNotesTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "ProjectNotes";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectNotesCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getProjectNotesReceiver=(responseJson:any)=>{
    this.setState({
      configurationProjectNotes: responseJson
    })
  };

  
  // Customizable Area End
}
