import React from "react";

import {
  Input,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  withStyles,
  Theme,
  createStyles,
  Grid,
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ConfirmationModal from "../../../components/src/common/Modal/ConfirmationModal";
import { withRouter } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import RolesPermissionsController, {
  Props,
  configJSON,
} from "./RolesPermissionsController";
import Sidebar  from "../../../components/src/SideBar.web";


export class RolesPermissions extends RolesPermissionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { isLogout, userProfileDetails } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar navigation={this.props.navigation} >
        <div className={classes.container} >
          <Typography className={classes.heading}>{this.state.select_lang?.name[0]}</Typography>  
          <div className={classes.rolesAndPermissionContainer } >
          <Typography className={classes.subHeading} >{this.state.select_lang?.name[1]}</Typography>
            <Grid container spacing={1} className={classes.containerBox}>
            <Grid  className={classes.profileDetailsContainer} item xs={12} sm={12} md={4}  alignItems="center" justifyContent="flex-start" >
                <InputLabel className={classes.profileDetailTexts}>{this.state.select_lang?.name[3]}</InputLabel>
            </Grid>
            <Grid   className={classes.profileDetailsContainer}  item xs={12} sm={12}  md={8}   >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                <Input data-test-id="user-first-name" className={classes.nameDetailsContainer} style={{ marginRight:"16px"}} value={userProfileDetails?.attributes.first_name} disabled disableUnderline  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                <Input data-test-id="user-last-name" className={classes.nameDetailsContainers} value={userProfileDetails?.attributes.last_name} disabled disableUnderline  /> 
                </Grid>
              </Grid>
              </Grid>
              <Grid   className={classes.profileDetailsContainer} item xs={12} sm={12} md={4}  >
                <InputLabel className={classes.profileDetailText}>{this.state.select_lang?.name[4]}</InputLabel>
            </Grid>
            <Grid  className={classes.profileDetailsContainer} item xs={12} sm={12}  md={8}   justifyContent="flex-end" alignItems="center" >
                <Input data-test-id="user-email"  className={classes.profileDetailsInputContainer} value={userProfileDetails?.attributes.email} disabled disableUnderline  />
              </Grid>
              <Grid  className={classes.profileDetailsContainer} item xs={12} sm={12} md={4}    alignItems="center" justifyContent="flex-start" >
                <InputLabel className={classes.profileDetailText}>{this.state.select_lang?.name[5]}</InputLabel>
            </Grid>
            <Grid  className={classes.profileDetailsContainer} item xs={12} sm={12}  md={8}   justifyContent="flex-end"  alignItems="center" >
                <Input data-test-id="user-role" className={classes.profileDetailsInputContainer} value={userProfileDetails?.attributes?.role?.name}  disabled disableUnderline  />
              </Grid>
              <Grid  className={classes.profileDetailsContainer} item xs={12} sm={12} md={4}    alignItems="center" justifyContent="flex-start" >
                <InputLabel className={classes.profileDetailText}>{this.state.select_lang?.name[6]}</InputLabel>
            </Grid>
            <Grid  className={classes.profileDetailsContainer} item xs={12} sm={12}  md={8}   justifyContent="flex-end" alignItems="center" >
                <Input data-test-id="user-phone" className={classes.profileDetailsInputContainer} value={userProfileDetails?.attributes.full_phone_number}  disabled disableUnderline  />
              </Grid>
              <Grid  className={classes.profileDetailsContainer} item xs={12} sm={12} md={4}  xl={4}  alignItems="center" justifyContent="flex-start" >
                <InputLabel className={classes.profileDetailText}>{this.state.select_lang?.name[7]}</InputLabel>
            </Grid>
            <Grid  className={classes.profileDetailsContainer}  item xs={12} sm={12}  md={8}   justifyContent="flex-end" alignItems="center" >
                <Input data-test-id="user-area" className={classes.profileDetailsInputContainer} value={userProfileDetails?.attributes.area} disabled disableUnderline  />
              </Grid>
              <Grid  className={classes.profileDetailsContainers} item xs={12} md={12} lg={12} xl={12} container justifyContent="flex-end">
                <Button 
                  data-test-id="logout-btn"
                  className={classes.logoutButton}
                  onClick={this.openLogoutConfirmationModal}
                  >
                  {this.state.select_lang?.name[8]}
                  </Button>
              </Grid>
            </Grid>
            <ConfirmationModal
            data-test-id="logout-confirmation-modal"
            isOpen={isLogout}
            closeModal={this.closeLogoutConfirmationModal}
            confirmationText={this.state.select_lang?.name[2]}
            handleConfirmation={this.handelLogout}
            yes={this.state.select_lang?.name[9]}
            cancel={this.state.select_lang?.name[10]}
          />
          </div>
        </div>
              {/* <Dialog              
                 open={this.state.openModel} style={{ borderRadius: "50px" }}
                 PaperProps={{
                   style: {
                     borderRadius: "24px"
                   },
                 }}
               >
                   <Box
                     display={"flex"}
                     style={{ justifyContent: "flex-end", margin: "3%", marginBottom: "0px", cursor: "pointer" }}
                     onClick={this.closeModal}>
                    <CloseIcon />
                   </Box>
                   <DialogContent
                     style={{color: "#000000",lineHeight: "26px" ,textAlign:"center",padding:"22px", fontFamily: "Panton Narrow Bold",
                     fontWeight: 700, fontSize:"24px"}}
                   > {this.state.select_lang?.name[11]} </DialogContent>
                   <DialogActions className={classes.dialogActions}>
                  <button
                     data-test-id='modalClose'
                    onClick={this.campaignNo}
                    style={{
                      fontFamily: "Panton Narrow Bold",
                      fontWeight: 700, fontSize:"16px", color: "#005E6E",
                      backgroundColor: "#FCFCFC" ,width: "194px", height: "56px", cursor: "pointer",borderRadius:"43px" ,border:"1px solid #005E6E"
                    }}> {this.state.select_lang?.name[12]}
                  </button>
                  <button
                    onClick={this.campaignSave}
                    style={{
                      backgroundColor: "#005E6E", border: "none", width: "194px", height: "56px", borderRadius: "50px",
                      fontFamily: "Panton Narrow Bold",
                     fontWeight: 700, fontSize:"16px", color: "#fff", marginLeft: "0px"
                    }}>
                   {this.state.select_lang?.name[13]}
                  </button>
                  </DialogActions>
               </Dialog> */}     </Sidebar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle = (theme:Theme)=>createStyles({
  container:{
   boxSizing:"border-box",
   padding:"0px 16px 0px 16px",
   '@media(max-width:500px)':{
    padding:"0px 0px 0px 12px",
   },
  },
  containerBox:{
    '@media(max-width:500px)':{
      padding:"0px 33px 0px 23px",
     },
     '@media(max-width:390px)':{
      padding:"0px 12px 0px 0px",
     },
  },
  rolesAndPermissionContainer:{
    width: "100%",
   minHeight:'850px',
   height: "calc(100vh - 175px)",
    borderRadius: "32px",
    marginTop:'-7px',
    background: "#FFF",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
    boxSizing:"border-box",
    padding:"50px 56px 0px 39px",
    marginBottom:'39px',
    '@media(max-width:960px)':{
      height:'unset',
    },
    '@media(max-width:650px)':{
      minHeight:'870px',
    },
    '@media(max-width:600px)':{
      minHeight:'unset',
    },
    '@media(max-width:500px)':{
      boxShadow:'none',
      padding:'0px 0px 18px 0px',
    },
    '@media(min-width:1900px)':{
    
     height: "calc(100vh - 185px)",
    },
  },

  heading:{
    padding:'55px 0px 42px 0px',
    color: "#000",
    fontFamily: "Panton Narrow Bold !important",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    letterSpacing:"-0.36px",
    '@media(max-width:500px)':{
      padding:" 22px 0px 12px 0px",
      fontFamily: "Panton Narrow Bold !important",
    fontSize: "20px",
    },
    '@media(min-width:1900px)':{
      fontSize: "44px",
      padding:'60px 0px 48px 0px',
    }
    ,
  },
  subHeading:{
    color: "#373838",
    fontSize: "36px",
    fontWeight: 400,
    fontFamily: "Panton Narrow Regular",
    lineHeight: "44px",
    letterSpacing: "-0.36px",
    marginBottom:"60px",
    '@media(max-width:500px)':{
      fontSize: "20px",
    fontFamily: "Panton Narrow Regular",
    marginBottom:'12px !important',
    },
  },    
  profilDetailsContainer:{
    width:"80%"
  },
  nameDetailsContainer:{
    display: "flex",
    width: "100%",
    padding: "12px 16px 11px 16px",
    alignItems: "center",
    borderRadius: "12px",
    marginTop:'-20px',
    height:'49px',
    border: "1px solid #878787",
    background:  "#FFF",
    "&>input":{
      color:"black",
      fontFamily:"Panton Narrow Regular"
    },
  },
  nameDetailsContainers:{
    display: "flex",
    width: "100%",
    padding: "12px 16px 11px 16px",
    alignItems: "center",
    borderRadius: "12px",
    border: "1px solid #878787",
    background:  "#FFF",
    marginTop:'-20px',
    height:'49px',
    "&>input":{
      color:"black",
      fontFamily:"Panton Narrow Regular"
    },
    '@media(max-width:650px)':{
      marginTop:'10px',
     },
    '@media(max-width:500px)':{
     marginBottom:'19px',
     marginTop:'8px',
    },
  },
  profileDetailsContainer:{
   marginTop:"44px",
   "&:first-child":{
     marginTop:"0px"
   },
   "&:nth-child(2)":{
     marginTop:"30px"
   },
   '@media(max-width:500px)':{
    // marginLeft:23,
    "&:nth-child(2)":{
      marginTop:"30px",
      marginLeft:23,
      marginRight:33,

    },
    
   },
   '@media(max-width:380px)':{
    // marginLeft:23,
    "&:nth-child(2)":{
      marginTop:"30px",
      marginLeft:8,
      marginRight:8,

    },
    
   },
   '@media(max-width:910px)':{
    "&:first-child":{
      marginTop:"0px"
    },
    "&:nth-child(2)":{
      marginTop:"20px"
    },
  }
  
  },
  profileDetailsContainers:{
    marginTop:"72px",
    "&:first-child":{
      marginTop:"0px"
    },
    "&:nth-child(2)":{
      marginTop:"unset"
    },
    '@media(max-width:500px)':{
      "&:nth-child(2)":{
        marginTop:"30px",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginLeft:'16px'
      },
     },
     '@media(min-width:1900px)':{
      marginTop:'100px'
     },
   },
  profileDetailsInputContainer:{
    width: "100%",
    padding: "12px 16px 11px 16px",
    borderRadius: "12px",
    border: "1px solid #878787",
    background: "#FFFFFF",
    height:'49px',
    "& input":{
      color:"black",
      fontFamily:"Panton Narrow Regular"
   }, 
   '@media(max-width:500px)':{
    marginBottom:'15px',
   },
  },
  profileDetailText:{
    // marginLeft:"20px",
    fontSize: "18px",
    // paddingLeft:"1rem",
    color: "#000",
    fontFamily:"Panton Narrow Bold",
    fontWeight: 700,
    lineHeight: "26px",
     marginTop:'12px',
     '@media (max-width:500px)':{
      marginTop:'6px',
      },
  },
  profileDetailTexts:{
    // marginLeft:"20px",
    fontSize: "18px",
    // paddingLeft:"1rem",
    color: "#000",
    fontFamily:"Panton Narrow Bold",
    fontWeight: 700,
    lineHeight: "26px",
     marginTop:'21px',
     '@media (max-width:960px)':{
      marginTop:'unset',
     },
     '@media (max-width:500px)':{
      marginBottom:'8px',
     },
  },
  logoutButton:{
    width: "144px",
    height: "49px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "43px", 
    "text-transform": "none",
    background: "#005E6E",
    color:"#FFF",
    fontSize: "16px",
    fontWeight: 700,
    marginBottom:"29px",
    lineHeight: "44px",
    "&:hover":{
      background: "#005E6E",
    },
    "&>span":{
      fontFamily: "Panton Narrow Bold",
    },
    '@media(max-width:960px)':{
      marginBottom:90
    },
    '@media(max-width:480px)':{
      marginTop:0,
      marginBottom:'29px'
    }
    
  },
  inputBox:{
    width: "652px",
  },
  dialogActions: {
    flexWrap: "wrap", 
    margin: "15px 13px 15px",
    display:"flex",
    justifyContent:"space-evenly" ,
    "@media (max-width: 501px)":{
      justifyContent: "center",
      gap: "10px"
    }
  },
  "@media (max-width: 1400px)": {
    profileDetailsInputContainer: {
      width:"100%",
      // padding:"15px 0px 22px 16px",
    },
    nameDetailsContainer:{
      width:'100%',
      //  padding:"15px 0px 22px 16px",
    },
    nameDetailsContainers:{
      width:'100%',
      // padding:"15px 0px 22px 16px",
    },
    rolesAndPermissionContainer:{
      // padding:"30px 53px 25px 36px"
    },
    heading:{
      // margin:"20px 0px 20px 52px"
    },
    subHeading:{
      marginBottom:"24px"
    },
    profileDetailsContainer:{
      marginTop:"8px",
      "&:last-child":{
        marginTop:"0px"
      },
      '@media(max-width:500px)':{
        marginLeft:'23px',
        marginRight:'33px'
      },
      '@media(max-width:380px)':{
        marginLeft:'8px',
        marginRight:'8px'
      },
      '@media(max-width:1440px)':{
        marginTop:"44px",
      }
    },
    profileDetailsContainers:{
      marginTop:"3px",
      "&:last-child":{
        marginTop:"100px"
      },
      '@media(max-width:480px)':{
        marginTop:'35px !important',
        marginBottom:'-50px',
      }
    }

  },
  "@media (max-width: 960px)":{
    profileDetailsContainer:{
      marginTop:"10",
      "&:nth-child(2)":{
        marginTop:"20"
      },
    },
    profileDetailsContainers:{
      marginTop:"10",
      "&:nth-child(2)":{
        marginTop:"20"
      },
      '@media(max-width:500px)':{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
       
      },
    },
    container:{
      marginTop:"68px",
      overflow: "scroll",
      height: "100%"
    }
  },
  "@media (max-width: 500px)":{
    profileDetailsContainer:{
      marginTop:"8px"
    },
    profileDetailsContainers:{
      marginTop:"8px",
      
    },
    container:{
      marginTop:"68px",
      overflow: "scroll",
      height: "100%"
    }
  }
})
export default withRouter(withStyles(useStyle)(RolesPermissions as any) as any) as any;
// Customizable Area End
