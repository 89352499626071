import React from "react";
// Customizable Area Start
import {
  Theme,
  Box,
  Typography,
  Slider,
  Tooltip,
  Button,
  Zoom,
  Switch,
  Modal
} from "@material-ui/core";

import {
  withStyles,
  createStyles,
  ThemeProvider,
  createTheme,
  styled
} from "@material-ui/core/styles";
import AddonsController, {
  Props, Addon
} from "./AddonsController.web";
import Sidebar from "../../../components/src/SideBar.web";
import { withRouter } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    },
  }
});

interface ValueLabelProps {
  value: number;
  children: React.ReactElement;
  open: boolean;
}


const AddonsWhiteOnBlackTooltip = withStyles((theme) => ({
  arrow: {
    color:"#E9E9E9",
    content: '""',
  },
  tooltip: {
    backgroundColor: "#E9E9E9",
    maxWidth: "231px",
    height: "auto",
    color: "black",
    // left: "40px",
    position: "relative",
    marginTop: "20px",
    borderBottomRightRadius: "12px",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    borderRadius: "12px",
    borderTopRightRadius: "12px",
    borderBottomLeftRadius: "12px",
    padding: "15px 20px 15px 20px",
    
  },

}))(Tooltip);

import {
  infoIcon, info, cancelImage
} from "./assets";

const PrettoSlider = withStyles({
  thumb: {
    height: "40px",
    strokeWidth: "5px",
    backgroundColor: '#fff',
    border: '3px solid #285D6D',
    marginLeft: "-20px",
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    flexShrink: 0,
    width: "40px",
  },
  root: { 
    borderRadius: "55px",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    background: "#fff",
    height: "2%",
    marginLeft:"10px",
    marginRight:"10px",

   /* shadow-md */
  },
  valueLabel: {
    // left: 'calc(-50% + 4px)',
  },
   active: {
     color: '#285D6D',
    //   width: "564px",
    // height: "38px",
  },
  track: {
    [theme.breakpoints.down("lg")]:{
      height: "60%",
    },
    [theme.breakpoints.down("md")]:{
      height: "60%",
    },
    [theme.breakpoints.down("xl")]:{
      height: "70%",
    },
    [theme.breakpoints.down("sm")]:{
      height: "60%",
    },
    [theme.breakpoints.down("xs")]:{
      height: "60%",
    },
    borderTopRightRadius: "55px",
    borderBottomRightRadius: "55px",    
    color: '#285D6D',
    // height: "38px",
    padding:'1px',
    borderTopLeftRadius: "55px",
    borderBottomLeftRadius: "55px",
  },
  rail: {
    background:'rgba(255, 255, 255, 0.8)',
    borderRadius: "55px",
    height: "25px",
    },
})(Slider);
const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '14px',
    color: '#005E6E',
    fontWeight: 700,
    backgroundColor: 'white',
  },
}))(Tooltip);


// Customizable Area End

export class Addons extends AddonsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  //istanbul ignore next 
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const AddonsData: Addon[] = this.state.AddonsData;
    const BoxView = Box as any;
    const WhiteOnBlackTooltipView = AddonsWhiteOnBlackTooltip as any;
    const maxCharacters = 202;
    const truncatedText = this.state.AddonData?.body ? this.state.AddonData.body.substring(0, maxCharacters) : '';
    const shouldShowReadMore = this.state.AddonData?.body && this.state.AddonData.body.length > maxCharacters;
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider  theme={theme}>
        <Sidebar navigation={this.props.navigation} activePath={""} onDashboardSave={this.onDashboardSave}>
          <BoxView className={classes.mainBudgetContainerBox} style={{}}>
           <BoxView  className={classes.budgetRoot}>
              <div className={classes.budgetInnerRoot}>
              <Typography className={classes.typoBudget}>
              {this.state.addonsHeadingTooltip?.data?.name}
                            <WhiteOnBlackTooltipView
                              TransitionComponent={Zoom}
                              title={this.state.addonsHeadingTooltip?.data?.description}
                              arrow
                              // open={true}
                              placement="bottom-start"
                              open={this.state.tooltipOpenAddonsHeadingTooltip}
                              onClose={this.handleTooltipCloseAddonsHeadingData}
                            >
                              <img
                                src={info}
                                className={classes.headingIcon}
                                style={{ }}
                                
                                alt="Info"
                                data-test-id='mouseIcon'
                                onMouseLeave={()=>this.handleImageImageMouseOver()}
                                onMouseEnter={()=>this.handleImageImageMouseHover()}
                                 onClick={() => this.handleMouse()}
                              />
                            </WhiteOnBlackTooltipView>
                            </Typography>
                            
                            </div>
             {/* <BoxView className={classes.AddonsInnerContainer} > */}
            
              {AddonsData.filter(item => !item.campaign_info&& typeof item.id === 'number').map((addon:Addon,index:number) => ( 
              
             <BoxView key={addon.id} className={classes.AddonsSubContainer}>
            
              <BoxView className={classes.titleContainer}>
              
                <Typography style={{display:'flex', flexDirection:'row',justifyContent:' space-between'}}>

              
              <Typography className={classes.AddonstitleHeading } >

                
              {addon.name}
              {/* <img
                   src={infoIcon}
                   style={{ marginLeft: "16px",width:'19px', top:3,position:'relative' }}
                   alt="Info"
              /> */}
              <WhiteOnBlackTooltipView
                              TransitionComponent={Zoom}
                              title={addon.tooltip }
                              arrow
                              open={this.state.tooltipOpenCompanyName&&this.state.addonsTooltipId===addon.id}
                              // open={true}
                              placement="bottom-start"
                            >
                             <img
                               data-test-id="tooltipIcon"
                              onMouseLeave={()=>this.handletooltipMouseOver(addon.id)}
                              onMouseEnter={()=>this.handletooltipMouseHover(addon.id)}
                               onClick={() => this.handletooltip(addon.id)}
                   src={info}
                   style={{ marginLeft: "16px",width:'17px', top:3,position:'relative' }}
                   alt="Info"
              />
                            </WhiteOnBlackTooltipView>
              </Typography>
              <Typography  className={classes.swtichIcon} > 
              <WhiteOnBlackTooltipView
                            title={addon.tooltip}
                           open={this.isTooltipOpen(addon.id)}
                            arrow={true}
                            TransitionComponent={Zoom}
                            placement="top-end"
                            onClose={this.handleToggleTooltipClose}

                          >
                            <Switch   
                          disabled={this.state.viewData}
                          data-test-id={`toggle-${addon.id}`}
                          className={classes.toggle}
                          onMouseLeave={()=>this.handleToggleMouseOvers(addon.id)}
                          onMouseEnter={()=>this.handleToggleMouseHover(addon.id)}
                          onChange={() => this.handleSwitchChange(addon.id.toString())}
                          checked={this.state.toggleAddon.includes(addon.id.toString())}
                         onClick={ ()=>{

                           if(this.state.toggleAddon.includes(addon.id.toString())== true){
                            this.handletoggleTooletipShow(addon.id)
                          
                                 
                          }
                         }
                      }
                        /> 
                          </WhiteOnBlackTooltipView>
                       
                      </Typography>
              </Typography>
              <Typography >
              <Typography style={{ display: 'inline',marginTop:'16px' }}
              className={classes.Addonstitle}>
                {addon?.price_before_discount} CHF
              </Typography>
              <Typography style={{ display: 'inline',marginLeft:10 }}  className={classes.Addonstitles}>
              ({addon.price_type})
              </Typography>
              </Typography >
              <Typography className={classes.AddonstitleText}>
              {addon.discount_description}
              </Typography>
              </BoxView>
                 {index !== AddonsData.length - 1 && (
               <Typography className={classes.horigntalLine}>
                 <hr/>
                 </Typography>
                 )}
             </BoxView>
               ))}
                {/* </BoxView> */}
             </BoxView>
          <div className={classes.sideContainerBox}>
          <div className={classes.innerSideContainerBox}>
                <BoxView style={webStyle.SubContaineerr}>
                <Typography style={webStyle.titlesss}>
                        <span>
                          {this.state.AddonData?.heading}
                        </span>
                        <span>
                        <WhiteOnBlackTooltipView
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.AddonData?.tooltip}
                          open={this.state.tooltipOpenAddonDataTooltip}
                        onClose={this.handleTooltipCloseAddonsData}
                        >
                          <img src={info} 
                            data-test-id="dataTooltip"
                          onMouseLeave={()=>this.handleDataTooltipMouseOver()}
                         onMouseEnter={()=>this.handleDataTooltipMouseHover()}
                          onClick={() => this.handleDataTooltip()}
                          alt="Info"
                           style={{height: "12px", width: "12px" }} />
                        </WhiteOnBlackTooltipView>
                        </span>
                      </Typography>
                  <img src={this.state.AddonData?.image_url} style={webStyle.bannnerImagesAddon} />
                  <BoxView testID="readmoreText">
                   <Typography style={{
                        marginTop: "20px",
                        lineHeight: '19.38px',
                        wordBreak: 'break-all',
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'panton narrow regular'
                    }} >
                        {truncatedText}{" "}
                        {shouldShowReadMore && (
                            <span style={webStyle.readMoreeAddon} onClick={this.toggleModal}>
                                {this.state.AddonData?.read_more}
                            </span>
                        )}
                    </Typography>
                    </BoxView>
                  <Modal
                          open={this.state.isModalOpen}
                          onClose={this.toggleModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          BackdropProps={{
                            style: {
                              backgroundColor: 'transparent',
                            }
                          }}
                        >
                        <BoxView className={classes.addonsModalBoxStyle} >
                            <div  className={classes.addonsModalBoxStyles} >
                                <Typography className={classes.bodyText}>
                                    {this.state.AddonData?.body}
                                </Typography>
                                <div className={classes.addonsCancelImageWrapper} >
                                    <img src={cancelImage} alt="Close" style={webStyle.addonsCancelImage} onClick={this.toggleModal} />
                                </div>
                            </div>
                        </BoxView>
                        </Modal>
                </BoxView>
              </div>
              <BoxView className={classes.budgetButtonBox}>
              <Button onClick={this.handleQuote} disabled={this.state.viewData}  className={classes.budgetButton} data-test-id="add_s" variant="contained">
              {this.state.select_lang.name[0]}
              </Button>
              <Button onClick={this.handleConfigNav} disabled={this.state.viewData}  className={classes.budgetButton} data-test-id="add_ons" variant="contained">
                {this.state.select_lang.name[1]}
              </Button>
            </BoxView>
          </div>
          </BoxView>
               </Sidebar>
           </ThemeProvider>
            //Merge Engine End DefaultContainer
        )
    }
  
}

const ButtonContainer = styled(Button)({
  width: "194px",
  borderRadius: "43px",
  color: "#FFFFFF",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  textTransform: "capitalize" as const,
  "&:hover": { 
    backgroundColor: "#005E6E",
  },
});

export const useStyle = (theme: Theme) =>
  createStyles({
    AddonsSubContainer:{
      marginLeft:42,
      marginRight:'50px',
      "@media(max-width:500px)":{
        marginLeft:4,
        marginRight:'unset'
      },
      
     },
     AddonsInnerContainer:{
      // innerHeight:'700px'
     },
    mainBudgetContainerBox:
    {
     
      [theme.breakpoints.down("sm")]:{
        flexDirection:"column" ,
      },
      [theme.breakpoints.down("xs")]:{
        flexDirection:"column" ,
      },
      display:"flex",
      flex:1, 
      height:'calc(100vh - 40px)',
      marginBottom:'40px',
      minHeight:'890px',
      boxSizing:"border-box",
      padding:"40px 16px 0px 16px",
      gap:"16px",
      "@media(min-width:1900px)":{
        gap:"18px",
        paddingLeft:'17px',
      },
      "@media(max-width:500px)":{
        padding:"37px 15px 0px 36px",
        minHeight:'unset',
        height:"unset !important",
        // background:'red', 
      },
      "@media(max-width:375px)":{
        padding:"37px 15px 0px 15px",
      }
    },
    addonsModalBoxStyles:{
      display: 'flex',
       justifyContent: 'space-between',
       "@media(max-width:500px)":{
        display: 'unset',
        justifyContent:'unset',
       }
    }, 
    addonsCancelImageWrapper: {
      alignSelf: 'flex-start',
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
      position:"absolute",
      top:"18px",
      right:'18px',
      "@media(max-width:500px)":{
        position:'absolute',
        top:18,
        marginRight:'0px',
        right:'18px',
      }
    },
    bodyText: {
      flex: 1,
      wordBreak: 'break-all',
      
      fontFamily: 'panton narrow regular',
      fontSize: '16px',
      lineHeight: '19.38px',
      fontWeight: 400,
      padding:'48px'
    },
    addonsModalBoxStyle: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: '50%',
      transform: 'translate(-50%, -50%)',
      width: '573px',
      height: 'auto',
      borderRadius: '12px',
      background: '#FFFFFF',
      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: '80vh',
      '@media(max-width:500px)':{
        width: '290px',
       
        top: '52%',
        overflowY: 'auto',
        
      }
    },
    sideContainerBox:{ 
    
      width:'31.3%',
      "@media(max-width:500px)":{
        marginBottom:'20px',
        width:'104% !important',
        marginLeft:'-18px'
      },
      "@media(max-width:375px)":{
        width:'100% !important',
        marginLeft:'unset',
      },
   
    [theme.breakpoints.down("xs")]:{
      width:'100%',
      
    },
    [theme.breakpoints.down("sm")]:{
      width:'100%',

    },
  },
    innerSideContainerBox:{
      // height:'calc(100vh - 110px)',
      height:'94.5%',
      '@media(max-width:1380px)':{
        height:'93.7%',
      },
     
      [theme.breakpoints.down("xs")]:{
        width:'100%',
       
      },
      [theme.breakpoints.down("sm")]:{
        width:'100%',
        
      },
      "@media(max-width:960px)":{
        height:'unset'
      },
      
    },
    AddonstitleHeading:{
      marginTop:8,
      color: '#000', 
      fontWeight:700,
      fontFamily: 'Panton Narrow Bold !important',
       fontSize: '18px',
       lineHeight:'32px',
       '@media(max-width:500px)':{
        fontWeight:'700 !importnat',
        // fontFamily: 'Panton Narrow Bold',
         fontSize: '14px',
         lineHeight:'32px',
         marginTop:8,
         fontFamily: "Panton Narrow Bold !important",
       },
    },
    toggle:{
      "& .MuiSwitch-track":{
        borderRadius:"20px",
      },
      borderRadious:"20px",
      width: "51px",
      height: "31.5px",
      padding:0,
      "& .Mui-checked":{
        transform: "translateX(20px)",
        color:'#fff',
      },
     
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track":{
        background:'#005E6E',
      },
      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":{
        opacity:"1"
      },
      "&>span":{
        left: "-7px",
        top: "-6.1px",
        "&>span":{
          borderRadious:"20px",
          "&>span":{
            borderRadious:"20px", 
            width: "26px",
            height: "26px",
          }
        }
      }
  },
    AddonstitleText:{
      marginTop:2,
      color:'#005E6E',
      fontFamily: 'Panton Narrow Bold !importnat',
       fontSize: '14px',
       fontWeight:700,
       lineHeight:'26px',
       '@media (max-width:500px)':{
        marginTop:3,
       },
    },
    Addonstitles:{
      marginTop:'16',
      fontFamily: 'Panton Narrow Bold !important',
      fontSize: '16px',
      fontWeight:700,
      color: '#000', 
      lineHeight:'26px',
      '@media(max-width:500px)':{
        fontWeight:'700 !importnat',
        // fontFamily: 'Panton Narrow Bold',
         fontSize: '12px',
         lineHeight:'26px',
         fontFamily: "Panton Narrow Bold !important",
        }
    },
    Addonstitle:{
      marginTop:'16',
      fontFamily: 'Panton Narrow Bold',
      fontSize: '16px',
      fontWeight:700,
      color: '#000', 
      '@media(max-width:500px)':{
        fontWeight:'700 !importnat',
        // fontFamily: 'Panton Narrow Bold',
         fontSize: '14px',
         lineHeight:'26px',
         fontFamily: "Panton Narrow Bold !important",
        }
    },
    mainContainer:{
      // backgroundColor:"#900000",
      boxSizing:"border-box",
      padding:"0px 16px",
    },
    leftContainer:{
      backgroundColor:"#900000",
      width: "100%",
      height:"50%",
    },
    root: {
      height: "400px",
      width: "346px",
    },
    title: {
      fontSize: 14,
    },
    media: {
      paddingTop: '56.25%', // 16:9
      height: 0,
    },
    horigntalLine: {
      marginTop:'14px',
      marginRight:'119px',
       },
    mainHeading:{
      [theme.breakpoints.down("lg")]:{
        marginLeft: "25px",
      },
      fontFamily:"Panton Narrow Bold",
    fontSize: "36px",
    "font-weight": 700,
    marginLeft: "40px",  
    
   },
   subHeading:{
    [theme.breakpoints.down("lg")]:{
      marginLeft: "25px",
    },
    fontSize: "16px",
    "font-weight": 400,
    marginLeft: "40px",  
    fontFamily:"Panton Narrow Regular",
   
   },
   dashboardContainer:{
     padding: "42px 26px 58px 14px",
    width: "100%",
    "box-sizing":"border-box",
    marginTop: "24px",
    borderRadius: "32px",
    background: "#FFF",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
   },
   dashboardFilterContainer:{
    [theme.breakpoints.down("md")]:{
      justifyContent:"start",
     },
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    gap:'18px',
    marginBottom:"40px",
    width:"100%",
    paddingLeft:"14px",
    [theme.breakpoints.down("sm")]:{
     "flex-wrap":"wrap"
    },     
  },
  toggleAndDateFilter:{
    [theme.breakpoints.down("sm")]:{
      width:"100%",
      justifyContent:"space-between"

    },
    width:"410px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",
   
  },
  filterButton:{
    [theme.breakpoints.down("lg")]:{
      padding: "8px 16px",
      fontSize: "small",
      textTransform: "none",
    },
    padding: "12px 24px",
    borderRadius: "48px",
    border: "1px solid #373838",
    "& span":{
      fontFamily:"Panton Narrow Regular",
    },
   
  },
  headingIcon :{
    marginLeft: "16px",
    width:'19px',
    position:'inherit',
    fill:'#337E8B',
    "@media (max-width:1200px)": {
      width:'17px',
      position:'relative',
      top:'2px'
    },
    "@media (max-width:500px)": {
      width:'17px',
      position:'relative',
      top:'4px'
    },
  },
  budgetRoot:{
    width:'67.48%',
    flexShrink: 0,
    // height:"98%",
    borderRadius: "38px",
    background: "#FFF",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
    overflowY: 'auto',
    [theme.breakpoints.down("xs")]:{
      width:'100%',
    },
    [theme.breakpoints.down("sm")]:{
      width:'100%',
    },
    "@media (max-width:500px)": {
      borderRadius:'unset',
      boxShadow:'none',
      minHeight:"unset",
      // height:'87.5%'
    }
  },
  
  "@media (max-width:551px)": {
    horigntalLine: {
      marginRight:'118px',
    },
  },
  "@media (max-width:451px)": {
    horigntalLine: {
      marginRight:'100px',
    },
  },
  "@media (max-width:418px)": {
    horigntalLine: {
      marginRight:'44px',
    },
  },
  "@media (max-width:959px)": {
    budgetRoot: {
   marginTop:'56px',
    },
  },
  swtichIcon:{
    marginRight:'122px',
    marginTop:'9px',
    width:'48px',
    height:'28px',
    "@media (max-width:500px)": {
      marginRight:'47px',
    },
    "@media (max-width:380px)": {
      marginRight:'10px',
    }
  },
  "@media (max-width:550px)": {
    swtichIcon: {
  //  marginRight:'20%',
    },
  },
  "@media (max-width:420px)": {
    swtichIcon: {
  //  marginRight:'5%',
    },
  },
  budgetInnerRoot:{
    padding: "27px 0px 32px 42px",
    '@media(max-width:500px)':{
      padding:'unset',
      paddingTop:'20px',
      paddingBottom:'20px'
    },
  },
  budgetButton: {
    width: "176px",
    textTransform: "capitalize" as const,
    borderRadius: "43px",
    fontSize: "16px",
    color: "#353232",
    backgroundColor: "#e0e0e0",
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    height: "52px",
    boxShadow: "none",
    "&:hover": { 
      backgroundColor: "#005E6E",
      color: "#FFFFFF",
      boxShadow: "none",
    },
    '@media(max-width:500px)':{
      width:'176px'
    },
    '@media(max-width:390px)':{
      width:'167px'
    },
    '@media(max-width:350px)':{
      width:'142px'
    }
  },
  typoBudget:{
    // marginTop:1,
    color: "#000",
    fontFamily:' Panton Narrow Bold !important',
    lineHeight:'32px',
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    letterSpacing: "-0.12px",
    display:'block',
    marginTop:'-7px',
    height:'17px',
     marginBottom:'20px',
    "@media(max-width:500px)":{
      fontSize: "16px !important",
      lineHeight:'32px',
      fontFamily: "Panton Narrow Bold !important",
     
    }
  },
  // CHFRoot:{
  //   display:"flex",
  //   flexDirection:"row",
  //   width: "128px",
  //   height: "52px",
  //   flexShrink: 0,
  //   borderRadius: "14px",
  //   border: "1px solid var(--neutrals-cool-gray-500, #B4B4B4)",
  //   justifyContent: "center",
  //   marginLeft: "10px",
  //   marginBottom: "20px"
  // },
//   typoCHF:{
//     color: "#000",
//     fontFamily: "Panton",
//     fontSize: "18px",
//     fontStyle: "normal",   
//     fontSeight: 400,
//     alignSelf: "center",
//     marginRight: "10px",
// // "lineHeight": "26px", /* 144.444% */
//   },
//     typoPrice:{
//       color: "#000",
//       fontFamily: "Panton",
//       fontSize: "24px",
//       fontStyle: "normal",
//       fontWeight: 700,
// //    line-height: 32px; /* 133.333% */
//       letterSpacing: "-0.12px",
//       alignSelf: "center",
//     },
    // rangeMainRoot:{
    //   width:'95%'
    // },
    // rangeRoot:{
    //   display:'flex',
    //   flexDirection:"row",
    //   marginTop:10,
    // },
    // typoLeftRange:{
    //   fontSize: "14px",
    //   color:"#000",
    //   fontStyle:"normal",
    //   fontWeight: 400,
    //   fontFamily: "Panton",
    //   flex:1,
    // },
    // typoRightRange:{
    //   fontSize: "14px",
    //   color:"#000",
    //   fontStyle:"normal",
    //   fontWeight: 400,
    //   fontFamily: "Panton",
    //   flex:1,
    //   textAlign:"right"
    // },
    // actionIcon: {
    //   "&:hover": {
    //     cursor: "not-allowed"
    //   }
    // },
    // actionIconsHover: {
    //   color: "white",
    //   "&:hover": {
    //     backgroundColor: "inherit"
    //   }
    // },
    // campaignStatus: {
    //   marginRight: "6px"
    // },
  //   profileimage: {
  //    width: "24px",
  //    height: "24px",
  //    borderRadius: "24px",
  //    marginRight: "8px"
  //  },
  //  btnLogo:{
  //   marginLeft:"10px"
  // },
    // tableContainer: {
    //   "box-sizing": "border-box",
    //    width: "100%",
    //    height:"68%",
    //    overflow: "auto",
    //    paddingRight: "10px",
    //    "&::-webkit-scrollbar": {
    //      width: "8px",
    //      [theme.breakpoints.down("lg")]: {
    //        width: "5px"
    //      }
    //    },
    //    "&::-webkit-scrollbar-track": {
    //      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //      borderRadius: "8px",
    //      backgroundColor: "#CCDFE2",
    //      [theme.breakpoints.only("lg") ]: {
    //        width: "5px",
    //      }
    //    },
    //    "&::-webkit-scrollbar-thumb": {
    //      backgroundColor: "#005E6E",
    //      borderRadius: "8px",
    //      width: "6px",
    //      [theme.breakpoints.down("lg")]: {
    //        width: "4px"
    //      }
    //    },
    //    [theme.breakpoints.only("lg")]: {
    //      height:"52%",
    //      width:"97%"
    //    },
    //    [theme.breakpoints.down("md")]: {
    //      height:"48%"
    //    },
    
    //  },
    //  tableHeader: {
    //    fontSize: "20px",
    //    "font-weight": 700,
    //    border: "none",
    //    fontFamily: "Panton Narrow Bold",
    //    [theme.breakpoints.down("lg")]: {
    //      fontSize: "14px"
    //    }
    //  },
    //  tableRowHoverEffect: {
    //    "&:hover": {
    //      backgroundColor: "#005E6E",
    //      borderRadius: "56px",
    //      cursor: "pointer",
    //      "& > .MuiTableCell-body": {
    //        color: "white"
    //      }
    //    }
    //  },
    //  tableCell: {
    //    border: "none",
    //    fontFamily: "Panton Narrow Regular",
    //    "&:firstChild": {
    //      borderRadius: "56px 0 0 56px"
    //    },
    //    "&:lastChild": {
    //      borderRadius: "0 56px 56px 0"
    //    },
    //    [theme.breakpoints.down("lg")]: {
    //      fontSize: "small",
    //      padding: "1px"
    //    }
    //  },
    //  tableRowFirstCell: {
    //    borderRadius: "56px 0 0 56px",
    //    fontFamily: "Panton Narrow Regular",
    //    border: "none",
    //    [theme.breakpoints.down("lg")]: {
    //      fontSize: "small",
    //    }
    //  },
    //  tableRowLastCell: {
    //    borderRadius: "0 56px 56px  0 ",
    //    padding: "auto 0px",
    //    marginRight: "10px",
    //    width: "58px",
    //    fontFamily: "Panton Narrow Regular",
    //    border: "none",
    //    [theme.breakpoints.down("lg")]: {
    //      fontSize: "small",
    //      padding: "4px"
    //    }
    //  },
    //  tableScroll: {
    //    overflow: "auto"
    // //  },
    //  tableRowDisable: {
    //    opacity: "0.40",
    //    backgroundColor: "#FFFFFF",
    //    cursor: "not-allowed"
    //  },
     budgetButtonBox: {
      justifyContent: "space-around",
      "@media (max-width: 1440px)": {
         gap:"10px",
        },
       "@media(max-width:960px)":{
        marginTop:"40px",
        marginBottom:'36px',
       },
       "@media(max-width:500px)":{
         marginTop:"20px",
        marginBottom:'-19px',
       },
      //  [theme.breakpoints.down("md")]:{
      //    marginTop:"24px",
      //   },
      //   [theme.breakpoints.down("sm")]:{
      //     marginTop:"44x",
      //     justifyContent: "cennter",
      //   },
        // [theme.breakpoints.down("xs")]:{
        //   marginTop:"18px"
        // },
        alignItems: "center",
        display: "flex",
      },
    budgetDefaultBUtton: {
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      textDecoration: "none",
      textTransform: "capitalize" as const,
      fontSize: "16px",
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: "176px",
      borderRadius: "43px",
      color: "#353232",
      backgroundColor: "#e0e0e0",
      height: "52px",
      "&:hover": { 
        backgroundColor: "#005E6E",
        color: "#FFFFFF",
      },
    },
    
   
  });

  const webStyle = {
    SubContaineerr: {
        backgroundColor: "#FFFFFF",
        height: "auto",
        boxShadow: "0px 4px 16px 0px #00000040",
        padding:"33px 29.4px 24px 24px",
        borderRadius: "24px",
      },
      titlesss: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Panton Narrow Regular",
        marginBottom: "20px",
        display:"flex",
        alignItems:"center",
        gap:'12px',
      },
      bannnerImagesAddon: {
        width: "100%",
        height:'164px'
      },
      readMoreeAddon: {
        fontFamily: "Panton Narrow Bold",
        fontSize: "14px",
        fontWeight: 700,
        color: "#005E6E",
        cursor:"pointer"
      },
      imageADDons:{
        marginLeft: "15px",
        height:"13px",
        width:"13px"
      },
      descriptionTextAddOn:{
        marginTop: "32px" ,
        lineHeight:'19px'
      },
      objecftgdyStyle:{
        marginLeft:'22px',
        marginTop:'24px', 
      },
    
      addonsCancelImage: {
        cursor: 'pointer',
        width: '10.96px',
        height: '11px',
        marginLeft:'15px',
      },
    }
export default withRouter(withStyles(useStyle)(Addons as any) as any) as any;
