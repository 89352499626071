import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  google,
  bannerad,
} from "./assets";
import { getStorageData, logoutUser, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { testNavi } from "../../../../packages/blocks/utilities/src/CommonUtilities";
// Customizable Area End

export const configJSON = require("./config");


const options = [
  "Food",
  "Water supply",
  "Logistics",
];

const location = [
  "Local [0-25 km]",
  "Regional [25-50 km]",
  "National [Entire Switzerland]",
];

const Data = [0, 1, 2, 3];

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  location:any;
  campaign:any;
  objectives:any;
  clickObjectiveName:any
  history:any;
  // Customizable Area End
}
interface MenuItem {
  label: string;
  value: number;
}
interface S {
  txtInputValue: string;
  getRecommendationContentCallID:string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading:boolean;
  searchText:string;
  searchResults:[],
  results: [],
  dropdownVisible: boolean,
  hoverIndex:null,
  isDropdownOpen:boolean,
  location: [],
  anchorEl: null,
  // selectedItem:string[] | null,
  ios:boolean
  Benchmark:
    { 
      name:string,
      time:string,
    }[],
    selectedItem: number | null,
      menuItems: MenuItem[],
      switchState: boolean,
      tooltipOpenRecommendationBudget:boolean,
      tooltipTitleRecommendationBudget: string,
      tooltipOpen: boolean,
      premiumAds:PremiumAds[]  
      expandedPanel:  string | null,
      responseData: ResponseData,
      RecommendationData:ApiResponse,
      BudgetData: BudgetData,
      ChannelsData:ResponseData,
      DurationData:ResponseData,
      ConversionTrackingData:ResponseData,
      selectedSubChannelIds:any,
      industryBenchmarkData:any,
      selectlang: any,
      isComponentOpen:boolean,
      checkedAddOns: {}[],
      recommendationData: any,
      isModalOpen: boolean,
      updateObjective:{},
      tooltipTitleReccRoas: string,
      tooltipOpenReccRoas: boolean,
       tooltipTitle: string,
       tooltipTitleAddOns: string,
       tooltipOpenAddOns: boolean,
       tooltipTitleRecommendationData: string,
      tooltipOpenRecommendationData: boolean,
      tooltipAddonsData:boolean,
      tooltipAddonsDataId:number |null,
       tooltipTitleRecommendationDuration:string,
       tooltipOpenRecommendationDuration: boolean,
       tooltipTitleRecommendationIndustryBenefit: string,
       tooltipOpenRecommendationIndustryBenefit: boolean,
       tooltipTitleRecommendationChannel: string,
       tooltipOpenRecommendationChannel: boolean,
      recommendationBudgetHeadingTooltip:any,
      recommendationChannelHeadingTooltip:any,
      recommendationDurationTootlips: any,
      recommendationindustrybBenHeadingTooltip: any
      reccRoasHeadingTooltip:any
      openModel:boolean
      addonFlags: any
      quoteId : boolean,
      recommendationTooltipOpen: boolean
      toggle:any,
      showTooltip:any,
      viewData:boolean,
      ApiVariable:any,
      visitedQuoteKey:any,
      active_key:string,
      durationItem:any,
      initialVisit:boolean,
      updatedRecommChecked:boolean,
      loadertrue:boolean,
      benchmarkcategory:any,
      benchmarkshow:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ResponseData {
  data: {
    id: null,
    name: null,
    description?: string,
    original_keyword?: string,
    name_code: null,
  },
}

interface BudgetData {
  data: {
    id: null,
    name: null,
    description?: string,
    original_keyword?: string,
    name_code: null,
  },
}

interface PremiumAds  {
  img: string;
  title: string;
}

interface AddOn {
  id: number;
  name: string;
  price: string;
  price_type: string;
  discount_description: string;
  tooltip: string;
  recommended: boolean;
}

type Addon = {
  id: number;
  name: string;
  recommended: boolean;
}
interface ApiResponse {
  data: {
    budget: string;
    channel_mixes: any;
    duration: number;
    add_ons: AddOn[]; 
    addon_flags:any

  };
}

interface RecomendationContentData{
  data:{
    tooltip: string,
    heading: string,
    body: string,
    image_url: string
  }
}
export default class RecommendationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRorsCallID:string=""
  postRecommendationsCallID:string=""
  getBudgetCallID:string=""
  getChannelsCallID:string=""
  getDurationCallID:string=""
  getConversionTrackingCallID:string=""
  putRecommendationCallID:string=""
  getIndustryBenchmarkCallID:string=""
  putRecommendationToObjectiveCallID:string=""
  getRecommendationBudgetHeadingTooltipCallID:string=""
  getRecommendationChannelHeadingTooltipCallID:string=""
  getRecommendationDurationTooltipCallID:string=""
  getRecommendationIndBenchHeadingTooltipCcallID:string=""
  getRecommendationRoasHeadingTooltipCcallID:string=""
  getCampaignRecommendationCallID:string=""
  putRecommendationDisabledApiCallID:string=""
  getSerchIndustryCallId:string=""
  putCallID:string=""
  getBenchmarkInAllLanguageCallID:string=""

  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      getRecommendationContentCallID:"",
      isLoading:false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchText:"",
      searchResults:[],
      results: [],
      dropdownVisible: false,
      hoverIndex:null,
      isDropdownOpen:false,
      location:[],
      anchorEl: null,
      // selectedItem:null,
      ios:true,
      Benchmark:[
        { 
          name:"Cost Per Lead (avg.)",
          time:"25 / Month"
        },
        {
          name:"Cost Per Click (avg.)",
          time:"15 CHF"
        },
        {
          name:"Cost Per Impression (avg.)",
          time:"90 / Month"
        },
        {
          name:"Lead Value (avg.)",
          time:"90 / Month"
        },
      ],
      selectedItem: null,
      menuItems: [
        { label: "3 ", value: 3 },
        { label: "6 ", value: 6 },
        { label: "9 ", value: 9 },
        { label: "12 ", value: 12 },
      ],
      switchState: false,
      tooltipOpen: false,
      tooltipTitle: '',
      premiumAds:[
        {
          img:google,
          title:"Local",
      },
      {
        img:bannerad,
        title:"Search",
      }
    ],
    expandedPanel: null,
    RecommendationData: {
      data: {
        budget: "",
        channel_mixes: [],
        duration: 0,
        add_ons:[],
        addon_flags:{}
      }
    },
    responseData: {
      data:{
        id: null,
        name: null,
        name_code: null,
      } 
    },
    BudgetData:{
      data:{
        id: null,
        name: null,
        name_code: null,
      } 
    },
    ChannelsData:{
      data:{
        id: null,
        name: null,
        name_code: null,
      } 
    },
    DurationData:{
      data:{
        id: null,
        name: null,
        name_code: null,
      } 
    },
    ConversionTrackingData:{
      data:{
        id: null,
        name: null,
        name_code: null,
      }
    },
    selectedSubChannelIds:[] as string[],
    industryBenchmarkData:[],
    selectlang: {
      name: [],
      buttons:[],
      time:[]
  },
  isComponentOpen:false,
  checkedAddOns: [] as Addon[],
  recommendationData: {},
  isModalOpen: false,
  updateObjective:{},
  tooltipTitleReccRoas: '',
  tooltipOpenReccRoas: false,
  tooltipTitleAddOns: '',
  tooltipOpenAddOns: false,
  tooltipTitleRecommendationData: '',
  tooltipOpenRecommendationData: false,
  tooltipTitleRecommendationIndustryBenefit: '',
  tooltipOpenRecommendationIndustryBenefit: false,
  tooltipTitleRecommendationChannel: '',
  tooltipTitleRecommendationDuration: '',
  tooltipAddonsData:false,
  tooltipAddonsDataId:null ,
  tooltipOpenRecommendationDuration: false,
  tooltipOpenRecommendationChannel: false,
  tooltipOpenRecommendationBudget:false,
  tooltipTitleRecommendationBudget: '',
  recommendationBudgetHeadingTooltip:{},
  recommendationChannelHeadingTooltip:{},
  recommendationDurationTootlips:{},
  recommendationindustrybBenHeadingTooltip: {},
  reccRoasHeadingTooltip:{},
  openModel:true,
  addonFlags:false,
  quoteId : false,
  recommendationTooltipOpen: false,
  toggle:[],
  showTooltip:[],
  viewData:false,
  ApiVariable:{},
  visitedQuoteKey:{},
  active_key:"",
  durationItem:{},
  initialVisit:false,
  updatedRecommChecked:false,
  loadertrue:false,
  benchmarkcategory:"",
  benchmarkshow:false
      // Customizable Area End
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


    // Customizable Area Start
    async componentDidMount(): Promise<void> {
    await Promise.all([this.getRorsDetails(),this.postRecommendations(),this.getBudgetDetails(),this.getChannelsDetails(),this.getDurationDetails(),
    this.getConversionTrackingDetails(),
    this.getBenchmarkInAllLanguage(),
     this.getRecommenadtaionDurationTooltip(),
    this.getRecommendationContentDetails(), this.getRecommendationBudgetHeadingTooltip(), this.getRecommendationChannelHeadingTooltip(), 
      this.getRecommendationIndustryBenchmarkHeadingTooltip(), this.getRecommendationRoasHeadingTooltip()
    ]);

    const viewOption:any=localStorage.getItem("visitedView")
    const showOption:any=localStorage.getItem("showData")
    if(viewOption && showOption==="true"){
      this.setState({viewData:true})
    }
    const selectedCountry = localStorage.getItem("selectedCountry");
    const langData : any = {
      german: {name:["Kosten pro Lead (dur.)", "Kosten pro Klick (dur.)", "Kosten pro Impression (dur.)", "Lead-Wert (dur.)"],
              buttons: ["Unser Empfehlungs","Konfigurieren", "Zitat", 
              "Marketingkanäle sind nicht ausgewählt." ,"Bitte auswählen.", "Bitte stellen Sie eine Verbindung zum Administrator her.",
              "Das Budget ist nicht definiert.", "Bitte fügen Sie ein Budget hinzu.", "Bitte stellen Sie eine Verbindung zum Administrator her."],
              time:["/ Monat","CHF","/ Monat","/ Monat", "Monate"],
            },
      french: {name:["Coût par prospect (moy.)", "Coût par clic (moy.)", "Coût par impression (moy.)", "Valeur du prospect (moy.)"],
              buttons: ["Notre de recommandation","Configurer", "Citation",
               "Les canaux de marketing ne sont pas sélectionnés.", "Veuillez sélectionner.", "Veuillez vous connecter à l'administrateur.",
               "Le budget n'est pas défini,", "Veuillez ajouter un budget.", "Veuillez vous connecter à l'administrateur."],
              time:["/ Mois","CHF","/ Mois","/ Mois", "Mois"],
            },
      italian: {name:[" Costo per lead (medio)", "Costo per clic (medio)", "Costo per impressione (medio)", "Valore lead (medio)"],
                buttons: ["Nostra raccomandazione","Configura","Citazione", 
                "I canali di marketing non sono selezionati", "Seleziona.","Connettiti all'amministratore.",
                "Il budget non è definito,", "Aggiungi un budget.", "Connettiti all'amministratore."],
                time:["/ mese","franchi","/ mese","/ mese", "Mesi"],
              },
    };

      const selectedLanguage = langData[selectedCountry as any] || { name: [], time: [] };
      this.setState({selectlang:selectedLanguage})

    const persistId=localStorage.getItem("persistId");
    const visitedrecomm:any=localStorage.getItem("visitedrecomm")
if(persistId || visitedrecomm){
  this.getCampaignRecommendationData();
}

  }
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    
    if(responseJson && responseJson.statusCode === 401){
      logoutUser();           
      this.props.history.push("/EmailAccountLoginBlock");
    }
  
    switch (apiRequestCallId) {
      case this.getRorsCallID:
        this.getRorsReceiver(responseJson);
        break;
      case this.postRecommendationsCallID:
        this.postRecommendationsReceiver(responseJson);
        break;
      case this.getBudgetCallID:
        this.getBudgetReceiver(responseJson);
        break;
      case this.getChannelsCallID:
        this.getChannelsReceiver(responseJson);
        break;
      case this.getDurationCallID:
        this.getDurationReceiver(responseJson);
        break;
      case this.getConversionTrackingCallID:
        this.getConversionTrackingReceiver(responseJson);
        break;
      case this.getIndustryBenchmarkCallID:
        this.getIndustryBenchmarkReceiver(responseJson);
        break;
      case this.state.getRecommendationContentCallID:
        this.getRecommendationContentReceiver(responseJson)
        break;
      case this.putRecommendationToObjectiveCallID:
        this.RecommendationToObjectiveReceiver(responseJson)  
      break;
      case this.getRecommendationBudgetHeadingTooltipCallID:
        this.getRecommendationBudgetHeadingReceiver(responseJson)  
      break;
      case this.getRecommendationChannelHeadingTooltipCallID:
        this.getRecommendationChannelHeadingReceiver(responseJson)  
      break;
      case this.getRecommendationDurationTooltipCallID:
        this.getRecommendationDurationTooltipReceiver(responseJson);
        break;
        case this.getRecommendationIndBenchHeadingTooltipCcallID:
          this.getRecommendationIndustryBenchmarkHeadingReceiver(responseJson)  
        break;
        case this.getRecommendationRoasHeadingTooltipCcallID:
          this.getReccomendationRoasHeadingReceiver(responseJson);
          break; 
          case this.getCampaignRecommendationCallID:
              this.getCampaignSetUpReceiver(responseJson)
              break;
              case this.getBenchmarkInAllLanguageCallID:
                this.getSearchBenchmark(responseJson)
break;
case this.putCallID:
 const navigate= localStorage.getItem("navigate")
 if(navigate){
  this.props.history.push("/Dashboard")
 }
 if(this.state.updatedRecommChecked){
  this.setState({loadertrue:true})
}
  if(responseJson.data && this.state.quoteId){
    this.props.history.push("/Quote")
  }else if(this.state.initialVisit){
      this.send(testNavi(this.props))
    }
  
  break
    }
    
  
    runEngine.debugLog("Message Received", message);
  }
  
    // Customizable Area End
  

  // web events

  // Customizable Area Start


  getRorsDetails = async (): Promise<void> => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };

     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.getRorsCallID = requestMessageList.messageId;
 
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );

     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.getSerchIndustryEndPoint
      `${configJSON.getRoasEndPoint}?name=ROAS`
     );
 
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
 
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }

getRorsReceiver=(responseJson:ResponseData)=>{
  this.setState({ responseData: responseJson}); 
}
getCampaignRecommendationData=()=>{
  const visitedrecomm=localStorage.getItem("visitedrecomm");
  const  persistId= localStorage.getItem("persistId")
    let Camping_Id = localStorage.getItem('campaignID')
    const rowId=visitedrecomm?Camping_Id:persistId
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getCampaignRecommendationCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );
   requestMessageList.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);
   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.ConfigurationDataEndPoint}${rowId}`
   );
   runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }
getBudgetDetails = async (): Promise<void> => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getBudgetCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getBudgetEndPoint}?name=Budget`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

getBudgetReceiver=(responseJson:BudgetData)=>{
this.setState({ BudgetData: responseJson}); 
}
putRecommendationDisabledDataApi=async (): Promise<void> => {
  const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };   
  
   const requestMessageLists = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );
   requestMessageLists.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
   );

   requestMessageLists.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${"bx_block_custom_form/update_recommendation_campaign"}?id=${rowID}&page=recommendation`
   );
   requestMessageLists.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );
   this.putRecommendationDisabledApiCallID=requestMessageLists.messageId
   runEngine.sendMessage(requestMessageLists.id, requestMessageLists);
}
getChannelsDetails = async (): Promise<void> => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getChannelsCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getChannelsEndPoint}?name=Channels`
   );

   
   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}
getCampaignSetUpReceiver=(responseJson:any)=>{
  const res=responseJson?.campaign?.attributes 
  const newChecked=this.state.RecommendationData.data.channel_mixes.map((e:any)=> e.channel_id)
  const defaultChecked = responseJson.channel_mixes.map((e:any)=> e.channel_id)
  const newdeafu=res.budget ?defaultChecked:newChecked
  const addonsItem=responseJson.add_ons && responseJson.add_ons.map((i:any)=>i.id.toString())
  this.setState({
    selectedSubChannelIds:[...newdeafu],toggle:addonsItem
  })
  this.setState((prevState) => ({
    RecommendationData: {
      data: {
        budget:res.budget? res.budget : this.state.RecommendationData.data.budget,
        channel_mixes:this.state.RecommendationData.data.channel_mixes,
        duration:res.duration ? res.duration :this.state.RecommendationData.data.duration,
        add_ons:this.state.RecommendationData.data.add_ons,
        addon_flags:this.state.RecommendationData.data.addon_flags
      }
    }
  }));
  const visitedSetup=localStorage.getItem("visitedrecomm");
  const persistId=localStorage.getItem("persistId");
    if(visitedSetup || persistId){
    let key=responseJson.visited_key;
    this.setState({visitedQuoteKey:responseJson.visited_key,active_key:responseJson.active_page})
    if(!key.includes(responseJson.active_page) )
    {
       key.push(responseJson.active_page)
    }
    let CampaignUpdate =  {
     "campaign": {
       "active_page":"setup",
       "visited_key":key
     }, 
      "progress": {
      "current_page": "recommendation"
  }
 }  
    this.setState({ApiVariable:CampaignUpdate,initialVisit:true},()=>{
      this.PutSetupData()
    })
  }
 }
 PutSetupData = async () => {

  const campaignId = localStorage.getItem("campaignID")
  const persistId=localStorage.getItem("persistId")
  const rowID=persistId?persistId:campaignId
  const authToken = localStorage.getItem('accessToken')
  const header = {
    "Content-Type": "application/json",
    token: authToken
  };
  const PutSetupMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  PutSetupMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  PutSetupMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   `${configJSON.putCampaingChannelsSection}/${rowID}`
  );
  PutSetupMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
  );
  PutSetupMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(this.state.ApiVariable)
  );
  this.putCallID = PutSetupMessage.messageId;

  runEngine.sendMessage(PutSetupMessage.id, PutSetupMessage);
} 
getChannelsReceiver=(responseJson:ResponseData)=>{
this.setState({ ChannelsData: responseJson}); 
}
getBenchmarkInAllLanguage=async()=>{
  const category_name =  await getStorageData("campingIndustry");
  const encodedCategoryName = encodeURIComponent(category_name);
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getBenchmarkInAllLanguageCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_elasticsearch/industry_search?query=${encodedCategoryName}`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}
getDurationDetails = async (): Promise<void> => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getDurationCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getDurationEndPoint}?name=Duration`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

getDurationReceiver=(responseJson:ResponseData)=>{

this.setState({ DurationData: responseJson}); 
}


getConversionTrackingDetails = async (): Promise<void> => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getConversionTrackingCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getConversionTrackingEndPoint}?name=ConversionTracking`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

getConversionTrackingReceiver=(responseJson:ResponseData)=>{
this.setState({ ConversionTrackingData: responseJson}); 
}

postRecommendations = () => {   

  const selectedCountry = localStorage.getItem('selectedCountry');
  const authToken = localStorage.getItem('accessToken')
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: authToken
  };

  const objective_id = localStorage.getItem('objective_id');
  const radius_id = localStorage.getItem('radius_id');

  let formDataCampaign = {
    "data" : {
        "objective_id": objective_id,
        "radius_id": radius_id,
    }
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.postRecommendationsEndPoint}?language=${selectedCountry}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(formDataCampaign)
  );
  this.postRecommendationsCallID = requestMessage.messageId;

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  postRecommendationsReceiver=(responseJson:ApiResponse)=>{
    if(!responseJson ) {this.setState({isLoading:false })} else{this.setState({isLoading:true })} 
    const persistId=localStorage.getItem("persistId");  
    const visitedrecomm=localStorage.getItem("visitedrecomm") 
    if(!persistId && !visitedrecomm){
          const defaultChecked = responseJson.data.channel_mixes.map((e:any)=> e.channel_id)
          this.setState({
            RecommendationData: responseJson,
            selectedSubChannelIds: [...defaultChecked]
          })
        }   
    const addonsChecked = responseJson.data.add_ons.map((e)=>(e.id))

    this.setState({
      RecommendationData: responseJson,
      checkedAddOns:[...addonsChecked]
    },() => {
      const filteredRecommendationData = [];
      for(const item in this.state.RecommendationData.data.addon_flags)
      {
      if(this.state.RecommendationData.data.addon_flags[item]===false)
      {
        filteredRecommendationData.push(item)
      }
    }
    
    this.setState({showTooltip:filteredRecommendationData})
      this.setState({toggle: filteredRecommendationData});
    })
}



putRecommendation = async (item:any) => {
  const campaignId = localStorage.getItem("campaignID")
  const persistId=localStorage.getItem("persistId")
  const rowID=persistId?persistId:campaignId
  let language:any = localStorage.getItem('selectedCountry')
  const authToken = localStorage.getItem('accessToken')
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: authToken
  };
  let Campaign =  {
    "campaign": {
        "budget": this.state.RecommendationData.data.budget,
        "duration": item ? item : this.state.RecommendationData.data.duration,
        "channel_mix_ids": this.state.selectedSubChannelIds,
        "addon_ids": this.state.toggle,
        "language":`${language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()}`,
        "active_page":"objective",
        "visited_key":["setup","recommendation"]
        },
    "progress": {
        "current_page": "objective"
    }
}  
  localStorage.setItem('recData', JSON.stringify(Campaign) as any);
  localStorage.setItem('duraaa','duraaa')
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   `${configJSON.putCampaingChannelsSection}/${rowID}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(Campaign)
  );
  this.putRecommendationCallID = requestMessage.messageId;

  runEngine.sendMessage(requestMessage.id, requestMessage);

}

putRecommendationReceiver=(responseJson:any)=>{
}
onDashboardSave=()=>{
  let language:any = localStorage.getItem('selectedCountry')
  if(this.state.updatedRecommChecked){
  let Campaign =  {
    "campaign": {
        "budget": this.state.RecommendationData.data.budget,
        "duration": this.state.selectedItem?this.state.selectedItem:this.state.RecommendationData.data.duration,
        "channel_mix_ids": this.state.selectedSubChannelIds,
        "addon_ids": this.state.toggle,
        "language":language,
        },
    "progress": {
        "current_page": "recommendation"
    }
}   
   this.setState({ApiVariable:Campaign},()=>{
     this.PutSetupData()
   })
  }
  if(!this.state.updatedRecommChecked){
    
    this.props.history.push("/Dashboard")
  }
}

//istanbul ignore next
getSearchBenchmark=(responseJson:any)=>{
  const language= localStorage.getItem('selectedCountry');
  const selectedFlag=language && language.charAt(0).toLowerCase() + language.slice(1)
  const selectBenchmarklanguage=localStorage.getItem("selectBenchmarklanguage");
  const persistId=localStorage.getItem("persistId")
  const selectedCountry=persistId?selectBenchmarklanguage:selectedFlag
  if(selectedCountry === "german"){
  setStorageData("campingIndustry",responseJson[0].localsearch_schema_category_110_german)
  }else if(selectedCountry === "french"){
    setStorageData("campingIndustry",responseJson[0].localsearch_schema_category_110_french)
  }else if(selectedCountry === "italian"){
    setStorageData("campingIndustry",responseJson[0].localsearch_schema_category_110_italian)
  }
  this.getIndustryBenchmarkDetails()
   }

getIndustryBenchmarkDetails = async (): Promise<void> => {  
  const category_name =  await getStorageData("campingIndustry")
  const click_e = await getStorageData("clickObjectiveName")
  const language= localStorage.getItem('selectedCountry');
  const selectedCountry=language && language.charAt(0).toLowerCase() + language.slice(1)
  const selectBenchmarklanguage=localStorage.getItem("selectBenchmarklanguage");
  const encodedCategoryName = encodeURIComponent(category_name);
  this.setState({benchmarkcategory:encodedCategoryName})
 
  let click_data = "";

  if (click_e === 'Grow Your Brand' || click_e === 'Développez votre marque'
     || click_e === "Bauen Sie Ihre Marke aus" || click_e === "Fai crescere il tuo marchio") {
    click_data = "Brand_Awareness_2";
  } else if (click_e === 'Generate Clicks' || click_e === 'Générer des clics' 
     || click_e === 'Klicks generieren' || click_e === "Genera clic") {
    click_data = "Traffic"
  } else if (click_e === 'Get New Clients' || click_e === "Obtenez de nouveaux clients" 
     || click_e === "Gewinnen Sie neue Kunden" || click_e === "Ottieni nuovi clienti") {
    click_data = "Leads_3"
  }
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getIndustryBenchmarkCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getIndustryBenchmarkEnd}?campaign_objective=${click_data}&category_name=${this.state.benchmarkcategory}&language=${selectBenchmarklanguage?selectBenchmarklanguage:selectedCountry}`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}

getIndustryBenchmarkReceiver=(responseJson:any)=>{ 
  this.setState({ industryBenchmarkData: responseJson});
}
//istanbul ignore next
handleCheckboxChange = (channelMix: { channel_id: number; mandatory: boolean}, event: React.ChangeEvent<HTMLInputElement>) => {
  const isChecked = event.target.checked;
  this.setState({updatedRecommChecked:true})
  if (channelMix.mandatory){
    if (isChecked) {
      this.setState((prevState) => ({
        selectedSubChannelIds: [...prevState.selectedSubChannelIds, channelMix.channel_id],
      }));
    }
  } else{   
    if (isChecked) {
      this.setState((prevState) => ({
        selectedSubChannelIds: [...prevState.selectedSubChannelIds, channelMix.channel_id],
      }));
    } else {
      this.setState((prevState) => ({
        selectedSubChannelIds: prevState.selectedSubChannelIds.filter(
          (id:number) => id !== channelMix.channel_id
          ),
        }),()=>{
          // this.putRecommendation(this.state.selectedSubChannelIds)
        });
    }
  }
  this.UpdateNavigation()
  
};


  toggleDropdownList = () => {
    if(!this.state.viewData){
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  }
  };
  handleTooltipToggleRecommendationBudget = () => {
    this.setState({ tooltipOpenRecommendationBudget: !this.state.tooltipOpenRecommendationBudget });
  };
  handleLocationMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationChannel:true});
    }
  };
   handleLocationMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationChannel:false});}
  }
  handleMouseLocation=()=>{
    this.setState({tooltipOpenRecommendationChannel:!this.state.tooltipOpenRecommendationChannel});
    
  }
  handleDurationMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationDuration:true});
    }
  };
   handleDurationMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationDuration:false});}
  }
  handleMouseDuration=()=>{
    this.setState({tooltipOpenRecommendationDuration:!this.state.tooltipOpenRecommendationDuration});
   
  }
  handleAddonsMouseHovers=(id:number)=>{
    if(window.innerWidth>500){
    
    this.setState({tooltipAddonsData :true,tooltipAddonsDataId:id});
    }
  };
  handleToggleMouseOvers=(id:any)=>{
    if(window.innerWidth>500){
      this.setState({recommendationTooltipOpen :true,tooltipAddonsDataId:id});
    }
  }
  handleToggleMouseHover=(id:any)=>{
    if(window.innerWidth>500){
      this.setState({recommendationTooltipOpen :true,tooltipAddonsDataId:id}); 
      }
  }
  handleHeading=(id: any)=>{
    this.setState({recommendationTooltipOpen :true,tooltipAddonsDataId:id}); 
  }
  handleToggleTooltipClose=()=>{
    this.setState({recommendationTooltipOpen :false})
  }
   handleAddonsMouseOvers=(id:number)=>{
    if(window.innerWidth>500){
      

    this.setState({tooltipAddonsData :false,tooltipAddonsDataId:id});}
  }
  handleAddonsDuration=(id:number)=>{
    this.setState({ tooltipAddonsData :!this.state.tooltipAddonsData,tooltipAddonsDataId:id});
    setTimeout(() => {
      this.setState({ tooltipAddonsData: false });
    }, 2000);
  }
  handleHeadingMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationIndustryBenefit:true});
    }
  };
   handleHeadingMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationIndustryBenefit:false});}
  }
  handleMouseHeading=()=>{
  
    this.setState({tooltipOpenRecommendationIndustryBenefit:!this.state.tooltipOpenRecommendationIndustryBenefit});
  }
  handleeccRoasMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenReccRoas:true});
    }
  };
   handleeccRoasMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenReccRoas:false});}
  }
  handleMouseeccRoas=()=>{
    this.setState({tooltipOpenReccRoas:!this.state.tooltipOpenReccRoas});
    
  }
  handleRoasMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationData:true});
    }
  };
  handleRoasMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationData:false});}
  }
  handleMouseRoas=()=>{
    this.setState({tooltipOpenRecommendationData:!this.state.tooltipOpenRecommendationData});
    if(window.innerWidth<=500){

      this.setState({tooltipOpenRecommendationData:!this.state.tooltipOpenRecommendationData});
    }
  }

  handleImageImageMouseHovers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationBudget:true});
    }
  };
   handleImageImageMouseOvers=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenRecommendationBudget:false});}
  }
  handleMouseIndustyr=()=>{
  
    this.setState({tooltipOpenRecommendationBudget:!this.state.tooltipOpenRecommendationBudget});
    
  }
  handleTooltipCloseRecommendationBudget = () => {
    this.setState({ tooltipOpenRecommendationBudget: false });
  };
  
  handleTooltipOpenRecommendationBudget = (title: any) => {
    this.setState({ tooltipTitleRecommendationBudget: title, tooltipOpenRecommendationBudget: true });
  };
  handleTooltipToggleRecommendationChannel = () => {
    this.setState({ tooltipOpenRecommendationChannel: !this.state.tooltipOpenRecommendationChannel });
  };
  
  handleTooltipCloseRecommendationChannel = () => {
    this.setState({ tooltipOpenRecommendationChannel: false });
  };
  
  handleTooltipOpenRecommendationChannel = (title: any) => {
    this.setState({ tooltipTitleRecommendationChannel: title, tooltipOpenRecommendationChannel: true });
  };
  handleTooltipToggleRecommendationIndustryBenefit = () => {
    this.setState({ tooltipOpenRecommendationIndustryBenefit: !this.state.tooltipOpenRecommendationIndustryBenefit });
  };
  
  handleTooltipCloseRecommendationIndustryBenefit = () => {
    this.setState({ tooltipOpenRecommendationIndustryBenefit: false });
  };
  
  handleTooltipOpenRecommendationIndustryBenefit = (title: any) => {
    this.setState({ tooltipTitleRecommendationIndustryBenefit: title, tooltipOpenRecommendationIndustryBenefit: true });
  };
  handleTooltipToggleRecommendationDuration = () => {
    this.setState({ tooltipOpenRecommendationDuration: !this.state.tooltipOpenRecommendationDuration });
  };
  
  handleTooltipCloseRecommendationDuration = () => {
    this.setState({ tooltipOpenRecommendationDuration: false });
  };
  handleTooltipToggleReccRoas = () => {
    this.setState({ tooltipOpenReccRoas: !this.state.tooltipOpenReccRoas });
  };
  
  handleTooltipCloseReccRoas = () => {
    this.setState({ tooltipOpenReccRoas: false });
  };
  
  handleTooltipOpenReccRoas = (title: any) => {
    this.setState({ tooltipTitleReccRoas: title, tooltipOpenReccRoas: true });
  };
  
  handleTooltipOpenRecommendationDuration = (title: any) => {
    this.setState({ tooltipTitleRecommendationDuration: title, tooltipOpenRecommendationDuration: true });
  };
  handleTooltipToggleAddOns = () => {
    this.setState({ tooltipOpenAddOns: !this.state.tooltipOpenAddOns });
  };
  
  handleTooltipCloseAddOns = () => {
    this.setState({ tooltipOpenAddOns: false });
  };
  
  handleTooltipOpenAddOns = (title: any) => {
    this.setState({ tooltipTitleAddOns: title, tooltipOpenAddOns: true });
  };
  handleTooltipToggleRecommendationData = () => {
    this.setState({ tooltipOpenRecommendationData: !this.state.tooltipOpenRecommendationData });
  };
  
  handleTooltipCloseRecommendationData = () => {
    this.setState({ tooltipOpenRecommendationData: false });
  };
  
  handleTooltipOpenRecommendationData = (title: any) => {
    this.setState({ tooltipTitleRecommendationData: title, tooltipOpenRecommendationData: true });
  };

  UpdateNavigation=()=>{
    const visitedSetup=localStorage.getItem("visitedrecomm");
    const  persistId= localStorage.getItem("persistId")
    if((visitedSetup|| persistId) && !this.state.loadertrue){
    let activeItemss = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
    let index = activeItemss.indexOf('setup');
    let activeItemsTrimmed = activeItemss.slice(0, index + 1);
    let CampaignUpdate:any =  {
      "campaign": {
    "active_page":"setup",
    "visited_key":activeItemsTrimmed
      },
    "progress": {
        "current_page": "setup"
    }
  }
  this.setState({ApiVariable:CampaignUpdate,initialVisit:true},()=>{
    this.PutSetupData()
  })
    this.putRecommendationDisabledDataApi()
    localStorage.removeItem('configSelectDate');
  }
}
  handleItemClick = (item:number) => {
    this.setState({updatedRecommChecked:true})
    this.setState({
      selectedItem: item,
      isDropdownOpen: false,
    });
    this.putRecommendation(item)
    this.UpdateNavigation()
  };
  
  handleCheckout=()=>{
   
    localStorage.setItem('mycurrentPage', "visitrecom");
    this.setState({quoteId : true})
    const visitedSetup=localStorage.getItem("visitedrecomm");
    const persistId=localStorage.getItem("persistId");
    let language:any = localStorage.getItem('selectedCountry')
    const MycPage = localStorage.getItem("mycurrentPage"); 
 
    let key=this.state.visitedQuoteKey;    
    if(visitedSetup || persistId){
    if(!key.includes(this.state.active_key)){
    key.push(this.state.active_key)
     }else if(MycPage){        
        key = ["setup","recommendation"]
      }
      
    }
    
    let Campaign =  {
      "campaign": {
          "budget": this.state.RecommendationData.data.budget,
          "duration": this.state.selectedItem?this.state.selectedItem:this.state.RecommendationData.data.duration,
          "channel_mix_ids": this.state.selectedSubChannelIds,
          "addon_ids": this.state.toggle,
          "language":language,
          "active_page":"setup",
          "visited_key":visitedSetup?key:["setup","recommendation"]
          },
      "progress": {
          "current_page": "Quote"
      }
  }   
     this.setState({ApiVariable:Campaign},()=>{
       this.PutSetupData()
     })
     localStorage.setItem("visitedrecomm","visitedrecomm")
   
  
}
  handleChange = (event: { target: { value: number; }; }) => {
    this.setState({ selectedItem: event.target.value });
  };
  
  
  handleSwitchChange = (ids:string) => {
    this.setState({updatedRecommChecked:true})
    if(this.state.RecommendationData.data?.addon_flags && !this.state.RecommendationData.data?.addon_flags[ids])
    {
      
      return;
    }
    this.setState({})
    let arr = [...this.state.toggle]
    if(!this.state.toggle.includes(ids))
    {
      arr.push(ids)
      this.setState({toggle: arr})
    } else {
      arr = arr.map((item) => {
          if(item !== ids)
          {
            return item;
          }
        })
      this.setState({toggle: arr})
    }

    this.UpdateNavigation()
  }; 

  isTooltipOpen = (id:any) => {
    const { showTooltip, recommendationTooltipOpen, tooltipAddonsDataId } = this.state;

    if (
      showTooltip.includes(id.toString()) &&
      recommendationTooltipOpen &&
      tooltipAddonsDataId === id
    ) {
      return true;
    } else {
      return false;
    }
  }; 
  
  
  handleChangePanel = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
   if(!this.state.viewData){
    this.setState((prevState) => ({
      expandedPanel: isExpanded ? panel : null,
    }));
   }
  
  };

  getRecommendationContentDetails = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "recommendation";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };
  
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.setState({
      getRecommendationContentCallID: requestMessageList.messageId
     })
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.setUpContent}?language=${selectedCountry}&name=${name}`
    );
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  
  getRecommendationContentReceiver=(responseJson:RecomendationContentData)=>{
    this.setState({
      recommendationData: responseJson
    })
  };
  
  toggleModal = () => {
    
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleConfigureClick=()=>{
  let Camping_Id = localStorage.getItem('campaignID')
  const authToken = localStorage.getItem('accessToken')
  let language:any = localStorage.getItem('selectedCountry')
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: authToken
  };
  let Campaign =  {
    "campaign": {
        "budget": this.state.RecommendationData.data.budget,
        "duration": this.state.selectedItem?this.state.selectedItem:this.state.RecommendationData.data.duration,
        "language":`${language.charAt(0).toUpperCase() +language.slice(1).toLowerCase()}`,
        "channel_mix_ids": this.state.selectedSubChannelIds,
        "addon_ids": this.state.toggle,
        "active_page":"objective",
        "visited_key":["setup","recommendation"]
        },
    "progress": {
        "current_page": "objective"
    }
}  
 localStorage.setItem("recommdata",JSON.stringify(Campaign))
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   `bx_block_custom_form/campaigns/${Camping_Id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(Campaign)
  );
  this.putRecommendationToObjectiveCallID = requestMessage.messageId;

  runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  RecommendationToObjectiveReceiver=(responseJson:any)=>{
  localStorage.setItem("visitedrecomm","visitedrecomm")
    this.props.history.push("/Objective")
  
  }

  getRecommendationBudgetHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Budget";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecommendationBudgetHeadingTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getRecommendationBudgetHeadingReceiver=(responseJson:any)=>{
    this.setState({
      recommendationBudgetHeadingTooltip: responseJson
    })
  };

  getRecommendationChannelHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Channels";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecommendationChannelHeadingTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getRecommendationChannelHeadingReceiver=(responseJson:any)=>{
    this.setState({
      recommendationChannelHeadingTooltip: responseJson
    })
  };

  getRecommenadtaionDurationTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    
    const name = "Duration";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecommendationDurationTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getRecommendationDurationTooltipReceiver=(responseJson:any)=>{
    this.setState({
      recommendationDurationTootlips: responseJson
    })
  };

  getRecommendationIndustryBenchmarkHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "IndustryBenchmark";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };
  
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getRecommendationIndBenchHeadingTooltipCcallID = requestMessageList.messageId;
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  
  getRecommendationIndustryBenchmarkHeadingReceiver=(responseJson:any)=>{
    this.setState({
      recommendationindustrybBenHeadingTooltip: responseJson
    })
  };
  
  getRecommendationRoasHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "ROAS";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };
  
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getRecommendationRoasHeadingTooltipCcallID = requestMessageList.messageId;
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );
  
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  
  getReccomendationRoasHeadingReceiver=(responseJson:any)=>{
    this.setState({
      reccRoasHeadingTooltip: responseJson
    })
  };


  closeModal = () => {
    this.setState({ openModel: false });
  };
  // Customizable Area End
}