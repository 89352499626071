import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import { getStorageData, logoutUser, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { testNavi } from "../../../../packages/blocks/utilities/src/CommonUtilities";
interface ResponseData {
  data: {
    id: null |number,
    name: null |string,
    description?: string,
    original_keyword?: string,
    name_code: null,
  },
  campaign_objective_id: any
}
import {
  ClassNameMap,
  ClassKeyOfStyles,
} from '@material-ui/styles';
interface ObjectiveResponseData {
  id: number;
  name: string;
  duration: number;
  description: string;
  photo_url:string;
  clicked:boolean;
  tool_tip:string;
}
interface CampaignResponseData {
  data:{ 
    id: number;
    type:string;
      attributes:{
        company_name: string;
        industry: string;
        objective_id: number;
        budget:string;
        radius_id:number;
        duration:string;
        created_at: string,
        updated_at: string,
        status: string,
        user_photo_link: null,
        user: null
      }
  },
  campaign_objective_id:number
}

interface UpdateCampaignResponseData {
    data: {
        id: string,
        type: string,
        attributes: {
            company_name: string,
            industry: string,
            location: string,
            objective_id: number,
            budget: string,
            radius_id: number,
            duration: string,
            created_at: string,
            updated_at: string,
            status: string,
            user_photo_link: any,
            addons: any,
            channel_mixes: any,
            user: any
        },
        relationships: {
            channel_mixes: {
                data: any
            },
            addons: {
                data: any
            }
        }
    }
}

interface ObjectiveContentData{
  data:{
    tooltip: string,
    heading: string,
    body: string,
    image_url: string
  }
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes: ClassNameMap<ClassKeyOfStyles<string>>;  
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  getObjectivesMessageId: string;
  getCampaignDetailsMessageId:string;
  getObjectiveContentCallID:string;
  isAuthenticated:boolean 
  loading: boolean;
  tooltipOpen: boolean,
  objectiveId:number|null
  tooltipTitle: string,
  tooltipOpenObjectiveIndustryBenefit: boolean,
  tooltipTitleObjectiveIndustryBenefit: string,
  tooltipOpenObjectiveHeading: boolean,
  tooltipTitleObjectiveHeading: string,
  tooltipOpenObjectiveRoas: boolean,
  tooltipTitleObjectiveRoas: string,
  tooltipOpenObjectiveData: boolean,
  tooltipTitleObjectiveData: string,
  tooltipOpenObjectiveDatas: boolean,
  tooltipTitleObjectiveDatas: string,
  selectedBudget: number;
  objectivesDetails: any;
  selectedObjective:number;
  campaignDetails:any;
  updateCampaignMessageId:string; 
  Benchmark:
    { 
      name:string,
      time:string,
    }[],
    select_lang: any,
    industryBenchmarkData:any,
    responseData: ResponseData,
    objectiveData: any,
    isModalOpen: boolean,
    updateChannels:{},
    objectiveHeadingTooltip:any,
    objectiveindustrybBenHeadingTooltip: any
    objectiveRoasHeadingTooltip:any
    obj:string,
    clickObjectiveName:string,
    viewData:boolean,
    clicked:boolean,
    objectApiVariable:any,
    channelNavigate:boolean,
    QuoteNavigate:boolean,
    visitedQuoteKey:any,
    active_key:any,
    initialVisit:boolean,
    updatedObjChecked:boolean,
    loaderTrue:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BudgetController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getIndustryBenchmarkObjCallID:string="";
  getOIbjectiveHeadingTooltipCcallID:string=""
  getOIbjectiveIndBenchHeadingTooltipCcallID:string=""
  getOIbjectiveRoasHeadingTooltipCcallID:string=""
  putCampaignApiCallID:string=""
  getCampaignObjectiveCallID:string=""
  putDisabledApiCallID:string=""
  getBenchmarkInAllLanguageObjCallID:string=""
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {      
      getObjectivesMessageId:"",
      getCampaignDetailsMessageId:"",
      getObjectiveContentCallID:"",
      isAuthenticated:false,
      loading: false,
      tooltipOpen: false,
      tooltipTitle: '',
      tooltipOpenObjectiveRoas: false,
      tooltipTitleObjectiveRoas: '',
      tooltipOpenObjectiveIndustryBenefit: false,
      tooltipTitleObjectiveIndustryBenefit: '',
      tooltipOpenObjectiveHeading: false,
      tooltipTitleObjectiveHeading: '',
      tooltipOpenObjectiveData: false,
      tooltipTitleObjectiveData: '',
      tooltipOpenObjectiveDatas: false,
      tooltipTitleObjectiveDatas: '',
      selectedBudget:0,
      objectiveId:null,
      objectivesDetails:[],
      selectedObjective:0,
      campaignDetails:{},
      updateCampaignMessageId:"",
      Benchmark:[
        { 
          name:"Cost Per Lead (avg.)",
          time:"25 / Month"
        },
        {
          name:"Cost Per Click (avg.)",
          time:"15 CHF"
        },
        {
          name:"Cost Per Impression (avg.)",
          time:"90 / Month"
        },
        {
          name:"Lead Value (avg.)",
          time:"90 / Month"
        },
      ],
      responseData: {
        data:{
          id: 1,
          name: "Roas",
          name_code: null,
        },
        campaign_objective_id: null
      },
      objectiveData:{},
      isModalOpen: false,
      select_lang: {
        name: [],
        time:[],
        buttons_lang: []
    },
    industryBenchmarkData:[],
    updateChannels:{},
    objectiveHeadingTooltip:{},
    objectiveindustrybBenHeadingTooltip:{},
    objectiveRoasHeadingTooltip:{},
    obj:'',
    clickObjectiveName:"",
    viewData:false,
    clicked:false,
    objectApiVariable:{},
    channelNavigate:false,
    QuoteNavigate:false,
    visitedQuoteKey:{},
    active_key:"",
    initialVisit:false,
    updatedObjChecked:false,
    loaderTrue:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start 
    this.getObjectiveDetails();
    this.getsetUpContentDetails();
    this.getObjectiveHeadingTooltip();
    this.getBenchmarkObjInAllLanguage();
    this.getObjectiveIndustryBenchmarkHeadingTooltip();
    this.getObjectiveRoasHeadingTooltip()
    const selectedCountry = localStorage.getItem("selectedCountry");
    const viewOption:any=localStorage.getItem("visitedView")
    const showOption:any=localStorage.getItem("showData")
    this.setState({channelNavigate:false})
    if(viewOption && showOption==="true"){
      this.setState({viewData:true})
    }
    const lang : any = {
      german: {
               name:["Kosten Lead (Dur.)", "Kosten pro Klick (Dur.)", "Kosten Impression (Dur.)", "Lead-Wert (Dur.)"],
               time:["/ Monat","CHF","/ Monat","/ Monat"],
               buttons_lang: ["Zitat", "Nächste"]
              },
      french: {
               name:["Coût par prospect (moy.)", "Coût par clic (moy.)", "Coût par impression (moy.)", "Valeur du prospect (moy.)"],
               time:["/ Mois","CHF","/ Mois","/ Mois"],
               buttons_lang: ["Citation","Suivante"]
              },
      italian: {
               name:[" Costo per lead (medio)", "Costo per clic (medio)", "Costo per impressione (medio)", "Valore lead (medio)"],
               time:["/ mese","franchi","/ mese","/ mese"],
               buttons_lang: ["Citazione","Prossima"]
              },
    };

    const selected_Language = lang[selectedCountry as any] || { name: [], time: [] };
    this.setState({select_lang:selected_Language})
    const persistId=localStorage.getItem("persistId");
    const visitedObjective=localStorage.getItem("visitedObjective");
    if(persistId || visitedObjective){
      this.getCampaignRecommendationData();
    }
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson && responseJson.statusCode === 401){
        this.props.history.push("/EmailAccountLoginBlock");
        logoutUser();          
      }
       
      if (!apiRequestCallId || !responseJson) {

       return;
      }

      switch (apiRequestCallId) {

       case this.state.getObjectivesMessageId:
         this.getObjectiveReceiver(responseJson);
         break;
       case this.state.getCampaignDetailsMessageId:
         this.getCampaignDetailsReceiver(responseJson);
         break;
       case this.state.updateCampaignMessageId:
         this.updateCampaignObjectiveReceiver(responseJson);
         break;
       case this.state.getObjectiveContentCallID:
         this.getContentReceiver(responseJson);
         break;
        case this.getIndustryBenchmarkObjCallID:
         this.getIndustryBenchmarkReceiver(responseJson);
         break;
         case this.getOIbjectiveHeadingTooltipCcallID:
         this.getObjectiveHeadingReceiver(responseJson);
         break;
         case this.getOIbjectiveIndBenchHeadingTooltipCcallID:
         this.getObjectiveIndustryBenchmarkHeadingReceiver(responseJson);
         break;
         case this.getOIbjectiveRoasHeadingTooltipCcallID:
         this.getRoasHeadingReceiver(responseJson);
         break;
         case this.putCampaignApiCallID:
          this.UpdateCampaignApiStatus(responseJson);
          break;
          case this.getBenchmarkInAllLanguageObjCallID:
            this.getSearchObjBenchmark(responseJson)
            break;
         case this.getCampaignObjectiveCallID:
          this.getObjectiveData(responseJson)
break;
      }
      runEngine.debugLog("Message Received", message);
    } 
    // Customizable Area End
 }

  // Customizable Area Start
 
  getObjectiveDetails = async (): Promise<void> => {
    const campaignID = localStorage.getItem("campaignID")
    const category_name =  await getStorageData("campingIndustry")
    const encodedCategoryName = encodeURIComponent(category_name);

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
     const language = localStorage.getItem('selectedCountry');
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );

     
     this.setState({
      getObjectivesMessageId: requestMessageList.messageId
     });
 
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );

     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.getObjectiveApiEndPoint
      `${configJSON.getObjectiveApiEndPoint}?language=${language}&campaign_id=${campaignID}&category_name=${encodedCategoryName}`
     );
 
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
 
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  getObjectiveData=(responseJson:any)=>{
    const res=responseJson.campaign.attributes
    this.setState({
      selectedObjective: res.objective_id
    });
    const visitedSetup=localStorage.getItem("visitedObjective");
    const persistId=localStorage.getItem("persistId");
    if(visitedSetup || persistId){
      this.setState({visitedQuoteKey:responseJson.visited_key,active_key:responseJson.active_page})
      let key=responseJson.visited_key;
      if(!key.includes(responseJson.active_page)){
        key.push(responseJson.active_page)
      }
     
      let CampaignUpdate =  {
       "campaign": {
         "active_page":"objective",
         "visited_key":key
       },
       "progress": {
        "current_page": "objective"
    }
   }  

      this.setState({objectApiVariable:CampaignUpdate,initialVisit:true},()=>{
    
        this.updateCampaignObjectiveDetails()
      })
  }
  }
 getObjectiveReceiver = (responseJson: ResponseData) => { 
  
  this.setState({
    objectivesDetails: responseJson.data ?? [],
    selectedObjective: responseJson?.campaign_objective_id,
   
  });
}

getRorsReceiver=(responseJson:ResponseData)=>{
  this.setState({ responseData: responseJson}); 
}
getCampaignRecommendationData=()=>{
  const visitedSetup=localStorage.getItem("visitedObjective");
  const  persistId= localStorage.getItem("persistId")
  let Camping_Id = localStorage.getItem('campaignID')
  const rowId=visitedSetup?Camping_Id:persistId
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getCampaignObjectiveCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );
   requestMessageList.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
);
   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${"bx_block_custom_form/get_campaign?id="}${rowId}`
   );
   runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }
getCampaignDetailsReceiver = (responseJson: CampaignResponseData) => {
this.setState({
  selectedObjective: responseJson.data.attributes.objective_id,
  campaignDetails: responseJson.campaign_objective_id
})
}
updateData=()=>{
  this.setState({channelNavigate:true})
  this.postApiData() 
  localStorage.setItem("visitedObjective","visitedObjective") 
}
updateCampaignObjectiveDetails = async (): Promise<void> => {
  const campaignId = localStorage.getItem("campaignID")
  const persistId=localStorage.getItem("persistId")
  const rowID=persistId?persistId:campaignId
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };   
   
   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.setState({
    updateCampaignMessageId: requestMessageList.messageId
   })

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.postCreateCampaingEndPoint}/${rowID}`
   );
   requestMessageList.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(this.state.objectApiVariable)    
  );


   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}
//istanbul ignore next
updateCampaignObjectiveReceiver = (responseJson: UpdateCampaignResponseData) => { 
  const navigate= localStorage.getItem("navigate")
  if(this.state.initialVisit){
    this.send(testNavi(this.props))
  }
  if(this.state.updatedObjChecked){
    this.setState({loaderTrue:true})
  }
  if(navigate){
    this.props.history.push("/Dashboard")
  }
  if(this.state.channelNavigate || this.state.QuoteNavigate){
    if(this.state.updatedObjChecked){
      this.putCampaignApi();
    }else{
      if(this.state.channelNavigate){
        this.props.history.push("/Channels")
      } else if(this.state.QuoteNavigate){
        this.props.navigation.navigate("Quote")
      }
  }
  
}    
}
getBenchmarkObjInAllLanguage=async()=>{
  const category_name =  await getStorageData("campingIndustry")
  const encodedCategoryName = encodeURIComponent(category_name);
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getBenchmarkInAllLanguageObjCallID = requestMessageList.messageId;

   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_elasticsearch/industry_search?query=${encodedCategoryName}`
   );

   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageList.id, requestMessageList);
  
}
getSearchObjBenchmark=async(responseJson:any)=>{
  const language= localStorage.getItem('selectedCountry');
  const selectedFlagLang=language && language.charAt(0).toLowerCase() + language.slice(1)
  const selectBenchmark=localStorage.getItem("selectBenchmarklanguage");
  const persistId=localStorage.getItem("persistId")
  const selectedCountry=persistId?selectBenchmark:selectedFlagLang
  if(selectedCountry === "german"){
  setStorageData("campingIndustry",responseJson[0].localsearch_schema_category_110_german)
  }else if(selectedCountry === "french"){
    setStorageData("campingIndustry",responseJson[0].localsearch_schema_category_110_french)
  }else if(selectedCountry === "italian"){
    setStorageData("campingIndustry",responseJson[0].localsearch_schema_category_110_italian)
  }
  this.getIndustryBenchmarkDetails()
   }
putCampaignApi=async (): Promise<void> => {
  const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };   
  
   const requestMessageList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );
   requestMessageList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
   );

   requestMessageList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.putCampaignApiEndPoint}${rowID}`
   );
   requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );
   this.putCampaignApiCallID=requestMessageList.messageId
   runEngine.sendMessage(requestMessageList.id, requestMessageList);
}
putDisabledDataApi=async (): Promise<void> => {
  const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };   
  
   const requestMessageDisableList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );
   requestMessageDisableList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
   );

   requestMessageDisableList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${"bx_block_custom_form/update_recommendation_campaign"}?id=${rowID}&page=objective`
   );
   requestMessageDisableList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );
   this.putDisabledApiCallID=requestMessageDisableList.messageId
   runEngine.sendMessage(requestMessageDisableList.id, requestMessageDisableList);
}
UpdateCampaignApiStatus=(responseJson:any)=>{

  if(this.state.initialVisit){
    this.send(testNavi(this.props))
  }

  if(this.state.channelNavigate){
    this.props.history.push("/Channels")
  } else if(this.state.QuoteNavigate){
    this.props.navigation.navigate("Quote")
  }
}

onDashboardSave=()=>{
  if(this.state.updatedObjChecked){
  let formDataCampaign =  {
    "campaign": {
      "objective_id": this.state.selectedObjective,
    },
    "progress": {
      "current_page": "objective"
    }  
  }  
  this.setState({objectApiVariable:formDataCampaign},()=>this.updateCampaignObjectiveDetails())
}
if(!this.state.updatedObjChecked){
  this.props.history.push("/Dashboard")
}
}
//istanbul ignore next
handleObjectiveClick = (clickedObjective: ObjectiveResponseData) => { 
  if(!this.state.viewData){
    this.setState({updatedObjChecked:true})
 this.setState({selectedObjective:clickedObjective.id})
  this.setState({clicked:true})
  localStorage.removeItem("withoutObj")
  this.setState({
    selectedObjective: clickedObjective.id,
    obj:"objective",
    clickObjectiveName:clickedObjective.name,
  })  
  localStorage.setItem('objective_id', clickedObjective.id?.toString() || '');
  setStorageData("clickObjectiveName", clickedObjective.name); 
  this.getIndustryBenchmarkDetails() 
  const visitedSetup=localStorage.getItem("visitedObjective");
  const  persistId= localStorage.getItem("persistId")
  if((visitedSetup|| persistId) && !this.state.loaderTrue){
  let activeItemss = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
  let index = activeItemss.indexOf('objective');
  let activeItemsTrimmed = activeItemss.slice(0, index + 1);
  let formDataCampaign =  {
    "campaign": {
    "active_page":"objective",
    "visited_key":activeItemsTrimmed
    },
    "progress": {
      "current_page": "objective"
    }  
  }  
  this.setState({objectApiVariable:formDataCampaign},()=>this.updateCampaignObjectiveDetails())

  this.putDisabledDataApi();
  localStorage.removeItem('configSelectDate');

}
  }
};
postApiData=()=>{
  let formDataCampaign =  {
    "campaign": {
      "objective_id": this.state.selectedObjective,
    "active_page":"channels",
    "visited_key":["setup","recommendation","objective"]
    },
    "progress": {
      "current_page": "channels"
    }  
  }  
  this.setState({objectApiVariable:formDataCampaign},()=>this.updateCampaignObjectiveDetails())
}
handleQuote=()=>{
  localStorage.setItem("mycurrentPage","visitObjective")
  const visitedSetup=localStorage.getItem("visitedObjective");
    const persistId=localStorage.getItem("persistId");
    const MycPage = localStorage.getItem("mycurrentPage")
    let key=this.state.visitedQuoteKey;
    if(visitedSetup || persistId){
      if( !key.includes(this.state.active_key)){
        key.push(this.state.active_key)
      } else if(MycPage){        
        key = ["setup","recommendation"]
      }
    }
  let formDataCampaign =  {
    "campaign": {
      "objective_id": this.state.selectedObjective,
    "active_page":"objective",
    "visited_key":visitedSetup? key :["setup","recommendation","objective"]
    },
    "progress": {
      "current_page": "objective"
    }  
  }  
 
  this.setState({objectApiVariable:formDataCampaign,QuoteNavigate:true},()=>this.updateCampaignObjectiveDetails())
  localStorage.setItem("visitedObjective","visitedObjective")  
}

RedirectPage=()=>{
   localStorage.setItem("withoutObj","withoutObj")
  let formDataCampaign =  {
    "campaign": {
    "active_page":"channels",
    "visited_key":["setup","recommendation","objective"]
    },
    "progress": {
      "current_page": "channels"
    }
  }
  this.setState({channelNavigate:true})
  this.setState({objectApiVariable:formDataCampaign},()=>this.updateCampaignObjectiveDetails())
  localStorage.setItem("visitedObjective","visitedObjective") 
  
}

handleImageImageMouseHover=()=>{
  if(window.innerWidth>500){

  this.setState({tooltipOpenObjectiveHeading:true});
  }
};
 handleImageImageMouseOver=()=>{
  if(window.innerWidth>500){

  this.setState({tooltipOpenObjectiveHeading:false});}
}
 handleMouse=()=>{
  
    this.setState({tooltipOpenObjectiveHeading:!this.state.tooltipOpenObjectiveHeading});
  
}

handleobjectiveDataMouseHover=(id: number)=>{
  if(window.innerWidth>500){
    

  this.setState({tooltipOpenObjectiveData:true,objectiveId: id});
  }
};
 handleobjectiveDataMouseOver=(id: number)=>{
 
  if(window.innerWidth>500){

  this.setState({tooltipOpenObjectiveData:false,objectiveId: id});}
}
 handleobjectiveData=(id: number)=>{
      this.setState({tooltipOpenObjectiveData:!this.state.tooltipOpenObjectiveData,objectiveId: id });
  
}
handleObjectiveRoasMouseHover=()=>{
  if(window.innerWidth>500){

  this.setState({tooltipOpenObjectiveRoas:true});
  }
};
 handleObjectiveRoasMouseOver=()=>{
  if(window.innerWidth>500){

  this.setState({tooltipOpenObjectiveRoas:false});}
}
 handleObjectiveRoas=()=>{
  if(window.innerWidth<=500){

    this.setState({tooltipOpenObjectiveRoas:!this.state.tooltipOpenObjectiveRoas});
  }
}
 handleobjectiveDatasMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenObjectiveDatas:true});
    }
  };
   handleobjectiveDatasMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenObjectiveDatas:false});}
  }
   handleobjectiveDatas=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenObjectiveDatas:!this.state.tooltipOpenObjectiveDatas});
    }
  }
  handleBenefitMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenObjectiveIndustryBenefit:true});
    }
  };
   handleBenefitMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenObjectiveIndustryBenefit:false});}
  }
   handleBenefit=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenObjectiveIndustryBenefit:!this.state.tooltipOpenObjectiveIndustryBenefit});
    }
  }

handleTooltipToggleObjectiveDatas = () => {
  this.setState({ tooltipOpenObjectiveDatas: !this.state.tooltipOpenObjectiveDatas });
};

handleTooltipCloseObjectiveDatas = () => {
  this.setState({ tooltipOpenObjectiveDatas: false });
};

handleTooltipOpenObjectiveDatas = (title: string) => {
  this.setState({ tooltipTitleObjectiveDatas: title, tooltipOpenObjectiveDatas: true });
};
handleTooltipToggleObjectiveRoas = () => {
  this.setState({ tooltipOpenObjectiveRoas: !this.state.tooltipOpenObjectiveRoas });
};

handleTooltipCloseObjectiveRoas = () => {
  this.setState({ tooltipOpenObjectiveRoas: false });
};

handleTooltipOpenObjectiveRoas = (title: any) => {
  this.setState({ tooltipTitleObjectiveRoas: title, tooltipOpenObjectiveRoas: true });
};
handleTooltipToggleObjectiveIndustryBenefit = () => {
  this.setState({ tooltipOpenObjectiveIndustryBenefit: !this.state.tooltipOpenObjectiveIndustryBenefit });
};

handleTooltipCloseObjectiveIndustryBenefit = () => {
  this.setState({ tooltipOpenObjectiveIndustryBenefit: false });
};

handleTooltipOpenObjectiveIndustryBenefit = (title: any) => {
  this.setState({ tooltipTitleObjectiveIndustryBenefit: title, tooltipOpenObjectiveIndustryBenefit: true });
};
handleTooltipToggleObjectiveData = () => {
  this.setState({ tooltipOpenObjectiveData: !this.state.tooltipOpenObjectiveData });
};

handleTooltipCloseObjectiveData = () => {
  this.setState({ tooltipOpenObjectiveData: false });
};

handleTooltipOpenObjectiveData = (title: any) => {
  this.setState({ tooltipTitleObjectiveData: title, tooltipOpenObjectiveData: true });
};
handleTooltipToggleObjectiveHeading = () => {
  this.setState({ tooltipOpenObjectiveHeading: !this.state.tooltipOpenObjectiveHeading });
};

handleTooltipCloseObjectiveHeading = () => {
  this.setState({ tooltipOpenObjectiveHeading: false });
};

handleTooltipOpenObjectiveHeading = (title: any) => {
  this.setState({ tooltipTitleObjectiveHeading: title, tooltipOpenObjectiveHeading: true });
};

getsetUpContentDetails = async (): Promise<void> => {
  const selectedCountry = localStorage.getItem('selectedCountry');
  const name = "objective";
  
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
  };

  const requestMessageLists = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.setState({
    getObjectiveContentCallID: requestMessageLists.messageId
   })

  requestMessageLists.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  requestMessageLists.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setUpContent}?language=${selectedCountry}&name=${name}`
  );

  requestMessageLists.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessageLists.id, requestMessageLists);
};

getContentReceiver=(responseJson:ObjectiveContentData)=>{
  this.setState({
    objectiveData: responseJson
  })
};

toggleModal = () => {
  this.setState({ isModalOpen: !this.state.isModalOpen });
};
//istanbul ignore next
getIndustryBenchmarkDetails = async (): Promise<void> => {
  const category_name =  await getStorageData("campingIndustry")
  const click_e = await getStorageData("clickObjectiveName")
  const language= localStorage.getItem('selectedCountry')
  const selectedCountry=language && language.charAt(0).toLowerCase() + language.slice(1)
  const selectBenchmarklanguage=localStorage.getItem("selectBenchmarklanguage");
  const encodedCategoryName = encodeURIComponent(category_name);
 let click_data = "";
 if (click_e === 'Grow Your Brand' || click_e === 'Développez votre marque' || click_e === "Bauen Sie Ihre Marke aus" || click_e === "Fai crescere il tuo marchio") {
   click_data = "Brand_Awareness_2";
 } else if (click_e === 'Generate Clicks' || click_e === 'Générer des clics' || click_e === 'Klicks generieren' || click_e === "Genera clic") {
   click_data = "Traffic";
 } else if (click_e === 'Get New Clients' || click_e === "Obtenez de nouveaux clients" || click_e === "Gewinnen Sie neue Kunden" || click_e === "Ottieni nuovi clienti") {
   click_data ="Leads_3"; 
 }
 
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };

   const requestMessageListObjs = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getIndustryBenchmarkObjCallID = requestMessageListObjs.messageId;

   requestMessageListObjs.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
   );
   requestMessageListObjs.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getIndustryBenchmarkEnd}?campaign_objective=${click_data}&category_name=${encodedCategoryName}&language=${selectBenchmarklanguage?selectBenchmarklanguage:selectedCountry}`
   );

   requestMessageListObjs.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );

   runEngine.sendMessage(requestMessageListObjs.id, requestMessageListObjs);
}

getIndustryBenchmarkReceiver=(responseJson:any)=>{ 
  
  this.setState({ industryBenchmarkData: responseJson});
}


getObjectiveHeadingTooltip = async (): Promise<void> => {
  const selectedCountry = localStorage.getItem('selectedCountry');
  const name = "Objective";
  
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
  };

  const requestMessageLists = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getOIbjectiveHeadingTooltipCcallID = requestMessageLists.messageId;

  requestMessageLists.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  requestMessageLists.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
  );

  requestMessageLists.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessageLists.id, requestMessageLists);
};

getObjectiveHeadingReceiver=(responseJson:any)=>{
  this.setState({
    objectiveHeadingTooltip: responseJson
  })
};

getObjectiveIndustryBenchmarkHeadingTooltip = async (): Promise<void> => {
  const selectedCountry = localStorage.getItem('selectedCountry');
  const name = "IndustryBenchmark";
  
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
  };

  const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getOIbjectiveIndBenchHeadingTooltipCcallID = requestMessageList.messageId;

  requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
  );

  requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessageList.id, requestMessageList);
};

getObjectiveIndustryBenchmarkHeadingReceiver=(responseJson:any)=>{
  this.setState({
    objectiveindustrybBenHeadingTooltip: responseJson
  })
};

getObjectiveRoasHeadingTooltip = async (): Promise<void> => {
  const selectedCountry = localStorage.getItem('selectedCountry');
  const name = "ROAS";
  
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
  };

  const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getOIbjectiveRoasHeadingTooltipCcallID = requestMessageList.messageId;

  requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
  );

  requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessageList.id, requestMessageList);
};

getRoasHeadingReceiver=(responseJson:any)=>{
  this.setState({
    objectiveRoasHeadingTooltip: responseJson
  })
};
  // Customizable Area End

}