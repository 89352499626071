// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { Redirect } from 'react-router-dom'
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ProjectReporting from "../../blocks/ProjectReporting/src/ProjectReporting";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import Download from "../../blocks/Download/src/Download";
import Settings from "../../blocks/Settings/src/Settings";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Customform from "../../blocks/customform/src/Customform";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import ProjectNotes from "../../blocks/ProjectNotes/src/ProjectNotes.web";
import Routes  from "./Routes";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Objective from "../../blocks/customform/src/Objective.web";
import SetUp from "../../blocks/customform/src/SetUp.web";
import Channels from "../../blocks/customform/src/Channels.web";
import Congratulations from "../../blocks/customform/src/Congratulations.web"
import Recommendation from '../../blocks/customform/src/Recommendation.web';
import Budget from "../../blocks/customform/src/Budget.web";
import Configuration from "../../blocks/customform/src/Configurations.web";
import Addons from "../../blocks/customform/src/Addons.web"
import Location from '../../blocks/customform/src/Location.web';
import Quote from '../../blocks/customform/src/Quote.web';

const routeMap = {
ProjectReporting:{
 component:ProjectReporting,
path:"/ProjectReporting"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
Download:{
 component:Download,
path:"/Download"},
Settings:{
 component:Settings,
path:"/Settings"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Customform:{
 component:Customform,
path:"/Customform"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
 path:"/Dashboard",
},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
ProjectNotes:{
 component:ProjectNotes,
path:"/ProjectNotes"},
  Home: {
component:EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Quote:{
    component:Quote,
   path:"/Quote"
  },
  Objective:{
    component:Objective,
   path:"/Objective"
  },
  SetUp:{
    component:SetUp,
   path:"/SetUp"
  },
  Channels:{
    component:Channels,
   path:"/Channels"
  },
  Congratulations:{
    component:Congratulations,
   path:"/Congratulations"
  },
  Recommendation:{
    component:Recommendation,
   path:"/Recommendation"
  },
  Budget:{
    component:Budget,
   path:"/Budget"
  },
  Configuration:{
    component:Configuration,
   path:"/Configuration"
  },
  Addons:{
    component:Addons,
   path:"/Addons"
  },
  Location:{
    component:Location,
   path:"/Location"
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    const isAuthenticated = localStorage.getItem('accessToken') 
    
    return (
        <View style={{ height: '100vh' }}>
          { WebRoutesGenerator({ routeMap }) }
          {/* <Routes /> */}
          <ModalContainer />
        </View>
    );
  }
}

export default App;
