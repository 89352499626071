Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings Body";
exports.redirectURL = " https://localsearchapp-293771-ruby.b293771.dev.eastus.az.svc.builder.cafe/bx_block_login/logins"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End