import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  Grid,
  Typography,
  Tooltip,
  Zoom,
  Input,
  InputAdornment,
  BoxProps,
  TooltipProps
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  withStyles,
  styled,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { info, copyIcon ,landingPageImage ,backIcon, campaignCheck, campaignDone} from "./assets";
import { Link,withRouter } from 'react-router-dom';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const WhiteOnBlackTooltip = withStyles((theme) => ({
  tooltip: {
    height: "auto",
    color: "black",
    marginTop: "20px",
    position: "relative",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    padding: "15px 20px 15px 20px",
    fontWeight: 400,
    maxWidth: "231px",
    fontFamily: "Panton Narrow Regular",
    borderRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  arrow: {
    color:"#E9E9E9",
    content: '""',
  },
}))(Tooltip);

// Customizable Area End

import CongratulationsController, {
  Props,
} from "./CongratulationsController.web";

export  class Congratulations extends CongratulationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
  //istanbul ignore next
  renderBoxView = () => {
    const { classes } = this.props
    const BoxView = Box as React.FC<BoxProps>;
    const { budget, campaing, startdateWithFlow, startDate, select_lang } = this.state;

    let backgroundColor;
    if ((budget && campaing && (startdateWithFlow ? startDate : budget))) {
      backgroundColor = "#005E6E";
    } else {
      backgroundColor = "#B4B4B4";
    }

    const disabled = (!budget || !campaing) || (startdateWithFlow && !startDate);
    return (
      <BoxView className={classes.btn}>
        <Button
          variant="outlined"
          className={classes.button1}
          data-test-id="CampainID"
          disabled={this.state.viewData}
          onClick={() => this.handleCopyToClipboard(this.state.checkout.productId, 'copiedProductId')}
        >
          {this.state.select_lang?.name[4]}
        </Button>
        
        <Button
          variant="contained"
          style={{
            ...webStyle.button,
            backgroundColor: backgroundColor
          }}
          disabled={disabled || this.state.viewData}
          onClick={this.handleButtonClick}
        >
          {select_lang?.name[5]}
        </Button>
      </BoxView>
    )
  }
 //istanbul ignore next
  render() {
    const BoxView = Box as React.FC<BoxProps>;
    const{classes}=this.props;
    const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as React.FC<TooltipProps>;
    console.log(this.state.start_flow,"this.state.start_flow")
    return (
      // Customizable Area Start
      
      <>
      <ThemeProvider theme={theme}>
        {/* <canvas id="confetti"></canvas> */}
            <BoxView style={{...webStyle.Complete,backgroundImage:`url(${landingPageImage})`, boxSizing: 'border-box'}}>
                 <CongrasBox>
                 <CongrasData>
                  <BoxView className={classes.back}  data-test-id="arrow" onClick={this.backArrow}>

                    <img src={backIcon} style={webStyle.backArrow} />

                    <Typography style={{cursor:"pointer"}} >{this.state.select_lang?.name[0]}</Typography>
                  </BoxView>
                  <BoxView  className={classes.scrollView} >
                    <BoxView style={webStyle.titleView}>
                   <Typography className={classes.font} >
                   {this.state?.campaignDetailscxfTooltip?.data?.name}
                      <WhiteOnBlackTooltipView
                        TransitionComponent={Zoom}
                        title={this.state?.campaignDetailscxfTooltip?.data?.description}
                        arrow={true}
                        placement="bottom-start"
                        open={this.state.tooltipOpenQuoteHeading}
                        onClose={this.handleTooltipCloseQuoteHeading}
                      >
                        <img
                          src={info}
                          data-test-id='handleHeading'
                          onMouseLeave={()=>this.handleImageImageMouseOver()}
                          onMouseEnter={()=>this.handleImageImageMouseHover()}
                           onClick={() => this.handleMouse()}                          style={{ marginLeft: "15px" }}
                          alt="Info"
                        />
                      </WhiteOnBlackTooltipView>
                    </Typography>
                    <Typography style={webStyle.text}>
                    {this.state.select_lang?.name[2]}
                    <span style={{color:'black',fontSize:'20px',fontFamily:'pantone',fontWeight:400}}>:</span>
                    </Typography>
                    </BoxView>
                    <Typography className={classes.fonts} >
                    <img src={this.state.campaing ? campaignDone : campaignCheck} alt="campaignCheck" style={{marginRight: "10px", marginTop: "3px"}} />
                    {this.state?.productIdTooltip?.data?.name}
                      <WhiteOnBlackTooltipView
                        TransitionComponent={Zoom}
                        title={this.state?.productIdTooltip?.data?.description}
                        arrow={true}
                        placement="bottom-start"
                        open={this.state.tooltipOpenProduct}
                        onClose={this.handleTooltipCloseProduct}
                      >
                        <img
                          src={info}
                          style={{ marginLeft: "15px" }}
                          alt="Info"
                          data-test-id='producttooltip'
                          onMouseLeave={()=>this.handleproductIdTooltipMouseOver()}
                          onMouseEnter={()=>this.handleproductIdTooltipMouseHover()}
                           onClick={() => this.handleproductIdTooltip()}
                        />
                      </WhiteOnBlackTooltipView>
                    </Typography>
                    <Input
                      fullWidth
                      data-test-id="user-productId"
                      style={{...webStyle.profileDetailsInputContainer,fontFamily: "Panton Narrow Regular"}}
                      disableUnderline
                      placeholder="Se_lo_FA_In_Go_Ba_Pr_Regional_6mos"
                      inputProps = {
                        {
                          sx: {
                            '&::placeholder': {
                             
                              fontWeight:'bold',
                              fontFamily:'Panton Narrow Regular'
                            },
                          },
                        }} 
                      name="productId"
                      autoComplete="off"
                      onChange={this.handleInputChange}
                      value={this.state.checkout.productId}
                      disabled
                      endAdornment={
                        <InputAdornment position="end"
                        data-test-id="InputAdornment">
                          <img
                            src={copyIcon}
                            alt="copy"
                            data-test-id="productId"
                            style={this.state.copiedProductId ? { ...webStyle.copyIcon} : webStyle.copyIcon}
                            onClick={() => this.handleCopyToClipboard(this.state.checkout.productId, 'copiedProductId')}
                          />
                            {this.state.copiedProductId && <span style={{ marginLeft: '5px', color: '#005E6E' }}>{this.state.select_lang.name[7]}!</span>}
                        </InputAdornment>
                      }
                    />
                    <Typography className={classes.fontes}>
                    <img src={this.state.budget ? campaignDone : campaignCheck} alt="campaignCheck"style={{marginRight: "10px", marginTop: "3px"}} />
                    {this.state?.campDetailsBudgetHeadingTooltip?.data?.name}
                      <WhiteOnBlackTooltipView
                        TransitionComponent={Zoom}
                        title={this.state?.campDetailsBudgetHeadingTooltip?.data?.description}
                        arrow={true}
                        placement="bottom-start"
                        open={this.state.tooltipOpenBudget}
                        onClose={this.handleTooltipCloseBUdget}
                      >
                        <img
                          src={info}
                          data-test-id='budgetTooltip'
                          onMouseLeave={()=>this.handleBudgetHeadingMouseOver()}
                          onMouseEnter={()=>this.handleBudgetHeadingMouseHover()}
                           onClick={() => this.handleBudgetHeading()}
                          style={{ marginLeft: "15px" }}
                          alt="Info"
                        />
                      </WhiteOnBlackTooltipView>
                    </Typography>
                    <Input
                      fullWidth
                      data-test-id="user-budget"
                      style={{...webStyle.profileDetailsInputContainer, fontFamily: "Panton Narrow Regular"}}
                      disableUnderline
                      placeholder={"CHF875/" + this.state.select_lang?.name[6]}
                      inputProps = {
                        {
                          sx: {
                            '&::placeholder': {
                            
                              fontWeight:'bold',
                              fontFamily:'Panton Narrow Regular'
                            },
                          },
                        }} 
                      name="budget"
                      autoComplete="off"
                      onChange={this.handleInputChange}
                      value={this.state.checkout.budget}
                      disabled
                      endAdornment={
                        <InputAdornment position="end" data-test-id="endAdornment-budget">
                          <img
                            data-test-id="copy-budget"
                            id='copiedBudget'
                            src={copyIcon}
                            alt="copy"
                            style={this.state.copiedBudget ? { ...webStyle.copyIcon} : webStyle.copyIcon}
                            onClick={() => this.handleCopyToClipboard(this.state.checkout.budget, 'copiedBudget')}
                          />
                          {this.state.copiedBudget && <span style={{ marginLeft: '5px', color: '#005E6E' }}>{this.state.select_lang.name[7]}!</span>}
                        </InputAdornment>
                      }
                    />
                  {this.state.start_flow && 
                      ( <React.Fragment>
                        <Typography className={classes.fontes}>
                      <img src={this.state.startDate ? campaignDone : campaignCheck} alt="campaignCheck" style={{marginRight: "10px", marginTop: "3px"}} />
                        {this.state.campDetailsStartdate?.data?.name}
                        <WhiteOnBlackTooltipView
                          TransitionComponent={Zoom}
                          title={this.state.campDetailsStartdate?.data?.description}
                          arrow={true}
                          placement="bottom-start"
                          open={this.state.tooltipOpenConfigurationDate}
                          onClose={this.handleTooltipCloseConfigurationDate}
                        >
                          <img
                            src={info}
                            style={{ marginLeft: "15px" }}
                            alt="Info"
                            data-test-id='conigdate'
                            onMouseLeave={()=>this.handleConfigurationDateMouseOver()}
                            onMouseEnter={()=>this.handleConfigurationDateMouseHover()}
                             onClick={() => this.handleConfigurationDate()}   
                           />
                        </WhiteOnBlackTooltipView>
                      </Typography>
                      <Input
                        fullWidth
                        data-test-id="user-startDate"
                        style={{...webStyle.profileDetailsInputContainer, fontFamily: "Panton Narrow Regular"}}
                        disableUnderline
                        placeholder="22.03.2023"
                        inputProps={{
                          sx: {
                            '&::placeholder': {
                          
                              fontWeight: 'bold',
                              fontFamily:'panton Narrow Regular  !important',
                            },
                          },
                        }}
                        name="startDate"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                        value={this.state.checkout.startDate}
                        disabled
                        endAdornment={
                          <InputAdornment position="end" data-test-id="endAdornment-startDate">
                            <img
                              data-test-id="copy-startDate"
                              src={copyIcon}
                              alt="copy"
                              style={this.state.copiedStartDate ? { ...webStyle.copyIcon } : webStyle.copyIcon}
                              onClick={() => this.handleCopyToClipboard(this.state.checkout.startDate, 'copiedStartDate')}
                            />
                            {this.state.copiedStartDate && <span style={{ marginLeft: '5px', color: '#005E6E' }}>{this.state.select_lang.name[7]}!</span>}
                          </InputAdornment>
                        }
                      />
                      </React.Fragment>)
                      }
               {this.renderBoxView()}
                  </BoxView>
                </CongrasData>
                </CongrasBox>
              </BoxView>
      </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  Complete:{
    width:"100%",
    height:"100%",
    background:"#c3dbdf",
    display:"flex",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat:"no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
  },
  backArrow:{
    width:"11px",
    height:"21px",
    cursor:"pointer"
  },
  button: {
    width: "194px",
    borderRadius: "43px",
    backgroundColor: "#005E6E",
    color: "#FFFFFF",
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    height: "50px",
    fontSize: "20px",
    textTransform: "capitalize" as const,
    cursor:"pointer"
  },
 
 
  container:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "60px",
  },
 
  text:{
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    fontSize: "16px",
    marginTop:"2px"
  },
  profileDetailsInputContainer: {
    padding: "12.5px 20px 11.5px 20px",
    borderRadius: "12px",
    border: "1px solid #B4B4B4",
    marginTop: "13px",
    background: "#FFFFFF",
    color:'#000000',
    fontFamily:'panton Narrow Regular ',
    fontSize:'16px',
    LineHeight:'19.2px',
    fontWeight:400,
  },
  copyIcon:{
    height:"20px",
    width:"20px",
    cursor:'pointer'
  },
  titleView:{
    marginTop:"54px",
    marginBottom:"25px",
  },

 
  /* YourComponent.css */
};

const CongrasBox : any= styled("div")({
  backgroundColor: "white" as "white",
    borderRadius: "54px" as "54px",
    top: "45%" as "45%",
    left: "50%" as "50%",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    
  height: 'calc(100vh - 65px) !important',
    width: "773px" as "773px",
    boxShadow: "0px 4px 12px 2px #84848480" as "0px 4px 12px 2px #84848480",
    marginTop:'10px',
    "@media (max-width: 1920px)": {
      width: "40%",
    },
    "@media (max-width: 1440px)": {
      width: "50%",
    },
    "@media (max-width: 1366px)": {
      width: "41%",
    },
    "@media (max-width: 1025px)": {
      width: "72%",
    },
    
    "@media (max-width: 960px)": {
      width: "85%",
    },
    "@media (max-width: 500px)": {
      width: "99%",
    },
});

const CongrasData : any= styled(Box)({
  height: "91%",
  width: "552px",
  marginTop: "100px",
  "@media (max-width: 1920px)": {
    width: "80%",
  },
  "@media (max-width: 767px)": {
    width: "100%",
    marginTop: "60px",
    // padding: "20px",
  },
  
});
export const useStyle = (theme: Theme) =>
  createStyles({
    font:{
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      fontSize: "24px",
      display:'flex',
      alignItems:'center',
      '@media(max-width:500px)':{
        fontSize: "20px", 
      }
    },
    fonts:{
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      fontSize: "20px",
      display:'flex',
       alignItems:'center',
      '@media(max-width:500px)':{
        fontSize: "16px", 
      }
    },
    fontes:{
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      fontSize: "20px",
      display:'flex',
      alignItems:'center',
      marginTop:24,
      '@media(max-width:500px)':{
        fontSize: "16px", 
      }
    },
    back:{
      display: "flex",
      justifyContent: "flex-start",
      gap:"15px",
      '@media(max-width:500px)':{
        marginLeft:'15px',
      },     
    },
    scrollView:{
      height: "calc(-100px + 79vh)" as const,
      overflowY:"scroll" as const,
      overflowX: "hidden",
      scrollbarWidth: "thin",
      paddingRight:'10px',
      paddingBottom:"3px",
      '@media(max-width:500px)':{
        marginInline:'15px',
      },
      
    },
    btn:{
      display:"flex",
      justifyContent: "space-between",
      marginTop: "134px",
      '@media(max-width:500px)':{
        marginBottom:'7px',
        display:'flex',
        flexDirection:'column',
       
        alignItem:'center',
        marginTop: "52px",
        justifyContent: 'center',
       alignItems: 'center',
      },
      '@media(max-width:350px)':{
        marginBottom:'5px',
      }
    },
    button1:{
      borderRadius: "43px",
      border:"1px solid #005E6E",
      color: "#005E6E",
      fontFamily: "Panton Narrow Bold",
      fontWeight: 700,
      height: "50px",
      fontSize: "18px",
      textTransform: "capitalize" as const,
      '@media(max-width:960px)':{
        width: "250px",

      },
      '@media(max-width:500px)':{
       marginBottom:'20px',
       width: "220px !important",
       fontSize: "16px",
       
      },
     
      '@media(max-width:1025px)':{
        width: "256px"
      }
    },
   })
export default withRouter(withStyles(useStyle)(Congratulations as any) as any) as any;
// Customizable Area End
