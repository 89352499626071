import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface ResponseData {
  data: {
    id: number;
    name: string;
    heading: string | null;
    body: string | null;
  }
  image_url: string | null
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // classes?: any;
  contentType:string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isReadMore:boolean;
  contentData: any;
  readMoreVisibility: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contentDetailCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isReadMore:true,
      contentData:{imageURL:"", description:""},
      readMoreVisibility: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    console.log('data receive message', message);
    
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    console.log('data responseJson', responseJson);
    
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.contentDetailCallId && responseJson) {
      this.contentDetailSuccessData(responseJson)
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getContentDetails();
  }

  toggleReadMore = () => { 
    this.setState({isReadMore:!this.state.isReadMore}); 
  }; 

  getContentDetails = async (): Promise<void> => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      // token: localStorage.getItem("accessToken")
     };

     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.contentDetailCallId = requestMessageList.messageId;
 
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );

     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getContentDetails}?name=${this.props.contentType}`
     );
 
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
 
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }

 contentDetailSuccessData = (responseJson:ResponseData) => {
  let contentData = this.state.contentData
  if(responseJson != null){
    contentData = {
     imageURL: responseJson.image_url != null ? responseJson.image_url : "",
     description: responseJson.data.body != null ? responseJson.data.body : ""
   }
  }
  
  this.setState({
    contentData: contentData,
    readMoreVisibility: contentData.description != "" && contentData.description.length > 150 ? true: false
  });
}
  // Customizable Area End
}