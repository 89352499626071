import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  google,
  bannerad,
} from "./assets";
import { logoutUser } from "../../../framework/src/Utilities";


// Customizable Area Start
import { testNavi } from "../../../../packages/blocks/utilities/src/CommonUtilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  location:any;
  history:any
  // Customizable Area End
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  expandedPanel: string | null,
  premiumAds:PremiumAds[],
  channelsList:any,
  ChannelsData:ResponseData,
  selectedChannelIds:{}[],
  checkboxStates: {},
  campaignDetails:{}[],
  channelData: any,
  tooltipOpenChanelsDatas: boolean,
  tooltipTitleChanelsDatas: string,
  tooltipOpenchannelHeadingTooltip: boolean,
  tooltipTitlechannelHeadingTooltip: string,
  tooltipOpen: boolean,
  tooltipTitle: string,
  isModalOpen: boolean,
  channelHeadingTooltip: any,
  select_lang:any,
  viewData:boolean,
  channelDataiD:any,
  channelApiVariable:any,
  QuoteNavigate:boolean,
  locationNavigate:boolean,
  channelShow:boolean,
  visitedQuoteKey:any,
  active_key:string,
  channel_Obj:boolean,
  updatedChannelChecked:boolean,
  loaderTrue:boolean
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface PremiumAds  {
  img: string;
  title: string;
}

interface ResponseData {
  data: {
    id: null,
    name: null,
    description?: string,
    original_keyword?: string,
    name_code: null,
  },
}
interface SubChannel {
  id: number;
  name: string;
  enabled: boolean;
  photo: string;
}

interface Channel {
  channel_id: number;
  channel_name: string;
  sub_channels: SubChannel[];
}


interface ChannelContentData{
  data:{
    tooltip: string,
    heading: string,
    body: string,
    image_url: string
  }
}
export default class RecommendationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCampaingChannelsCallID:string=""
  getChannelToolTipCallID:string=""
  putCampaingChannelsCallID:string=""
  getChannelContentCallID:string=""
  getChannelHeadingTooltipCallID:string=""
  getCampaignChannelCallID:string=""
  putChannelDisabledApiCallID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      expandedPanel: null,
      premiumAds:[
        {
          img:google,
          title:"Local",
      },
      {
        img:bannerad,
        title:"Search",
      }
    ],
    channelsList:[],
    ChannelsData:{
      data:{
        id: null,
        name: null,
        name_code: null,
      } 
    },
    selectedChannelIds:[] as string[],
    checkboxStates:{},
    campaignDetails:[],
    channelData: {},
    isModalOpen: false,
    tooltipOpen: false,
    tooltipTitle: '',
    tooltipOpenChanelsDatas: false,
    tooltipTitleChanelsDatas: '',
    tooltipOpenchannelHeadingTooltip: false,
    tooltipTitlechannelHeadingTooltip: '',
    channelHeadingTooltip:{},
    select_lang:{name:[]},
    viewData:false,
    channelDataiD:[],
    channelApiVariable:{},
    QuoteNavigate:false,
    locationNavigate:false,
    channelShow:false,
    visitedQuoteKey:{},
    active_key:"",
    channel_Obj:false,
    updatedChannelChecked:false,
    loaderTrue:false
      // Customizable Area End
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


    // Customizable Area Start
    async componentDidMount(): Promise<void> {
      const getLocalData:any = localStorage.getItem("sidebarData")
      const viewOption:any=localStorage.getItem("visitedView")
      const showOption:any=localStorage.getItem("showData")
  
      if(viewOption && showOption==="true"){
        this.setState({viewData:true})
      }
    await Promise.all([this.getChannelsTooltipDetails(),this.getChannelContentDetails(), this.getChannelHeadingTooltip()
    ]);
    const selectedCountry = localStorage.getItem("selectedCountry");
    const lang : any = {
      german: {
               name:["Zitat", "Nächste"],
              },
      french: {
               name:["Citation","Suivante"],
              },
      italian: {
               name:["Citazione","Prossima"],
              },
    };

    const selected_Language = lang[selectedCountry as any] || { name: [], time: [] };
    this.setState({select_lang:selected_Language})
    const  withoutObj=localStorage.getItem("withoutObj");
  const recommData:any=localStorage.getItem("recommdata")
  const recc=JSON.parse(recommData)
  if(withoutObj){
    let CampaignUpdate =  {
      "campaign": {
        "budget": recc.campaign.budget,
        "duration": recc.campaign.duration,
        "language":recc.campaign.language,
        "channel_mix_ids": recc.campaign.channel_mix_ids,
      }, 
       "progress": {
       "current_page": "channels"
   }
  }  
     this.setState({channelApiVariable:CampaignUpdate,channel_Obj:true},()=>{
       this.putCreateCampaing()
     })
  }else{
    this.getChannelsDetails()
  }
    const persistId=localStorage.getItem("persistId");
    const visitedChannel=localStorage.getItem("visitedChannel")
    if(visitedChannel || persistId){
      this.getCampaignRecommendationData();
      this.setState({channelShow:true})
    }
  }
  
    async receive(from: string, message: Message) {
      if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
        return;
    } 
    
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if(responseJson && responseJson.statusCode === 401){
      this.props.history.push("/EmailAccountLoginBlock");
      logoutUser();          
    }

    if (!apiRequestCallId || !responseJson) {
        return;
    }
    if (apiRequestCallId === this.getCampaingChannelsCallID && responseJson) {
      this.getChannelsReceiver(responseJson)
    }else if(apiRequestCallId === this.getChannelToolTipCallID && responseJson){
      this.getChannelsTooltipReceiver(responseJson)
    }else if(apiRequestCallId === this.putCampaingChannelsCallID && responseJson){
      this.putChannelsReceiver(responseJson)
    } else if(apiRequestCallId === this.getChannelContentCallID){
      this.getContentReceiver(responseJson);
     } else if (apiRequestCallId === this.getChannelHeadingTooltipCallID){
        this.getChannelHeadingReceiver(responseJson)
     }else if(apiRequestCallId ===this.getCampaignChannelCallID){
      this.getChannelData(responseJson)
     }


    
      runEngine.debugLog("Message Recived", message);
    }
    // Customizable Area End
  

  // web events

  // Customizable Area Start

  getChannelsDetails = async (): Promise<void> => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
    const objective_id = localStorage.getItem('objective_id');
    const selectedCountry = localStorage.getItem('selectedCountry')
    const Camping_Id = localStorage.getItem('campaignID')     
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaingChannelsCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
  
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChannelsSectionEndPoint}?objective_id=${objective_id}&language=${selectedCountry}&campaign_id=${Camping_Id}`
     );
  
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
  
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getChannelsReceiver=(responseJson:any)=>{
    const channelIddd=responseJson.data.map((i:any)=>i.channel_id)
      const arr1=  responseJson.campaign_channels_ids 
      const respons=  responseJson.data.filter((i:any)=>arr1.includes(i.channel_id))
      if(respons.length===0){
        this.setState({channelDataiD:channelIddd})
      }else{
        this.setState({channelDataiD:arr1})
      }
      this.setState({channelsList:responseJson.data , selectedChannelIds:[respons]}) 
      localStorage.removeItem("withoutObj") 
  }

  getChannelsTooltipDetails = async (): Promise<void> => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getChannelToolTipCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
  
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChannelsEndPoint}?name=Channels`
     );
  
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
  
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  
  getChannelsTooltipReceiver=(responseJson:ResponseData)=>{
  this.setState({ ChannelsData: responseJson}); 
 }
  getCampaignRecommendationData=()=>{
    const visitedSetup=localStorage.getItem("visitedChannel");
    const  persistId= localStorage.getItem("persistId")
    let Camping_Id = localStorage.getItem('campaignID')
    const rowId=visitedSetup?Camping_Id:persistId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaignChannelCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ConfigurationDataEndPoint}${rowId}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
   }

   getChannelData=(responseJson:any)=>{
    const persistId=localStorage.getItem("persistId");
    if(persistId){
      const defaultChecked = responseJson.channel_mixes.map((e:any)=> e.channel_id)
      this.setState({
        selectedChannelIds: [...defaultChecked]
      })
    }
      let key=responseJson.visited_key;      
      this.setState({visitedQuoteKey:responseJson.visited_key,active_key:responseJson.active_page})   
      if(!key.includes(responseJson.active_page) )
      {
         key.push(responseJson.active_page)
      }
      let CampaignUpdate =  {
       "campaign": {
         "active_page":"channels",
         "visited_key":key
       }, 
        "progress": {
        "current_page": "channels"
    }
   }  
   
      this.setState({channelApiVariable:CampaignUpdate},()=>{
        this.putCreateCampaing()
      })
    
   }
  putCreateCampaing = () => {
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")    
    const rowID=persistId?persistId:campaignId
    const authToken = localStorage.getItem('accessToken')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.putCampaingChannelsSection}/${rowID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.channelApiVariable)
    );
    this.putCampaingChannelsCallID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


// ----------Camping reciver -----------------
 
putChannelsReceiver=(responseJson:any)=>{
 
  if(this.state.channel_Obj){
    this.getChannelsDetails()
  }
  this.send(testNavi(this.props))
  if(this.state.updatedChannelChecked){
    this.setState({loaderTrue:true})
  }
  const navigate= localStorage.getItem("navigate")
 if(navigate){
  this.props.history.push("/Dashboard")
 }
  if(this.state.locationNavigate){
    this.props.history.push("/Location")
  } else if(this.state.QuoteNavigate){
    this.props.navigation.navigate("Quote")
  }
}
putChannelDisabledDataApi=async (): Promise<void> => {
  const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
  
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("accessToken")
   };   
  
   const requestMessageChannelList = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
   );
   requestMessageChannelList.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.sellerDetailsAPIMethodPUT
   );

   requestMessageChannelList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${"bx_block_custom_form/update_recommendation_campaign"}?id=${rowID}&page=channels`
   );
   requestMessageChannelList.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
   );
   this.putChannelDisabledApiCallID=requestMessageChannelList.messageId
   runEngine.sendMessage(requestMessageChannelList.id, requestMessageChannelList);
}
handleQuote=()=>{
  localStorage.setItem('mycurrentPage',"visitChannel")
  const visitedSetup=localStorage.getItem("visitedChannel");
  const persistId=localStorage.getItem("persistId");
  const MycPage = localStorage.getItem("mycurrentPage");
  
  let key=this.state.visitedQuoteKey;
  
  if(visitedSetup || persistId){
    if(!key.includes(this.state.active_key) ){
    key.push(this.state.active_key)
    }
   else if(MycPage){
        key = ["setup","recommendation","objective","channels"]
    }
  }
 
  let Campaign = {
    "campaign": {
    "channel_mix_ids": this.state.channelDataiD,
    "active_page":"channels",
    "visited_key":visitedSetup?key:["setup","recommendation","objective","channels"]
        },
    "progress": {
         "current_page": "Quote"
    }
  }
 
  localStorage.setItem("visitedChannel","visitedChannel")
  this.setState({channelApiVariable:Campaign},()=> this.putCreateCampaing())
localStorage.removeItem("withoutObj")
this.setState({QuoteNavigate:true})
}
onDashboardSave=()=>{
  if(this.state.updatedChannelChecked){
  let Campaign = {
    "campaign": {
    "channel_mix_ids": this.state.channelDataiD,
        },
    "progress": {
         "current_page": "channels"
    }
  }
  this.setState({channelApiVariable:Campaign},()=> this.putCreateCampaing())
  localStorage.removeItem("withoutObj")
}
if(!this.state.updatedChannelChecked){
  this.props.history.push("/Dashboard")
}
}
handleLocationNav=()=>{
  this.setState({locationNavigate:true})
  
  let Campaign = {
    "campaign": {
    "channel_mix_ids": this.state.channelDataiD,
    "active_page":"location",
    "visited_key":["setup","recommendation","objective","channels"]
        },
    "progress": {
         "current_page": "location"
    }
  }
  localStorage.setItem("visitedChannel","visitedChannel")
  this.setState({channelApiVariable:Campaign},()=> this.putCreateCampaing())
  
  localStorage.removeItem("withoutObj")

}

handleChangePanel = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
  if(!this.state.viewData){
   this.setState((prevState) => ({
     expandedPanel: isExpanded ? panel : null,
   }));
  }
 
 };
  handleHeadingMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenchannelHeadingTooltip:true});
    }
  };
   handleHeadingMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenchannelHeadingTooltip:false});}
  }
   handleHeading=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenchannelHeadingTooltip:!this.state.tooltipOpenchannelHeadingTooltip});
    }
  }
  handlechannelDataMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenChanelsDatas:true});
    }
  };
   handlechannelDataMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenChanelsDatas:false});}
  }
   handlechannelData=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenChanelsDatas:!this.state.tooltipOpenChanelsDatas});
    }
  }

  handleTooltipToggleObjectiveDatas = () => {
    this.setState({ tooltipOpenChanelsDatas: !this.state.tooltipOpenChanelsDatas });
  };
  
  handleTooltipCloseChanelsDatas = () => {
    this.setState({ tooltipOpenChanelsDatas: false });
  };
  
  handleTooltipOpenChanelsDatas = (title: string) => {
    this.setState({ tooltipTitleChanelsDatas: title, tooltipOpenChanelsDatas: true });
  };

  handleTooltipToggleObjectiveData = () => {
    this.setState({ tooltipOpenchannelHeadingTooltip: !this.state.tooltipOpenchannelHeadingTooltip });
  };
  
  handleTooltipCloseChanelsData = () => {
    this.setState({ tooltipOpenchannelHeadingTooltip: false });
  };
  
  handleTooltipOpenChanelsData = (title: any) => {
    this.setState({ tooltipTitlechannelHeadingTooltip: title, tooltipOpenchannelHeadingTooltip: true });
  };
  //stanbul ignore next
handleCheckboxChange = (channelMix: { mandatory: any; channel_id: any; }, event:any) => {
  const isChecked = event.target.checked;
  this.setState({updatedChannelChecked:true})
  if(channelMix.mandatory)
  {
    return ;
  }
  let arr = [...this.state.channelDataiD]
  if(!this.state.channelDataiD.includes(channelMix.channel_id))
  {
    arr.push(channelMix.channel_id)
    this.setState({channelDataiD: arr})
  } else {
    arr = arr.filter((item) => item !== channelMix.channel_id);
    this.setState({channelDataiD: arr})
  }
  const visitedSetup=localStorage.getItem("visitedChannel");
  const  persistId= localStorage.getItem("persistId")
  if((visitedSetup|| persistId) && !this.state.loaderTrue){
    let activeItemss = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
    let index = activeItemss.indexOf('channels');
    let activeItemsTrimmed = activeItemss.slice(0, index + 1);    
    let Campaign = {
      "campaign": {
      "active_page":"channels",
      "visited_key":activeItemsTrimmed
          },
      "progress": {
           "current_page": "channels"
      }
    }
      this.setState({channelApiVariable:Campaign,channel_Obj:false},()=> this.putCreateCampaing())
    this.putChannelDisabledDataApi()
    localStorage.removeItem('configSelectDate');
   }
  
  }
  


  getChannelContentDetails = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "channels";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };
  
    const requestMessageLists = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getChannelContentCallID = requestMessageLists.messageId;
  
    requestMessageLists.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
  
    requestMessageLists.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.setUpContent}?language=${selectedCountry}&name=${name}`
    );
  
    requestMessageLists.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessageLists.id, requestMessageLists);
  };
  
  getContentReceiver=(responseJson:ChannelContentData)=>{
    this.setState({
      channelData: responseJson
    })
  };
  
  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  getChannelHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Channels";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChannelHeadingTooltipCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getChannelHeadingReceiver=(responseJson:any)=>{
    this.setState({
      channelHeadingTooltip: responseJson
    })
  };
  // Customizable Area End
}