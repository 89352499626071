import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";

// Customizable Area Start
import { testNavi } from "../../../../packages/blocks/utilities/src/CommonUtilities";
import {
  ClassNameMap,
  ClassKeyOfStyles,
} from '@material-ui/styles';
import { logoutUser } from "../../../framework/src/Utilities";
interface ResponseData {
  data: {
    id: number;
    type: string;
    attributes:{
      min_value: string;
      max_value: string;
    }
  },
  campaign_budget: string
}
interface UpdateCampaignBudgetResponseData {
  data: {
      attributes: {
          company_name: string,
          industry: string,
          location: string,
          objective_id: number,
          budget: string,
          radius_id: number,
          duration: string,
          created_at: string,
          updated_at: string,
          status: string,
          user_photo_link: any,
          addons: any,
          channel_mixes: any,
          user: any
      },
      id: string,
      type: string,
      relationships: {
          channel_mixes: {
              data: any
          },
          addons: {
              data: any
          }
      }
  }
}
interface BudgetContentData{
  data:{
    tooltip: string,
    heading: string,
    body: string,
    image_url: string
  }
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  
  // Customizable Area Start
  history:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  getBudgetRangeMessageId: string;
  getBudgetContentCallID:string;
  isAuthenticated:boolean 
  loading: boolean;
  selectedBudget: number;
  selectedValuePrice:boolean,
  marks: any;
  selectedObjectives:number;
  updateCampaignBudgetMessageId:string;
  budgetData: any,
  isModalOpen: boolean,
  budgetDetails:object,
  budgetHeadingTooltip:any,
  tooltipOpen: boolean,
  tooltipTitle: string,
  tooltipOpenBudgetHeadingTooltip: boolean,
  tooltipTitleBudgetHeadingTooltip: string,
  tooltipOpenBudgetDataTooltip: boolean,
  tooltipTitleBudgetDataTooltip: string,
  select_lang: any,
  viewData:boolean,
  budgetApiVariable:any,
  QuoteNavigate:boolean,
  AddonNavigate:boolean,
  visitedQuoteKey:any,
    active_key:string,
    initialBudVisit:boolean,
    updatedBudgetChecked:boolean,
    loaderTrue:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BudgetController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBudgetHeadingTooltipCallID:string=""
  getCampaignBudgetCallID:string=""
  putBudgetDisabledApiCallID:string=" "
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      getBudgetRangeMessageId:"",
      updateCampaignBudgetMessageId:"",
      getBudgetContentCallID:"",
      isAuthenticated:false,
      loading: false,
      selectedBudget:0,
      selectedValuePrice:false,
      selectedObjectives:0,
      marks:[],
      budgetData: {},
      isModalOpen: false,
      budgetDetails:{},
      budgetHeadingTooltip:{},
      tooltipOpen: false,
      tooltipTitle: '',
      tooltipOpenBudgetHeadingTooltip: false,
      tooltipTitleBudgetHeadingTooltip: '',
      tooltipOpenBudgetDataTooltip: false,
      tooltipTitleBudgetDataTooltip: '',
      select_lang: {name: []},
      viewData:false,
      budgetApiVariable:{},
      QuoteNavigate:false,
      AddonNavigate:false,
      visitedQuoteKey:{},
    active_key:"",
    initialBudVisit:false,
    updatedBudgetChecked:false,
    loaderTrue:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const persistId=localStorage.getItem("persistId");
    const visitedBudget=localStorage.getItem("visitedBudget")
    if(visitedBudget || persistId){
      this.getCampaignSetUpData()
    }
    const selectedCountry = localStorage.getItem("selectedCountry");
    const viewOption:any=localStorage.getItem("visitedView")
    const showOption:any=localStorage.getItem("showData")
    if(viewOption && showOption==="true"){
      this.setState({viewData:true})
    }
    this.getBudgetRange();
    const lang : any = {
      german: { name:["Zitat", "Nächste"]},
      french: {name:["Citation","Suivante"]},
      italian: {name:["Citazione","Prossima"]},
    };
    const selected_Language = lang[selectedCountry as any] || { name: []};
    this.setState({select_lang:selected_Language})
    this.getBudgetContentDetails();
    this.getBudgetlHeadingTooltip();

    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.state.getBudgetRangeMessageId:
            this.budgetSuccessData(responseJson);
            break;
          case this.state.updateCampaignBudgetMessageId:
            this.updateCampaignBudgetReceiver(responseJson);
            break;
          case this.state.getBudgetContentCallID:
            this.getBudgetContentReceiver(responseJson);
            break;
            case this.getBudgetHeadingTooltipCallID:
              this.getBudgetHeadingReceiver(responseJson);
              break;
            case this.getCampaignBudgetCallID:
              this.getCampaignSetUpReceiver(responseJson);
              break;
          default:
            break;
        }
  
        if (responseJson.statusCode === 401) {
          logoutUser();
          this.props.history.push("/EmailAccountLoginBlock");
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onSliderChange=(value: any)=>{
    this.setState({updatedBudgetChecked:true})
 if(!this.state.viewData){
  this.setState({
    selectedBudget: value
  })
  const  persistId= localStorage.getItem("persistId")
  const visitedSetup=localStorage.getItem("visitedBudget");
  if((visitedSetup|| persistId) && !this.state.loaderTrue){
  let activeItemss = ['setup', 'objective', 'channels', 'location', "budget", "addons", "configuration", 'quote', 'campaign details'];
  let index = activeItemss.indexOf('budget');
  let activeItemsTrimmed = activeItemss.slice(0, index + 1);
  let CampaignUpdate =  {
    "campaign": {
      "visited_key":activeItemsTrimmed,
      "active_page":"budget"
    }, 
     "progress": {
     "current_page": "budget"
 }
}  
   this.setState({budgetApiVariable:CampaignUpdate},()=>{
     this.updateCampaignBudget()
   })
      this.putBudgetDisabledDataApi()
      localStorage.removeItem('configSelectDate');
  }
 }}
 
  
  
  getBudgetRange = async () => {
    const persistId=localStorage.getItem("persistId");
    const objective_id = localStorage.getItem('objective_id');
    let Camping_Id = localStorage.getItem('campaignID')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.setState({
        getBudgetRangeMessageId: requestMessage.messageId,
      });
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.budgetRange}?objective_id=${objective_id}&campaign_id=${persistId?persistId:Camping_Id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      );
      
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCampaignSetUpData=()=>{

    const visitedSetup=localStorage.getItem("visitedSetup");
    const  persistId= localStorage.getItem("persistId")
    let Camping_Id = localStorage.getItem('campaignID')
    const rowId=visitedSetup?Camping_Id:persistId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaignBudgetCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ConfigurationDataEndPoint}${rowId}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
   }
  getCampaignSetUpReceiver=(responseJson:any)=>{
    const persistId=localStorage.getItem("persistId");
    const visitedSetup=localStorage.getItem("visitedBudget");
    if(visitedSetup || persistId){
      let key=responseJson.visited_key;
      this.setState({visitedQuoteKey:responseJson.visited_key,active_key:responseJson.active_page})

      if(!key.includes(responseJson.active_page) )
      {
         key.push(responseJson.active_page)
      }
      let Campaign =  {
       "campaign": {
         "visited_key":key,
         "active_page":"budget"
       }, 
        "progress": {
        "current_page": "budget"
    }
   }  
      this.setState({budgetApiVariable:Campaign,initialBudVisit:true},()=>{
        this.updateCampaignBudget()
      })
    }
     
   }
  budgetSuccessData = (responseJson:ResponseData | any) => {
    const campaignBudget = responseJson.campaign_budget;
    const minValue = Math.round(parseInt(responseJson.data.attributes.min_value));
    const maxValue = Math.round(parseInt(responseJson.data.attributes.max_value));


  let budget = [{
    value: minValue
},
{
    value: maxValue
}
];
    this.setState({
      marks: budget,
      selectedBudget: campaignBudget || `${minValue}.0`,
    });
    
  }

  updateCampaignBudget = async (): Promise<void> => {
    const campaignId = localStorage.getItem("campaignID")
    const persistId=localStorage.getItem("persistId")
    const rowID=persistId?persistId:campaignId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
  
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.setState({
      updateCampaignBudgetMessageId: requestMessageList.messageId
     })
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
     );
  
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCreateCampaingEndPoint}/${rowID}`
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.budgetApiVariable)
    );
  
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
  
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  onDashboardSave=()=>{
    if(this.state.updatedBudgetChecked){
      let formDataCampaign =  {
        "campaign": {
            "budget": this.state.selectedBudget,
            },
        "progress": {
            "current_page": "budget"
        }
      }
      this.setState({budgetApiVariable:formDataCampaign},()=>this.updateCampaignBudget())
    }
    if(!this.state.updatedBudgetChecked){
      this.props.history.push("/Dashboard")
    } 
  }
  postBudgetApiData=()=>{
    localStorage.setItem("visitedBudget","visitedBudget")
    let formDataCampaign =  {
      "campaign": {
        "active_page":"addons",
          "budget": this.state.selectedBudget,
      "visited_key":["setup","recommendation","objective","channels","location","budget"]
          },
      "progress": {
          "current_page": "addons"
      }
    }
    this.setState({budgetApiVariable:formDataCampaign},()=>this.updateCampaignBudget())
  }
  handleQuote=()=>{
    localStorage.setItem("mycurrentPage","visitBudget")
    const persistId=localStorage.getItem("persistId");
    const visitedSetup=localStorage.getItem("visitedBudget");
    const MycPage = localStorage.getItem("mycurrentPage")
    let key=this.state.visitedQuoteKey;
    if(visitedSetup || persistId){

      if(!key.includes(this.state.active_key)){
        key.push(this.state.active_key)
      }else if(MycPage){        
        key = ["setup","recommendation","objective","channels","location"]
      }
    }
    let formDataCampaign =  {
      "campaign": {
        "active_page":"budget",
          "budget": this.state.selectedBudget,
      "visited_key":visitedSetup?key:["setup","recommendation","objective","channels","location"]
          },
      "progress": {
          "current_page": "Quote"
      }
    }
    this.setState({budgetApiVariable:formDataCampaign},()=>this.updateCampaignBudget())
    this.setState({QuoteNavigate:true})
    localStorage.setItem("visitedBudget","visitedBudget")
  }
  putBudgetDisabledDataApi=async (): Promise<void> => {
    const campaignId = localStorage.getItem("campaignID")
      const persistId=localStorage.getItem("persistId")
      const rowID=persistId?persistId:campaignId
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };   
    
     const requestMessageBudgetList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
     requestMessageBudgetList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
     );
  
     requestMessageBudgetList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_custom_form/update_recommendation_campaign"}?id=${rowID}&page=budget`
     );
     requestMessageBudgetList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
     this.putBudgetDisabledApiCallID=requestMessageBudgetList.messageId
     runEngine.sendMessage(requestMessageBudgetList.id, requestMessageBudgetList);
  }
  handleAddonNav=()=>{
    this.setState({AddonNavigate:true})
this.postBudgetApiData()
  }
  updateCampaignBudgetReceiver = (responseJson: UpdateCampaignBudgetResponseData | any) => {
    if(this.state.initialBudVisit){
      this.send(testNavi(this.props))
    } 
    if(this.state.updatedBudgetChecked){
      this.setState({loaderTrue:true})
    }
    const navigate= localStorage.getItem("navigate")
    if(navigate){
     this.props.history.push("/Dashboard")
    }
    if(this.state.AddonNavigate){
              this.props.history.push("/Addons")
            } else if(this.state.QuoteNavigate){
              this.props.navigation.navigate("Quote")
            }

  }

  getBudgetContentDetails = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "budget";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };
  
    const requestMessageLists = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.setState({
      getBudgetContentCallID: requestMessageLists.messageId
     })
  
    requestMessageLists.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
  
    requestMessageLists.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.setUpContent}?language=${selectedCountry}&name=${name}`
    );
  
    requestMessageLists.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessageLists.id, requestMessageLists);
  };
  
  getBudgetContentReceiver=(responseJson:BudgetContentData)=>{
    this.setState({
      budgetData: responseJson
    })
  };
  
  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleTooltipToggleObjectiveData = () => {
    this.setState({ tooltipOpenBudgetDataTooltip: !this.state.tooltipOpenBudgetDataTooltip });
  };
  
  handleTooltipCloseBudgetData = () => {
    this.setState({ tooltipOpenBudgetDataTooltip: false });
  };
  
  handleTooltipOpenBudgetData = (title: string) => {
    this.setState({ tooltipTitleBudgetDataTooltip: title, tooltipOpenBudgetDataTooltip: true });
  };
  handleTooltipToggleObjectiveDatas = () => {
    this.setState({ tooltipOpenBudgetHeadingTooltip: !this.state.tooltipOpenBudgetHeadingTooltip });
  };
  
  handleImageImageMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenBudgetHeadingTooltip:true});
    }
  };
   handleImageImageMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenBudgetHeadingTooltip:false});}
  }
   handleMouse=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenBudgetHeadingTooltip:!this.state.tooltipOpenBudgetHeadingTooltip});
    }
  }
  
  handleExplaniBUdgetMouseHover=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenBudgetDataTooltip:true});
    }
  };
   handleExplaniBUdgetMouseOver=()=>{
    if(window.innerWidth>500){

    this.setState({tooltipOpenBudgetDataTooltip:false});}
  }
   handleExplaniBUdget=()=>{
    if(window.innerWidth<=500){

      this.setState({tooltipOpenBudgetDataTooltip:!this.state.tooltipOpenBudgetDataTooltip});
    }
  }

  handleTooltipCloseChanelsDatas = () => {
    this.setState({ tooltipOpenBudgetHeadingTooltip: false });
  };
  
  handleTooltipOpenChanelsDatas = (title: string) => {
    this.setState({ tooltipTitleBudgetHeadingTooltip: title, tooltipOpenBudgetHeadingTooltip: true });
  };

  getBudgetlHeadingTooltip = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const name = "Budget";
    
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("accessToken")
    };

    const requestMessageLists = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBudgetHeadingTooltipCallID = requestMessageLists.messageId;

    requestMessageLists.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessageLists.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.tooltipHeading}?language=${selectedCountry}&name=${name}`
    );

    requestMessageLists.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageLists.id, requestMessageLists);
  };

  getBudgetHeadingReceiver=(responseJson:any)=>{
    this.setState({
      budgetHeadingTooltip: responseJson
    })
  };

  // Customizable Area End

}