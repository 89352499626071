import React from "react";

import {
  Box,
  Input,
  Button,
  InputAdornment,
  // Customizable Area Start
  Zoom,
  Tooltip,
  Grid,
  Typography,
  Select,
  MenuItem,
  Modal,
  Theme,
  FormControl,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  withStyles,
  styled,
  createStyles
} from "@material-ui/core/styles";
import Sidebar from "../../../components/src/SideBar.web";
import { withRouter } from "react-router-dom";
import {
  info,
  infoIcon,
  cardImage,
  dropdownArrow,
  cancelImage,
} from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const WhiteOnBlackTooltip = withStyles((theme) => ({
  tooltip: {
    color: "black",
    // left: "40px",
    height: "auto",
    padding: "15px 20px 15px 20px",
    marginTop: "20px",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    maxWidth: "231px",
    borderRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    position: "relative",
    
  },
  arrow: {
    content: '""',
    color:"#E9E9E9"
  },
}))(Tooltip);

const customStyles = {
  listRoot: {
    padding: "12px 20px",
  },
  menuItem: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      color: "green",
      backgroundColor: "transparent",
    },
  },
};

const CustomGrid = styled(Grid)({
  padding: "35px 0px 33px 16px",
   width: "100%",
   height:'100%',
  '@media (max-width: 960px)': {
  padding: "0px",
  maxWidth: '90%',
  margin: 'auto',
  marginTop:'89px',
}
})
const CustomButtonBoxView = styled(Box)({
  display: "flex",
justifyContent: "end",
  '@media (max-width: 960px)': {
  marginTop:'40px',
  marginBottom:'20px',
  justifyContent: "center",
  },
  '@media (max-width: 500px)': {
    marginTop:'46px',
    marginBottom:'38px',
    },
})

const CustomBoxView = styled(Box)({
  overflowY: "auto" as "auto",
    height: "100%",
    display:'flex',
  '@media (max-width: 960px)': {
    // background: 'red',
    // flexDirection:"column" ,
    
  }
})
const Custom4BoxView = styled(Box)({
  display:'flex',
  // width:'100%',
  padding: '40px 16px 0px 16px',
  '@media (max-width: 961px)': {
    display:'flex',
    // background: 'red',
     flexDirection:"column" ,
     padding: '78px 28px 0px 20px',
  },
  '@media (max-width: 500px)': {
   paddingTop:"70px",
   padding: '78px 24px 0px 24px',
   "& .MuiList-root": {
    left: '25px',
    right: '25px',
  },
    
  },
  '@media (max-width: 390px)': {
    padding: '78px 10px 0px 10px',
     
   },
   "& .setupYourIndustry":{
    fontFamily: "Panton Narrow Bold !important",
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    display:'flex',
    marginTop:"16px",
    "@media(max-width:500px)":{
      fontSize: "16px !important",
      lineHeight:'26px',
      marginTop:"16px"
    }
   }
})
const CustomBoxViewleftMain = styled(Box)({
  '@media(max-width:500px)':{
    boxShadow: "none",
  }
})

const CustomBoxViewRight = styled(Box)({
 
  width:'32.5%',
  '@media (max-width: 961px)': {
    width:'100%',
    
  },
  '@media (max-width:500px)': {
    width:'102%',
    
  },
  '@media (max-width: 395px)': {
    width:'94.5%',
    marginLeft:'9px'
    
  },
  '@media (max-width: 375px)': {
    width:'100% !important',
    marginLeft:'unset'
  },
})
const CustomBoxViewleftBox= styled(Box)({
  width:'67.37%',
  '@media (max-width: 961px)': {
    width:'100%',
    
  }
})
const Custom2BoxView = styled(Box)({
  '@media (min-width: 960) and (max-width: 1440px)': {
    maxWidth: '348px',
    width: '100%',
  },
  '@media(min-width: 960px)': {
    marginLeft: 16,
    // marginRight: 10,
  },
  '@media(max-width: 960px)': {
    marginTop: 16,
  },
  '@media(max-width: 500px)': {
    marginTop:'-25px',
  },
})
const nextPath = "/Objective";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        "&:hover, &.Mui-selected": {
          backgroundColor: "transparent !important",
          color: "#005E6E !important",
          fontWeight: 700,
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover, &.Mui-selected  &.Mui-selected:hover": {
          fontWeight: 700,
          backgroundColor: "transparent !important",
          color: "#005E6E !important",
        },
      },
    },
    MuiGrid: {
      root: {
        "&.MuiGrid-spacing-xs-2": {
          "@media (max-width: 960px)": {
            marginTop:"20px"
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "12px",
      },
      list: {
        padding: "24px",
      },
    },
    MuiSelect: {
      select: {
        color: "#B4B4B4",
        fontWeight: 400,
      },
    },
  },
});
interface Objective {
  id: number;
  name: string;
  duration: number;
  description: string;
  photo_url:null;
  clicked:boolean;
  tool_tip:string;
}
interface IndustryItem {
  id: number;
  localsearch_rubrikname_english: string;
  localsearch_schema_category_110: string;
}

const text =
  "Lörem ipsum esm välfärdsbrott, örem ipsum esm välfärdsbrott,pseudobelLörem ipsum esm välfärdsbrott, örem ipsum esmvälfärdsbrott, pseudobel";
// Customizable Area End

import SetUpController, { Props } from "./SetUpController.web";
import { Divider } from "react-native-elements";
import Recommendation from "./Recommendation.web";

export  class SetUp extends SetUpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End
  //istanbul ignore next
  render() {
      const { classes }:any = this.props;
      const BoxView = Box as any;
      const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as any;
      const maxCharacters = 200;
      const truncatedText = this.state.setupData?.body ? this.state.setupData.body.substring(0, maxCharacters) : '';
      const shouldShowReadMore = this.state.setupData?.body && this.state.setupData.body.length > maxCharacters;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sidebar navigation={this.props.navigation} postCreateCampaign = {this.postCreateCampaing}  onUpdateDashboardClick={this.onUpdateDashboardClick}  ShowModel = {this.state.ShowModel}  classes={{}}>
          <Custom4BoxView>
            <CustomBoxViewleftBox>
            < CustomBoxViewleftMain sx={webStyle.mainBox}>
                      <Grid container spacing={1}>
                      <Typography className={classes.lableSetup}>
                          {this.state.select_lang.name[4]}
                            </Typography> 
                        <Grid item sm={10} xs={12}>
                          <Typography className={classes.lables}>
                            {this.state?.setupComapnyName?.data?.name}
                            <span style={{ color: "#EA5053" }}>*</span>
                            <WhiteOnBlackTooltipView
                              TransitionComponent={Zoom}
                              title={this.state.setupComapnyName?.data?.description}
                              arrow={true}
                              placement="top-start"
                              open={this.state.tooltipOpenCompanyName}
                              onClose={this.handleTooltipCloseCompanyName}
                            
                            >
                              <img
                                src={info}
                                className={classes.headingIcon}
                                alt="Info"
                                data-test-id='heading'
                                onMouseLeave={()=>this.handleImageImageMouseOver()}
                                onMouseEnter={()=>this.handleImageImageMouseHover()}
                                
                                onClick={() => this.handleMouse()}
                              />
                            </WhiteOnBlackTooltipView>
                          </Typography>
                          <Input
                            fullWidth
                             data-test-id="company_name"
                           
                            className={classes.profileDetailsInputContainerInput}
                            disableUnderline
                            placeholder={this.state.select_lang?.name[0]}
                            name="companyName"
                            autoComplete="off"
                            onChange={this.handleChange}
                            value={this.state.campaing.companyName}
                            disabled={this.state.viewData}
                            inputProps = {
                              {
                                sx: {
                                  '&::placeholder': {
                                    color: '#B4B4B4',
                                    fontWeight:'bold',
                                    fontFamily:'Panton Narrow Regular'
                                  },
                                },
                              }}  
                          />
                           <span style={webStyle.errorMsg} data-test-id="company_name_error">{this.state.errors.companyName}</span>
                        </Grid>
                        <Grid item sm={10} xs={12}>
                          <Typography className="setupYourIndustry">
                            
                          {this.state.setupYourIndustry?.data?.name}
                            <span style={{ color: "#EA5053" }}>*</span>
                            <WhiteOnBlackTooltipView
                              TransitionComponent={Zoom}
                              title={this.state.setupYourIndustry?.data?.description}
                              arrow={true}
                              placement="bottom-start"
                              open={this.state.tooltipOpenIndustry}
                              onClose={this.handleTooltipCloseIndustry}
                              
                            >
                              <img
                                src={info}
                                className={classes.headingIcon}
                                alt="Info"
                                data-test-id='industry'
                                onMouseLeave={()=>this.handleImageImageMouseOvers()}
                                onMouseEnter={()=>this.handleImageImageMouseHovers()}
                                
                                onClick={() => this.handleMouseIndustyr()}
                              />
                            </WhiteOnBlackTooltipView>
                          </Typography>
                          <BoxView sx={{ position: "relative" }}>
                            <Input
                              fullWidth
                              // data-test-id="industry_data"
                              name="industry"
                              placeholder={this.state.select_lang?.name[1]}
                              className={classes.profileDetailsInputContainerInput}
                              disabled={this.state.viewData}
                              inputProps = {
                                {
                                  sx: {
                                    '&::placeholder': {
                                      color: '#B4B4B4',
                                      fontWeight:'bold',
                                      fontFamily:'Panton Narrow Regular'
                                    },
                                  },
                                }}  
                              value={String(this.state.campaing.industry)}
                              data-test-id='showDrop'
                              onChange={this.handleChange}
                              onFocus={this.showDropdown}
                              onInput={this.showDropdown}
                              disableUnderline
                              autoComplete="off"
                              endAdornment={
                                <InputAdornment position="end">
                                  <SearchIcon style={{ }} className={classes.serachCss} />
                                </InputAdornment>
                              }
                            /> 
                            <span style={webStyle.errorMsg} data-test-id="industry_data_error">{this.state.errors.industry}</span>
                            {this.state.campaing.industry?.length>0 &&
                            // this.state.2050014 &&
                            this.state.dropdownVisible &&
                              this.state.industryData.length > 0 &&
                               (
                                <div
                                  className="custom-dropdown"
                                  style={webStyle.serch}
                                  ref={(this.divRef)as any}
                                >
                                  <div style={{ padding: 24 }}>
                                  {[...new Set(this.state.industryData.map(item => item.localsearch_schema_category_110))].map(
                                      (uniqueIndustryItem, index ,array) => (
                                        <div key={index}>
                                          <MenuItem
                                            id='search-industry'
                                            style={webStyle.Items}
                                            onClick={() => this.handleResultClick(uniqueIndustryItem)}
                                          >
                                            {uniqueIndustryItem}
                                          </MenuItem>
                                          {index < array.length - 1 && <Divider />}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                          </BoxView>
                        </Grid>
                        <Grid item sm={10} xs={12}>
                          <Typography className={classes.labless} >
                          {this.state.setupYourLocation?.data?.name}
                            <span style={{ color: "#EA5053" }}>*</span> 
                            <WhiteOnBlackTooltipView
                              TransitionComponent={Zoom}
                              title={this.state.setupYourLocation?.data?.description}
                              arrow={true}
                              placement="bottom-start"
                              open={this.state.tooltipOpenLocation}
                              onClose={this.handleTooltipCloseLocation}
                            >
                              <img
                                src={info}
                                className={classes.headingIcon}
                                alt="Info"
                                data-test-id='Location'

                                onMouseLeave={()=>this.handleLocationMouseOvers()}
                                onMouseEnter={()=>this.handleLocationMouseHovers()}
                                
                                onClick={() => this.handleMouseLocation()}
                              />
                            </WhiteOnBlackTooltipView>
                          </Typography>
                          <SelectField fullWidth >
        <SelectSubStyle
          fullWidth
          name="location"
          disabled={this.state.viewData} 
          value={this.state.campaing.location}
          onChange={this.handleChange}
          IconComponent={ExpandMoreIcon}
          variant="outlined"

          displayEmpty
          disableUnderline
          style={{
      borderRadius: "12px",
      marginTop:'16px',
      backgroundColor: 'transparent !important',
      }}
      renderValue={
        this.state.campaing.location !== ""
          ? undefined
          : () => <span style={{color:"#B4B4Be"}}>{this.state.select_lang?.name[2]}</span>
      }
          // error={this.state.residenceError}
          // onChange={(e: any) => {
          //   this.handleSeprateValidationSelect("residence", e.target.value, "residenceError");

          // }}
          // value={this.state.residence}
          MenuProps={{
            MenuListProps: {
              style: {
                padding: "24px",
                maxHeight: 398,
                
              },
            },
            anchorOrigin: {
              horizontal: "left",
              vertical: "bottom",
            },
            getContentAnchorEl: null,

          }}
        >
             {this.state.radiusData?.map((item, index) => {
                              return(
                               
                                 <MenuItem value={item.id} style={{...webStyle.Items,
                                     borderBottom: index < this.state.radiusData.length - 1 ? '1px solid #B4B4B4' : 'none'}} key={item.id}>
                                    {item.name}
                                 </MenuItem>
                                 
                              )
                            }
                            )}
        
        </SelectSubStyle>
    
      </SelectField>
                    
                          
                          <span style={webStyle.errorMsg}>{this.state.errors.location}</span>
                        </Grid> 
                        <Grid item sm={10} xs={12}>
                          <Typography className={classes.labless} style={{ marginBottom:"16px"}}>
                          {this.state.setupYourObjective?.data?.name}
                            <span style={{ color: "#EA5053" }}>*</span>
                            <WhiteOnBlackTooltipView
                              TransitionComponent={Zoom}
                              title={this.state.setupYourObjective?.data?.description}
                              arrow
                              placement="bottom-start"
                              open={this.state.tooltipOpenObjective}
                              onClose={this.handleTooltipCloseObjective}
                            >
                              <img
                                src={info}
                                className={classes.headingIcon}
                                alt="Info"
                                data-test-id='objective'
                                onMouseLeave={()=>this.handleObjectiveMouseOvers()}
                                onMouseEnter={()=>this.handleObjectiveMouseHovers()}
                                
                                onClick={() => this.handleMouseObjective()}                              />
                            </WhiteOnBlackTooltipView>
                          </Typography>
                          <span style={webStyle.errorMsg}>{this.state.errors.objectives}</span>
                          <Grid container style={{margin:'-10px',width:'102.3%'}}>
                            {this.state.objectivesDetails.map(
                              (objective: Objective) => (
                                <Grid item md={6} xl={4} xs={12} key={objective.id} style={{padding:'9px 10px 8px 10px'}}>
                                  <BoxView style={{ position: "relative" }}>
                                    <img
                                       src={objective.photo_url ? objective.photo_url : cardImage || ""}
                                      // src={cardImage}
                                      style={{
                                        ...webStyle.imgGroup, filter: objective.id === this.state.campaing.selectedObjective ? "" : "grayscale(100%)",
                                      }}  
                                      alt="Card"
                                      data-test-id={`objective-${objective.id}`}
                                      id='objective-details'
                                      onClick={() =>
                                        this.handleObjectiveClick(objective)
                                      }
                                    />
                                    <BoxView
                                      style={{
                                        position: "absolute",
                                        bottom: "36px",
                                        left: "18px",
                                      }}
                                    >
                                      <Typography style={webStyle.text}>
                                        {objective.name}
                                        <WhiteOnBlackTooltipView
                                          title={objective.tool_tip}
                                          arrow={true}
                                          placement="bottom-start"
                                          open={this.state.tooltipOpenobjeective&&this.state.tooltipId===objective.id}

                                        >
                                          <img
                                            src={infoIcon}
                                            style={{
                                              marginLeft: "10px",
                                              height: "12px",
                                              width:"12px",
                                            }}
                                            alt="Info"
                                            data-test-id='objecti'
                                            onMouseLeave={()=>this.handleobjectiveMouseOver(objective.id)}
                                            onMouseEnter={()=>this.handleobjectiveMouseHover(objective.id)}
                                             onClick={() => this.handleobjective(objective.id)}
                                          />
                                        </WhiteOnBlackTooltipView>
                                      </Typography>
                                      <Typography style={webStyle.smalltext}>
                                        {objective.description}
                                      </Typography>
                                    </BoxView>
                                  </BoxView>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CustomBoxViewleftMain >
            </CustomBoxViewleftBox>
            <CustomBoxViewRight>
            <Custom2BoxView sx={webStyle.subcontainer}  >
                      <BoxView style={webStyle.subBox}>

                <Typography style={webStyle.title}>
                        <span>
                          {this.state.setupData?.heading}
                        </span>
                        <span>
                        <WhiteOnBlackTooltipView
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.setupData?.tooltip}
                          open={this.state.tooltipOpenData}
                          onClose={this.handleTooltipCloseData}
                        >
                          <img src={info}
                          data-test-id='setupdata'
                          onMouseLeave={()=>this.handlesetupDataMouseOvers()}
                         onMouseEnter={()=>this.handlesetupDataMouseHovers()}
                         onClick={() => this.handleMousesetupData()}                          
                          alt="Info" style={{height: "12px", width: "12px" }} />
                        </WhiteOnBlackTooltipView>
                        </span>
                      </Typography>
                        <img style={webStyle.img} src={this.state.setupData?.image_url} />
                        <Typography style={{
                        marginTop: "20px",
                        lineHeight: '19.38px',
                        wordBreak: 'break-all',
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'panton narrow regular'
                    }} >
                        {truncatedText}{" "}
                        {shouldShowReadMore && (
                            <span style={webStyle.readMore} onClick={this.toggleModal}>
                                {this.state.setupData?.read_more}
                            </span>
                        )}
                    </Typography>
                
                        <Modal
                          open={this.state.isModalOpen}
                          onClose={this.toggleModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          BackdropProps={{
                            style: {
                              backgroundColor: 'transparent',
                            }
                          }}
                        >
                          <BoxView className={classes.modalBoxStyle} >
                            <div className={classes.modalBoxStyles}>
                                <Typography className={classes.bodyText}
                                >
                                    {this.state.setupData?.body}
                                </Typography>
                                <div className={classes.cancelImageWrapper} >
                                    <img src={cancelImage} alt="Close" style={webStyle.cancelImage} onClick={this.toggleModal} />
                                </div>
                            </div>
                        </BoxView>
                        </Modal>
               </BoxView>
                      {!this.state.isComponentOpen && (
                        < CustomButtonBoxView >
                          <ButtonContainer
                            variant="contained"
                            data-test-id="next_Page"
                            onClick={() => this.nextPage()}
                            disabled={this.state.viewData}
                          >
                              {this.state.select_lang?.name[3]}
                          </ButtonContainer>
                        </CustomButtonBoxView >
                      )}
                    </Custom2BoxView>
            </CustomBoxViewRight>
          
          </Custom4BoxView>
        </Sidebar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonContainer = styled(Button)({
  width: "194px",
  borderRadius: "43px",
  color: "#353232",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  textTransform: "capitalize" as const,
  boxShadow: "none",
  "&:hover": { 
    backgroundColor: "#005E6E",
    color: "#FFFFFF",
    boxShadow: "none",
  },
  '@media(max-width:500px)':{
    width:'176px'
  },
  '@media(max-width:390px)':{
    width:'167px'
  },
  '@media(max-width:350px)':{
    width:'142px'
  }
});
  const webStyle = {
  root: {
    "& .MuiTableCell-root": {
      backgroundColor: "red",
    },
    "& .MuiList-root": {
      padding: "12px 20px" as "12px 20px",
    },
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "transparent" as "transparent",
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "transparent" as "transparent",
    },
    "& .MuiMenuItem-root:hover": {
     
      backgroundColor: "transparent" as "transparent",
    },
  },
  font: {
    fontFamily: "Panton Narrow Regular",
    fontSize: "14px",
    fontWeight: 400,
    color: "#B4B4B4",
    cursor: "pointer",
    "& :hover": {
      fontWeight: 600,
      color: "#005E6E",
    },
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  text: {
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    color: "#FFFFFF",
    fontSize: "16px",
    display:"flex",
    alignItems:"center",
  },
  smalltext: {
    fontFamily: "Panton Narrow Regular",
    fontSize: "12px",
    fontWeight: 400,
    color: "#F8FAFC",
    marginTop:"-4px",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },

  mainBox: {
    backgroundColor: "#FFFFFF",

    minHeight:'845px',
    boxShadow: "0px 4px 16px 0px #00000040",
    padding: "21px 41px",
    borderRadius: "32px",
    marginRight:"-2px",
    heighr: "970px",
    overflow: "auto",
    marginBottom:'40px',
    height: 'calc(100vh - 122px)',
    "@media (max-width: 960px)": {
    height: 'unset',
    },
    "@media (max-width: 500px)": {
      borderRadius:'unset',
      },
    "& .MuiSelect-select":{
      color:"black",
    },
    "& .MuiSelect-icon":{
      top: "calc(50% - 12px)",
      right: "15px",
      color: "rgb(180, 180, 180)",      
      position: "absolute",
      pointerEvents: "none"
    }
  },
  subBox: {
    backgroundColor: "#FFFFFF",
    height: "auto",
    boxShadow: "0px 4px 16px 0px #00000040",
    
    padding: "33px 29.4px 24px 24px",
    borderRadius: "24px",
  },
  lable: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    display:'flex',
  },
  
  profileDetailsInputContainer: {
    padding: "8px 16px 12px 12px",
    borderRadius: "12px",
    border: "1px solid #B4B4B4",
    marginTop: "7px",
    background: "#FFFFFF",

  },
  divider:{
    boderBottom:"1px solid #B4B4B4"
  },
  subcontainer:{
    height: "96.2%",
   
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
     "@media (max-width: 960px)": {
     padding:"0px",
   
     paddingBottom: "15px",
    },
    "@media (max-width: 500px)": {
      width:"100%",
     },
  },
  WhiteOnBlackTooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",    
    fontSize: "14px",
    color: "#fff",
    borderRadius: "10px",
    width: "135px",
    padding: "10px",
    textAlign: "left",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      top: "-15px",
      left: "10px",
      borderStyle: "solid",
      borderWidth: "10px 10px 0",
      borderColor: "rgba(0, 0, 0, 0.8) transparent transparent transparent",
    },
  },
  errorMsg:{
    color: '#005E6E',
    fontFamily:"panton Narrow Regular",
    fontWeight:600
  },
  title: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Panton Narrow Regular",
     paddingBottom: "20px",
    display:"flex",
    alignItems:"center",
    
    gap:'12px'
  },
  img: {
    width: "100%",
    height:'164px',
  },
  imgGroup: {
    width: "100%",
    cursor:"pointer",
    borderRadius: "12px",
    height: 175
  },
  card: {
    border: "1px solid",
    bordeRadius: "12px",
  },
  infoIcon: {
    marginleft: "15px",
  },
  border: {
    borderBottom: "none",
  },
  
  button: {
    width: "194px",
    borderRadius: "43px",
    backgroundColor: "#B4B4B4",
    color: "#FFFFFF",
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    height: "52px",
    textTransform: "capitalize" as const,
    "&:hover": { 
      backgroundColor: "#005E6E",
    },
    '@media(max-width:500px)':{
      width:'176px'
    },
    '@media(max-width:390px)':{
      width:'167px'
    },
    '@media(max-width:350px)':{
      width:'143px'
    }
  },
 industySuggestionItems : {
    color: "#B4B4Be",
    fontFamily: "Panton Narrow Regular",
    fontSize: "16px",
    padding: "12px 7px 16px 5px",
    "& :hover": {
      backgroundColor: "transparent",
      color: "#005E6E",
    },
  },
  Items: {
    color: "#B4B4Be",
    fontFamily: "Panton Narrow Regular",
    fontSize: "16px",
    padding: "16px 24px",
    "& :hover": {
      backgroundColor: "transparent",
      color: "#005E6E",
    },
  },
  alert:{
      border: "1px solid red",
      borderLeftWidth: "3px",
      borderRightWidth: "3px",
      borderRadius: "10px",
  },
  serch: {
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.4)" as "0px 4px 16px 0px rgba(0, 0, 0, 0.4)",
    borderRadius: "12px",
    position: "absolute" as const,
    zIndex: 999,
    background: "#FFFFFF",
    maxWidth: "100%",
    width: "100%",
  },
  readMore: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "14px",
    fontWeight: 700,
    color: "#005E6E",
    cursor:"pointer",
  },
  cancelImage: {
    cursor: 'pointer',
    width: '10.96px',
    height: '11px',
    marginLeft:'15px',
  },
  cancelImageWrapper: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'flex-',
    position:'absolute',
    top:'18px',
    right:'18px'
  },
   
  /* YourComponent.css */
};
export const SelectSubStyle=styled(Select)({
  backgroundColor:'transparent',
  '& .MuiPaper-rounded':{
    borderRadius:"12px"
  },
  "& .MuiSelect-select":{
    backgroundColor:'transparent'
  }
})
export const SelectField = styled(FormControl)({
  "& .MuiSelect-select":{
    backgroundColor:'transparent'
  },
  '& .MuiPaper-rounded':{
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#B4B4B4",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#B4B4B4",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#B4B4B4",
    borderWidth: "1px"
  },
  '& .MuiSelect-outlined.MuiSelect-outlined':{
    paddingLeft:"20px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B4B4B4",
    borderWidth: "1px",
    borderRadius: "8px"
  },

  "& .MuiInputBase-input": {
    lineHeight: "19.2px",
    
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px"
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  }
});

export const useStyle = (theme: Theme) =>
  createStyles({
    lables: {
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
      display:'flex',
      lineHeight:'32px',
      letter:'-0.5%',
      "@media(max-width:500px)":{
        fontSize: "16px !important",
        lineHeight:'26px',
        marginTop:"16px"
      }
    },
    serachCss:{
      color: "#B4B4B4",
      "@media(max-width:500px)":{
        marginRight:'10px', 
      } 
    },
    labless: {
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
      display:'flex',
      marginTop:'16px',
      lineHeight:'32px',
      letter:'-0.5%',
      "@media(max-width:500px)":{
        fontSize: "16px !important",
        lineHeight:'26px',
        marginTop:"16px"
      }
    },
    cancelImageWrapper: {
      alignSelf: 'flex-start',
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
      position:'absolute',
      top:'18px',
      right:'18px',
      "@media(max-width:500px)":{
        position:'absolute',
        top:18,
        marginRight:'0px',
        right:'18px',
      }
    },
    bodyText: {
      flex: 1,
      fontFamily: 'panton narrow regular',
      fontSize: '16px',
      lineHeight: '19.38px',
      fontWeight: 400,
      wordBreak: 'break-all',
      // paddingBottom: '5px',
      padding:'48px',
      // "@media(max-width:500px)":{
      //   marginTop:25,
      // },
    },
    modalBoxStyles:{
      display: 'flex',
       justifyContent: 'space-between',
       "@media(max-width:500px)":{
        display: 'unset',
        justifyContent:'unset',
       }
    },
    modalBoxStyle: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: '50%',
      transform: 'translate(-50%, -50%)',
      width: '573px',
      height: 'auto',
      borderRadius: '12px',
      background: '#FFFFFF',
      boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
      
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: '80vh',
      '@media(max-width:500px)':{
        width: '290px',
        // padding: '24px 12px 39px 12px',
        top: '52%',
        overflowY: 'auto',
        
      }
    },
    lableSetup: {
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "20px",
      fontWeight: 700,
      color: "#000000",
      lineHeight:'normal',
      margin:'12px 0px 16px -12px',
      '@media(min-width:500px)':{
        display:'none !important',
      },
    },
    headingIcon:{
      marginLeft:'16px',
      width:'17px',
      height:'17px',
      // marginTop:'10px',
      display:'flex',
      alignSelf:'center',
      "@media(max-width:500px)":{
        display:'flex',
        alignSelf:'center',
      }
    },
    profileDetailsInputContainer: {
      padding: "8px 16px 12px 12px",
      borderRadius: "12px",
      border: "1px solid #B4B4B4",
      marginTop: "11px",
      background: "#FFFFFF",
      "@media(max-width:500px)":{
        padding: "12px 0px 12px 16px ",
        marginTop: "16px",
      }
    },
    profileDetailsInputContainerInput: {
      padding: "12px 16px 11px 20px",
      borderRadius: "12px",
      border: "1px solid #B4B4B4",
      marginTop: "16px",
      background: "#FFFFFF",
      "@media(max-width:500px)":{
        padding: "12px 0px 12px 16px ",
        marginTop: "16px",
      }
    },
   

  
  })
  export default withRouter(withStyles(useStyle)(SetUp as any) as any) as any;
// Customizable Area End