Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.apiContentType = "application/json";
exports.campaignListEndPoint="/bx_block_custom_form/campaigns"
exports.GET = "GET",
exports.userProfileEndPoint="/account_block/profile",
exports.userListEndPoint = "/account_block/accounts",
exports.assignCampaingToUser = "/bx_block_custom_form/assign"
exports.duplicateCampaign = "/bx_block_custom_form/campaigns"
exports.getRadiusEndPoint="bx_block_custom_form/radius";
exports.deletecampaignsEndPoint="bx_block_custom_form/campaigns"
exports.campaignsAPIMethod = "DELETE"
exports.validationApiMethodType = "GET";
exports.mockApiResponseCampaignList = [
  {
  "data":{
    "id": "28",
    "type": "campaign",
    "attributes": {
        "company_name": "API TEST 000",
        "industry": "IT and CS",
        "location": null,
        "objective_id": 56,
        "budget": null,
        "radius_id": 33,
        "duration": null,
        "created_at": "2023-10-30T11:55:01.237Z",
        "updated_at": "2023-10-30T11:55:01.237Z",
        "status": "incompleted",
        "user_photo_link": null,
        "user": null
    }
},
},
{
  "data":{
    "id": "28",
    "type": "campaign",
    "attributes": {
        "company_name": "API TEST 000",
        "industry": "IT and CS",
        "location": null,
        "objective_id": 56,
        "budget": null,
        "radius_id": 33,
        "duration": null,
        "created_at": "2023-10-25T11:55:01.237Z",
        "updated_at": "2023-10-25T11:55:01.237Z",
        "status": "incompleted",
        "user_photo_link": null,
        "user": null
    }
},
}

]

exports.userDetailsMock =  {
  "id": "1",
  "type": "account",
  "attributes": {
      "email": "advertiseone.user@localsearch.ch",
      "first_name": "AdvertiseOne",
      "full_phone_number": "41583333333",
      "last_name": "User Testuser",
      "created_at": "2023-08-16T06:22:42.397Z",
      "updated_at": "2023-08-16T06:22:42.420Z",
      "device_id": null,
      "unique_auth_id": "pYQFLWXz1DBHsueLrIHR1wtt",
      "area": "Bezirk 2.14, Romero",
      "role": {
          "id": 20,
          "name": "User",
          "created_at": "2023-08-09T06:48:58.214Z",
          "updated_at": "2023-08-09T06:48:58.214Z",
          "role_id": 2
      }
  }
}

exports.mockApiResponseUserRoleList = [
      {
          "id": "6",
          "type": "account",
          "attributes": {
              "email": "bertan.findik@localsearch.ch",
              "first_name": "Bertan",
              "full_phone_number": "",
              "last_name": "Findik",
              "created_at": "2023-10-26T09:19:44.827Z",
              "updated_at": "2023-10-26T09:19:44.840Z",
              "device_id": null,
              "unique_auth_id": "02XjYxTrrIVIeC19YR41xgtt",
              "role_id": 20,
              "area": "Bezirk 2.01, Krecar",
              "role": {
                  "id": 20,
                  "name": "User",
                  "created_at": "2023-08-09T06:48:58.214Z",
                  "updated_at": "2023-08-09T06:48:58.214Z",
                  "role_id": 1
              }
          }
        }           
     ]
// Customizable Area End