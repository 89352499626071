import React from "react";
// Customizable Area Start
import {
    Theme,
    Box,
    Typography,
    Tooltip,
    Button,
    Zoom,
    Input,
    MenuItem,
    Select,
    InputAdornment,
    Modal,
    Paper,
} from "@material-ui/core";

import {
    withStyles,
    createStyles,
    ThemeProvider,
    createTheme,
    styled,
} from "@material-ui/core/styles";

import LocationController, {
    Props
} from "./LocationController.web";
import Sidebar from "../../../components/src/SideBar.web";
import { withRouter } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Circle } from 'react-leaflet';
import { info, mapIcon, cancelImage } from "./assets";
import L, { IconOptions, PointExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SelectField } from "./SetUp.web";

const budgetWhiteOnBlackTooltip = withStyles((theme) => ({
    tooltip: {
        color: "black",
        height: "auto",
        padding: "15px 20px 15px 20px",
        marginTop: "20px",
        backgroundColor: "#E9E9E9",
        fontSize: "16px",
        fontFamily: "Panton Narrow Regular",
        fontWeight: 400,
        maxWidth: "231px",
        borderRadius: "12px",
        borderTopRightRadius: "12px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        position: "relative",
    },
    arrow: {
        content: '""',
        color: "#E9E9E9"
    },
}))(Tooltip);
const Custom4BoxView = styled(Box)({
    display: 'flex',
    padding: '40px 16px 0px 16px',
    gap: '16px',
    '@media (max-width: 961px)': {
        display: 'flex',

        flexDirection: "column",
        padding: '78px 28px 0px 20px',
    },
    '@media (max-width: 500px)': {
       
        padding: '78px 31px 0px 15px',
    },
    '@media (max-width: 375px)': {
        //  marginTop:"70px" ,
        padding: '78px 10px 0px 10px',
    },
    '@media (min-width: 1900px)': {
        gap: '21px',
        paddingLeft: '19px',
    }
})

const CustomBoxViewRight = styled(Box)({

    width: '31.1%',
    '@media (max-width: 961px)': {
        width: '100%',

    }
});
const CustomBoxViewleftBox = styled(Box)({
    width: '67.5%',
    '@media (max-width: 961px)': {
        width: '100%',

    }
})

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        },
    }
});

// Customizable Area End

export class Location extends LocationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTooltip = () => {

        const WhiteOnBlackTooltipView = budgetWhiteOnBlackTooltip as any;
        const { classes } = this.props;
        const BoxView = Box as any;
        const maxCharacters = 202;
        const truncatedText = this.state.locationData?.body ? this.state.locationData.body.substring(0, maxCharacters) : '';
        const shouldShowReadMore = this.state.locationData?.body && this.state.locationData.body.length > maxCharacters;

        return (
            <BoxView style={webStyle.subconinerr}>
                <Typography style={webStyle.title}>
                    <span>
                        {this.state.locationData?.heading}
                    </span>
                    <span>
                        <WhiteOnBlackTooltipView
                            arrow={true}
                            TransitionComponent={Zoom}
                            placement="bottom-start"
                            title={this.state.locationData?.tooltip}
                            open={this.state.tooltipOpenchannelHeadingTooltip}
                           onClose={this.handleTooltipCloseChanelsData}
                        >
                            <img src={info} 
                            data-test-id='handleChanels'
                         onMouseLeave={()=>this.handleImageImageMouseOver()}
                         onMouseEnter={()=>this.handleImageImageMouseHover()}
                          onClick={() => this.handleMouse()}
                            alt="Info" style={{ height: "12px", width: "12px" }} />
                        </WhiteOnBlackTooltipView>
                    </span>
                </Typography>
                <img src={this.state.locationData?.image_url} style={webStyle.bannerImageLocation} />
                <BoxView testID="readmoreText">
                    <Typography style={{
                        marginTop: "20px",
                        lineHeight: '19.38px',
                        wordBreak: 'break-all',
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'panton narrow regular'
                    }} >
                        {truncatedText}{" "}
                        {shouldShowReadMore && (
                            <span className={classes.locationReadMoreText} onClick={this.toggleModal}>
                                {this.state.locationData?.read_more}
                            </span>
                        )}
                    </Typography>
                </BoxView>
                <BoxView testID="modalToShowText">
                    <Modal
                        open={this.state.isModalOpen}
                        onClose={this.toggleModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        BackdropProps={{
                            style: {
                                backgroundColor: 'transparent',
                            }
                        }}
                    >
                        <BoxView className={classes.locationModalStyle} >
                            <div className={classes.locationModalStyles}>
                                <Typography  className={classes.locationBodyText} style={{ wordBreak: 'break-all', flex: '1'}}>
                                    {this.state.locationData?.body}
                                </Typography>
                                <div  className={classes.locationCancelImageWrapper} >
                                    <img src={cancelImage} alt="Close"  className={classes.locationCancelImage}  onClick={this.toggleModal} />
                                </div>
                            </div>
                        </BoxView>

                    </Modal>
                </BoxView>
            </BoxView>
        );
    };


    renderSearchBar = () => {
        const { classes } = this.props;
        const BoxView = Box as any;

        return (

            <BoxView sx={{ position: "relative" }} testID="searchInput">
                <Input
                    name="handleinput"
                    fullWidth
                    placeholder={this.state.select_lang?.name[0]}
                    data-test-id="handleinputsearch"
                    className={classes.inputContainer}
                    value={this.state.searchValue === null ?"":this.state.searchValue}
                    onChange={this.handleInputChange}
                    disableUnderline
                    autoComplete="off"
                    disabled={this.state.viewData}
                    inputProps={
                        {
                            sx: {
                                '&::placeholder': {
                                    color: '#B4B4B4',
                                    fontFamily: "panton narrow regular",
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '19.38px',
                                    //marginLeft:'8px'
                                },
                                
                            },marginLeft:'8px'
                        }}
                    endAdornment={
                        <InputAdornment position="end">
                            <BoxView testID="searchButton">
                                <SearchIcon data-test-id="searchIcon" style={{
                                    cursor: "pointer",
                                    color: "#B4B4B4",
                                    height: "20px",
                                    width: "20px",
                                    paddingTop: "5px",
                                    marginRight:'5px'
                                }} onClick={this.handleSearch} />
                            </BoxView>
                        </InputAdornment>
                    }
                    onKeyPress={this.handleKeyPress}
                />
                <span style={webStyle.errorMsg}>{this.state.validationErrorMessages.searcchInputError}</span>
            </BoxView>
        );
    };

     renderHeading = () => {
        const WhiteOnBlackTooltipView = budgetWhiteOnBlackTooltip as any;
        const { classes } = this.props;

         return (
            
            <Typography className={classes.typoLocation}>
            {this.state?.locationHeadingTooltip?.data?.name}
            <WhiteOnBlackTooltipView
                TransitionComponent={Zoom}
                title={this.state?.locationHeadingTooltip?.data?.description}
                open={this.state.tooltipOpenChanelsDatas}
                onClose={this.handleTooltipCloseChanelsDatas}
                arrow
                placement="bottom-start"
            >
                <img
                    src={info}
                    style={{ marginLeft: "15px", width: '19px', position: 'relative', top:"7px" }}
                    alt="Info"
                    data-test-id='chanelsData'
                    onMouseLeave={()=>this.handleChanelsDataMouseOver()}
                    onMouseEnter={()=>this.handleChanelsDataMouseHover()}
                     onClick={() => this.handleChanelsData()}
                />
            </WhiteOnBlackTooltipView>
           
           
        </Typography>
       
     );
     };


    // Customizable Area End
    //istanbul ignore next
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const BoxView = Box as any;
        const { position, error, mapRef } = this.state;
        const iconOptions: IconOptions = {
            iconUrl: mapIcon,
            iconSize: [25, 41] as PointExpression,
            iconAnchor: [12, 41] as PointExpression,
            popupAnchor: [1, -34] as PointExpression,
        };
        // Customizable Area End

        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <Sidebar navigation={this.props.navigation} activePath={""} onDashboardSave={this.onDashboardSave} >
                    <Custom4BoxView>
                        <CustomBoxViewleftBox>
                            <div className={classes.locationRoot}>
                                <div className={classes.locationInnerRoot}>
                                    {this.renderHeading()}
                                    {this.renderSearchBar()}
                                    <BoxView testID="selectItem" >
                                   
                    
                                        <SelectField fullWidth>

                                        <Select
                                            fullWidth
                                            name="location"
                                           
                                            value={this.state.selectedRadius}
                                            onChange={this.handleSelectValueChnage}
                                            IconComponent={ExpandMoreIcon}
                                            
                                            variant="outlined"
                                            className={classes.expandIcon}
                                            displayEmpty
                                            disableUnderline
                                            disabled={this.state.viewData}
                                           
                                            
                                            renderValue={() => (
                                                <span
                                                    // className={classes.selectPLaceholder}
                                                    className={this.state.selectedRadius && this.state.selectedRadius != "local" ? classes.selectPlaceholderActive : classes.selectPlaceholder}
                                                >
                                                    {this.state.selectedRadius !== ""
                                                        ? this.state.radiusData.find((item: any) => item.id === this.state.selectedRadius)?.name || this.state.select_lang?.name[1]
                                                        : this.state.select_lang?.name[1]
                                                    }
                                                </span>
                                            )}
                                            style={{
                                                ...webStyle.profileDetailsInputContainer,
                                                  
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    component: CustomPaper,
                                                    style: {
                                                        marginTop: '-3px',
                                                        zIndex: 9999,
                                                    },
                                                },
                                                MenuListProps: {
                                                    style: {
                                                      padding: "24px",
                                                      maxHeight: '200px',
                                                      

                                                    },
                                                    
                                                  },
                                                 
                                            
                                                anchorOrigin: {
                                                    
                                                    horizontal: "left",
                                                    vertical: "bottom",
                                                },

                                              
                                                getContentAnchorEl: null,
                                            }}
                                        >

                                            {this.state.radiusData?.map((item: any, index: any) => (
                                                <MenuItmStyle
                                                    data-test-id="selectradiusoptions"
                                                    value={item.id}
                                                    style={{
                                                        ...webStyle.Items,
                                                        borderBottom: index < this.state.radiusData.length - 1 ? '1px solid #B4B4B4' : 'none',
                                                        // padding: "10px",
                                                        // marginLeft: "5px",
                                                        // marginRight: "5px",
                                                        fontFamily: "panton narrow regular",
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                        lineHeight: '19.38px',
                                                    }}
                                                    key={item.id}
                                                >
                                                    {item.name}
                                                </MenuItmStyle>
                                            ))}
                                        </Select>
                                        </SelectField>
                                        <span style={webStyle.errorMsg}>{this.state.validationErrorMessages.selectRangeError}</span>

                                    </BoxView>
                                    <div className={classes.mapContainerr} >
                                        {error && <p style={webStyle.errorMsg}>{error}</p>}
                                        <MapContainer
                                            data-test-id="mapcirclecontainer"
                                            ref={mapRef}
                                            //center={[51.505, -0.09]}
                                            center={[46.8182, 8.2275]}
                                            zoom={2}
                                            zoomControl={false}
                                            attributionControl={false}
                                            className={classes.mapStyle}
                                        >
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                            {position && (
                                                <>
                                                    <Marker position={position} icon={L.icon(iconOptions)} />
                                                    {this.state.selectedRadius && (
                                                        <div>
                                                            {this.state.radiusData.map((radius: any) => {
                                                                if (this.state.selectedRadius === radius.id) {
                                                                    const name = radius.name;
                                                                    let fillColor;
                                                                    let color;

                                                                    if (name.includes("Local") || name.includes("Regional") || name.includes("National")) {
                                                                        fillColor = 'black';
                                                                        color = 'black';
                                                                    }

                                                                    return (
                                                                        <Circle
                                                                            key={radius.id}
                                                                            center={position}
                                                                            pathOptions={{ fillColor, color }}
                                                                            radius={radius.range * 1000}
                                                                        />
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </div>
                                                    )}

                                                </>
                                            )}

                                        </MapContainer>
                                    </div>
                                </div>
                            </div>
                        </CustomBoxViewleftBox>
                        <CustomBoxViewRight >
                            <div className={classes.sideContainerBox}>
                                <div className={classes.innerSideContainerBox}>
                                    {this.renderTooltip()}
                                </div>
                                <BoxView className={classes.locationButtonBox}>
                                <Button variant="contained" disabled={this.state.viewData} className={classes.locationBtn} data-test-id="Quote_page" onClick={this.handleQuote}>
                                {this.state.select_lang.name[2]}
                                    </Button>
                                   
                                    <Button variant="contained" disabled={this.state.viewData} className={classes.locationBtn} data-test-id="location_page" onClick={this.handleNext}>
                                    {this.state.select_lang.name[3]}
                                    </Button>
                                </BoxView>
                            </div>
                        </CustomBoxViewRight >
                    </Custom4BoxView>
                </Sidebar>
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        )
    }
}

const ButtonContainer = styled(Button)({ 
  width: "194px",
  borderRadius: "43px",
  color: "#FFFFFF",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  textTransform: "capitalize" as const,
  "&:hover": { 
    backgroundColor: "#005E6E",
  },
});

export const useStyle = (theme: Theme) =>
    createStyles({
        mainBudgetContainerBox:
        {

            display: "flex",
            flex: 1,
            height: "auto",
            boxSizing: "border-box",
            gap: "16px",

        },
        locationRoot: {
            flex: 1,
            flexShrink: 0,
            height: "calc(100vh - 80px)",
            marginBottom:'40px',
            borderRadius: "38px",
            background: "#FFF",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
            minHeight: '741px',
            '@media (max-width:960px)': {
                height:'unset',
            },
            '@media (max-width:500px)': {
                boxShadow: 'none',
                borderRadius: 'unset',
                height: 'unset',
                minHeight: 'unset',
            },
        },
        inContainer: {
            '@media (max-width:500px)': {
                marginLeft: '12px',
                marginRight: '18px',
            },
        },
        locationInnerRoot: {
            padding: "27px 164px 0px 41px",
            '@media (max-width:800px)': {
                paddingRight: '70px'
            },
            '@media (max-width:500px)': {
                padding: "0px 0px 0px 0px",
            },
            '@media (max-width:390px)': {
                padding: "0px 0px 0px 0px",
            },
        },
        typoLocation: {
            color: "#000",
            fontFamily: "Panton Narrow Bold !important",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 700,
            letterSpacing: "-0.12px",
            lineHeight: '32px',
            display: 'flex',
            height:'17px',
            marginTop:'-7px',
            marginBottom:'20px',
            "@media(max-width:500px)": {
                fontSize: "16px",
                lineHeight: '32px',
                marginLeft: '12px',
                fontFamily: "Panton Narrow Bold",
                fontWeight: 700,
                marginTop: '17px',
            }

        },
        sideContainerBox: {
            minHeight: "741px",
            height: "calc(100vh - 78px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            '@media (max-width:961px)': {
                height:'unset',
                minHeight: '400px',
            },
            
            "@media(max-width:500px)": {
                height: 'unset',
                minHeight: '400px',
                width:'100%',
                marginLeft:'7px'
            },
            "@media(max-width:390px)": {
                 width:'102.8%'
            },
            "@media(max-width:375px)": {
                width:'100%',
                marginLeft:'1px'

           },
        },
        innerSideContainerBox: {
          
            height: "calc(100vh - 126px)",
            "@media(max-width:1380px)": {
                
            // height: "calc(100vh - 110px)",
            // },
            
            // "@media(max-width:1020px)": {
                
                height: "calc(100vh - 30px)",
            },
            "@media (max-width: 1280px) and (max-height: 720px)": {
                height: "calc(100vh - 30px)",
            },
            "@media(min-width:1020px) and (min-height:720)": {
                
                height: "calc(100vh - 110px)",
            },
            "@media (max-width: 1366px) and (max-height: 786px)": {
                height: "calc(100vh - 80px)", // Adjusted to 110px
            },
            "@media(min-width:1900px)": {
               
                height: "calc(100vh - 126px)",
            },
            "@media(max-width:500px)": {
               
                height: "unset",
            },
            // "@media(min-width:1280px) and (max-height: 1024px)": {
               
            //     height: "unset",
            // },
            // "@media(min-width:1900px)": {
               
            //     height: "calc(100vh - 107px)",
            // },
            
            
        },
        locationButtonBox: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            
            "@media (max-width: 1440px)": {
               gap:"10px",
               
              },
            "@media(max-width:961px)": {
                marginTop: "40px",
                marginBottom:'36px',
            },
            "@media(max-width:500px)": {
                marginTop: "22px",
                marginBottom: '37px',
                justifyContent:'center',
                gap:'20px',
            },
            "@media(max-width:390px)": {
                gap:'15px',
            },
            "@media(max-width:375px)": {
                gap:'10px',
            }

        },
        locationDefaultBtn: {
            fontFamily: "Panton Narrow Bold",
            fontWeight: 700,
            textTransform: "capitalize" as const,
            fontSize: "16px",
            textDecoration: "none",
            cursor:'pointer',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: "176px",
            borderRadius: "43px",
            color: "#353232",
            backgroundColor: "#e0e0e0",
            height: "52px",
            "&:hover": { 
                backgroundColor: "#005E6E",
                color: "#FFFFFF",
            },
        },
        locationBtn: {
            width: "176px",
            borderRadius: "43px",
            color: "#353232",
            backgroundColor: "#e0e0e0",
            fontWeight: 700,
            fontFamily: "Panton Narrow Bold",
            height: "52px",
            fontSize: "16px",
            boxShadow: "none",
            textTransform: "capitalize" as const,
            "&:hover": { 
                backgroundColor: "#005E6E",
                color: "#FFFFFF",
                boxShadow: "none",
            },
            '@media(max-width:500px)':{
                     width:'176px'
                     },
                     '@media(max-width:390px)':{
                 width:'167px'
                   },
                      '@media(max-width:350px)':{
                        width:'143px'
                     }
        },
        headingImage: {
            marginLeft: "15px",
            width: '19px',
            position: 'relative',
            "@media(max-width:500px)": {
                width: '17px',
            },
        },
        inputContainer: {
            padding: "12.5px 4px 11.5px 20px",
            borderRadius: "12px",
            border: "1px solid #B4B4B4",
            marginTop: "40px",
            background: "#FFFFFF",
            width: "100%",
            height:'48px',
            fontFamily: 'panton narrow regular',
            color:'black'
        },
        mapStyle: {
            height: '384px',
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            border: "1px solid #B4B4B4",
            borderRadius: "12px",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
        },
        errorMessage: {
            color: 'red',
            fontFamily: "Panton narrow semibold",
            fontSize: "15px",
            lineHeight: "24px",
            fontWeight: 700,
            marginTop: "-15px"
        },
        menuItem: {
            fontSize: "16px",
            fontFamily: "Panton narrow regular",
            lineHeight: "24px",
            fontWeight: 700
        },
        selectTag: {
            padding: "12px 7px 12px 18px",
            borderRadius: "12px",
            border: "1px solid #B4B4B4",
            marginTop: "16px",
            background: "#FFFFFF",
            width: "100%",
            height: "52px",
        },
        mapContainerr: {
            padding: "44px 0px 0px 0px",
            width: '100%',
        },
        locationReadMoreText: {
            fontFamily: "Panton Narrow Bold",
            fontSize: "14px",
            fontWeight: 700,
            color: "#005E6E",
            cursor: 'pointer',
        },
        locationModalStyle: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            right: '50%',
            transform: 'translate(-50%, -50%)',
            width: '573px',
            height: 'auto',
            borderRadius: '12px',
            background: '#FFFFFF',
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
            
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            maxHeight: '80vh',
            '@media(max-width:500px)':{
                width: '290px',
                
                top: '52%',
                overflowY: 'auto',
                
              }
        },
        locationBodyText: {
            flex: 1,
            fontFamily: 'panton narrow regular',
            fontSize: '16px',
            lineHeight: '19.38px',
            fontWeight: 400,
          padding:'48px',
        },
        locationCancelImage: {
            cursor: 'pointer',
            width: '10.96px',
            height: '11px',
            marginLeft: '15px',
            position:"absolute",
            top:"18px",
            right:'18px',
            "@media(max-width:500px)":{
               top:"18px",
               right:'18px'
               }
        },
        locationModalStyles:{
            display: 'flex',
             justifyContent: 'space-between',
             "@media(max-width:500px)":{
              display: 'unset',
              justifyContent:'unset',
             }
          },
        locationCancelImageWrapper: {
            alignSelf: 'flex-start',
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'flex-end',
            "@media(max-width:500px)":{
                position:'absolute',
                top:18,
                marginRight:'0px',
                right:'18px',
              }
        },
        expandIcon : {
            "& .MuiSelect-icon": {
              right: "9px",
              color: "rgb(180, 180, 180)",
              cursor: "pointer",
              height:'24px',
              width:'24px'
            },
            
          },
        selectPlaceholder: {
            //   color: '#B4B4B4',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19.38px',
              marginLeft: '-1px'
            
          },
          selectPlaceholderActive: {
            color: 'black',
            fontFamily: 'panton narrow regular',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19.38px',
            marginLeft: '-1px'
          },
          dropRaduis:{
            borderRadius:'12px'
          }
         
    });


  
      const CustomPaper = styled(Paper)({
        borderRadius: '12px !important',
        
    });
    
    const MenuItmStyle=styled(MenuItem)({
        color:"#B4B4Be",
        "& :hover": {
            backgroundColor: "transparent",
            color: "#005E6E",
          },
        "&.MuiListItem-button:hover":{
            backgroundColor: "transparent",
            color: "#005E6E",
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "transparent",
            color: "#005E6E",
        },
       

    })
    
const webStyle = {
    subconinerr: {
        backgroundColor: "#FFFFFF",
        height: "auto",
        boxShadow: "0px 4px 16px 0px #00000040",
        padding: "33px 29.4px 24px 24px",
        borderRadius: "24px",
    },
    locationTextTitle: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Panton Narrow Regular",
        marginBottom: "19px",
        display: "flex",
        alignItems: "center",
    },
    bannerImageLocation: {
        width: "100%",
        height: '164px',
    },
    readMoreLocation: {
        fontFamily: "Panton Narrow Bold",
        fontSize: "14px",
        fontWeight: 700,
        color: "#005E6E",
    },
    imageOfLocation: {
        marginLeft: "15px",
        height: "13px",
        width: "13px"
    },
    textDescriptionLocation: {
        marginTop: "30px"
    },
  
    title: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Panton Narrow Regular",
        marginBottom: "20px",
        display: "flex",
        alignItems: "center",
        gap: '12px',
    },
    profileDetailsInputContainer: {
        borderRadius: "12px",
        border: "#B4B4B4",
        marginTop: "24px",
        background: "#FFFFFF",
        height:'48px',
        //padding:'5px '
    },
    Items: {
        // color: "#B4B4Be",
        fontFamily: "Panton Narrow Regular",
        fontSize: "16px",
        padding: "16px 20px",
        "& :hover": {
            backgroundColor: "transparent",
            color: "#005E6E",
          },
    },
    errorMsg: {
        color: '#005E6E',
        fontFamily: "panton Narrow Regular",
        fontWeight: 600
    },
}
export default withRouter(withStyles(useStyle)(Location as any) as any) as any;
