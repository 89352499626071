import React from 'react'
import { Switch,Typography } from "@material-ui/core";
import { makeStyles, createStyles } from '@material-ui/core/styles';


interface IToggle {
  toggleCampaignList:()=>void;
  text: string
}

function Toggle({toggleCampaignList, text}:IToggle) {
  const classes = useStyles();
  return (
   <div className={classes.toggleContainer}> 
    <Typography className={classes.toggleTxt} >{text}</Typography>
    <Switch
      data-test-id="toggle-campaign"
      className={classes.toggle}
      onChange={toggleCampaignList}
    />
   </div>
  )
}
const useStyles = makeStyles(theme=>createStyles({
  toggleContainer:{
    display:"flex",
    justifyContent:"space-evenly",
    alignItems:"center",
    gap: "10px"
    // width:"225px",
  },
  toggleTxt:{
    fontFamily:"Panton Narrow Light",
  },
  toggle:{
    width: "51px",
    height: "31.5px",
    padding:0,
    borderRadious:"20px",
    "& .Mui-checked":{
      transform: "translateX(20px)",
      color:'#fff',
    },
    "& .MuiSwitch-track":{
      borderRadius:"20px",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track":{
      background:'#005E6E',
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":{
      opacity:"1"
    },
    "&>span":{
      top: "-6.1px",
      left: "-7px",
      "&>span":{
        borderRadious:"20px",
        "&>span":{
          width: "26px",
          height: "26px",
          borderRadious:"20px", 
        }
      }
    }
}
}));

export default Toggle