Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions";
exports.labelBodyText = "RolesPermissions Body";

exports.btnExampleTitle = "CLICK ME";

exports.apiContentType = "application/json";
exports.userProfileEndPoint="/account_block/profile"
exports.userProfileRestMethod = "GET"

exports.logoutUser = "/bx_block_logout/user_logout"
exports.logoutUserRestMethod = "DELETE"

exports.deletecampaignsEndPoint="bx_block_custom_form/campaigns"



// Customizable Area End